import * as GQTypes from './graphql-operations';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export const ParquetDataFieldsFragmentDoc = gql`
    fragment ParquetDataFields on ParquetData {
  id
  numRecords
  ndjsonSignedUrl
  ndjsonSize
  ndjsonByteBoundaries
  columns {
    name
    sqlType
    minValue
    maxValue
    numNulls
    approxDistinctValues
    sampleValues
  }
}
    `;
export const ActivityDataExtractorFieldsFragmentDoc = gql`
    fragment ActivityDataExtractorFields on ActivityDataExtractor {
  id
  createdAt
  kind
  user {
    id
    name
  }
  sql
  userUploadedTables {
    id
    parentFileMetadata {
      id
      name
      category
    }
  }
  warnings
  error
  published
  generated
  runSqlOutput {
    ...ParquetDataFields
  }
  transformedOutput {
    ...ParquetDataFields
  }
  cleanAdtOutput {
    ...ParquetDataFields
  }
  duckdbVersion
  bartActivityType
  generated
  facilitiesSurveyStatus
}
    ${ParquetDataFieldsFragmentDoc}`;
export const PermissionFieldsFragmentDoc = gql`
    fragment PermissionFields on PermissionItem {
  id
  objectId
  permission
  object {
    __typename
    id
    name
  }
  revokedAt
}
    `;
export const RoleInfoFragmentDoc = gql`
    fragment RoleInfo on Role {
  id
  name
  description
  permissions {
    ...PermissionFields
  }
}
    ${PermissionFieldsFragmentDoc}`;
export const UserRoleFieldsFragmentDoc = gql`
    fragment UserRoleFields on UserRoleAssignment {
  id
  revokedAt
  role {
    id
    ...RoleInfo
  }
}
    ${RoleInfoFragmentDoc}`;
export const AssigneeForEditDataIssuesPageFieldsFragmentDoc = gql`
    fragment AssigneeForEditDataIssuesPageFields on User {
  id
  name
  permissions(orgId: $orgId, includeDeletedOrgAccess: true) {
    ...PermissionFields
  }
  roles(orgId: $orgId, includeDeletedOrgAccess: true) {
    ...UserRoleFields
  }
}
    ${PermissionFieldsFragmentDoc}
${UserRoleFieldsFragmentDoc}`;
export const UserUploadTaskForAssigneePermissionCheckFragmentDoc = gql`
    fragment UserUploadTaskForAssigneePermissionCheck on UserUploadTask {
  id
  assignee {
    ...AssigneeForEditDataIssuesPageFields
  }
  datasource {
    id
    name
    dataset {
      id
      name
    }
  }
}
    ${AssigneeForEditDataIssuesPageFieldsFragmentDoc}`;
export const CommentForCommentDisplayFieldsFragmentDoc = gql`
    fragment CommentForCommentDisplayFields on Comment {
  id
  person {
    id
    name
    isWatershedEmployee
    isWatershedContractor
  }
  message
  createdAt
  isPublished
}
    `;
export const DataIssueLinkedObjectFieldsFragmentDoc = gql`
    fragment DataIssueLinkedObjectFields on DataIssueLinkedObject {
  __typename
  ... on UserUploadTask {
    ...UserUploadTaskForAssigneePermissionCheck
  }
  ... on FinancialsReviewItem {
    id
  }
  ... on ValueMappingTask {
    id
  }
}
    ${UserUploadTaskForAssigneePermissionCheckFragmentDoc}`;
export const DataIssueForEditDataIssuesPageFieldsFragmentDoc = gql`
    fragment DataIssueForEditDataIssuesPageFields on DataIssue {
  __typename
  id
  title
  description
  state
  isPublished
  isCustomerInitiated
  createdAt
  tags
  assignee {
    ...AssigneeForEditDataIssuesPageFields
  }
  comments {
    edges {
      node {
        ...CommentForCommentDisplayFields
      }
    }
  }
  files {
    edges {
      node {
        id
      }
    }
  }
  linkedObject {
    ...DataIssueLinkedObjectFields
  }
}
    ${AssigneeForEditDataIssuesPageFieldsFragmentDoc}
${CommentForCommentDisplayFieldsFragmentDoc}
${DataIssueLinkedObjectFieldsFragmentDoc}`;
export const AdtEditorDatasourceFragmentDoc = gql`
    fragment AdtEditorDatasource on Datasource {
  id
  name
  dataset {
    id
    name
  }
  userUploadTasks {
    edges {
      node {
        ...UserUploadTaskForAssigneePermissionCheck
        issues {
          edges {
            node {
              ...DataIssueForEditDataIssuesPageFields
            }
          }
        }
        userUploads(categories: [User, BuildingSurvey, Integration]) {
          edges {
            node {
              id
              allAttempts {
                id
                description
                userUploadedTables {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${UserUploadTaskForAssigneePermissionCheckFragmentDoc}
${DataIssueForEditDataIssuesPageFieldsFragmentDoc}`;
export const ActivityDataTableFieldsForEditorFragmentDoc = gql`
    fragment ActivityDataTableFieldsForEditor on ActivityDataTable {
  id
  name
  filterInterval
  publishedExtractor {
    ...ActivityDataExtractorFields
  }
  draftBartExtractor {
    ...ActivityDataExtractorFields
  }
  draftCadtExtractor {
    ...ActivityDataExtractorFields
  }
  draftScratchpadExtractor {
    ...ActivityDataExtractorFields
  }
  latestBartActivityType
  datasources {
    ...AdtEditorDatasource
  }
  datasets {
    id
    name
  }
  primaryDatasource {
    ...AdtEditorDatasource
  }
  recommendedActivityTypes
  connectedToAutoBart
  autoBartKind
  autoBartActivityType
  measurementProject {
    id
  }
}
    ${ActivityDataExtractorFieldsFragmentDoc}
${AdtEditorDatasourceFragmentDoc}`;
export const OrganizationForAdtEditorFragmentDoc = gql`
    fragment OrganizationForAdtEditor on Organization {
  id
  name
  demoOrg
  testOrg
}
    `;
export const UserUploadedTablesByIdFieldsFragmentDoc = gql`
    fragment UserUploadedTablesByIdFields on UserUploadedTable {
  id
  sheetName
  sqlTableName
  shouldIgnore
  numRows
  gcloudStorageUrl
  sourceFileDeleted
  userReviewMetadata {
    columnMappings {
      watershedColumnName
      sourceFileColumnName
    }
    currencyOverride {
      selectedCurrency
    }
    buildingAreaUnitOverride
  }
  userIngestionReview {
    id
    ingestionSchemaId
  }
  parentFileMetadata {
    id
    name
    notesForWatershed: description
    category
  }
}
    `;
export const BuildingSurveyStatusForAdtEditorFragmentDoc = gql`
    fragment BuildingSurveyStatusForAdtEditor on BuildingSurveyStatus {
  latestSnapshotUut {
    id
  }
  latestSnapshotUpToDate
  latestSnapshotIsEmptyFile
  numChangedBuildingsSinceUutGenerated
  measurementProjectIntervalMatches
  numAddedBuildingsSinceUutGenerated
  numDeletedBuildingsSinceUutGenerated
  bartActivityType
  numOmittedPartialBuildings
  latestSnapshotIsEmptyFile
}
    `;
export const OrgFieldsForAdminNavFragmentDoc = gql`
    fragment OrgFieldsForAdminNav on Organization {
  id
  name
  hasUsers
  demoOrg
  testOrg
  watershedPlanLegacy
}
    `;
export const UserUploadFieldsForActionsFragmentDoc = gql`
    fragment UserUploadFieldsForActions on UserUpload {
  id
  name
  latestAttempt {
    id
    userUploadedTables {
      id
      shouldIgnore
    }
  }
}
    `;
export const AddSupplierPointOfContactDialog_MarketplacePointOfContactFragmentDoc = gql`
    fragment AddSupplierPointOfContactDialog_MarketplacePointOfContact on MarketplacePointOfContact {
  id
  name
  email
}
    `;
export const MarketplacePurchaseFieldsForDocumentsTableCardFragmentDoc = gql`
    fragment MarketplacePurchaseFieldsForDocumentsTableCard on MarketplacePurchase {
  id
  org {
    id
  }
  lineItems {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;
export const MarketplacePurchaseLineItemFieldsForDocumentsTableCardFragmentDoc = gql`
    fragment MarketplacePurchaseLineItemFieldsForDocumentsTableCard on MarketplacePurchaseLineItem {
  id
  name
  org {
    id
  }
}
    `;
export const MarketplaceProjectArchetypesForLineItemDialogFragmentDoc = gql`
    fragment MarketplaceProjectArchetypesForLineItemDialog on MarketplaceProjectArchetype {
  id
  name
  mechanism
  unit
}
    `;
export const AdminMeasurementTaskObjectDatasetFieldsFragmentDoc = gql`
    fragment AdminMeasurementTaskObjectDatasetFields on Dataset {
  id
  name
  canonicalDataset {
    id
    kind
  }
  datasources {
    id
    name
  }
}
    `;
export const AdminDatasetForMeasurementPageFragmentDoc = gql`
    fragment AdminDatasetForMeasurementPage on Dataset {
  id
  name
  canonicalDataset {
    id
    kind
  }
}
    `;
export const MeasurementProjectsForMeasurementProjectSelectorFragmentDoc = gql`
    fragment MeasurementProjectsForMeasurementProjectSelector on MeasurementProject {
  id
  name
  coverageInterval
  active
}
    `;
export const FileMetadataFieldsForOrgFilesDialogFragmentDoc = gql`
    fragment FileMetadataFieldsForOrgFilesDialog on FileMetadata {
  id
  name
  description
  directory
  size
  createdAt
  gcloudStorageUrl
  revisionRoot
  deletedAt
  uploader {
    id
    name
    email
  }
}
    `;
export const PipelineResultForCreateFootprintSnapshotDialogFragmentDoc = gql`
    fragment PipelineResultForCreateFootprintSnapshotDialog on PipelineResult {
  id
  name
  pipelineKind
  orgId
  createdAt
  coverageInterval
  runUserId
  pipelineRunConfig
  deletedAt
  summary {
    format
    emissions {
      month
      kgCo2e
      businessCategory
      adtName
      footprintKind
    }
    warnings {
      blocking
      message
    }
  }
}
    `;
export const PipelineRunForCreateFootprintDialogFragmentDoc = gql`
    fragment PipelineRunForCreateFootprintDialog on PipelineRun {
  id
  name
  createdAt
  pipelineResults {
    ...PipelineResultForCreateFootprintSnapshotDialog
  }
}
    ${PipelineResultForCreateFootprintSnapshotDialogFragmentDoc}`;
export const FootprintForCreateFootprintSnapshotDialogFragmentDoc = gql`
    fragment FootprintForCreateFootprintSnapshotDialog on Footprint {
  id
  name
  locked
}
    `;
export const AdtFieldsForRunPipelineDialogFragmentDoc = gql`
    fragment AdtFieldsForRunPipelineDialog on ActivityDataTable {
  id
  lastRefreshedAt
  name
  publishedExtractor {
    id
    createdAt
    kind
    facilitiesSurveyStatus
    bartId
  }
  draftCadtExtractor {
    id
    createdAt
  }
  draftBartExtractor {
    id
    createdAt
  }
  latestBartActivityType
  filterInterval
  stage
}
    `;
export const GetFootprintSnapshotDetailsSnapshotFragmentDoc = gql`
    fragment GetFootprintSnapshotDetailsSnapshot on FootprintSnapshot {
  id
  summary {
    emissions {
      month
      businessCategory
      pipeline
      kgCo2e
      footprintKind
    }
  }
  marketplaceAllocationInstructionIds
  tags {
    id
    tagName
  }
  categoryRules {
    id
    stableId
  }
  exclusionRules {
    id
    stableId
  }
}
    `;
export const DataIssueForOrgInboxFieldsFragmentDoc = gql`
    fragment DataIssueForOrgInboxFields on DataIssue {
  ...DataIssueForEditDataIssuesPageFields
  createdAt
}
    ${DataIssueForEditDataIssuesPageFieldsFragmentDoc}`;
export const InboxItemFieldsFragmentDoc = gql`
    fragment InboxItemFields on OrganizationInboxItem {
  __typename
  ... on DataIssue {
    ...DataIssueForOrgInboxFields
  }
  ... on UserUploadTask {
    id
    createdAt
    datasource {
      id
      name
    }
    measurementProject {
      id
      name
    }
    taskState: state
    userUploads(excludeProcessingModes: [IdiRawFile, NoProcessing]) {
      edges {
        node {
          id
          name
          status
          processingMode
          userVisibleAttempt {
            id
            createdAt
            gcloudStorageUrl
            size
            userUploadedTables {
              id
            }
          }
          latestAttempt {
            id
            userUploadedTables {
              id
              shouldIgnore
            }
          }
        }
      }
    }
  }
}
    ${DataIssueForOrgInboxFieldsFragmentDoc}`;
export const SharedFileForFilesTableFragmentDoc = gql`
    fragment SharedFileForFilesTable on SharedFile {
  id
  createdAt
  expiresAt
  user {
    id
    name
  }
  watershedEmployee {
    id
    name
  }
  fileMetadata {
    id
    name
    deletedAt
  }
  datasource {
    id
    name
    dataset {
      id
      name
    }
  }
  measurementProject {
    id
    name
  }
}
    `;
export const SharedFileRequestForTableFragmentDoc = gql`
    fragment SharedFileRequestForTable on SharedFileRequest {
  id
  createdAt
  user {
    id
    name
  }
  fulfilledBy {
    id
    name
  }
  fulfilledAt
  fileMetadata {
    id
    name
    userUploadTask {
      id
      measurementProject {
        id
        name
      }
    }
  }
  datasource {
    id
    name
    dataset {
      id
      name
    }
  }
}
    `;
export const AdminUserFieldsForOrgPageFragmentDoc = gql`
    fragment AdminUserFieldsForOrgPage on User {
  id
  name
  displayName
  isWatershedEmployee
  email
  loginActivated
  consecutiveLoginFailures
  didLatestEmailBounce
  orgAccessId(orgId: $id)
}
    `;
export const AdminUsersForOrgPageFragmentDoc = gql`
    fragment AdminUsersForOrgPage on Organization {
  __typename
  adminUsers: users(filters: {isAdmin: true, includeWatershedEmployees: true}) {
    edges {
      node {
        ...AdminUserFieldsForOrgPage
      }
    }
  }
}
    ${AdminUserFieldsForOrgPageFragmentDoc}`;
export const WatershedEmployeeFieldsForOrgPageFragmentDoc = gql`
    fragment WatershedEmployeeFieldsForOrgPage on WatershedEmployee {
  __typename
  id
  name
  email
}
    `;
export const OrgPointOfContactFieldsForOrgPageFragmentDoc = gql`
    fragment OrgPointOfContactFieldsForOrgPage on OrgPointOfContact {
  __typename
  id
  kind
  watershedEmployee {
    ...WatershedEmployeeFieldsForOrgPage
  }
  createdAt
  deletedAt
}
    ${WatershedEmployeeFieldsForOrgPageFragmentDoc}`;
export const OrganizationFieldsForOrgPageFragmentDoc = gql`
    fragment OrganizationFieldsForOrgPage on Organization {
  __typename
  id
  name
  bigqueryDatasetName
  legalName
  helpChannel
  domains
  passwordAuthDisabled
  notionUrl
  magicLinkDisabled
  demoOrg
  region
  isWatershed
  watershedPlan
  watershedPlanLegacy
  workosOrgId
  fiscalYearStartMonth
  currency
  iconRemotePath
  logoRemotePath
  country
  productWaitlistTypes
  lifecycle {
    nextStage
    completedStage
    kickoffBlurb
  }
  pointsOfContact {
    ...OrgPointOfContactFieldsForOrgPage
  }
  pointsOfContactIncludingDeleted {
    ...OrgPointOfContactFieldsForOrgPage
  }
  apiKeys {
    id
    secret
  }
  workosConnections {
    id
    name
    connectionType
  }
  company {
    id
    name
  }
  canAccessCorporate
  canAccessFinance
  testOrg
  stagingOrg
  watershedSlackChannelId
  deletedAt
  profile {
    id
    name
    fiscalYearStartMonth
    country
    industry
    isCompanyDataConfirmed
    hasMeasuredFootprintOutsideOfWatershed
    lastNonWatershedMeasurementYearString
    hasClimateCommitments
    climateCommitments
    otherClimateCommitment
    climateGoals
    climateMotivation
    completedAt
    completedBy {
      id
      name
    }
    updatedAt
    updatedBy {
      id
      name
    }
  }
}
    ${OrgPointOfContactFieldsForOrgPageFragmentDoc}`;
export const UserTaskPermissionsFragmentDoc = gql`
    fragment UserTaskPermissions on UserUploadTask {
  id
  measurementProject {
    id
    name
    active
  }
  datasource {
    id
    name
    dataset {
      id
      name
    }
  }
}
    `;
export const UserFieldsForOrgPageFragmentDoc = gql`
    fragment UserFieldsForOrgPage on User {
  id
  name
  displayName
  isWatershedEmployee
  email
  loginActivated
  signupLink(orgId: $orgId)
  orgAccessId(orgId: $orgId)
  orgAccessCreatedAt(orgId: $orgId)
  consecutiveLoginFailures
  userUploadTasks {
    ...UserTaskPermissions
  }
  roles(orgId: $orgId) {
    ...UserRoleFields
  }
  permissions(orgId: $orgId) {
    id
    ...PermissionFields
  }
  didLatestEmailBounce
  preferredLocale
}
    ${UserTaskPermissionsFragmentDoc}
${UserRoleFieldsFragmentDoc}
${PermissionFieldsFragmentDoc}`;
export const UserUploadedTableDataPreviewFieldsFragmentDoc = gql`
    fragment UserUploadedTableDataPreviewFields on UserUploadedTable {
  id
  sqlTableName
  shouldIgnore
  parseConfig {
    noHeader
    skipTopNRows
    skipBottomNRows
    meltIdVars
  }
  dataPreview {
    schema {
      fields {
        name
        type
      }
    }
    dataUntyped
  }
}
    `;
export const SerializableErrorFieldsFragmentDoc = gql`
    fragment SerializableErrorFields on SerializableError {
  message
  code
  stackTrace
  errorType
  details
}
    `;
export const PlaygroundSqlRunForPlaygroundFragmentDoc = gql`
    fragment PlaygroundSqlRunForPlayground on PlaygroundSqlRun {
  id
  createdAt
  state
  user {
    id
    name
  }
  error
  jobError {
    ...SerializableErrorFields
  }
  sql
  output {
    ...ParquetDataFields
  }
}
    ${SerializableErrorFieldsFragmentDoc}
${ParquetDataFieldsFragmentDoc}`;
export const ReferenceDataCitationFieldsFragmentDoc = gql`
    fragment ReferenceDataCitationFields on ReferenceDataCitation {
  id
  displayName
  slug
  internalSourceUrl
  internalSourceNotes
  externalSourceUrl
  externalSourceNotes
  hasLicenseRestrictions
  publicDisclosureId
  privateDisclosureId
  isCustomerProvided
  isSupplierSpecific
  createdAt
  updatedAt
  archivedAt
  isArchived
  orgId
  author {
    id
    name
  }
  org {
    id
    name
  }
}
    `;
export const DatasourceForShareFilesFragmentDoc = gql`
    fragment DatasourceForShareFiles on Datasource {
  id
  name
  dataset {
    id
    name
  }
}
    `;
export const UserUploadForShareFilesFragmentDoc = gql`
    fragment UserUploadForShareFiles on UserUpload {
  id
  name
  deletedAt
}
    `;
export const MeasurementProjectForShareFilesFragmentDoc = gql`
    fragment MeasurementProjectForShareFiles on MeasurementProject {
  id
  name
  coverageStartDate
  userUploadTasks {
    edges {
      node {
        id
        datasource {
          ...DatasourceForShareFiles
        }
        userUploads(includeDeleted: true) {
          edges {
            node {
              ...UserUploadForShareFiles
            }
          }
        }
      }
    }
  }
}
    ${DatasourceForShareFilesFragmentDoc}
${UserUploadForShareFilesFragmentDoc}`;
export const SupplierColumnFieldsAdminFragmentDoc = gql`
    fragment SupplierColumnFieldsAdmin on SupplierTableColumn {
  id
  field
  headerName
}
    `;
export const TargetForTargetsFormFragmentDoc = gql`
    fragment TargetForTargetsForm on Target {
  id
  title
  descriptor
  kind
  unit
  targetAmount
}
    `;
export const OrganizationForTargetsFormFragmentDoc = gql`
    fragment OrganizationForTargetsForm on Organization {
  id
  targets {
    edges {
      node {
        ...TargetForTargetsForm
      }
    }
  }
}
    ${TargetForTargetsFormFragmentDoc}`;
export const UserUploadedTableForAdtSelectorDialogFragmentDoc = gql`
    fragment UserUploadedTableForAdtSelectorDialog on UserUploadedTable {
  __typename
  id
  error
  errorType
  sqlTableName
  status
  shouldIgnore
  isUsingLatestUIR
  integrationDataPull {
    id
    integrationConnection {
      id
    }
  }
  parentFileMetadata {
    id
    category
    userUploadTask {
      id
      state
    }
  }
}
    `;
export const UserUploadAttemptForAdtSelectorDialogFragmentDoc = gql`
    fragment UserUploadAttemptForAdtSelectorDialog on UserUploadAttempt {
  __typename
  id
  name
  createdAt
  size
  uploader {
    id
    name
    email
  }
  gcloudStorageUrl
  deletedAt
  userUploadedTables {
    ...UserUploadedTableForAdtSelectorDialog
  }
}
    ${UserUploadedTableForAdtSelectorDialogFragmentDoc}`;
export const UserUploadForAdtSelectorDialogFragmentDoc = gql`
    fragment UserUploadForAdtSelectorDialog on UserUpload {
  __typename
  id
  name
  userUploadTask {
    id
    datasource {
      id
      name
    }
    measurementProject {
      id
      name
    }
  }
  userVisibleAttempt {
    id
    description
  }
  allAttempts {
    ...UserUploadAttemptForAdtSelectorDialog
  }
}
    ${UserUploadAttemptForAdtSelectorDialogFragmentDoc}`;
export const BusinessActivityTypeSettingsSchemaFragmentDoc = gql`
    fragment BusinessActivityTypeSettingsSchema on BusinessActivityTypeSettings {
  id
  businessActivityTypeName
  dataReadWriteMode
  schemaActivationMode
  latestTypeInstance {
    id
    versionId
    versionName
  }
}
    `;
export const CanonicalClimateProgramProjectRequirementGhgpFieldsFragmentDoc = gql`
    fragment CanonicalClimateProgramProjectRequirementGhgpFields on CanonicalClimateProgramProjectRequirementGhgp {
  id
  ghgScope
  ghgScope3Category
  minimumFidelity
  additionalNotesMd
}
    `;
export const CompanyRelationshipsCardFragmentDoc = gql`
    fragment CompanyRelationshipsCard on Company {
  id
  name
  isDemo
  companyRelationships {
    id
    companyA {
      id
      name
    }
    companyB {
      id
      name
    }
    relationshipType
    relationshipStartDate
    relationshipEndDate
  }
}
    `;
export const EngagementTaskAllFieldsFragmentDoc = gql`
    fragment EngagementTaskAllFields on EngagementTask {
  id
  companyId
  rootCompanyId
  approvedAt
  createdAt
  crossOrgModelId
  deletedAt
  dueDate
  expiresAt
  id
  initialSupplierAssigneeEmail
  latestCeeWorkflowId
  proposeTargetIntensityType
  reportingYear
  rootAssignee
  status
  statusChangedAt
  submittedAt
  supplierAssignee
  supplierFacingDescription
  supplierOrgId
  surveyKind
  watershedGeneric
}
    `;
export const SharedMeasurementProjectAllFieldsFragmentDoc = gql`
    fragment SharedMeasurementProjectAllFields on SharedMeasurementProject {
  id
  measurementProjectId
  orgId
}
    `;
export const CompanyClimateCommitmentRawAllFieldsFragmentDoc = gql`
    fragment CompanyClimateCommitmentRawAllFields on CompanyClimateCommitmentRaw {
  commitmentMadeDate
  commitmentPeriodEnd
  commitmentPeriodStart
  createdAt
  deletedAt
  description
  publicDisclosureId
  externalUrl
  id
  kind
  orgId
  privateDisclosureId
  sbtBaselineYear
  sbtCommitmentStage
  sbtCommitmentType
  sbtCommitmentDeadline
  sbtLongTermTargetYear
  sbtNearTermTargetYear
  sbtNetZeroTargetYear
  sbtNetZeroCommitted
  sbtTargetClassification
  targetPercentageCleanEnergy
  targetYear
  updatedAt
  userEditorId
  watershedEditorId
}
    `;
export const FilterExpressionGroupAllFieldsFragmentDoc = gql`
    fragment FilterExpressionGroupAllFields on FilterExpressionGroup {
  conjunction
  expressions {
    field
    operator
    value
  }
}
    `;
export const DisclosureTargetDetailsAllFieldsFragmentDoc = gql`
    fragment DisclosureTargetDetailsAllFields on DisclosureTargetDetails {
  id
  name
  description
  baseYear
  emissionsType
  reductionRate
  intensityType
  publicDisclosureId
  privateDisclosureId
  companyClimateCommitmentId
  createdAt
  deletedAt
  emissionsTargetId
  filters {
    ...FilterExpressionGroupAllFields
  }
  orgId
  updatedAt
  unit
  unitDescription
}
    ${FilterExpressionGroupAllFieldsFragmentDoc}`;
export const DisclosureInitiativeAllFieldsFragmentDoc = gql`
    fragment DisclosureInitiativeAllFields on DisclosureInitiative {
  createdAt
  description
  endYearMonth
  filters {
    ...FilterExpressionGroupAllFields
  }
  id
  initiativeType
  name
  orgId
  privateDisclosureId
  startYearMonth
  updatedAt
  startDate
  endDate
  emissionsTimeseriesId
  intensityType
}
    ${FilterExpressionGroupAllFieldsFragmentDoc}`;
export const PrivateDisclosureAllFieldsFragmentDoc = gql`
    fragment PrivateDisclosureAllFields on PrivateDisclosure {
  id
  companyId
  assetCorporateId
  assetPersonalMotorVehicleInsuranceId
  orgId
  footprintDisclosureId
  surveyId
  privateDisclosureType
  createdAt
  disclosureQualityScore
  disclosureQualityExplanation
  thirdPartyVerified
  updatedAt
  userEditorId
  watershedEditorId
  engagementTaskId
  sourceId
  companyIngestionRecordUpdateId
  recordStartAt
  recordEndAt
}
    `;
export const CompanyNameFieldsFragmentDoc = gql`
    fragment CompanyNameFields on CompanyName {
  id
  name
  cleanedName
  isPrimary
  sourceId
}
    `;
export const PublicDisclosureForAdminEditFragmentDoc = gql`
    fragment PublicDisclosureForAdminEdit on PublicDisclosure {
  id
  reportType
  companyId
  publishingYear
  reportingYears
  publicUrl
  qualityScore
  cdpVendorData {
    id
    publishingYear
    reportingYear
    scope1Nonzero
    scope2Nonzero
    scope3Nonzero
    totalEmissionsNonzero
    scope301Or302Nonzero
    scope1Verified
    scope2Verified
    scope3Verified
    pctEvaluationStatusesMatchResponse
    disclosureQualityScore
    scope301EvaluationStatus
    scope302EvaluationStatus
    scope303EvaluationStatus
    scope304EvaluationStatus
    scope305EvaluationStatus
    scope306EvaluationStatus
    scope307EvaluationStatus
    scope308EvaluationStatus
    scope309EvaluationStatus
    scope310EvaluationStatus
    scope311EvaluationStatus
    scope312EvaluationStatus
    scope313EvaluationStatus
    scope314EvaluationStatus
    scope315EvaluationStatus
    scope316EvaluationStatus
    scope317EvaluationStatus
  }
  externalScore
  gsUrl
  source
  recordEndAt
}
    `;
export const EmissionsYearAllFieldsFragmentDoc = gql`
    fragment EmissionsYearAllFields on EmissionsYear {
  id
  createdAt
  publicDisclosureId
  privateDisclosureId
  footprintEstimateOutputId
  buildingEstimateOutputId
  emissionsYearSource
  assetCorporateId
  expenseCategory
  reportingYear
  numEmployees
  revenue
  revenueCurrency
  currencyConversionRate
  scope1
  scope2
  scope2Market
  scope2Location
  scope3
  scope301
  scope302
  scope303
  scope304
  scope305
  scope306
  scope307
  scope308
  scope309
  scope310
  scope311
  scope312
  scope313
  scope314
  scope315
  scope316
  scope317
  units
  percentageCleanEnergy
  totalMwh
  orgId
  scenario
  updatedAt
}
    `;
export const CompanyClimateCommitmentFieldsFragmentDoc = gql`
    fragment CompanyClimateCommitmentFields on CompanyClimateCommitment {
  __typename
  id
  kind
  description
  externalUrl
  commitmentMadeDate
  commitmentPeriodStart
  commitmentPeriodEnd
  ... on ScienceBasedTargetCommitment {
    submittedToSBTi
    commitment {
      id
      nearTermTargetYear
      longTermTargetYear
      baselineYear
      stage
      targetClassification
      netZeroTargetYear
      commitmentDeadline
      commitmentType
      netZeroCommitted
    }
  }
  ... on NetZeroCommitment {
    id
    targetYear
  }
  ... on CarbonNeutralCommitment {
    id
    targetYear
  }
  ... on CleanEnergyCommitment {
    id
    targetYear
    targetPercentageCleanEnergy
  }
}
    `;
export const CompanyUrlFieldsFragmentDoc = gql`
    fragment CompanyUrlFields on CompanyUrl {
  id
  url
  source {
    id
    sourceKey
  }
  sourceId
}
    `;
export const CompanySourceKeyFieldsFragmentDoc = gql`
    fragment CompanySourceKeyFields on CompanySourceKey {
  id
  sourceKey
  sourceUrl
  sourceNotes
  priority
  userVisibleSourceUrl
  userVisibleSourceNotes
  createdAt
  updatedAt
  editor {
    id
    name
  }
}
    `;
export const CompanyFieldsFragmentDoc = gql`
    fragment CompanyFields on Company {
  id
  name
  names {
    ...CompanyNameFields
  }
  isins {
    id
    isin
    sourceId
  }
  beas {
    id
    bea
    sourceId
  }
  leis {
    id
    lei
    sourceId
    isPrimary
  }
  duns {
    id
    dunsNumber
    sourceId
    isPrimary
  }
  eins {
    id
    ein
    sourceId
    isPrimary
  }
  vats {
    id
    vatId
    sourceId
    isPrimary
    countryAlpha2
  }
  localRegistries {
    id
    localRegistryId
    sourceId
    isPrimary
    countryAlpha2
  }
  tickerSymbols {
    id
    tickerSymbol
    exchangeSymbol
    isSourcePrimary
    sourceId
  }
  sAndP {
    id
    sAndPId
    sourceId
  }
  countries {
    id
    countryAlpha2
    isPrimary
    sourceId
  }
  disclosures(opts: {historicalYearFilters: {}, climateCommitmentFilters: {}}) {
    id
    publicDisclosure {
      ...PublicDisclosureForAdminEdit
    }
    historicalEmissionsYears {
      ...EmissionsYearAllFields
    }
    climateCommitments {
      ...CompanyClimateCommitmentFields
    }
  }
  description
  logo {
    ...CompanyUrlFields
  }
  companyWebsite {
    ...CompanyUrlFields
  }
  sustainabilityWebsite {
    ...CompanyUrlFields
  }
  isDemo
  editor {
    id
    name
  }
  sourceKey {
    ...CompanySourceKeyFields
  }
  replacedBy {
    __typename
    ... on Company {
      id
      name
    }
  }
  createdAt
  updatedAt
  isHidden
}
    ${CompanyNameFieldsFragmentDoc}
${PublicDisclosureForAdminEditFragmentDoc}
${EmissionsYearAllFieldsFragmentDoc}
${CompanyClimateCommitmentFieldsFragmentDoc}
${CompanyUrlFieldsFragmentDoc}
${CompanySourceKeyFieldsFragmentDoc}`;
export const CompanyListFieldsFragmentDoc = gql`
    fragment CompanyListFields on Company {
  id
  name
  description
  url
  fullLogoUrl
  naicsCode
  naicsCodeSource
  countryAlpha2
  sfdcOpportunityId
  sfdcStage
  isDemo
  sustainabilityWebsiteUrl
}
    `;
export const CompanyChangeRequestFieldsFragmentDoc = gql`
    fragment CompanyChangeRequestFields on CompanyChangeRequest {
  id
  org {
    id
    name
    demoOrg
  }
  action
  deletedAt
  payload
  userEditor {
    id
    name
  }
  createdAt
}
    `;
export const CompanyIngestionDiffFieldsFragmentDoc = gql`
    fragment CompanyIngestionDiffFields on CompanyIngestionDiff {
  id
  primaryCompanyName
  sourceRecord {
    __typename
    ... on CdpVendorData {
      id
      publishingYear
      reportingYear
      scope1Nonzero
      scope2Nonzero
      scope3Nonzero
      totalEmissionsNonzero
      scope301Or302Nonzero
      scope1Verified
      scope2Verified
      scope3Verified
      pctEvaluationStatusesMatchResponse
      disclosureQualityScore
      scope301EvaluationStatus
      scope302EvaluationStatus
      scope303EvaluationStatus
      scope304EvaluationStatus
      scope305EvaluationStatus
      scope306EvaluationStatus
      scope307EvaluationStatus
      scope308EvaluationStatus
      scope309EvaluationStatus
      scope310EvaluationStatus
      scope311EvaluationStatus
      scope312EvaluationStatus
      scope313EvaluationStatus
      scope314EvaluationStatus
      scope315EvaluationStatus
      scope316EvaluationStatus
      scope317EvaluationStatus
    }
    ... on SbtTarget {
      id
    }
    ... on Company {
      ...CompanyListFields
    }
    ... on CompanyChangeRequest {
      ...CompanyChangeRequestFields
    }
    ... on IngestedSbtiCompanyUpdate {
      id
    }
  }
  company {
    id
    name
    names {
      ...CompanyNameFields
    }
  }
  companyMatchScore
  companyMatchResults
  processedAt
  createdAt
  updatedAt
  isCompanyDemo
}
    ${CompanyListFieldsFragmentDoc}
${CompanyChangeRequestFieldsFragmentDoc}
${CompanyNameFieldsFragmentDoc}`;
export const CompanyIngestionRecordUpdateFieldsFragmentDoc = gql`
    fragment CompanyIngestionRecordUpdateFields on CompanyIngestionRecordUpdate {
  id
  diff {
    ...CompanyIngestionDiffFields
  }
  tableName
  oldValue
  newValue
  status
  createdAt
  updatedAt
}
    ${CompanyIngestionDiffFieldsFragmentDoc}`;
export const SbtTargetFieldsFragmentDoc = gql`
    fragment SbtTargetFields on SbtTarget {
  id
  companyName
  isin
  lei
  location
  region
  sector
  organizationType
  action
  commitmentType
  commitmentDeadline
  status
  reasonForCommitmentExtensionOrRemoval
  fullTargetLanguage
  companyTemperatureAlignment
  target
  targetWording
  scope
  targetValue
  targetType
  targetSubtype
  targetClassification
  baseYear
  targetYear
  yearType
  datePublished
  createdAt
  updatedAt
  dt
}
    `;
export const IngestionExampleDataFieldsFragmentDoc = gql`
    fragment IngestionExampleDataFields on IngestionExampleData {
  id
  title
  columns {
    fieldName
    title
    description
    examples
    required
    optionalToMap
    defaultValue
  }
  rows {
    fields {
      fieldName
      value
    }
  }
  datasetId
}
    `;
export const CtsTransformParquetDataFragmentDoc = gql`
    fragment CtsTransformParquetData on ParquetData {
  id
  numRecords
  ndjsonSignedUrl
  ndjsonSize
  ndjsonByteBoundaries
  columns {
    name
    sqlType
    minValue
    maxValue
    numNulls
    approxDistinctValues
    sampleValues
  }
}
    `;
export const CtsTransformRunFieldsFragmentDoc = gql`
    fragment CtsTransformRunFields on CustomerTargetSchemaTransformRun {
  id
  sql
  transform {
    id
    transformTarget
    businessActivityType {
      id
      name
    }
  }
  input {
    id
    name
    category
    revisionRootFileMetadata {
      id
      name
    }
  }
  output {
    ...CtsTransformParquetData
  }
  standardizedOutput {
    ...CtsTransformParquetData
  }
  warnings {
    message
    blocking
  }
  error
  createdAt
  runner {
    id
    name
  }
}
    ${CtsTransformParquetDataFragmentDoc}`;
export const CtsTransformForEditorFragmentDoc = gql`
    fragment CtsTransformForEditor on CustomerTargetSchemaTransform {
  id
  deletedAt
  sql
  transformTarget
  businessActivityType {
    id
    name
  }
  runs {
    ...CtsTransformRunFields
  }
}
    ${CtsTransformRunFieldsFragmentDoc}`;
export const CtsTestFragmentDoc = gql`
    fragment CtsTest on CustomerTargetSchemaTransformTestCase {
  id
  name
  inputJson
  kind
  expectedOutput {
    ...ParquetDataFields
  }
  latestRun {
    id
    status
    testDiffResults
    transformRun {
      id
      standardizedOutput {
        ...ParquetDataFields
      }
      error
    }
  }
}
    ${ParquetDataFieldsFragmentDoc}`;
export const CtsTransformForTestsFragmentDoc = gql`
    fragment CtsTransformForTests on CustomerTargetSchemaTransform {
  id
  runs {
    ...CtsTransformRunFields
  }
  testCases {
    ...CtsTest
  }
}
    ${CtsTransformRunFieldsFragmentDoc}
${CtsTestFragmentDoc}`;
export const CtsVersionAdminFragmentDoc = gql`
    fragment CtsVersionAdmin on CustomerTargetSchemaVersion {
  id
  schemaJson
  semverVersion
  versionNotesMd
  exampleData
  formattingNotesMd
  createdAt
  publishedAt
  publishedForTestingAt
  unpublishedAt
  schema {
    id
    name
    versions {
      id
      publishedAt
      publishedForTestingAt
      unpublishedAt
    }
  }
  globalIngestionExampleData {
    ...IngestionExampleDataFields
  }
  transformsIncludingDeleted {
    ...CtsTransformForEditor
    ...CtsTransformForTests
  }
  lastEditedByUser {
    id
    name
  }
  existsInRegionalDb
  schemaVersionId
}
    ${IngestionExampleDataFieldsFragmentDoc}
${CtsTransformForEditorFragmentDoc}
${CtsTransformForTestsFragmentDoc}`;
export const CtsInfoHeaderFragmentDoc = gql`
    fragment CtsInfoHeader on CustomerTargetSchema {
  id
  name
  descriptionMd
  rank
  versions {
    ...CtsVersionAdmin
  }
  latestPublishedVersion {
    id
    publishedAt
  }
  canonicalDatasets {
    id
    name
  }
  schemaId
}
    ${CtsVersionAdminFragmentDoc}`;
export const FileMetadataForCtsVersionEditorFragmentDoc = gql`
    fragment FileMetadataForCtsVersionEditor on FileMetadata {
  id
  remotePath
  gcloudStorageUrl
}
    `;
export const MeasurementProjectFieldsForCreateDataIssueFragmentDoc = gql`
    fragment MeasurementProjectFieldsForCreateDataIssue on MeasurementProject {
  id
  name
  active
  coverageInterval
  userUploadTasks {
    edges {
      node {
        __typename
        id
        state
        datasource {
          id
          name
          dataset {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export const ReferenceDataSourceFieldsForPickerFragmentDoc = gql`
    fragment ReferenceDataSourceFieldsForPicker on ReferenceDataSource {
  id
  name
  latestPublishedVersion {
    id
    versionName
    latestSignedParquetOutputUrl(orgId: $orgId)
    latestSchema {
      id
      schemaJson
    }
  }
}
    `;
export const EmissionsModelAncestorFieldsFragmentDoc = gql`
    fragment EmissionsModelAncestorFields on EmissionsModelAncestor {
  rootIsLatestPublishedVersion
  ancestorStableModel {
    id
    title
    description
  }
  ancestorForkOriginStableModelId
  relationship {
    parentId
    childId
  }
}
    `;
export const EmissionsModelReleaseOrgAccessFieldsFragmentDoc = gql`
    fragment EmissionsModelReleaseOrgAccessFields on EmissionsModelReleaseOrgAccess {
  id
  org {
    id
    name
    demoOrg
    testOrg
  }
  createdAt
}
    `;
export const EmissionsModelReleaseMetadataFragmentDoc = gql`
    fragment EmissionsModelReleaseMetadata on EmissionsModelRelease {
  id
  updatedAt
  userAuthorId
  user {
    id
    name
  }
  org {
    id
    name
    demoOrg
    testOrg
  }
  displayName
  businessActivityTypeName
  internalNotes
  externalNotes
  orgId
  lifecyclePhase
  isCustomToUserOrg
  latestCompatibleBat {
    id
    versionName
  }
  isOrgGated
  orgAccess {
    ...EmissionsModelReleaseOrgAccessFields
  }
}
    ${EmissionsModelReleaseOrgAccessFieldsFragmentDoc}`;
export const EmissionsModelStableFieldsForReleasesFragmentDoc = gql`
    fragment EmissionsModelStableFieldsForReleases on EmissionsModelStable {
  id
  title
  description
  org {
    id
    name
  }
  latestPublishedVersion {
    id
    createdAt
    changelog
    valid
    author {
      id
      name
    }
  }
  matchers {
    id
    businessActivityType
    filterExpression
    keepBatVersionAtLatest
  }
  releases {
    ...EmissionsModelReleaseMetadata
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;
export const GlobalFootprintTagFieldsForReleasesFragmentDoc = gql`
    fragment GlobalFootprintTagFieldsForReleases on GlobalFootprintTag {
  id
  createdAt
  tagName
  description
  watershedEditor {
    id
    displayName
  }
}
    `;
export const EmissionsModelReleaseDetailsPageFragmentDoc = gql`
    fragment EmissionsModelReleaseDetailsPage on EmissionsModelRelease {
  ...EmissionsModelReleaseMetadata
  emissionsModels {
    ...EmissionsModelStableFieldsForReleases
  }
  globalFootprintTags {
    ...GlobalFootprintTagFieldsForReleases
  }
  measurementTestSuites {
    id
    title
    description
    kind
    createdAt
    updatedAt
    status
  }
  org {
    id
    name
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}
${EmissionsModelStableFieldsForReleasesFragmentDoc}
${GlobalFootprintTagFieldsForReleasesFragmentDoc}`;
export const EmissionsModelReleaseListEntryFragmentDoc = gql`
    fragment EmissionsModelReleaseListEntry on EmissionsModelRelease {
  ...EmissionsModelReleaseMetadata
  emissionsModels {
    id
    title
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;
export const OrganizationListPageOrganizationFragmentDoc = gql`
    fragment OrganizationListPageOrganization on Organization {
  id
  name
  domains
  demoOrg
  testOrg
  stagingOrg
  canAccessFinance
  hasUsers
  watershedPlanLegacy
}
    `;
export const FeatureFlagDetailsFragmentDoc = gql`
    fragment FeatureFlagDetails on FeatureFlag {
  id
  name
  description
  stage
  enabledForAllOrgs
  enabledForOrgs {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;
export const UserFeatureFlagDetailsFragmentDoc = gql`
    fragment UserFeatureFlagDetails on FeatureFlag {
  id
  name
  description
  stage
  enabledForAllUsers
  enabledForUsers {
    id
    name
  }
}
    `;
export const FootprintFieldsAdminFragmentDoc = gql`
    fragment FootprintFieldsAdmin on Footprint {
  id
  name
  orgId
  locked
  createdAt
  createdBy {
    id
    name
    displayName
  }
  deletedAt
  updatedAt
  defaultFootprintSnapshot {
    id
    footprintInterval
    isLatest
  }
  status
}
    `;
export const FootprintSnapshotForFootprintSelectorAdminFragmentDoc = gql`
    fragment FootprintSnapshotForFootprintSelectorAdmin on FootprintSnapshot {
  id
  name
  description
  status
  createdAt
  identifier
  createdBy {
    id
    displayName
  }
  footprintConfiguration {
    id
  }
  footprintInterval
  watershedFootprintReviewRequest {
    id
    status
  }
  reviewStatus
}
    `;
export const GlobalFootprintTagsForGlobalTagsEditorFragmentDoc = gql`
    fragment GlobalFootprintTagsForGlobalTagsEditor on GlobalFootprintTag {
  id
  tagName
  description
  rulesList
  recordEndAt
  createdAt
  watershedEditor {
    id
    name
  }
}
    `;
export const FinancialsReviewItemAdminDialogFragmentDoc = gql`
    fragment FinancialsReviewItemAdminDialog on FinancialsReviewItem {
  id
  createdAt
  account {
    accountId
    description
  }
  status
  initiallyIncluded
  exclusionReason
  initialIndustryClassification {
    id
    shortDescription
    longDescription
  }
  prompt
  userResponse
  respondingUser {
    id
    name
  }
  discussion {
    id
    state
  }
  group {
    id
    measurementProject {
      id
    }
  }
}
    `;
export const MeasurementTestSuiteMetadataLiteFieldsFragmentDoc = gql`
    fragment MeasurementTestSuiteMetadataLiteFields on MeasurementTestSuite {
  id
  title
  kind
  status
  organization {
    id
    name
  }
  calculationTags {
    id
    name
  }
  emissionsModelReleases {
    ...EmissionsModelReleaseMetadata
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;
export const MeasurementTestSuiteMetadataFieldsFragmentDoc = gql`
    fragment MeasurementTestSuiteMetadataFields on MeasurementTestSuite {
  ...MeasurementTestSuiteMetadataLiteFields
  description
  createdAt
  deletedAt
}
    ${MeasurementTestSuiteMetadataLiteFieldsFragmentDoc}`;
export const MeasurementTestSuiteChangelogFieldsFragmentDoc = gql`
    fragment MeasurementTestSuiteChangelogFields on MeasurementTestSuiteChangelog {
  id
  author {
    id
    name
  }
  measurementResourceEvent {
    id
    createdAt
    eventKind
    eventData
    author {
      id
      name
    }
  }
  changelog
  createdAt
}
    `;
export const MeasurementTestSuiteBartMetadataFieldsFragmentDoc = gql`
    fragment MeasurementTestSuiteBartMetadataFields on MeasurementTestSuiteBart {
  id
  title
  activityTypeName
  fieldDefaults
  hiddenFields
  businessActivityType {
    id
    name
    versionName
    tSchema
  }
}
    `;
export const MeasurementTestRowFieldsFragmentDoc = gql`
    fragment MeasurementTestRowFields on MeasurementTestRow {
  id
  description
  bartRowId
  activityRow
}
    `;
export const FootprintTestSuiteConfigFieldsFragmentDoc = gql`
    fragment FootprintTestSuiteConfigFields on FootprintTestSuiteConfig {
  id
  pipelineStartDate
  pipelineEndDate
  customTagNames
  marketplaceAllocationInstructionIds
  exclusionRuleIds
  categoryRuleIds
  shouldExclude
}
    `;
export const MeasurementTestSuiteDetailsWithoutExecutionsFragmentDoc = gql`
    fragment MeasurementTestSuiteDetailsWithoutExecutions on MeasurementTestSuite {
  ...MeasurementTestSuiteMetadataFields
  latestChangelogEntry {
    ...MeasurementTestSuiteChangelogFields
  }
  measurementTestSuiteBarts {
    ...MeasurementTestSuiteBartMetadataFields
    measurementTestRows {
      ...MeasurementTestRowFields
    }
  }
  footprintTestSuiteConfig {
    ...FootprintTestSuiteConfigFields
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}
${MeasurementTestSuiteChangelogFieldsFragmentDoc}
${MeasurementTestSuiteBartMetadataFieldsFragmentDoc}
${MeasurementTestRowFieldsFragmentDoc}
${FootprintTestSuiteConfigFieldsFragmentDoc}`;
export const MethodologyTestSuiteExecutionMetadataFieldsFragmentDoc = gql`
    fragment MethodologyTestSuiteExecutionMetadataFields on MethodologyTestSuiteExecution {
  id
  createdAt
  status
  author {
    id
    name
  }
}
    `;
export const FootprintTestExecutionMetadataFieldsFragmentDoc = gql`
    fragment FootprintTestExecutionMetadataFields on FootprintTestSuiteExecution {
  id
  status
  error
  warnings
  failedStep
}
    `;
export const PrepublicationTestSuiteExecutionFieldsFragmentDoc = gql`
    fragment PrepublicationTestSuiteExecutionFields on PrepublicationTestSuiteExecution {
  measurementTestSuite {
    ...MeasurementTestSuiteDetailsWithoutExecutions
  }
  methodologyTestSuiteExecution {
    ...MethodologyTestSuiteExecutionMetadataFields
  }
  footprintTestSuiteExecution {
    ...FootprintTestExecutionMetadataFields
  }
  errorMessage
}
    ${MeasurementTestSuiteDetailsWithoutExecutionsFragmentDoc}
${MethodologyTestSuiteExecutionMetadataFieldsFragmentDoc}
${FootprintTestExecutionMetadataFieldsFragmentDoc}`;
export const MeasurementTestSuiteListFieldsFragmentDoc = gql`
    fragment MeasurementTestSuiteListFields on MeasurementTestSuite {
  ...MeasurementTestSuiteMetadataFields
  measurementTestSuiteBarts {
    ...MeasurementTestSuiteBartMetadataFields
    measurementTestRowCount
  }
  latestMethodologyTestSuiteExecution {
    id
    status
  }
  latestFootprintTestSuiteExecution {
    id
    status
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}
${MeasurementTestSuiteBartMetadataFieldsFragmentDoc}`;
export const MeasurementTestSuiteLiteListFieldsFragmentDoc = gql`
    fragment MeasurementTestSuiteLiteListFields on MeasurementTestSuite {
  ...MeasurementTestSuiteMetadataLiteFields
  measurementTestSuiteBarts {
    id
    activityTypeName
  }
}
    ${MeasurementTestSuiteMetadataLiteFieldsFragmentDoc}`;
export const MethodologyTestRowResultFieldsFragmentDoc = gql`
    fragment MethodologyTestRowResultFields on MethodologyTestRowResult {
  id
  measurementTestRowId
  emissionsModelStable {
    id
    title
  }
  measurementTestRow {
    id
    bartRowId
    activityRow
  }
  frozenExpectation
  result
  diff
  status
  resultAccepted
}
    `;
export const MethodologyTestSuiteExecutionWithResultsFieldsFragmentDoc = gql`
    fragment MethodologyTestSuiteExecutionWithResultsFields on MethodologyTestSuiteExecution {
  ...MethodologyTestSuiteExecutionMetadataFields
  methodologyTestRowResults {
    ...MethodologyTestRowResultFields
  }
}
    ${MethodologyTestSuiteExecutionMetadataFieldsFragmentDoc}
${MethodologyTestRowResultFieldsFragmentDoc}`;
export const FootprintTestExecutionStepResultFieldsFragmentDoc = gql`
    fragment FootprintTestExecutionStepResultFields on FootprintTestExecutionStepResult {
  id
  resultRecords {
    id
    month
    bartRowId
    bartName
    emStableId
    emStableTitle
    emVariableName
    rowSplitKey
    ghgCategoryId
    categoryId
    subcategoryId
    description
    quantityKgCo2e
    allocationLocationQuantityKgCo2E
    fullDiff
    status
    inActual
    inExpected
    resultAccepted
  }
}
    `;
export const VersionFieldsForGlobalEmissionsModelTreeFragmentDoc = gql`
    fragment VersionFieldsForGlobalEmissionsModelTree on EmissionsModelVersion {
  id
  title
  emissionsModelJson
  referenceDataVersions {
    id
    slug
  }
}
    `;
export const EmissionsModelMatcherFieldsFragmentDoc = gql`
    fragment EmissionsModelMatcherFields on EmissionsModelMatcher {
  id
  kind
  emissionsModelStableId
  businessActivityType
  businessActivityTypeId
  filterExpression
  quantitySpecifier
  bindings
  keepBatVersionAtLatest
}
    `;
export const StableFieldsForGlobalEmissionsModelTreeFragmentDoc = gql`
    fragment StableFieldsForGlobalEmissionsModelTree on EmissionsModelStable {
  id
  latestPublishedVersion {
    ...VersionFieldsForGlobalEmissionsModelTree
    importedDescendants {
      ...VersionFieldsForGlobalEmissionsModelTree
    }
  }
  matchers {
    ...EmissionsModelMatcherFields
  }
  releases {
    id
    businessActivityTypeName
    displayName
    lifecyclePhase
    latestCompatibleBat {
      id
      versionName
    }
  }
}
    ${VersionFieldsForGlobalEmissionsModelTreeFragmentDoc}
${EmissionsModelMatcherFieldsFragmentDoc}`;
export const SchemaRegistrySchemaSchemaFragmentDoc = gql`
    fragment SchemaRegistrySchemaSchema on SchemaRegistrySchema {
  id
  name
  namespace
  createdAt
  settings {
    updatedAt
    enforceBatchMigrations
    readVersion {
      id
      version
    }
    writeVersion {
      id
      version
    }
    dualWriteVersion {
      id
      version
    }
    dualWriteMode
  }
  latestSchemaVersion {
    id
    version
    updatedAt
  }
}
    `;
export const SchemaRegistrySchemaFragmentFragmentDoc = gql`
    fragment SchemaRegistrySchemaFragment on SchemaRegistrySchema {
  id
  name
}
    `;
export const SchemaRegistrySchemaVersionFragmentFragmentDoc = gql`
    fragment SchemaRegistrySchemaVersionFragment on SchemaRegistrySchemaVersion {
  id
  schema {
    ...SchemaRegistrySchemaFragment
  }
  version
}
    ${SchemaRegistrySchemaFragmentFragmentDoc}`;
export const DataRegistryBatchMigratorRunSchemaFragmentDoc = gql`
    fragment DataRegistryBatchMigratorRunSchema on DataRegistryBatchMigratorRun {
  id
  author {
    id
    name
  }
  description
  createdAt
  status
  fromSchemaVersion {
    ...SchemaRegistrySchemaVersionFragment
  }
  toSchemaVersion {
    ...SchemaRegistrySchemaVersionFragment
  }
  workflowId
  filters
  successCount
  failureCount
  error
  deletedAt
  region
}
    ${SchemaRegistrySchemaVersionFragmentFragmentDoc}`;
export const DataRegistryMigratorSchemaFragmentDoc = gql`
    fragment DataRegistryMigratorSchema on DataRegistryMigrator {
  id
  createdAt
  migratorType
  migrationCode
  inputVersion {
    id
    version
  }
  outputVersion {
    id
    version
  }
}
    `;
export const SchemaRegistrySchemaVersionSchemaFragmentDoc = gql`
    fragment SchemaRegistrySchemaVersionSchema on SchemaRegistrySchemaVersion {
  id
  version
  createdAt
  schemaJson
  schemaCount
}
    `;
export const FootprintDisclosureShareLogFieldsFragmentDoc = gql`
    fragment FootprintDisclosureShareLogFields on FootprintDisclosureShareLog {
  id
  orgId
  footprintDisclosureId
  sharedToOrgId
  sharedByUserId
}
    `;
export const FootprintDisclosureFieldsFragmentDoc = gql`
    fragment FootprintDisclosureFields on FootprintDisclosure {
  id
  createdAt
  reportingYear
  footprintSnapshot {
    id
    configs {
      coverageInterval
    }
  }
  shareLogs {
    ...FootprintDisclosureShareLogFields
  }
}
    ${FootprintDisclosureShareLogFieldsFragmentDoc}`;
export const UserFieldsForFinancePageFragmentDoc = gql`
    fragment UserFieldsForFinancePage on User {
  id
  name
  displayName
}
    `;
export const PortcoOrgFieldsForFinancePageFragmentDoc = gql`
    fragment PortcoOrgFieldsForFinancePage on Organization {
  id
  name
}
    `;
export const LifecycleAssessmentForAdminListViewFragmentDoc = gql`
    fragment LifecycleAssessmentForAdminListView on LifecycleAssessment {
  id
  productName
  productUnit
  productDescription
  boundary
  dataUsedNotes
  supplierProductExternalId
  createdAt
  updatedAt
  validityStartDate
  validityEndDate
  referenceStartDate
  referenceEndDate
}
    `;
export const MaterialVariantForAdminListViewFragmentDoc = gql`
    fragment MaterialVariantForAdminListView on MaterialVariant {
  id
  name
  lifecycleAssessmentId
  isDefaultVariant
  createdAt
  updatedAt
}
    `;
export const HistoricalEmissionsYearFieldsFragmentDoc = gql`
    fragment HistoricalEmissionsYearFields on EmissionsYear {
  id
  publicDisclosureId
  reportingYear
  numEmployees
  revenue
  revenueCurrency
  revenueUsd
  scope1
  scope2Market
  scope2Location
  scope3
  scope301
  scope302
  scope303
  scope304
  scope305
  scope306
  scope307
  scope308
  scope309
  scope310
  scope311
  scope312
  scope313
  scope314
  scope315
  scope316
  scope317
  units
  percentageCleanEnergy
  totalMwh
  scope123RevenueIntensityKgco2e {
    id
    scope1
    scope2Market
    scope2Location
    scope3
  }
}
    `;
export const PublicDisclosureTableFragmentDoc = gql`
    fragment PublicDisclosureTable on PublicDisclosure {
  id
  company {
    id
    name
  }
  publicUrl
  publishingYear
  reportType
  gsUrl
  historicalEmissionsYearsDetails {
    id
    publicDisclosureId
    reportingYear
    numEmployees
    revenue
    revenueCurrency
    revenueUsd
    scope1
    scope2Market
    scope2Location
    scope3
    scope301
    scope302
    scope303
    scope304
    scope305
    scope306
    scope307
    scope308
    scope309
    scope310
    scope311
    scope312
    scope313
    scope314
    scope315
    scope316
    scope317
    units
    percentageCleanEnergy
    totalMwh
    scope123RevenueIntensityKgco2e {
      id
      scope1
      scope2Market
      scope2Location
      scope3
    }
  }
}
    `;
export const FormalReportFieldsAdminFragmentDoc = gql`
    fragment FormalReportFieldsAdmin on Report {
  id
  name
  isHidden
  reportKind
  footprintSnapshot {
    id
  }
  createdAt
  deletedAt
  config {
    id
    reportType
  }
}
    `;
export const ReportQuestionForCopyFragmentDoc = gql`
    fragment ReportQuestionForCopy on ReportQuestion {
  id
  identifier
}
    `;
export const ReportAnswerForAdminTableFragmentDoc = gql`
    fragment ReportAnswerForAdminTable on ReportAnswer {
  id
  outputJson
  updatedAt
  question {
    id
    identifier
    componentId
    isComputed
  }
  report {
    id
    name
    organization {
      id
      name
      demoOrg
    }
    config {
      id
      reportType
      shortName
    }
    createdAt
  }
}
    `;
export const ReportQuestionContainerFieldsFragmentDoc = gql`
    fragment ReportQuestionContainerFields on ReportQuestionContainer {
  id
  label
  description
  statusJson
  guidanceJson
  fullWidth
  itemIds
}
    `;
export const ReportQuestionRawFieldsFragmentDoc = gql`
    fragment ReportQuestionRawFields on ReportQuestion {
  id
  title
  subtitle
  createdAt
  updatedAt
  deletedAt
  identifier
  componentId
  staticInputJson
  dynamicInputJson
  statusJson
  guidanceJson
  isComputed
  isDynamic
  allowNotes
  allowedAttachmentKinds
}
    `;
export const ExternalReportQuestionFieldsFragmentDoc = gql`
    fragment ExternalReportQuestionFields on ExternalReportQuestion {
  id
  type {
    id
    type
    revision
    specUrl
  }
  reportQuestionIdentifier {
    id
    identifier
  }
  question
}
    `;
export const ExternalReportAnswerFieldsFragmentDoc = gql`
    fragment ExternalReportAnswerFields on ExternalReportAnswer {
  id
  questionPhrasing
  answer
  answerType
  index
  watershedQualityScore
  question {
    ...ExternalReportQuestionFields
  }
  publicDisclosure {
    id
    company {
      id
      name
    }
  }
}
    ${ExternalReportQuestionFieldsFragmentDoc}`;
export const VendorMatchingTaskFieldsForAdminFragmentDoc = gql`
    fragment VendorMatchingTaskFieldsForAdmin on VendorMatchingTask {
  id
  measurementProject {
    id
    name
  }
  wasAutomapped
}
    `;
export const SupplierChartConfigFieldsAdminFragmentDoc = gql`
    fragment SupplierChartConfigFieldsAdmin on SupplierChartConfig {
  id
  orgId
  title
  description
  chartType
  ... on SupplierStandardChartConfig {
    colorScale
    xField
    aggregateType
  }
  ... on SupplierEngagementFunnelChartConfig {
    funnels {
      id
    }
  }
  ... on SupplierTaskStatusChangeOverTimeChartConfig {
    colorScale
    tasksStatusChangeConfig {
      status
      engagementTaskConfigId
    }
    isCumulative
  }
  ... on SupplierMetricsChartConfig {
    metricsTypes
  }
  ... on SupplierSbtCommitmentOverTimeChartConfig {
    colorScale
  }
}
    `;
export const SupplierViewFieldsAdminFragmentDoc = gql`
    fragment SupplierViewFieldsAdmin on SupplierView {
  id
  charts: chartsV2 {
    __typename
    ...SupplierChartConfigFieldsAdmin
  }
  chartOrder {
    chartId
    visible
  }
}
    ${SupplierChartConfigFieldsAdminFragmentDoc}`;
export const ActiveWatershedEmployeeFieldsFragmentDoc = gql`
    fragment ActiveWatershedEmployeeFields on WatershedEmployee {
  id
  name
  handle
  email
  user {
    id
    createdAt
    accessibleOrgs {
      id
      name
    }
    primaryLoginOrgId
  }
  orgPointsOfContact {
    id
    orgId
    kind
  }
}
    `;
export const AdminFeatureFlagDetailsFragmentDoc = gql`
    fragment AdminFeatureFlagDetails on FeatureFlag {
  id
  name
  activatedForUser
}
    `;
export const InstructionsStepBaseFieldsFragmentDoc = gql`
    fragment InstructionsStepBaseFields on InstructionsStep {
  descriptionMd
  fileMetadataId
  remotePath
  publicUrl
}
    `;
export const SdiInstructionsStepBaseFieldsFragmentDoc = gql`
    fragment SdiInstructionsStepBaseFields on SdiInstructionsStep {
  title
  descriptionMd
}
    `;
export const SdiInstructionsFaqStepBaseFieldsFragmentDoc = gql`
    fragment SdiInstructionsFaqStepBaseFields on SdiInstructionsFaqType {
  question
  answerMd
}
    `;
export const InstructionsBundleBaseFieldsFragmentDoc = gql`
    fragment InstructionsBundleBaseFields on InstructionsBundle {
  instructionsDataFormattingMd
  instructionsIntroMd
  sdiInstructionsDataFormattingMd
  sdiInstructionsIntroMd
  ghgScopes
  usedFor
  potentialOverlaps
  instructionsSteps {
    ...InstructionsStepBaseFields
  }
  sdiInstructionsSteps {
    ...SdiInstructionsStepBaseFields
  }
  sdiInstructionsFaq {
    ...SdiInstructionsFaqStepBaseFields
  }
}
    ${InstructionsStepBaseFieldsFragmentDoc}
${SdiInstructionsStepBaseFieldsFragmentDoc}
${SdiInstructionsFaqStepBaseFieldsFragmentDoc}`;
export const CanonicalDatasetBaseFieldsFragmentDoc = gql`
    fragment CanonicalDatasetBaseFields on CanonicalDataset {
  id
  name
  description
  usedFor
  potentialOverlaps
  kind
  instructions {
    ...InstructionsBundleBaseFields
  }
  selfServeDisabledReason
}
    ${InstructionsBundleBaseFieldsFragmentDoc}`;
export const CanonicalDatasourceBaseFieldsFragmentDoc = gql`
    fragment CanonicalDatasourceBaseFields on CanonicalDatasource {
  id
  name
  instructions {
    ...InstructionsBundleBaseFields
  }
}
    ${InstructionsBundleBaseFieldsFragmentDoc}`;
export const IntegrationPartnerBaseFieldsFragmentDoc = gql`
    fragment IntegrationPartnerBaseFields on IntegrationPartner {
  id
  name
}
    `;
export const CanonicalProjectRequirementDatasetFieldsFragmentDoc = gql`
    fragment CanonicalProjectRequirementDatasetFields on CanonicalClimateProgramProjectRequirementDataset {
  id
  canonicalDataset {
    id
    name
  }
  additionalNotesMd
}
    `;
export const CanonicalProjectRequirementGhgpFieldsFragmentDoc = gql`
    fragment CanonicalProjectRequirementGhgpFields on CanonicalClimateProgramProjectRequirementGhgp {
  id
  ghgScope
  ghgScope3Category
  minimumFidelity
  additionalNotesMd
}
    `;
export const CanonicalClimateProgramProjectFieldsFragmentDoc = gql`
    fragment CanonicalClimateProgramProjectFields on CanonicalClimateProgramProject {
  id
  name
  description
  hidden
  hiddenFromMeasurement
  kind
  timeEstimate
  costEstimate
  iconTypeOverride
  iconType
  measurementObjectCopy
  matchObjectType
  commitmentType
  reportKind
  routeType
  datasetRequirements {
    ...CanonicalProjectRequirementDatasetFields
  }
  ghgpRequirements {
    ...CanonicalProjectRequirementGhgpFields
  }
}
    ${CanonicalProjectRequirementDatasetFieldsFragmentDoc}
${CanonicalProjectRequirementGhgpFieldsFragmentDoc}`;
export const DisclosureForAdminEditFragmentDoc = gql`
    fragment DisclosureForAdminEdit on Disclosure {
  id
  publicDisclosure {
    ...PublicDisclosureForAdminEdit
  }
  privateDisclosure {
    id
    privateDisclosureType
    companyId
    disclosureQualityScore
  }
  historicalEmissionsYears {
    ...EmissionsYearAllFields
  }
  climateCommitments {
    ...CompanyClimateCommitmentFields
  }
}
    ${PublicDisclosureForAdminEditFragmentDoc}
${EmissionsYearAllFieldsFragmentDoc}
${CompanyClimateCommitmentFieldsFragmentDoc}`;
export const CustomerTargetSchemaForListViewFragmentDoc = gql`
    fragment CustomerTargetSchemaForListView on CustomerTargetSchema {
  id
  name
  descriptionMd
  rank
  deletedAt
  versions {
    id
    createdAt
    publishedAt
    publishedForTestingAt
    unpublishedAt
    lastEditedByUser {
      id
      name
    }
    transforms {
      id
      testCases {
        id
        kind
      }
    }
  }
  canonicalDatasets {
    id
    name
  }
}
    `;
export const EmailSentForAdminTableFragmentDoc = gql`
    fragment EmailSentForAdminTable on EmailSent {
  id
  kind
  fromEmail
  subject
  htmlContent
  createdAt
  users {
    id
    recipientUser {
      id
      name
      email
    }
    relationshipKind
    recipientEmail
  }
  org {
    id
    name
    demoOrg
  }
  openCount
  clickCount
}
    `;
export const EmeMissingReferenceValueMetadataFieldsFragmentDoc = gql`
    fragment EmeMissingReferenceValueMetadataFields on EmeMissingReferenceValueMetadata {
  slug
  revisionId
  column
  rowId
}
    `;
export const EmeMissingTraceMetadataFieldsFragmentDoc = gql`
    fragment EmeMissingTraceMetadataFields on EmeMissingTraceMetadata {
  missingReferenceValueCitations {
    ...EmeMissingReferenceValueMetadataFields
  }
  missingReferenceValueDescriptions {
    ...EmeMissingReferenceValueMetadataFields
  }
  missingBartValueMetadata {
    businessActivityTypeName
    column
  }
}
    ${EmeMissingReferenceValueMetadataFieldsFragmentDoc}`;
export const EmissionsModelStableBaseFieldsFragmentDoc = gql`
    fragment EmissionsModelStableBaseFields on EmissionsModelStable {
  id
  title
  description
  scope
  createdAt
  tags {
    id
    name
  }
}
    `;
export const EmissionsModelVersionFieldsForImportedDescendantsFragmentDoc = gql`
    fragment EmissionsModelVersionFieldsForImportedDescendants on EmissionsModelVersion {
  id
  title
  description
  changelog
  createdAt
  schemaVersion
  emissionsModelJson
  parameterResolverJson
  stableModel {
    id
    latestPublishedVersion {
      id
      changelog
      createdAt
    }
  }
}
    `;
export const EmissionsModelActiveDraftFieldsForEditorFragmentDoc = gql`
    fragment EmissionsModelActiveDraftFieldsForEditor on EmissionsModelActiveDraft {
  id
  activeAuthor {
    id
    name
  }
  isCheckedOut
  autosavedAt
  emissionsModelJson
  parameterResolverJson
  importedDescendants {
    ...EmissionsModelVersionFieldsForImportedDescendants
  }
  matchers
}
    ${EmissionsModelVersionFieldsForImportedDescendantsFragmentDoc}`;
export const EmissionsModelVersionFieldsForEditorFragmentDoc = gql`
    fragment EmissionsModelVersionFieldsForEditor on EmissionsModelVersion {
  id
  kind
  changelog
  createdAt
  author {
    id
    name
  }
  schemaVersion
  emissionsModelJson
  parameterResolverJson
  activeDraftForVersion {
    ...EmissionsModelActiveDraftFieldsForEditor
  }
  importedDescendants {
    ...EmissionsModelVersionFieldsForImportedDescendants
  }
}
    ${EmissionsModelActiveDraftFieldsForEditorFragmentDoc}
${EmissionsModelVersionFieldsForImportedDescendantsFragmentDoc}`;
export const EmissionsModelReleaseFieldsForEmEditorFragmentDoc = gql`
    fragment EmissionsModelReleaseFieldsForEMEditor on EmissionsModelRelease {
  id
  businessActivityTypeName
  displayName
  lifecyclePhase
  internalNotes
  externalNotes
}
    `;
export const EmissionsModelReleaseFieldsForMatcherValidationFragmentDoc = gql`
    fragment EmissionsModelReleaseFieldsForMatcherValidation on EmissionsModelRelease {
  id
  displayName
  businessActivityTypeName
  lifecyclePhase
  emissionsModelsWithMatchers: emissionsModels {
    id
    matchers {
      id
      businessActivityType
      kind
      keepBatVersionAtLatest
    }
  }
}
    `;
export const EmissionsModelStableFieldsFragmentDoc = gql`
    fragment EmissionsModelStableFields on EmissionsModelStable {
  ...EmissionsModelStableBaseFields
  latestVersion {
    ...EmissionsModelVersionFieldsForEditor
  }
  org {
    id
    name
  }
  matchers {
    ...EmissionsModelMatcherFields
  }
  releases {
    ...EmissionsModelReleaseFieldsForEMEditor
  }
  relatedReleases {
    ...EmissionsModelReleaseFieldsForMatcherValidation
  }
  isMutable
  isArchived
}
    ${EmissionsModelStableBaseFieldsFragmentDoc}
${EmissionsModelVersionFieldsForEditorFragmentDoc}
${EmissionsModelMatcherFieldsFragmentDoc}
${EmissionsModelReleaseFieldsForEmEditorFragmentDoc}
${EmissionsModelReleaseFieldsForMatcherValidationFragmentDoc}`;
export const EmissionsModelStableListViewFieldsFragmentDoc = gql`
    fragment EmissionsModelStableListViewFields on EmissionsModelStable {
  id
  title
  description
  scope
  isArchived
  org {
    id
    name
  }
  latestPublishedVersion {
    id
    createdAt
  }
  matchers {
    id
    businessActivityType
  }
  releases {
    ...EmissionsModelReleaseMetadata
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;
export const EngagementTaskQuestionFieldsAdminFragmentDoc = gql`
    fragment EngagementTaskQuestionFieldsAdmin on EngagementTaskQuestion {
  id
  label
  description
  componentId
  editable
  deletable
  answerIsPrivate
  questionKey
  statusJson
  whyCollectThis
  videoLink
}
    `;
export const EngagementTaskContainerFieldsAdminFragmentDoc = gql`
    fragment EngagementTaskContainerFieldsAdmin on EngagementTaskContainer {
  id
  label
  description
  componentId
  questions {
    ...EngagementTaskQuestionFieldsAdmin
  }
  editable
  statusJson
  sidebarStepLabelOverride
  eyebrowText
  headerImage
}
    ${EngagementTaskQuestionFieldsAdminFragmentDoc}`;
export const EngagementTaskContainerWithSubSectionFieldsAdminFragmentDoc = gql`
    fragment EngagementTaskContainerWithSubSectionFieldsAdmin on EngagementTaskContainer {
  ...EngagementTaskContainerFieldsAdmin
  containers {
    ...EngagementTaskContainerFieldsAdmin
  }
}
    ${EngagementTaskContainerFieldsAdminFragmentDoc}`;
export const EngagementTaskConfigFieldsAdminFragmentDoc = gql`
    fragment EngagementTaskConfigFieldsAdmin on EngagementTaskConfig {
  id
  name
  sidebarTitle
  type
  category
  order
  publishedAt
  rootFacingDescription
  defaultSupplierFacingDescription
  engagementTaskInputs
  optionalEngagementTaskInputs
  canDeleteInAdmin
  containers {
    ...EngagementTaskContainerWithSubSectionFieldsAdmin
  }
  timeNeeded
  configGroup
}
    ${EngagementTaskContainerWithSubSectionFieldsAdminFragmentDoc}`;
export const GlobalTagsForFootprintDebuggerFragmentDoc = gql`
    fragment GlobalTagsForFootprintDebugger on GlobalFootprintTag {
  id
  tagName
  recordStartAt
  recordEndAt
}
    `;
export const ReportsForFootprintDebuggerFragmentDoc = gql`
    fragment ReportsForFootprintDebugger on Report {
  id
  reportKind
  publishedAt
  isHidden
}
    `;
export const FootprintSnapshotForFootprintDebuggerFragmentDoc = gql`
    fragment FootprintSnapshotForFootprintDebugger on FootprintSnapshot {
  id
  orgId
  createdAt
  footprintInterval
  footprintAssemblyRun {
    id
    workflowId
  }
  marketplaceAllocationInstructions {
    id
  }
  tags {
    id
    tagName
  }
  globalTags {
    ...GlobalTagsForFootprintDebugger
  }
  categoryRules {
    id
  }
  exclusionRules {
    id
  }
  intermediateFileIds
  createdBy {
    id
    name
    isWatershed
  }
  reportsUsingFootprint {
    ...ReportsForFootprintDebugger
  }
  excludedScopesWithPriceGating
  assemblyDebugJson
}
    ${GlobalTagsForFootprintDebuggerFragmentDoc}
${ReportsForFootprintDebuggerFragmentDoc}`;
export const FootprintSnapshotExclusionRuleForFootprintDebuggerFragmentDoc = gql`
    fragment FootprintSnapshotExclusionRuleForFootprintDebugger on FootprintSnapshotExclusionRule {
  id
  footprintExclusionRule {
    id
    recordStartAt
    recordEndAt
  }
  impactedEmissions {
    excludedTco2eQuantity
    excludedMwhConsumed
  }
}
    `;
export const InstructionImpactForFootprintDebuggerFragmentDoc = gql`
    fragment InstructionImpactForFootprintDebugger on MarketplaceAllocationInstructionImpact {
  id
  createdAt
  updatedAt
  amountKwh
  allocatedKwh
}
    `;
export const CleanPowerImpactForFootprintDebuggerFragmentDoc = gql`
    fragment CleanPowerImpactForFootprintDebugger on CleanPowerImpact {
  appliedInstructions {
    ...InstructionImpactForFootprintDebugger
  }
}
    ${InstructionImpactForFootprintDebuggerFragmentDoc}`;
export const IntegrationDataPullFieldsFragmentDoc = gql`
    fragment IntegrationDataPullFields on IntegrationDataPull {
  id
  createdAt
  createdBy {
    id
    name
  }
  totalChunksToFetch
  chunksFetched
  totalFilesToProcess
  filesProcessed
  userUploadedTable {
    id
    sqlTableName
  }
  completed
  backgroundJob {
    id
    state
    error {
      ...SerializableErrorFields
    }
  }
  adtName
  adtUrl
}
    ${SerializableErrorFieldsFragmentDoc}`;
export const DatasourceFieldsFragmentDoc = gql`
    fragment DatasourceFields on Datasource {
  id
  name
  dataset {
    id
    name
  }
}
    `;
export const IntegrationConnectionFieldsFragmentDoc = gql`
    fragment IntegrationConnectionFields on IntegrationConnection {
  id
  createdAt
  updatedAt
  deletedAt
  isActive
  org {
    id
    name
  }
  integrationPartner {
    id
    name
    externalId
  }
  integrationDataPulls {
    edges {
      node {
        ...IntegrationDataPullFields
      }
    }
  }
  datasources {
    edges {
      node {
        ...DatasourceFields
      }
    }
  }
  netsuiteIntegrationSettings {
    id
    transactionTypeFilters
    accountNamesToExclude
    usePostingPeriodForDateFilters
  }
}
    ${IntegrationDataPullFieldsFragmentDoc}
${DatasourceFieldsFragmentDoc}`;
export const IntegrationRequestFieldsFragmentDoc = gql`
    fragment IntegrationRequestFields on IntegrationRequest {
  id
  createdAt
  updatedAt
  requestMethod
  requestUrl
  responseStatus
  integrationConnectionId
  orgId
  requestBody
  responseFileMetadata {
    id
    gcloudStorageUrl
  }
}
    `;
export const IntegrationConnectionListFieldsFragmentDoc = gql`
    fragment IntegrationConnectionListFields on IntegrationConnection {
  id
  org {
    id
    name
  }
  integrationPartner {
    id
    name
    dataType
  }
  createdAt
  updatedAt
  deletedAt
  isActive
}
    `;
export const MarketplaceProjectForArchetypeFieldsFragmentDoc = gql`
    fragment MarketplaceProjectForArchetypeFields on MarketplaceProject {
  id
  name
}
    `;
export const MarketplaceProjectOfferingFieldsFragmentDoc = gql`
    fragment MarketplaceProjectOfferingFields on MarketplaceProjectOffering {
  __typename
  id
  supplier {
    id
    name
  }
  project {
    id
    name
    deletedAt
    status
    archetype {
      id
      name
      unit
      mechanism
    }
  }
  vintage
  createdAt
  availability
  priceUsdCents
  priceWithFeeUsdCents
  noteMd
}
    `;
export const MarketplaceProjectArchetypeFieldsFragmentDoc = gql`
    fragment MarketplaceProjectArchetypeFields on MarketplaceProjectArchetype {
  id
  kind
  unit
  isUserVisible
  name
  descriptionMd
  summaryMd
  imageUrl
  bannerImageUrl
  certificateBlurb
  certificateColor
  additionalityRating
  permanenceRating
  projects {
    edges {
      node {
        ...MarketplaceProjectForArchetypeFields
      }
    }
  }
  offerings {
    edges {
      node {
        ...MarketplaceProjectOfferingFields
      }
    }
  }
}
    ${MarketplaceProjectForArchetypeFieldsFragmentDoc}
${MarketplaceProjectOfferingFieldsFragmentDoc}`;
export const MarketplaceProjectArchetypeListFieldsFragmentDoc = gql`
    fragment MarketplaceProjectArchetypeListFields on MarketplaceProjectArchetype {
  __typename
  id
  name
  isUserVisible
  mechanism
  kind
  unit
  summaryMd
  priceUsdCentsMin
  priceUsdCentsMax
  projects {
    edges {
      node {
        ...MarketplaceProjectForArchetypeFields
      }
    }
  }
}
    ${MarketplaceProjectForArchetypeFieldsFragmentDoc}`;
export const MarketplaceDeveloperFieldsFragmentDoc = gql`
    fragment MarketplaceDeveloperFields on MarketplaceDeveloper {
  id
  name
  imageUrl
  url
  descriptionMd
  deletedAt
}
    `;
export const MarketplaceDeveloperWithProjectsFieldsFragmentDoc = gql`
    fragment MarketplaceDeveloperWithProjectsFields on MarketplaceDeveloper {
  ...MarketplaceDeveloperFields
  projects {
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${MarketplaceDeveloperFieldsFragmentDoc}`;
export const MarketplaceDeveloperListFieldsFragmentDoc = gql`
    fragment MarketplaceDeveloperListFields on MarketplaceDeveloper {
  ...MarketplaceDeveloperFields
}
    ${MarketplaceDeveloperFieldsFragmentDoc}`;
export const MarketplaceDocumentForDetailPageFragmentDoc = gql`
    fragment MarketplaceDocumentForDetailPage on MarketplaceDocument {
  id
  name
  createdAt
  deletedAt
  kind
  noteMd
  uploader {
    id
    name
  }
  downloadUrl
  purchase {
    id
    name
  }
  purchaseLineItem {
    id
    purchase {
      id
      name
    }
    offering {
      id
      vintage
      project {
        id
        name
      }
      supplier {
        id
        name
      }
    }
  }
}
    `;
export const MarketplaceSupplierForMarketplacePurchaseLineItemFragmentDoc = gql`
    fragment MarketplaceSupplierForMarketplacePurchaseLineItem on MarketplaceSupplier {
  id
  name
}
    `;
export const MarketplacePurchaseLineItemFieldsForMarketplaceDocumentListFragmentDoc = gql`
    fragment MarketplacePurchaseLineItemFieldsForMarketplaceDocumentList on MarketplacePurchaseLineItem {
  id
  offering {
    id
    supplier {
      ...MarketplaceSupplierForMarketplacePurchaseLineItem
    }
  }
}
    ${MarketplaceSupplierForMarketplacePurchaseLineItemFragmentDoc}`;
export const MarketplaceDocumentListFieldsFragmentDoc = gql`
    fragment MarketplaceDocumentListFields on MarketplaceDocument {
  createdAt
  deletedAt
  id
  kind
  name
  downloadUrl
  org {
    id
    name
  }
  uploader {
    id
    name
  }
  purchaseLineItem {
    ...MarketplacePurchaseLineItemFieldsForMarketplaceDocumentList
  }
  purchase {
    id
    lineItems {
      edges {
        node {
          ...MarketplacePurchaseLineItemFieldsForMarketplaceDocumentList
        }
      }
    }
  }
}
    ${MarketplacePurchaseLineItemFieldsForMarketplaceDocumentListFragmentDoc}`;
export const MarketplacePurchaseListFieldsFragmentDoc = gql`
    fragment MarketplacePurchaseListFields on MarketplacePurchase {
  id
  org {
    id
    name
    demoOrg
  }
  createdAt
  updatedAt
  name
  totalCostUsdCents
  totalFeeUsdCents
  status
  offerExpiration
  eacMetadata {
    id
    requestNotes
    interval
    ghgCategoryIds
    marketTco2e
  }
  waitingOnParty
  customerStatus
  noteMd
  documents {
    edges {
      node {
        id
        kind
      }
    }
  }
}
    `;
export const EacPriceEstimateFieldsFragmentDoc = gql`
    fragment EacPriceEstimateFields on EacPriceEstimate {
  id
  updatedAt
  country
  alternativeCountries
  minCreditPurchase
  standard
  priceEstimateUsdCents
  defaultProject {
    id
    name
  }
}
    `;
export const MarketplaceSupplierForProjectFieldsFragmentDoc = gql`
    fragment MarketplaceSupplierForProjectFields on MarketplaceSupplier {
  id
  name
}
    `;
export const MarketplaceProjectFieldsFragmentDoc = gql`
    fragment MarketplaceProjectFields on MarketplaceProject {
  id
  name
  deletedAt
  status
  internalNotesMd
  registryId
  archetype {
    id
    name
  }
  developer {
    ...MarketplaceDeveloperFields
  }
  supplier {
    ...MarketplaceSupplierForProjectFields
  }
  offerings {
    edges {
      node {
        ...MarketplaceProjectOfferingFields
      }
    }
  }
}
    ${MarketplaceDeveloperFieldsFragmentDoc}
${MarketplaceSupplierForProjectFieldsFragmentDoc}
${MarketplaceProjectOfferingFieldsFragmentDoc}`;
export const MarketplaceProjectListFieldsFragmentDoc = gql`
    fragment MarketplaceProjectListFields on MarketplaceProject {
  __typename
  id
  name
  status
  registryId
  archetype {
    id
    name
  }
  developer {
    ...MarketplaceDeveloperFields
  }
  supplier {
    ...MarketplaceSupplierForProjectFields
  }
  offerings(first: 1) {
    edges {
      node {
        id
      }
    }
  }
}
    ${MarketplaceDeveloperFieldsFragmentDoc}
${MarketplaceSupplierForProjectFieldsFragmentDoc}`;
export const TrackedEditorFieldsFragmentDoc = gql`
    fragment TrackedEditorFields on TrackedEditor {
  __typename
  ... on Person {
    id
    name
  }
}
    `;
export const MarketplacePurchaseLineItemFieldsFragmentDoc = gql`
    fragment MarketplacePurchaseLineItemFields on MarketplacePurchaseLineItem {
  id
  org {
    id
    name
    demoOrg
  }
  createdAt
  updatedAt
  purchase {
    id
    name
  }
  archetype {
    id
    name
  }
  project {
    id
    name
    supplier {
      id
      name
    }
  }
  offering {
    id
    vintage
  }
  documents {
    edges {
      node {
        id
        kind
      }
    }
  }
  cleanPowerContractualInstrument
  vintage
  amount
  amountUnit
  mechanism
  coverageInterval
  unitPriceUsdCents
  unitFeeUsdCents
  deliveryDate
  closeDate
  status
  waitingOnParty
  noteMd
  eacMetadata {
    id
    countryWithEmissions
    countryWithEacs
    numEacsRequested
    mwh
    marketTco2e
  }
  appliedToFootprint
}
    `;
export const MarketplacePurchaseFieldsFragmentDoc = gql`
    fragment MarketplacePurchaseFields on MarketplacePurchase {
  id
  org {
    id
    name
    productWaitlistTypes
  }
  createdAt
  updatedAt
  deletedAt
  offerExpiration
  name
  status
  waitingOnParty
  customerStatus
  noteMd
  totalCostUsdCents
  totalFeeUsdCents
  editor {
    ...TrackedEditorFields
  }
  customerUser {
    id
    name
  }
  history {
    edges {
      node {
        id
        editor {
          ...TrackedEditorFields
        }
        name
        status
        offerExpiration
        recordStartAt
        recordEndAt
      }
    }
  }
  lineItems {
    edges {
      node {
        ...MarketplacePurchaseLineItemFields
      }
    }
  }
  eacMetadata {
    id
    updatedAt
    requestNotes
    interval
    ghgCategoryIds
    marketTco2e
  }
}
    ${TrackedEditorFieldsFragmentDoc}
${MarketplacePurchaseLineItemFieldsFragmentDoc}`;
export const MarketplaceDocumentFieldsForTableCardFragmentDoc = gql`
    fragment MarketplaceDocumentFieldsForTableCard on MarketplaceDocument {
  id
  name
  createdAt
  kind
  uploader {
    id
    name
  }
}
    `;
export const MarketplacePurchaseFieldsForDetailPageFragmentDoc = gql`
    fragment MarketplacePurchaseFieldsForDetailPage on MarketplacePurchase {
  ...MarketplacePurchaseFields
  lineItems {
    edges {
      node {
        id
        name
      }
    }
  }
  documents {
    edges {
      node {
        ...MarketplaceDocumentFieldsForTableCard
      }
    }
  }
}
    ${MarketplacePurchaseFieldsFragmentDoc}
${MarketplaceDocumentFieldsForTableCardFragmentDoc}`;
export const AllocationInstructionFieldsForMarketplacePurchaseLineItemFragmentDoc = gql`
    fragment AllocationInstructionFieldsForMarketplacePurchaseLineItem on MarketplaceAllocationInstruction {
  id
  createdAt
  deletedAt
  amountKwh
  isZeroedOut
  interval
  locationCity
  locationGrid
  locationState
  locationCountry
  buildingName
  vendorEntity
  vendorSubentity
  product
  categoryId
  ghgCategoryId
  footprintKind
  subcategoryId
  results {
    edges {
      node {
        id
        isZeroedOut
        amountAllocatedKwh
      }
    }
  }
  usedInFootprintSnapshot
}
    `;
export const MarketplacePurchaseLineItemForPurchaseLineItemPageFragmentDoc = gql`
    fragment MarketplacePurchaseLineItemForPurchaseLineItemPage on MarketplacePurchaseLineItem {
  id
  name
  org {
    id
    name
  }
  createdAt
  updatedAt
  deletedAt
  purchase {
    id
    name
  }
  archetype {
    id
    name
  }
  project {
    id
    name
  }
  offering {
    id
    vintage
  }
  cleanPowerContractualInstrument
  vintage
  amount
  amountUnit
  mechanism
  coverageInterval
  unitPriceUsdCents
  unitFeeUsdCents
  deliveryDate
  closeDate
  status
  waitingOnParty
  noteMd
  documents {
    edges {
      node {
        ...MarketplaceDocumentFieldsForTableCard
      }
    }
  }
  allocations {
    edges {
      node {
        ...AllocationInstructionFieldsForMarketplacePurchaseLineItem
      }
    }
  }
  eacMetadata {
    id
    updatedAt
    countryWithEmissions
    countryWithEacs
    numEacsRequested
    mwh
    marketTco2e
  }
}
    ${MarketplaceDocumentFieldsForTableCardFragmentDoc}
${AllocationInstructionFieldsForMarketplacePurchaseLineItemFragmentDoc}`;
export const MarketplaceProjectForSupplierPageFragmentDoc = gql`
    fragment MarketplaceProjectForSupplierPage on MarketplaceProject {
  id
  name
  archetype {
    id
    name
  }
  status
}
    `;
export const MarketplaceSupplierFieldsFragmentDoc = gql`
    fragment MarketplaceSupplierFields on MarketplaceSupplier {
  id
  name
  projects {
    edges {
      node {
        ...MarketplaceProjectForSupplierPage
      }
    }
  }
  offerings {
    edges {
      node {
        ...MarketplaceProjectOfferingFields
      }
    }
  }
  pointsOfContact {
    __typename
    id
    name
    email
  }
  watershedEmployees {
    __typename
    id
    name
    email
  }
}
    ${MarketplaceProjectForSupplierPageFragmentDoc}
${MarketplaceProjectOfferingFieldsFragmentDoc}`;
export const MarketplaceSupplierListFieldsFragmentDoc = gql`
    fragment MarketplaceSupplierListFields on MarketplaceSupplier {
  __typename
  id
  name
  offerings {
    edges {
      node {
        id
      }
    }
  }
}
    `;
export const DataRegistryMigratorRunSchemaFragmentDoc = gql`
    fragment DataRegistryMigratorRunSchema on DataRegistryMigratorRun {
  id
  status
  createdAt
  migrator {
    id
  }
  inputTable {
    id
    name
    isDraft
    createdAt
  }
  outputTable {
    id
    name
    isDraft
  }
  organization {
    id
    name
  }
  errorMessage
}
    `;
export const SchemaRegistrySchemaSettingsFragmentDoc = gql`
    fragment SchemaRegistrySchemaSettings on SchemaRegistrySchema {
  settings {
    updatedAt
    enforceBatchMigrations
    readVersion {
      id
      version
    }
    writeVersion {
      id
      version
    }
    dualWriteVersion {
      id
      version
    }
    dualWriteMode
  }
}
    `;
export const UserUploadFieldsForTaskTableFragmentDoc = gql`
    fragment UserUploadFieldsForTaskTable on UserUpload {
  id
  name
  deletedAt
  allAttempts {
    id
    createdAt
    size
    gcloudStorageUrl
    deletedAt
    uploader {
      id
      name
    }
    userUploadedTables {
      id
      sqlTableName
      shouldIgnore
    }
  }
  latestAttempt {
    id
    name
    gcloudStorageUrl
    userUploadedTables {
      id
      shouldIgnore
    }
  }
}
    `;
export const UserUploadTaskUploadsFieldsFragmentDoc = gql`
    fragment UserUploadTaskUploadsFields on UserUploadTask {
  id
  state
  measurementProject {
    id
    name
    coverageInterval
  }
  userUploads(
    includeDeleted: true
    excludeProcessingModes: [IdiRawFile, NoProcessing]
  ) {
    edges {
      node {
        ...UserUploadFieldsForTaskTable
      }
    }
  }
}
    ${UserUploadFieldsForTaskTableFragmentDoc}`;
export const DatasourceForDatasetPageFragmentDoc = gql`
    fragment DatasourceForDatasetPage on Datasource {
  id
  name
  importInstructionsMd
  refreshCadence
  isUserConfirmed
  customUsedFor
  customPotentialOverlaps
  dataset {
    id
  }
  canonicalDatasource {
    id
    name
  }
  userUploadTasks {
    edges {
      node {
        id
        ...UserUploadTaskUploadsFields
      }
    }
  }
  instructions {
    ...InstructionsBundleBaseFields
  }
  notesFromWatershedMd
  customerTargetSchemaIds
}
    ${UserUploadTaskUploadsFieldsFragmentDoc}
${InstructionsBundleBaseFieldsFragmentDoc}`;
export const MeasurementTimelineForOrgPageFragmentDoc = gql`
    fragment MeasurementTimelineForOrgPage on MeasurementTimeline {
  id
  kickoff
  deadline
}
    `;
export const AdminMeasurementProjectCard_ProjectFragmentDoc = gql`
    fragment AdminMeasurementProjectCard_project on MeasurementTaskObject {
  __typename
  id
}
    `;
export const MeasurementTaskForOrgPageFragmentDoc = gql`
    fragment MeasurementTaskForOrgPage on MeasurementTask {
  id
  title
  link
  assignees {
    id
    name
  }
  isAssignedToWatershed
  statusSimplified
  object {
    ... on MeasurementProjectDataset {
      isCompleted
    }
    ... on UserUploadTask {
      datasource {
        id
      }
    }
    ...AdminMeasurementProjectCard_project
  }
}
    ${AdminMeasurementProjectCard_ProjectFragmentDoc}`;
export const MeasurementParentTaskForOrgPageFragmentDoc = gql`
    fragment MeasurementParentTaskForOrgPage on MeasurementParentTask {
  ...MeasurementTaskForOrgPage
  subTasks {
    ...MeasurementTaskForOrgPage
  }
  taskGroup
  dataset {
    id
    name
  }
}
    ${MeasurementTaskForOrgPageFragmentDoc}`;
export const UserUploadTaskFieldsForOrgPageFragmentDoc = gql`
    fragment UserUploadTaskFieldsForOrgPage on UserUploadTask {
  __typename
  id
  state
  datasource {
    id
    name
  }
  userUploads {
    edges {
      node {
        id
      }
    }
  }
  assignee {
    id
    name
  }
}
    `;
export const OrganizationDatasetsPageDatasetFragmentDoc = gql`
    fragment OrganizationDatasetsPageDataset on Dataset {
  id
  name
  explainer
  customUsedFor
  customPotentialOverlaps
  canonicalDataset {
    id
    name
    kind
  }
  datasources {
    id
    name
    customUsedFor
    customPotentialOverlaps
    customerTargetSchemaIds
  }
}
    `;
export const MeasurementProjectDatasetFieldsForOrgPageFragmentDoc = gql`
    fragment MeasurementProjectDatasetFieldsForOrgPage on MeasurementProjectDataset {
  id
  dataset {
    ...OrganizationDatasetsPageDataset
  }
  isCompleted
}
    ${OrganizationDatasetsPageDatasetFragmentDoc}`;
export const MeasurementProjectFieldsForOrgPageFragmentDoc = gql`
    fragment MeasurementProjectFieldsForOrgPage on MeasurementProject {
  __typename
  id
  name
  active
  status
  completedAt
  coverageStartDate
  coverageEndDate
  coverageInterval
  deadline
  isApprovalFlowEnabled
  measurementTimeline {
    ...MeasurementTimelineForOrgPage
  }
  measurementTasks {
    ...MeasurementParentTaskForOrgPage
  }
  userUploadTasks {
    edges {
      node {
        ...UserUploadTaskFieldsForOrgPage
      }
    }
  }
  measurementProjectDatasets {
    ...MeasurementProjectDatasetFieldsForOrgPage
  }
  supportedValueMappingRules
}
    ${MeasurementTimelineForOrgPageFragmentDoc}
${MeasurementParentTaskForOrgPageFragmentDoc}
${UserUploadTaskFieldsForOrgPageFragmentDoc}
${MeasurementProjectDatasetFieldsForOrgPageFragmentDoc}`;
export const ActivityDataTableFieldsFragmentDoc = gql`
    fragment ActivityDataTableFields on ActivityDataTable {
  id
  name
  primaryDatasource {
    id
    name
    hasIntegration
    activeIntegrationConnection {
      id
    }
    hasIntegrationOption
  }
  datasets {
    name
    id
    name
    canonicalDataset {
      id
      kind
    }
  }
  datasources {
    id
    name
    hasIntegration
    activeIntegrationConnection {
      id
    }
    hasIntegrationOption
  }
  lastRefreshedAt
  draftCadtExtractor {
    id
    createdAt
  }
  draftBartExtractor {
    id
    createdAt
    pushedBuildings
  }
  publishedExtractor {
    id
    createdAt
    facilitiesSurveyStatus
    bartActivityType
  }
  filterInterval
  connectedToAutoBart
  autoBartKind
  autoBartTaskApprovalState
  measurementProject {
    id
    name
  }
  stage
}
    `;
export const DatasetFieldsForOrgPageFragmentDoc = gql`
    fragment DatasetFieldsForOrgPage on Dataset {
  id
  name
  datasources {
    id
    name
  }
  canonicalDataset {
    id
    kind
  }
}
    `;
export const MeasurementProjectForFootprintPageFragmentDoc = gql`
    fragment MeasurementProjectForFootprintPage on MeasurementProject {
  id
  name
  active
  coverageInterval
}
    `;
export const PipelineResultForOrgFragmentDoc = gql`
    fragment PipelineResultForOrg on PipelineResult {
  id
  name
  pipelineKind
  orgId
  createdAt
  coverageInterval
  runUserId
  pipelineRunConfig
  deletedAt
  summary {
    format
    emissions {
      month
      kgCo2e
      businessCategory
      adtName
      footprintKind
    }
    warnings {
      blocking
      message
    }
  }
  parquetDataId
  prkFileMetadataId
  emissionsModelEvaluations {
    id
    emissionsModelVersion {
      id
      stableModel {
        id
        title
      }
    }
    activityDataExtractor {
      id
      activityDataTable {
        id
        name
      }
    }
    evaluatedExtendedBart {
      dashboardFileMetadataId
    }
    uniqueBartRowCount
    bartTotalRowCount
    evaluatedBartId
    evaluatedEmtaFmdId
  }
}
    `;
export const FootprintSnapshotOrgFootprintsFieldsFragmentDoc = gql`
    fragment FootprintSnapshotOrgFootprintsFields on FootprintSnapshot {
  id
  createdAt
  identifier
  name
  description
  status
  isUserVisible
  configs {
    pipelineResultId
    coverageInterval
  }
  footprint {
    id
    name
    nextFootprintVersionIdentifier
  }
  extraAdeIdsForIntDenoms
  extraAdeIdsForNonEmissiveActivity
  marketplaceAllocationInstructionIds
  tags {
    id
    tagName
    description
    watershedEditor {
      id
      name
      email
    }
  }
}
    `;
export const MeasurementProjectForEditDataIssuesPageFragmentDoc = gql`
    fragment MeasurementProjectForEditDataIssuesPage on MeasurementProject {
  id
  name
  coverageInterval
  active
  dataIssues {
    ...DataIssueForEditDataIssuesPageFields
  }
}
    ${DataIssueForEditDataIssuesPageFieldsFragmentDoc}`;
export const EmailPreview_EmailContentsFragmentDoc = gql`
    fragment EmailPreview_emailContents on EmailContents {
  recipients {
    email
    name
  }
  ccs {
    email
    name
  }
  sender {
    email
    name
  }
  subject
  bodyHtml
  bccs {
    email
    name
  }
}
    `;
export const EmailContentWithUserRecipientFieldsFragmentDoc = gql`
    fragment EmailContentWithUserRecipientFields on EmailContentsWithUserRecipient {
  userId
  emailContents {
    ...EmailPreview_emailContents
  }
}
    ${EmailPreview_EmailContentsFragmentDoc}`;
export const MappingAssumptionFragmentDoc = gql`
    fragment MappingAssumption on AssumptionRecordForAdmin {
  id
  assumptionId
  authorExplanation: assumptionSourceMemo
  inputEmissionsSpecifier
  inputEmissionsSubspecifier
  outputEmissionsSpecifier
  outputEmissionsSubspecifier
  vendorEntity
  startMonth
  endMonth
  excludeFromResults
  ghgCategoryId
  categoryId
  subcategoryId
  description
}
    `;
export const MeasurementMappingFieldsFragmentFragmentDoc = gql`
    fragment MeasurementMappingFieldsFragment on MeasurementMappingFields {
  author {
    id
    name
  }
  authorExplanation
  inputEmissionsSpecifier
  inputEmissionsSubspecifier
  outputEmissionsSpecifier
  outputEmissionsSubspecifier
  vendorEntity
  startMonth
  endMonth
  shouldInclude
  ghgCategoryId
  categoryId
  subcategoryId
  description
  updatedAt
}
    `;
export const CadtRowsToMapFieldsFragmentDoc = gql`
    fragment CadtRowsToMapFields on RawCadtRowToMap {
  emissions_specifier
  emissions_subspecifier
  unnormalized_emissions_subspecifier
  unit
  month_earliest
  month_latest
  top_vendors {
    vendor_entity
    amount
  }
  total_amount
  description_from_cadt
  adt_ids
}
    `;
export const PlanTargetForOrgPlanCategoriesPageFragmentDoc = gql`
    fragment PlanTargetForOrgPlanCategoriesPage on PlanTarget {
  id
  description
  reportDescription
  filters {
    conjunction
    expressions {
      field
      operator
      value
    }
  }
}
    `;
export const PlanForPlanPageFragmentDoc = gql`
    fragment PlanForPlanPage on Plan {
  id
  name
  createdAt
  deletedAt
  updatedAt
  updatedByName
  state
  baselineYearStart
  isHidden
  isSbtValidated
  variables {
    commitmentsSBTTermLength
  }
}
    `;
export const EngagementTaskFieldsAdminFragmentDoc = gql`
    fragment EngagementTaskFieldsAdmin on EngagementTask {
  id
  name
  crossOrgModelId
  status
  deletedAt
  company {
    id
  }
  engagementTaskConfig {
    id
    name
  }
}
    `;
export const SuppliersSettingsFieldsAdminFragmentDoc = gql`
    fragment SuppliersSettingsFieldsAdmin on SuppliersSettings {
  id
  includedGhgCategories
  viewLatestSupplierMapping
  suppressNotifications
}
    `;
export const ReferenceDataRevisionSchemaFragmentDoc = gql`
    fragment ReferenceDataRevisionSchema on ReferenceDataRevision {
  id
  revisionName
  author {
    id
    name
  }
  updates
  outputFileId
  sourceFileId
  internalSourceNotes
  createdAt
  archivedAt
  signedParquetOutputUrl
  signedSourceUrl
  organization {
    id
    name
  }
  state
  schemaJson
  assumptionSource {
    id
  }
  kind
  schemaLastCheckedAgainst
  lastCompatibleSchema
  isLatestForOrgAndState
  isOverlay
}
    `;
export const CompositeDataOrgDetailSchemaFragmentDoc = gql`
    fragment CompositeDataOrgDetailSchema on CompositeDataOrgDetail {
  organization {
    id
    name
  }
  latestPublishedRevision {
    ...ReferenceDataRevisionSchema
  }
}
    ${ReferenceDataRevisionSchemaFragmentDoc}`;
export const ReferenceDataSourceSchemaFragmentDoc = gql`
    fragment ReferenceDataSourceSchema on ReferenceDataSource {
  id
  name
  author {
    id
    name
  }
  orgId
  organization {
    id
    name
  }
  externalSourceUrl
  externalSourceNotes
  kind
  isCompositeData
  createdAt
  updatedAt
  archivedAt
  tags {
    id
    name
  }
  assumptionSources {
    id
    name
  }
  isSyncedWithCliq
}
    `;
export const ReferenceDataSourceSchemaWithOutputUrlFragmentDoc = gql`
    fragment ReferenceDataSourceSchemaWithOutputUrl on ReferenceDataSource {
  ...ReferenceDataSourceSchema
  latestSignedParquetOutputUrl(orgId: $orgId)
}
    ${ReferenceDataSourceSchemaFragmentDoc}`;
export const ReferenceDataVersionSchemaFragmentDoc = gql`
    fragment ReferenceDataVersionSchema on ReferenceDataVersion {
  id
  sourceId
  author {
    id
    name
  }
  versionName
  versionVintage
  createdAt
  updatedAt
  archivedAt
  state
  hasPublishedRevision
  canPublish
  latestSchema {
    id
    schemaJson
  }
}
    `;
export const ReferenceDataVersionSchemaWithOutputUrlFragmentDoc = gql`
    fragment ReferenceDataVersionSchemaWithOutputUrl on ReferenceDataVersion {
  ...ReferenceDataVersionSchema
  latestSignedParquetOutputUrl(orgId: $orgId)
}
    ${ReferenceDataVersionSchemaFragmentDoc}`;
export const CdpQuestionIdMappingFragmentDoc = gql`
    fragment CdpQuestionIdMapping on CdpQuestionIdMapping {
  id
  cdpRefId
  reportQuestionIdentifier {
    id
    identifier
  }
}
    `;
export const CdpColumnIdMappingFragmentDoc = gql`
    fragment CdpColumnIdMapping on CdpColumnIdMapping {
  id
  cdpRefId
  watershedColumnId
  otherColumnIdMapping
}
    `;
export const CdpIdMappingsFieldsFragmentDoc = gql`
    fragment CdpIdMappingsFields on CdpIdMappingsPayload {
  __typename
  id
  questionIdMappings {
    ...CdpQuestionIdMapping
  }
  columnIdMappings {
    ...CdpColumnIdMapping
  }
}
    ${CdpQuestionIdMappingFragmentDoc}
${CdpColumnIdMappingFragmentDoc}`;
export const CandidateQuestionMappingFieldsFragmentDoc = gql`
    fragment CandidateQuestionMappingFields on CdpCandidateQuestionIdMapping {
  cdpRefId
  cdpQuestionJson
  reportQuestion {
    id
    identifier
    identifierId
  }
}
    `;
export const CandidateColumnMappingFieldsFragmentDoc = gql`
    fragment CandidateColumnMappingFields on CdpCandidateColumnIdMapping {
  cdpRefId
  cdpParentRefId
  cdpColumnQuestionJson
  cdpParentQuestionJson
  columnId
  columnLabel
  otherColumnIdMapping
  parentReportQuestion {
    id
    identifier
    identifierId
  }
}
    `;
export const UnmappedWatershedQuestionFieldsFragmentDoc = gql`
    fragment UnmappedWatershedQuestionFields on ReportQuestion {
  id
  identifier
  componentId
  isComputed
}
    `;
export const ReportConfigFieldsForAdminTableFragmentDoc = gql`
    fragment ReportConfigFieldsForAdminTable on ReportConfig {
  id
  createdAt
  updatedAt
  deletedAt
  reportType
  shortName
  longName
  description
  brandColor
  isHiddenFromOverview
  isCreationBlocked
  isRegulatoryExposureBlocked
  useTableDisplay
  useMateriality
  useReportingIntention
  useOverrides
  useApprovals
  requiredPermissions
  reportObjectives
  isCorporateReport
  isFinanceReport
  status
}
    `;
export const ReportQuestionIdentifierFieldsFragmentDoc = gql`
    fragment ReportQuestionIdentifierFields on ReportQuestionIdentifier {
  id
  identifier
}
    `;
export const CommonOpportunityFieldsFragmentDoc = gql`
    fragment CommonOpportunityFields on ITcfdOpportunity {
  id
  title
  userDescription
}
    `;
export const ArchetypeOpportunityFieldsFragmentDoc = gql`
    fragment ArchetypeOpportunityFields on TcfdArchetypeOpportunity {
  ...CommonOpportunityFields
  geographies
  sectors
  distributionModel
}
    ${CommonOpportunityFieldsFragmentDoc}`;
export const RiskCommonFieldsFragmentDoc = gql`
    fragment RiskCommonFields on ITcfdRisk {
  id
  title
  userDescription
  kind
  possibleImpactDescription
  lowTrajectoryImpact
  mediumTrajectoryImpact
  highTrajectoryImpact
  physicalRiskSeverity
}
    `;
export const RiskArchetypeFieldsFragmentDoc = gql`
    fragment RiskArchetypeFields on TcfdArchetypeRisk {
  ...RiskCommonFields
  geographies
  sectors
  distributionModel
  whyWeChoseThisMd
}
    ${RiskCommonFieldsFragmentDoc}`;
export const QueryRunFieldsFragmentDoc = gql`
    fragment QueryRunFields on QueryRunInterface {
  id
  state
  jobError {
    ...SerializableErrorFields
  }
  error
}
    ${SerializableErrorFieldsFragmentDoc}`;
export const CompanyAutocompleteResultFragmentDoc = gql`
    fragment CompanyAutocompleteResult on Company {
  id
  name
  countryAlpha2
  naicsCode
  sAndP {
    id
    sAndPId
  }
  companyWebsite {
    id
    url
  }
  leis {
    id
    lei
    isPrimary
  }
}
    `;
export const CompanyAutocompleteResultWithSourceFragmentDoc = gql`
    fragment CompanyAutocompleteResultWithSource on Company {
  ...CompanyAutocompleteResult
  source
}
    ${CompanyAutocompleteResultFragmentDoc}`;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const PageInfoWithCountFragmentDoc = gql`
    fragment PageInfoWithCount on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
  totalRowCount
}
    `;
export const ActivityTypeSchemaFragmentDoc = gql`
    fragment ActivityTypeSchema on ActivityTypeSchema {
  id
  activityType
  schema {
    id
    jsonSchema
  }
}
    `;
export const BuildingPeriodInfoFragmentDoc = gql`
    fragment BuildingPeriodInfo on BuildingPeriodInterface {
  kind
  sizeQuantity
  startYearMonth
  endYearMonth
  partialCleanPowerPercent
  cleanPowerContractualInstrument
  powerUsageEffectiveness
  isBuildingEmpty
  customFieldValue
  customFieldId
  orgUnitTypeStableId
  orgUnitStableId
}
    `;
export const BuildingInfoFragmentDoc = gql`
    fragment BuildingInfo on BuildingInterface {
  streetAddress
  streetAddress2
  latitude
  longitude
  postalCode
  city
  state
  country
  latitude
  longitude
  startYearMonth
  endYearMonth
  sizeUnit
  buildingKind
  buildingSubkind
  leaseType
  buildingName
  buildingUniqueId
  isWithoutNaturalGas
  isWithoutRefrigerants
  periods {
    ...BuildingPeriodInfo
  }
  revision
  editState
}
    ${BuildingPeriodInfoFragmentDoc}`;
export const BuildingDiffFragmentDoc = gql`
    fragment BuildingDiff on CadtBuildingDiff {
  old {
    ...BuildingInfo
  }
  new {
    ...BuildingInfo
  }
  errors {
    message
    adminMessage
    code
  }
  buildingPresentInBart
  warnings {
    adminMessage
    message
    code
  }
}
    ${BuildingInfoFragmentDoc}`;
export const CustomFieldForBuldingDiffFragmentDoc = gql`
    fragment CustomFieldForBuldingDiff on IngestionCustomField {
  id
  name
}
    `;
export const OrgUnitTypeForBuildingDiffFragmentDoc = gql`
    fragment OrgUnitTypeForBuildingDiff on OrgUnitType {
  id
  name
  stableId
}
    `;
export const OrgUnitForBuildingDiffFragmentDoc = gql`
    fragment OrgUnitForBuildingDiff on OrgUnit {
  id
  name
  stableId
}
    `;
export const ReviewDocumentParseAttemptFragmentDoc = gql`
    fragment ReviewDocumentParseAttempt on DocumentParseAttempt {
  id
  userUploadId
  modelType
  createdAt
  updatedAt
  utilityUsageData {
    energyConsumptionAmount
    energyConsumptionUnit
    receiverAddress
    serviceAddress
    serviceStartDate
    serviceEndDate
  }
}
    `;
export const FinanceTagForGridFragmentDoc = gql`
    fragment FinanceTagForGrid on FinanceTag {
  id
  name
  description
  columnFormat
  columnConfiguration
  tagType
}
    `;
export const FundAllFieldsFragmentDoc = gql`
    fragment FundAllFields on Fund {
  __typename
  id
  name
  nameSortable
  fundGroup
  fundCategory
  excludeAsSandbox
  anonymousFundUuid
  createdAt
  orgId
  updatedAt
  tagData
  externalId
}
    `;
export const FinanceColumnAssetGridFragmentDoc = gql`
    fragment FinanceColumnAssetGrid on FinanceColumn {
  id
  field
  columnFormat
  columnConfiguration {
    ... on GridColumnConfigurationSingleSelect {
      __typename
      columnFormat
      options
    }
  }
  headerName
  description
  width
}
    `;
export const MeasurementProjectFieldsFragmentDoc = gql`
    fragment MeasurementProjectFields on MeasurementProject {
  id
  name
  active
  status
  createdAt
  completedAt
  coverageInterval
  coverageStartDate
  coverageEndDate
  deadline
}
    `;
export const UserUploadTaskFieldsFragmentDoc = gql`
    fragment UserUploadTaskFields on UserUploadTask {
  id
  assignee {
    id
    name
  }
  datasource {
    id
    dataset {
      id
      name
    }
  }
  measurementProject {
    id
    name
    active
    coverageStartDate
    coverageEndDate
    completedAt
  }
}
    `;
export const AssignableUserFieldsFragmentDoc = gql`
    fragment AssignableUserFields on User {
  id
  name
  displayName
}
    `;
export const ReportItemUiFragmentDoc = gql`
    fragment ReportItemUI on IReportItem {
  id
  itemNumber
  label
  sublabel
  optional
  optionalMessage
}
    `;
export const EngagementTaskConfigForTaskCreationFragmentDoc = gql`
    fragment EngagementTaskConfigForTaskCreation on EngagementTaskConfig {
  id
  name
  orgId
  order
  category
  rootFacingDescription
  defaultSupplierFacingDescription
  crossOrgModelId
  engagementTaskInputs
  type
  optionalEngagementTaskInputs
  timeNeeded
  type
}
    `;
export const BusinessActivityTypeLiteFragmentDoc = gql`
    fragment BusinessActivityTypeLite on BusinessActivityType {
  id
  versionName
  versionId
  name
  schemaJson
  tSchema
}
    `;
export const AllBusinessActivityTypeFieldFieldsFragmentDoc = gql`
    fragment AllBusinessActivityTypeFieldFields on BusinessActivityTypeField {
  id
  typeId
  versionId
  description
  fieldName
  fieldType
  fieldValueOneOf
  isRequired
  isProratable
  unitFieldName
}
    `;
export const AllBusinessActivityTypeFieldsFragmentDoc = gql`
    fragment AllBusinessActivityTypeFields on BusinessActivityType {
  id
  versionId
  name
  description
  fields {
    ...AllBusinessActivityTypeFieldFields
  }
}
    ${AllBusinessActivityTypeFieldFieldsFragmentDoc}`;
export const DataIssueUtils_DataIssueFragmentDoc = gql`
    fragment dataIssueUtils_DataIssue on DataIssue {
  comments {
    edges {
      node {
        id
        person {
          id
          isWatershedEmployee
          isWatershedContractor
        }
        createdAt
        isPublished
      }
    }
  }
}
    `;
export const PermissionOriginForFetchOrgUsersForPermissionFragmentDoc = gql`
    fragment PermissionOriginForFetchOrgUsersForPermission on UserPermissionOrigin {
  id
  permission {
    id
    permission
    object {
      id
      name
    }
  }
  role {
    id
    role {
      id
      name
    }
  }
}
    `;
export const UserForAllOrgUsersForPermissionFragmentDoc = gql`
    fragment UserForAllOrgUsersForPermission on User {
  id
  name
  orgAccessId(orgId: $orgId)
  permissionOrigin(permissions: $permissions, orgId: $orgId) {
    ...PermissionOriginForFetchOrgUsersForPermission
  }
}
    ${PermissionOriginForFetchOrgUsersForPermissionFragmentDoc}`;
export const UserForOrgUsersWithPermissionsFragmentDoc = gql`
    fragment UserForOrgUsersWithPermissions on User {
  id
  name
  permissions(orgId: $orgId) {
    id
    permission
  }
  roles(orgId: $orgId) {
    id
    role {
      id
      permissions {
        id
        permission
      }
    }
  }
}
    `;
export const OverviewReportConfigFieldsFragmentDoc = gql`
    fragment OverviewReportConfigFields on ReportConfig {
  id
  reportType
  shortName
  longName
  description
  brandColor
  status
  isHiddenFromOverview
  isCreationBlocked
  isRegulatoryExposureBlocked
  isCorporateReport
  isFinanceReport
  requiredPermissions
  reportObjectives
}
    `;
export const DeleteMarketplaceDeveloperCacheUpdateDocument = gql`
    query DeleteMarketplaceDeveloperCacheUpdate {
  marketplaceDevelopers {
    edges {
      node {
        id
      }
    }
  }
}
    `;

export function useDeleteMarketplaceDeveloperCacheUpdateQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDeleteMarketplaceDeveloperCacheUpdateQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDeleteMarketplaceDeveloperCacheUpdateQuery,
    GQTypes.GQDeleteMarketplaceDeveloperCacheUpdateQueryVariables
  >({ query: DeleteMarketplaceDeveloperCacheUpdateDocument, ...options });
}
export const UpdateActivityDataTableDocument = gql`
    mutation UpdateActivityDataTable($input: UpdateActivityDataTableInput!) {
  updateActivityDataTable(input: $input) {
    activityDataTable {
      ...ActivityDataTableFields
    }
  }
}
    ${ActivityDataTableFieldsFragmentDoc}`;

export function useUpdateActivityDataTableMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateActivityDataTableMutation,
    GQTypes.GQUpdateActivityDataTableMutationVariables
  >(UpdateActivityDataTableDocument);
}
export const UnpublishActivityDataTableDocument = gql`
    mutation UnpublishActivityDataTable($input: UnpublishActivityDataTableInput!) {
  unpublishActivityDataTable(input: $input) {
    workflowId
  }
}
    `;

export function useUnpublishActivityDataTableMutation() {
  return Urql.useMutation<
    GQTypes.GQUnpublishActivityDataTableMutation,
    GQTypes.GQUnpublishActivityDataTableMutationVariables
  >(UnpublishActivityDataTableDocument);
}
export const FetchIntegrationDataDocument = gql`
    mutation FetchIntegrationData($input: FetchIntegrationDataInput!) {
  fetchIntegrationData(input: $input) {
    job {
      id
    }
  }
}
    `;

export function useFetchIntegrationDataMutation() {
  return Urql.useMutation<
    GQTypes.GQFetchIntegrationDataMutation,
    GQTypes.GQFetchIntegrationDataMutationVariables
  >(FetchIntegrationDataDocument);
}
export const GetUserUploadedTableSchemaDocument = gql`
    query GetUserUploadedTableSchema($id: ID!) {
  userUploadedTable(id: $id) {
    id
    parseConfig {
      noHeader
      skipTopNRows
      skipBottomNRows
      meltIdVars
    }
    userReviewMetadata {
      columnMappings {
        watershedColumnName
        sourceFileColumnName
      }
    }
    dataPreview {
      schema {
        fields {
          name
          type
        }
      }
      dataUntyped
    }
  }
}
    `;

export function useGetUserUploadedTableSchemaQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUserUploadedTableSchemaQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUserUploadedTableSchemaQuery,
    GQTypes.GQGetUserUploadedTableSchemaQueryVariables
  >({ query: GetUserUploadedTableSchemaDocument, ...options });
}
export const ActivityDataTableEditorDataDocument = gql`
    query ActivityDataTableEditorData($orgId: ID!, $adtId: ID!) {
  organization(id: $orgId) {
    ...OrganizationForAdtEditor
  }
  enablePublishFileUploadFacilities: featureFlagEnabledForOrg(
    orgId: $orgId
    flag: EnablePublishFileUploadFacilities
  )
  activityDataTable(id: $adtId) {
    ...ActivityDataTableFieldsForEditor
  }
  activityTypeSchemas {
    ...ActivityTypeSchema
  }
}
    ${OrganizationForAdtEditorFragmentDoc}
${ActivityDataTableFieldsForEditorFragmentDoc}
${ActivityTypeSchemaFragmentDoc}`;

export function useActivityDataTableEditorDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQActivityDataTableEditorDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActivityDataTableEditorDataQuery,
    GQTypes.GQActivityDataTableEditorDataQueryVariables
  >({ query: ActivityDataTableEditorDataDocument, ...options });
}
export const GetUserUploadedTablesByIdDocument = gql`
    query GetUserUploadedTablesById($ids: [ID!]!) {
  userUploadedTablesById(ids: $ids) {
    ...UserUploadedTablesByIdFields
  }
}
    ${UserUploadedTablesByIdFieldsFragmentDoc}`;

export function useGetUserUploadedTablesByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUserUploadedTablesByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUserUploadedTablesByIdQuery,
    GQTypes.GQGetUserUploadedTablesByIdQueryVariables
  >({ query: GetUserUploadedTablesByIdDocument, ...options });
}
export const GetAdesByIdDocument = gql`
    query GetAdesById($ids: [ID!]!) {
  activityDataExtractorsById(ids: $ids) {
    ...ActivityDataExtractorFields
  }
}
    ${ActivityDataExtractorFieldsFragmentDoc}`;

export function useGetAdesByIdQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQGetAdesByIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetAdesByIdQuery,
    GQTypes.GQGetAdesByIdQueryVariables
  >({ query: GetAdesByIdDocument, ...options });
}
export const GetActivityDataExtractorDocument = gql`
    query GetActivityDataExtractor($adeId: ID!) {
  activityDataExtractor(id: $adeId) {
    ...ActivityDataExtractorFields
  }
}
    ${ActivityDataExtractorFieldsFragmentDoc}`;

export function useGetActivityDataExtractorQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetActivityDataExtractorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetActivityDataExtractorQuery,
    GQTypes.GQGetActivityDataExtractorQueryVariables
  >({ query: GetActivityDataExtractorDocument, ...options });
}
export const GetAdtRunsDocument = gql`
    query GetAdtRuns($adtId: ID!, $first: Int, $after: String) {
  activityDataTable(id: $adtId) {
    id
    activityDataExtractors(first: $first, after: $after) {
      edges {
        node {
          ...ActivityDataExtractorFields
        }
      }
    }
  }
}
    ${ActivityDataExtractorFieldsFragmentDoc}`;

export function useGetAdtRunsQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQGetAdtRunsQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetAdtRunsQuery,
    GQTypes.GQGetAdtRunsQueryVariables
  >({ query: GetAdtRunsDocument, ...options });
}
export const RunExtractorDocument = gql`
    mutation RunExtractor($input: RunExtractorInput!) {
  runExtractor(input: $input) {
    run {
      id
    }
  }
}
    `;

export function useRunExtractorMutation() {
  return Urql.useMutation<
    GQTypes.GQRunExtractorMutation,
    GQTypes.GQRunExtractorMutationVariables
  >(RunExtractorDocument);
}
export const PublishExtractorDocument = gql`
    mutation PublishExtractor($input: PublishExtractorInput!) {
  publishExtractor(input: $input) {
    jobId
  }
}
    `;

export function usePublishExtractorMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishExtractorMutation,
    GQTypes.GQPublishExtractorMutationVariables
  >(PublishExtractorDocument);
}
export const LazyBuildingsForAdtEditorDocument = gql`
    query LazyBuildingsForAdtEditor($orgId: ID!) {
  organization(id: $orgId) {
    id
    buildingsUntyped {
      id
      data
    }
  }
}
    `;

export function useLazyBuildingsForAdtEditorQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLazyBuildingsForAdtEditorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLazyBuildingsForAdtEditorQuery,
    GQTypes.GQLazyBuildingsForAdtEditorQueryVariables
  >({ query: LazyBuildingsForAdtEditorDocument, ...options });
}
export const BuildingSurveyStatusForAdtEditorDocument = gql`
    query BuildingSurveyStatusForAdtEditor($adtId: ID!) {
  activityDataTable(id: $adtId) {
    id
    measurementProject {
      id
      buildingSurveyStatus {
        ...BuildingSurveyStatusForAdtEditor
      }
      userUploadTasks {
        edges {
          node {
            id
            datasource {
              id
              name
              dataset {
                id
                canonicalDataset {
                  id
                  kind
                }
              }
            }
            assignee {
              id
              name
            }
            state
          }
        }
      }
    }
  }
}
    ${BuildingSurveyStatusForAdtEditorFragmentDoc}`;

export function useBuildingSurveyStatusForAdtEditorQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBuildingSurveyStatusForAdtEditorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBuildingSurveyStatusForAdtEditorQuery,
    GQTypes.GQBuildingSurveyStatusForAdtEditorQueryVariables
  >({ query: BuildingSurveyStatusForAdtEditorDocument, ...options });
}
export const AddActivityDataTableDialogDocument = gql`
    query AddActivityDataTableDialog($orgId: ID!) {
  organization(id: $orgId) {
    id
    name
    measurementProjects {
      ...MeasurementProjectsForMeasurementProjectSelector
    }
  }
  featureFlagEnabledForOrg(orgId: $orgId, flag: MultipleDatasourcesPerAdt)
}
    ${MeasurementProjectsForMeasurementProjectSelectorFragmentDoc}`;

export function useAddActivityDataTableDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAddActivityDataTableDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAddActivityDataTableDialogQuery,
    GQTypes.GQAddActivityDataTableDialogQueryVariables
  >({ query: AddActivityDataTableDialogDocument, ...options });
}
export const CreateActivityDataTableDocument = gql`
    mutation CreateActivityDataTable($input: CreateActivityDataTableInput!) {
  createActivityDataTable(input: $input) {
    table {
      id
      primaryDatasource {
        id
        hasIntegration
      }
    }
    activityDataTables {
      edges {
        node {
          ...ActivityDataTableFields
        }
      }
    }
  }
}
    ${ActivityDataTableFieldsFragmentDoc}`;

export function useCreateActivityDataTableMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateActivityDataTableMutation,
    GQTypes.GQCreateActivityDataTableMutationVariables
  >(CreateActivityDataTableDocument);
}
export const CreateCompanyClimateCommitmentDocument = gql`
    mutation CreateCompanyClimateCommitment($input: CreateCompanyClimateCommitmentInput!) {
  createCompanyClimateCommitment(input: $input) {
    commitment {
      id
    }
  }
}
    `;

export function useCreateCompanyClimateCommitmentMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCompanyClimateCommitmentMutation,
    GQTypes.GQCreateCompanyClimateCommitmentMutationVariables
  >(CreateCompanyClimateCommitmentDocument);
}
export const CreateDatasetDocument = gql`
    mutation CreateDataset($input: CreateDatasetInput!) {
  createDataset(input: $input) {
    dataset {
      id
    }
  }
}
    `;

export function useCreateDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateDatasetMutation,
    GQTypes.GQCreateDatasetMutationVariables
  >(CreateDatasetDocument);
}
export const UpdateDatasetDocument = gql`
    mutation UpdateDataset($input: UpdateDatasetInput!) {
  updateDataset(input: $input) {
    dataset {
      id
      name
      explainer
      customUsedFor
      customPotentialOverlaps
      canonicalDataset {
        id
        name
      }
      instructions {
        ...InstructionsBundleBaseFields
      }
      customExampleData {
        cells
      }
    }
  }
}
    ${InstructionsBundleBaseFieldsFragmentDoc}`;

export function useUpdateDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateDatasetMutation,
    GQTypes.GQUpdateDatasetMutationVariables
  >(UpdateDatasetDocument);
}
export const DeleteDatasetDocument = gql`
    mutation DeleteDataset($input: DeleteDatasetInput!) {
  deleteDataset(input: $input) {
    id
    org {
      id
      datasets {
        id
      }
    }
  }
}
    `;

export function useDeleteDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteDatasetMutation,
    GQTypes.GQDeleteDatasetMutationVariables
  >(DeleteDatasetDocument);
}
export const GetDatasourceDialogInputsDocument = gql`
    query GetDatasourceDialogInputs($orgId: ID!) {
  organization(id: $orgId) {
    id
    datasets {
      id
      name
      canonicalDataset {
        id
        kind
      }
    }
  }
}
    `;

export function useGetDatasourceDialogInputsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDatasourceDialogInputsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDatasourceDialogInputsQuery,
    GQTypes.GQGetDatasourceDialogInputsQueryVariables
  >({ query: GetDatasourceDialogInputsDocument, ...options });
}
export const CreateDatasourceAdminDocument = gql`
    mutation CreateDatasourceAdmin($input: CreateDatasourceAdminInput!) {
  createDatasourceAdmin(input: $input) {
    datasource {
      id
      ...DatasourceForDatasetPage
    }
    measurementProject {
      ...MeasurementProjectFieldsForOrgPage
    }
  }
}
    ${DatasourceForDatasetPageFragmentDoc}
${MeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useCreateDatasourceAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateDatasourceAdminMutation,
    GQTypes.GQCreateDatasourceAdminMutationVariables
  >(CreateDatasourceAdminDocument);
}
export const UpdateDatasourceAdminDocument = gql`
    mutation UpdateDatasourceAdmin($input: UpdateDatasourceAdminInput!) {
  updateDatasourceAdmin(input: $input) {
    datasource {
      id
      ...DatasourceForDatasetPage
    }
  }
}
    ${DatasourceForDatasetPageFragmentDoc}`;

export function useUpdateDatasourceAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateDatasourceAdminMutation,
    GQTypes.GQUpdateDatasourceAdminMutationVariables
  >(UpdateDatasourceAdminDocument);
}
export const CreateUserAdminVersionDocument = gql`
    mutation CreateUserAdminVersion($input: CreateUserAdminVersionInput!) {
  createUserAdminVersion(input: $input) {
    user {
      ...AssignableUserFields
    }
  }
}
    ${AssignableUserFieldsFragmentDoc}`;

export function useCreateUserAdminVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateUserAdminVersionMutation,
    GQTypes.GQCreateUserAdminVersionMutationVariables
  >(CreateUserAdminVersionDocument);
}
export const CreateUserUploadTaskDocument = gql`
    mutation CreateUserUploadTask($input: CreateUserUploadTaskInput!) {
  createUserUploadTask(input: $input) {
    measurementProject {
      id
      userUploadTasks {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
    `;

export function useCreateUserUploadTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateUserUploadTaskMutation,
    GQTypes.GQCreateUserUploadTaskMutationVariables
  >(CreateUserUploadTaskDocument);
}
export const AdminNavDocument = gql`
    query AdminNav {
  organizations {
    edges {
      node {
        ...OrgFieldsForAdminNav
      }
    }
  }
}
    ${OrgFieldsForAdminNavFragmentDoc}`;

export function useAdminNavQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQAdminNavQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQAdminNavQuery,
    GQTypes.GQAdminNavQueryVariables
  >({ query: AdminNavDocument, ...options });
}
export const RegenUserSignupTokenAdminVersionDocument = gql`
    mutation RegenUserSignupTokenAdminVersion($orgId: ID!, $userId: ID!) {
  regenerateUserSignupTokenAdminVersion(input: {orgId: $orgId, userId: $userId}) {
    user {
      id
      signupLink(orgId: $orgId)
    }
  }
}
    `;

export function useRegenUserSignupTokenAdminVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQRegenUserSignupTokenAdminVersionMutation,
    GQTypes.GQRegenUserSignupTokenAdminVersionMutationVariables
  >(RegenUserSignupTokenAdminVersionDocument);
}
export const ApplicableDatasourcesDocument = gql`
    query ApplicableDatasources($orgId: ID!, $integrationPartnerId: ID!) {
  applicableDatasources(
    orgId: $orgId
    integrationPartnerId: $integrationPartnerId
  ) {
    id
    name
    hasIntegration
    dataset {
      id
      name
    }
  }
}
    `;

export function useApplicableDatasourcesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQApplicableDatasourcesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQApplicableDatasourcesQuery,
    GQTypes.GQApplicableDatasourcesQueryVariables
  >({ query: ApplicableDatasourcesDocument, ...options });
}
export const ApplyIntegrationConnectionDocument = gql`
    mutation ApplyIntegrationConnection($input: ApplyIntegrationConnectionInput!) {
  applyIntegrationConnection(input: $input) {
    integrationConnection {
      id
      datasources {
        edges {
          node {
            id
          }
        }
      }
    }
    datasources {
      id
      activeIntegrationConnection {
        id
      }
      hasIntegration
    }
  }
}
    `;

export function useApplyIntegrationConnectionMutation() {
  return Urql.useMutation<
    GQTypes.GQApplyIntegrationConnectionMutation,
    GQTypes.GQApplyIntegrationConnectionMutationVariables
  >(ApplyIntegrationConnectionDocument);
}
export const GetCanonicalDatasetsWithOrgDocument = gql`
    query GetCanonicalDatasetsWithOrg($orgId: ID!) {
  canonicalDatasets {
    id
    name
    customerTargetSchemas {
      id
      name
      descriptionMd
    }
  }
  organization(id: $orgId) {
    id
    datasets {
      id
      canonicalDataset {
        id
      }
    }
  }
}
    `;

export function useGetCanonicalDatasetsWithOrgQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCanonicalDatasetsWithOrgQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCanonicalDatasetsWithOrgQuery,
    GQTypes.GQGetCanonicalDatasetsWithOrgQueryVariables
  >({ query: GetCanonicalDatasetsWithOrgDocument, ...options });
}
export const GetCanonicalDatasourcesDocument = gql`
    query GetCanonicalDatasources {
  canonicalDatasets {
    id
    name
    customerTargetSchemas {
      id
      name
    }
    canonicalDatasources {
      id
      name
    }
  }
}
    `;

export function useGetCanonicalDatasourcesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCanonicalDatasourcesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCanonicalDatasourcesQuery,
    GQTypes.GQGetCanonicalDatasourcesQueryVariables
  >({ query: GetCanonicalDatasourcesDocument, ...options });
}
export const ConfigureAutoBartDocument = gql`
    mutation ConfigureAutoBart($input: ConfigureAutoBartInput!) {
  configureAutoBart(input: $input) {
    assignedAdt {
      id
      connectedToAutoBart
    }
    unassignedAdt {
      id
      connectedToAutoBart
    }
  }
}
    `;

export function useConfigureAutoBartMutation() {
  return Urql.useMutation<
    GQTypes.GQConfigureAutoBartMutation,
    GQTypes.GQConfigureAutoBartMutationVariables
  >(ConfigureAutoBartDocument);
}
export const DatasetsForDatasourceSelectorDocument = gql`
    query DatasetsForDatasourceSelector($orgId: ID!) {
  organization(id: $orgId) {
    id
    datasets {
      id
      name
      datasources {
        id
        name
        hasIntegration
        userUploadTasks {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
}
    `;

export function useDatasetsForDatasourceSelectorQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDatasetsForDatasourceSelectorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDatasetsForDatasourceSelectorQuery,
    GQTypes.GQDatasetsForDatasourceSelectorQueryVariables
  >({ query: DatasetsForDatasourceSelectorDocument, ...options });
}
export const ShouldShowDuckDocument = gql`
    query ShouldShowDuck($duckName: String!) {
  shouldShowDuck(duckName: $duckName)
}
    `;

export function useShouldShowDuckQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQShouldShowDuckQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQShouldShowDuckQuery,
    GQTypes.GQShouldShowDuckQueryVariables
  >({ query: ShouldShowDuckDocument, ...options });
}
export const FindDuckDocument = gql`
    mutation FindDuck($input: FindDuckInput!) {
  findDuck(input: $input) {
    description
  }
}
    `;

export function useFindDuckMutation() {
  return Urql.useMutation<
    GQTypes.GQFindDuckMutation,
    GQTypes.GQFindDuckMutationVariables
  >(FindDuckDocument);
}
export const EditActivityDataTableDialogDocument = gql`
    query EditActivityDataTableDialog($orgId: ID!) {
  featureFlagEnabledForOrg(orgId: $orgId, flag: MultipleDatasourcesPerAdt)
}
    `;

export function useEditActivityDataTableDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEditActivityDataTableDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEditActivityDataTableDialogQuery,
    GQTypes.GQEditActivityDataTableDialogQueryVariables
  >({ query: EditActivityDataTableDialogDocument, ...options });
}
export const UpdateCompanyClimateCommitmentDocument = gql`
    mutation UpdateCompanyClimateCommitment($input: UpdateCompanyClimateCommitmentInput!) {
  updateCompanyClimateCommitment(input: $input) {
    commitment {
      id
    }
  }
}
    `;

export function useUpdateCompanyClimateCommitmentMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCompanyClimateCommitmentMutation,
    GQTypes.GQUpdateCompanyClimateCommitmentMutationVariables
  >(UpdateCompanyClimateCommitmentDocument);
}
export const UpdateMeasurementProjectDocument = gql`
    mutation UpdateMeasurementProject($input: UpdateMeasurementProjectInput!) {
  updateMeasurementProject(input: $input) {
    measurementProject {
      ...MeasurementProjectFieldsForOrgPage
    }
  }
}
    ${MeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useUpdateMeasurementProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMeasurementProjectMutation,
    GQTypes.GQUpdateMeasurementProjectMutationVariables
  >(UpdateMeasurementProjectDocument);
}
export const GetUserUploadedTableDocument = gql`
    query GetUserUploadedTable($id: ID!) {
  userUploadedTable(id: $id) {
    id
    sqlTableName
    status
    errorType
    error
    schema {
      index
      alias
      name
      kind
      forwardFill
    }
    parseConfig {
      noHeader
      skipTopNRows
      skipBottomNRows
      meltIdVars
    }
    userReviewMetadata {
      headerRowIndex
      skipBottomNRows
      columnMappings {
        watershedColumnName
        sourceFileColumnName
      }
      currencyOverride {
        selectedCurrency
      }
      buildingAreaUnitOverride
      stepsCompleted
    }
    userIngestionReview {
      id
      ingestionSchemaId
    }
    isUsingLatestUIR
    dataPreview {
      schema {
        fields {
          name
          type
        }
      }
      dataUntyped
    }
  }
}
    `;

export function useGetUserUploadedTableQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUserUploadedTableQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUserUploadedTableQuery,
    GQTypes.GQGetUserUploadedTableQueryVariables
  >({ query: GetUserUploadedTableDocument, ...options });
}
export const SetUserUploadedTableSqlTableNameDocument = gql`
    mutation SetUserUploadedTableSqlTableName($input: SetUserUploadedTableSqlTableNameInput!) {
  setUserUploadedTableSqlTableName(input: $input) {
    userUploadedTable {
      id
      sqlTableName
    }
  }
}
    `;

export function useSetUserUploadedTableSqlTableNameMutation() {
  return Urql.useMutation<
    GQTypes.GQSetUserUploadedTableSqlTableNameMutation,
    GQTypes.GQSetUserUploadedTableSqlTableNameMutationVariables
  >(SetUserUploadedTableSqlTableNameDocument);
}
export const MarkUserUploadAcknowledgedDocument = gql`
    mutation MarkUserUploadAcknowledged($input: MarkUserUploadAcknowledgedInput!) {
  markUserUploadAcknowledged(input: $input) {
    userUpload {
      id
      latestAttempt {
        id
        userUploadedTables {
          id
          shouldIgnore
        }
      }
    }
  }
}
    `;

export function useMarkUserUploadAcknowledgedMutation() {
  return Urql.useMutation<
    GQTypes.GQMarkUserUploadAcknowledgedMutation,
    GQTypes.GQMarkUserUploadAcknowledgedMutationVariables
  >(MarkUserUploadAcknowledgedDocument);
}
export const MarkUserUploadedTablesIgnoredDocument = gql`
    mutation MarkUserUploadedTablesIgnored($input: MarkUserUploadedTablesIgnoredInput!) {
  markUserUploadedTablesIgnored(input: $input) {
    userUploadedTables {
      id
      shouldIgnore
    }
  }
}
    `;

export function useMarkUserUploadedTablesIgnoredMutation() {
  return Urql.useMutation<
    GQTypes.GQMarkUserUploadedTablesIgnoredMutation,
    GQTypes.GQMarkUserUploadedTablesIgnoredMutationVariables
  >(MarkUserUploadedTablesIgnoredDocument);
}
export const SetUserUploadedTableNotIgnoredDocument = gql`
    mutation SetUserUploadedTableNotIgnored($userUploadedTableId: ID!) {
  setUserUploadedTableNotIgnored(
    input: {userUploadedTableId: $userUploadedTableId}
  ) {
    userUploadedTable {
      id
      shouldIgnore
    }
  }
}
    `;

export function useSetUserUploadedTableNotIgnoredMutation() {
  return Urql.useMutation<
    GQTypes.GQSetUserUploadedTableNotIgnoredMutation,
    GQTypes.GQSetUserUploadedTableNotIgnoredMutationVariables
  >(SetUserUploadedTableNotIgnoredDocument);
}
export const AdminDeleteFileDocument = gql`
    mutation AdminDeleteFile($fileId: ID!) @withOwner(owner: DataIngestion) {
  deleteFile(input: {fileId: $fileId}) {
    file {
      id
    }
    datasource {
      id
      summaryWithMetadata {
        id
        summaries {
          id
          signedUrl
        }
      }
    }
    measurementProject {
      id
    }
  }
}
    `;

export function useAdminDeleteFileMutation() {
  return Urql.useMutation<
    GQTypes.GQAdminDeleteFileMutation,
    GQTypes.GQAdminDeleteFileMutationVariables
  >(AdminDeleteFileDocument);
}
export const GetCleanPowerQueryPlaygroundLinkDocument = gql`
    query GetCleanPowerQueryPlaygroundLink($orgId: ID!, $pipelineRunConfig: String) {
  cleanPowerQueryPlaygroundLink(
    orgId: $orgId
    pipelineRunConfig: $pipelineRunConfig
  )
}
    `;

export function useGetCleanPowerQueryPlaygroundLinkQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCleanPowerQueryPlaygroundLinkQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCleanPowerQueryPlaygroundLinkQuery,
    GQTypes.GQGetCleanPowerQueryPlaygroundLinkQueryVariables
  >({ query: GetCleanPowerQueryPlaygroundLinkDocument, ...options });
}
export const LoginAsUserDocument = gql`
    mutation LoginAsUser($input: LoginAsUserInput!) {
  loginAsUser(input: $input) {
    token
    isSuccessful
  }
}
    `;

export function useLoginAsUserMutation() {
  return Urql.useMutation<
    GQTypes.GQLoginAsUserMutation,
    GQTypes.GQLoginAsUserMutationVariables
  >(LoginAsUserDocument);
}
export const AddSupplierPointOfContactDialog_DataDocument = gql`
    query AddSupplierPointOfContactDialog_Data {
  marketplacePointsOfContact {
    edges {
      node {
        ...AddSupplierPointOfContactDialog_MarketplacePointOfContact
      }
    }
  }
}
    ${AddSupplierPointOfContactDialog_MarketplacePointOfContactFragmentDoc}`;

export function useAddSupplierPointOfContactDialog_DataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAddSupplierPointOfContactDialog_DataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAddSupplierPointOfContactDialog_DataQuery,
    GQTypes.GQAddSupplierPointOfContactDialog_DataQueryVariables
  >({ query: AddSupplierPointOfContactDialog_DataDocument, ...options });
}
export const AddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactConnectionDocument = gql`
    mutation AddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactConnection($input: AddMarketplaceSupplierPointOfContactConnectionInput!) {
  addMarketplaceSupplierPointOfContactConnection(input: $input) {
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactConnectionMutation() {
  return Urql.useMutation<
    GQTypes.GQAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactConnectionMutation,
    GQTypes.GQAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactConnectionMutationVariables
  >(
    AddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactConnectionDocument
  );
}
export const AddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactDocument = gql`
    mutation AddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContact($input: AddMarketplaceSupplierPointOfContactInput!) {
  addMarketplaceSupplierPointOfContact(input: $input) {
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactMutation() {
  return Urql.useMutation<
    GQTypes.GQAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactMutation,
    GQTypes.GQAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactMutationVariables
  >(
    AddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactDocument
  );
}
export const AddSupplierWatershedEmployeeDialog_AddMarketplaceSupplierWatershedEmployeeDocument = gql`
    mutation AddSupplierWatershedEmployeeDialog_AddMarketplaceSupplierWatershedEmployee($input: AddMarketplaceSupplierWatershedEmployeeInput!) {
  addMarketplaceSupplierWatershedEmployee(input: $input) {
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useAddSupplierWatershedEmployeeDialog_AddMarketplaceSupplierWatershedEmployeeMutation() {
  return Urql.useMutation<
    GQTypes.GQAddSupplierWatershedEmployeeDialog_AddMarketplaceSupplierWatershedEmployeeMutation,
    GQTypes.GQAddSupplierWatershedEmployeeDialog_AddMarketplaceSupplierWatershedEmployeeMutationVariables
  >(
    AddSupplierWatershedEmployeeDialog_AddMarketplaceSupplierWatershedEmployeeDocument
  );
}
export const CreateEacMarketplaceProjectsDocument = gql`
    mutation CreateEacMarketplaceProjects($input: CreateEacMarketplaceProjectsInput!) {
  createEacMarketplaceProjects(input: $input) {
    affectedMarketplaceProjectIds
  }
}
    `;

export function useCreateEacMarketplaceProjectsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEacMarketplaceProjectsMutation,
    GQTypes.GQCreateEacMarketplaceProjectsMutationVariables
  >(CreateEacMarketplaceProjectsDocument);
}
export const MarketplaceArchetypesAndSuppliersDocument = gql`
    query MarketplaceArchetypesAndSuppliers {
  marketplaceProjectArchetypes {
    edges {
      node {
        id
        name
      }
    }
  }
  marketplaceSuppliers {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useMarketplaceArchetypesAndSuppliersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceArchetypesAndSuppliersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceArchetypesAndSuppliersQuery,
    GQTypes.GQMarketplaceArchetypesAndSuppliersQueryVariables
  >({ query: MarketplaceArchetypesAndSuppliersDocument, ...options });
}
export const CreateMarketplaceProjectDocument = gql`
    mutation CreateMarketplaceProject($input: CreateMarketplaceProjectInput!) {
  createMarketplaceProject(input: $input) {
    marketplaceProject {
      ...MarketplaceProjectFields
      supplier {
        id
        projects {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
}
    ${MarketplaceProjectFieldsFragmentDoc}`;

export function useCreateMarketplaceProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplaceProjectMutation,
    GQTypes.GQCreateMarketplaceProjectMutationVariables
  >(CreateMarketplaceProjectDocument);
}
export const UpdateMarketplaceProjectDocument = gql`
    mutation UpdateMarketplaceProject($input: UpdateMarketplaceProjectInput!) {
  updateMarketplaceProject(input: $input) {
    marketplaceProject {
      ...MarketplaceProjectFields
    }
  }
}
    ${MarketplaceProjectFieldsFragmentDoc}`;

export function useUpdateMarketplaceProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMarketplaceProjectMutation,
    GQTypes.GQUpdateMarketplaceProjectMutationVariables
  >(UpdateMarketplaceProjectDocument);
}
export const MarketplaceArchetypesAndDevelopersAndSuppliersDocument = gql`
    query MarketplaceArchetypesAndDevelopersAndSuppliers {
  marketplaceProjectArchetypes {
    edges {
      node {
        ...MarketplaceProjectArchetypeListFields
      }
    }
  }
  marketplaceDevelopers {
    edges {
      node {
        id
        name
      }
    }
  }
  marketplaceSuppliers {
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${MarketplaceProjectArchetypeListFieldsFragmentDoc}`;

export function useMarketplaceArchetypesAndDevelopersAndSuppliersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceArchetypesAndDevelopersAndSuppliersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceArchetypesAndDevelopersAndSuppliersQuery,
    GQTypes.GQMarketplaceArchetypesAndDevelopersAndSuppliersQueryVariables
  >({
    query: MarketplaceArchetypesAndDevelopersAndSuppliersDocument,
    ...options,
  });
}
export const DeleteMarketplaceAllocationInstructionDocument = gql`
    mutation DeleteMarketplaceAllocationInstruction($input: DeleteMarketplaceAllocationInstructionInput!) {
  deleteMarketplaceAllocationInstruction(input: $input) {
    allocationInstruction {
      ...AllocationInstructionFieldsForMarketplacePurchaseLineItem
    }
  }
}
    ${AllocationInstructionFieldsForMarketplacePurchaseLineItemFragmentDoc}`;

export function useDeleteMarketplaceAllocationInstructionMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMarketplaceAllocationInstructionMutation,
    GQTypes.GQDeleteMarketplaceAllocationInstructionMutationVariables
  >(DeleteMarketplaceAllocationInstructionDocument);
}
export const DeleteAllMarketplaceAllocationInstructionsDocument = gql`
    mutation DeleteAllMarketplaceAllocationInstructions($input: DeleteAllMarketplaceAllocationInstructionsInput!) {
  deleteAllMarketplaceAllocationInstructions(input: $input) {
    allocationInstructions {
      ...AllocationInstructionFieldsForMarketplacePurchaseLineItem
    }
  }
}
    ${AllocationInstructionFieldsForMarketplacePurchaseLineItemFragmentDoc}`;

export function useDeleteAllMarketplaceAllocationInstructionsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteAllMarketplaceAllocationInstructionsMutation,
    GQTypes.GQDeleteAllMarketplaceAllocationInstructionsMutationVariables
  >(DeleteAllMarketplaceAllocationInstructionsDocument);
}
export const BulkImportMarketplaceAllocationInstructionsDocument = gql`
    mutation BulkImportMarketplaceAllocationInstructions($input: BulkImportMarketplaceAllocationInstructionsInput!) {
  bulkImportMarketplaceAllocationInstructions(input: $input) {
    allocationInstructions {
      ...AllocationInstructionFieldsForMarketplacePurchaseLineItem
    }
  }
}
    ${AllocationInstructionFieldsForMarketplacePurchaseLineItemFragmentDoc}`;

export function useBulkImportMarketplaceAllocationInstructionsMutation() {
  return Urql.useMutation<
    GQTypes.GQBulkImportMarketplaceAllocationInstructionsMutation,
    GQTypes.GQBulkImportMarketplaceAllocationInstructionsMutationVariables
  >(BulkImportMarketplaceAllocationInstructionsDocument);
}
export const CreateMarketplaceProjectArchetypeDocument = gql`
    mutation CreateMarketplaceProjectArchetype($input: CreateMarketplaceProjectArchetypeInput!) {
  createMarketplaceProjectArchetype(input: $input) {
    marketplaceProjectArchetype {
      ...MarketplaceProjectArchetypeFields
    }
  }
}
    ${MarketplaceProjectArchetypeFieldsFragmentDoc}`;

export function useCreateMarketplaceProjectArchetypeMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplaceProjectArchetypeMutation,
    GQTypes.GQCreateMarketplaceProjectArchetypeMutationVariables
  >(CreateMarketplaceProjectArchetypeDocument);
}
export const UpdateMarketplaceProjectArchetypeDocument = gql`
    mutation UpdateMarketplaceProjectArchetype($input: UpdateMarketplaceProjectArchetypeInput!) {
  updateMarketplaceProjectArchetype(input: $input) {
    marketplaceProjectArchetype {
      ...MarketplaceProjectArchetypeFields
    }
  }
}
    ${MarketplaceProjectArchetypeFieldsFragmentDoc}`;

export function useUpdateMarketplaceProjectArchetypeMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMarketplaceProjectArchetypeMutation,
    GQTypes.GQUpdateMarketplaceProjectArchetypeMutationVariables
  >(UpdateMarketplaceProjectArchetypeDocument);
}
export const CreateMarketplaceAllocationInstructionDocument = gql`
    mutation CreateMarketplaceAllocationInstruction($input: CreateMarketplaceAllocationInstructionInput!) {
  createMarketplaceAllocationInstruction(input: $input) {
    allocationInstruction {
      ...AllocationInstructionFieldsForMarketplacePurchaseLineItem
    }
  }
}
    ${AllocationInstructionFieldsForMarketplacePurchaseLineItemFragmentDoc}`;

export function useCreateMarketplaceAllocationInstructionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplaceAllocationInstructionMutation,
    GQTypes.GQCreateMarketplaceAllocationInstructionMutationVariables
  >(CreateMarketplaceAllocationInstructionDocument);
}
export const EditMarketplaceAllocationInstructionDocument = gql`
    mutation EditMarketplaceAllocationInstruction($input: UpdateMarketplaceAllocationInstructionInput!) {
  updateMarketplaceAllocationInstruction(input: $input) {
    allocationInstruction {
      ...AllocationInstructionFieldsForMarketplacePurchaseLineItem
    }
  }
}
    ${AllocationInstructionFieldsForMarketplacePurchaseLineItemFragmentDoc}`;

export function useEditMarketplaceAllocationInstructionMutation() {
  return Urql.useMutation<
    GQTypes.GQEditMarketplaceAllocationInstructionMutation,
    GQTypes.GQEditMarketplaceAllocationInstructionMutationVariables
  >(EditMarketplaceAllocationInstructionDocument);
}
export const UploadMarketplaceDocumentsDocument = gql`
    mutation UploadMarketplaceDocuments($input: UploadMarketplaceDocumentsInput!) {
  uploadMarketplaceDocuments(input: $input) {
    documents {
      ...MarketplaceDocumentFieldsForTableCard
    }
  }
}
    ${MarketplaceDocumentFieldsForTableCardFragmentDoc}`;

export function useUploadMarketplaceDocumentsMutation() {
  return Urql.useMutation<
    GQTypes.GQUploadMarketplaceDocumentsMutation,
    GQTypes.GQUploadMarketplaceDocumentsMutationVariables
  >(UploadMarketplaceDocumentsDocument);
}
export const CreateMarketplaceProjectOfferingDocument = gql`
    mutation CreateMarketplaceProjectOffering($input: CreateMarketplaceProjectOfferingInput!) {
  createMarketplaceProjectOffering(input: $input) {
    marketplaceProjectOffering {
      ...MarketplaceProjectOfferingFields
    }
    marketplaceProject {
      ...MarketplaceProjectFields
    }
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
    addedVintageOfferings {
      ...MarketplaceProjectOfferingFields
    }
  }
}
    ${MarketplaceProjectOfferingFieldsFragmentDoc}
${MarketplaceProjectFieldsFragmentDoc}
${MarketplaceSupplierFieldsFragmentDoc}`;

export function useCreateMarketplaceProjectOfferingMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplaceProjectOfferingMutation,
    GQTypes.GQCreateMarketplaceProjectOfferingMutationVariables
  >(CreateMarketplaceProjectOfferingDocument);
}
export const UpdateMarketplacePurchaseLineItemDocument = gql`
    mutation UpdateMarketplacePurchaseLineItem($input: UpdateMarketplacePurchaseLineItemInput!) {
  updateMarketplacePurchaseLineItem(input: $input) {
    marketplacePurchaseLineItem {
      ...MarketplacePurchaseLineItemFields
    }
    marketplacePurchase {
      ...MarketplacePurchaseFields
    }
  }
}
    ${MarketplacePurchaseLineItemFieldsFragmentDoc}
${MarketplacePurchaseFieldsFragmentDoc}`;

export function useUpdateMarketplacePurchaseLineItemMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMarketplacePurchaseLineItemMutation,
    GQTypes.GQUpdateMarketplacePurchaseLineItemMutationVariables
  >(UpdateMarketplacePurchaseLineItemDocument);
}
export const CreateMarketplacePurchaseLineItemDocument = gql`
    mutation CreateMarketplacePurchaseLineItem($input: CreateMarketplacePurchaseLineItemInput!) {
  createMarketplacePurchaseLineItem(input: $input) {
    marketplacePurchaseLineItem {
      ...MarketplacePurchaseLineItemFields
    }
    marketplacePurchase {
      ...MarketplacePurchaseFields
    }
  }
}
    ${MarketplacePurchaseLineItemFieldsFragmentDoc}
${MarketplacePurchaseFieldsFragmentDoc}`;

export function useCreateMarketplacePurchaseLineItemMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplacePurchaseLineItemMutation,
    GQTypes.GQCreateMarketplacePurchaseLineItemMutationVariables
  >(CreateMarketplacePurchaseLineItemDocument);
}
export const DeleteMarketplacePurchaseLineItemDocument = gql`
    mutation DeleteMarketplacePurchaseLineItem($input: DeleteMarketplacePurchaseLineItemInput!) {
  deleteMarketplacePurchaseLineItem(input: $input) {
    marketplacePurchase {
      ...MarketplacePurchaseFields
    }
  }
}
    ${MarketplacePurchaseFieldsFragmentDoc}`;

export function useDeleteMarketplacePurchaseLineItemMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMarketplacePurchaseLineItemMutation,
    GQTypes.GQDeleteMarketplacePurchaseLineItemMutationVariables
  >(DeleteMarketplacePurchaseLineItemDocument);
}
export const MarketplacePurchaseLineItemFormDataDocument = gql`
    query MarketplacePurchaseLineItemFormData($orgId: ID!) {
  marketplaceProjectOfferings {
    edges {
      node {
        ...MarketplaceProjectOfferingFields
      }
    }
  }
  marketplaceProjectArchetypes {
    edges {
      node {
        ...MarketplaceProjectArchetypesForLineItemDialog
      }
    }
  }
  featureFlagEnabledForOrg(orgId: $orgId, flag: CsrdAvoidedCleanPower)
}
    ${MarketplaceProjectOfferingFieldsFragmentDoc}
${MarketplaceProjectArchetypesForLineItemDialogFragmentDoc}`;

export function useMarketplacePurchaseLineItemFormDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplacePurchaseLineItemFormDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplacePurchaseLineItemFormDataQuery,
    GQTypes.GQMarketplacePurchaseLineItemFormDataQueryVariables
  >({ query: MarketplacePurchaseLineItemFormDataDocument, ...options });
}
export const BulkUpdateMarketplacePurchaseLineItemsDocument = gql`
    mutation BulkUpdateMarketplacePurchaseLineItems($input: BulkUpdateMarketplacePurchaseLineItemsInput!) {
  bulkUpdateMarketplacePurchaseLineItems(input: $input) {
    marketplacePurchase {
      ...MarketplacePurchaseFields
    }
    createdLineItems {
      ...MarketplacePurchaseLineItemFields
    }
    deletedLineItems {
      ...MarketplacePurchaseLineItemFields
    }
    updatedLineItems {
      ...MarketplacePurchaseLineItemFields
    }
  }
}
    ${MarketplacePurchaseFieldsFragmentDoc}
${MarketplacePurchaseLineItemFieldsFragmentDoc}`;

export function useBulkUpdateMarketplacePurchaseLineItemsMutation() {
  return Urql.useMutation<
    GQTypes.GQBulkUpdateMarketplacePurchaseLineItemsMutation,
    GQTypes.GQBulkUpdateMarketplacePurchaseLineItemsMutationVariables
  >(BulkUpdateMarketplacePurchaseLineItemsDocument);
}
export const CreateMarketplaceSupplierDocument = gql`
    mutation CreateMarketplaceSupplier($input: CreateMarketplaceSupplierInput!) {
  createMarketplaceSupplier(input: $input) {
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useCreateMarketplaceSupplierMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplaceSupplierMutation,
    GQTypes.GQCreateMarketplaceSupplierMutationVariables
  >(CreateMarketplaceSupplierDocument);
}
export const UpdateMarketplaceSupplierDocument = gql`
    mutation UpdateMarketplaceSupplier($input: UpdateMarketplaceSupplierInput!) {
  updateMarketplaceSupplier(input: $input) {
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useUpdateMarketplaceSupplierMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMarketplaceSupplierMutation,
    GQTypes.GQUpdateMarketplaceSupplierMutationVariables
  >(UpdateMarketplaceSupplierDocument);
}
export const CompleteMeasurementProjectDocument = gql`
    mutation CompleteMeasurementProject($input: CompleteMeasurementProjectInput!) {
  completeMeasurementProject(input: $input) {
    measurementProject {
      id
      active
    }
  }
}
    `;

export function useCompleteMeasurementProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQCompleteMeasurementProjectMutation,
    GQTypes.GQCompleteMeasurementProjectMutationVariables
  >(CompleteMeasurementProjectDocument);
}
export const UncompleteMeasurementProjectDocument = gql`
    mutation UncompleteMeasurementProject($input: UncompleteMeasurementProjectInput!) {
  uncompleteMeasurementProject(input: $input) {
    measurementProject {
      id
      active
    }
  }
}
    `;

export function useUncompleteMeasurementProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQUncompleteMeasurementProjectMutation,
    GQTypes.GQUncompleteMeasurementProjectMutationVariables
  >(UncompleteMeasurementProjectDocument);
}
export const UpdateUserUploadTaskDocument = gql`
    mutation UpdateUserUploadTask($input: UpdateUserUploadTaskInput!) {
  updateUserUploadTask(input: $input) {
    userUploadTask {
      id
      state
      datasource {
        id
      }
    }
  }
}
    `;

export function useUpdateUserUploadTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateUserUploadTaskMutation,
    GQTypes.GQUpdateUserUploadTaskMutationVariables
  >(UpdateUserUploadTaskDocument);
}
export const AdminDeleteUserUploadTaskDocument = gql`
    mutation AdminDeleteUserUploadTask($input: DeleteUserUploadTaskInput!) {
  deleteUserUploadTask(input: $input) {
    measurementProject {
      ...MeasurementProjectFieldsForOrgPage
    }
  }
}
    ${MeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useAdminDeleteUserUploadTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQAdminDeleteUserUploadTaskMutation,
    GQTypes.GQAdminDeleteUserUploadTaskMutationVariables
  >(AdminDeleteUserUploadTaskDocument);
}
export const MarkMeasurementProjectDatasetCompletedDocument = gql`
    mutation MarkMeasurementProjectDatasetCompleted($input: MarkMeasurementProjectDatasetCompletedInput!) {
  markMeasurementProjectDatasetCompleted(input: $input) {
    measurementProjectDataset {
      ...MeasurementProjectDatasetFieldsForOrgPage
    }
    measurementProject {
      ...MeasurementProjectFieldsForOrgPage
    }
  }
}
    ${MeasurementProjectDatasetFieldsForOrgPageFragmentDoc}
${MeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useMarkMeasurementProjectDatasetCompletedMutation() {
  return Urql.useMutation<
    GQTypes.GQMarkMeasurementProjectDatasetCompletedMutation,
    GQTypes.GQMarkMeasurementProjectDatasetCompletedMutationVariables
  >(MarkMeasurementProjectDatasetCompletedDocument);
}
export const CreateFacilitiesSetupTaskDocument = gql`
    mutation CreateFacilitiesSetupTask($input: CreateFacilitiesSetupTaskInput!) {
  createFacilitiesSetupTask(input: $input) {
    measurementProject {
      ...MeasurementProjectFieldsForOrgPage
    }
  }
}
    ${MeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useCreateFacilitiesSetupTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFacilitiesSetupTaskMutation,
    GQTypes.GQCreateFacilitiesSetupTaskMutationVariables
  >(CreateFacilitiesSetupTaskDocument);
}
export const DeleteFacilitiesSetupTaskDocument = gql`
    mutation DeleteFacilitiesSetupTask($input: DeleteFacilitiesSetupTaskInput!) {
  deleteFacilitiesSetupTask(input: $input) {
    measurementProject {
      ...MeasurementProjectFieldsForOrgPage
    }
  }
}
    ${MeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useDeleteFacilitiesSetupTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFacilitiesSetupTaskMutation,
    GQTypes.GQDeleteFacilitiesSetupTaskMutationVariables
  >(DeleteFacilitiesSetupTaskDocument);
}
export const DeleteMeasurementProjectDocument = gql`
    mutation DeleteMeasurementProject($input: DeleteMeasurementProjectInput!) {
  deleteMeasurementProject(input: $input) {
    org {
      id
      measurementProjects {
        ...MeasurementProjectFieldsForOrgPage
      }
    }
  }
}
    ${MeasurementProjectFieldsForOrgPageFragmentDoc}`;

export function useDeleteMeasurementProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMeasurementProjectMutation,
    GQTypes.GQDeleteMeasurementProjectMutationVariables
  >(DeleteMeasurementProjectDocument);
}
export const OrgSpecificEmissionsModelsDocument = gql`
    query OrgSpecificEmissionsModels($orgId: ID!) {
  emissionsModelsForOrg(orgId: $orgId) {
    id
    title
    description
    isArchived
    kind
    scope
    matchers {
      id
      businessActivityType
    }
    latestPublishedVersion {
      id
      createdAt
      changelog
      title
      author {
        id
        displayName
      }
    }
  }
}
    `;

export function useOrgSpecificEmissionsModelsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgSpecificEmissionsModelsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgSpecificEmissionsModelsQuery,
    GQTypes.GQOrgSpecificEmissionsModelsQueryVariables
  >({ query: OrgSpecificEmissionsModelsDocument, ...options });
}
export const OrgSpecificReferenceDataDocument = gql`
    query OrgSpecificReferenceData($orgId: ID!) {
  latestReferenceDataRevisionsForOrg(orgId: $orgId) {
    id
    createdAt
    archivedAt
    state
    organization {
      id
      name
    }
    source {
      id
      name
    }
    version {
      id
      versionName
    }
    author {
      id
      name
    }
  }
}
    `;

export function useOrgSpecificReferenceDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgSpecificReferenceDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgSpecificReferenceDataQuery,
    GQTypes.GQOrgSpecificReferenceDataQueryVariables
  >({ query: OrgSpecificReferenceDataDocument, ...options });
}
export const OrganizationFilesDocument = gql`
    query OrganizationFiles($orgId: ID!, $fileFilters: FileMetadataFilterInput) {
  organization(id: $orgId) {
    id
    measurementProjects {
      id
      name
      userUploadTasks {
        edges {
          node {
            id
            datasource {
              id
              name
              dataset {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  files(orgId: $orgId, filters: $fileFilters) {
    edges {
      node {
        ...FileMetadataFieldsForOrgFilesDialog
      }
    }
  }
}
    ${FileMetadataFieldsForOrgFilesDialogFragmentDoc}`;

export function useOrganizationFilesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationFilesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationFilesQuery,
    GQTypes.GQOrganizationFilesQueryVariables
  >({ query: OrganizationFilesDocument, ...options });
}
export const AdtsForRunPipelineDialogDocument = gql`
    query AdtsForRunPipelineDialog($orgId: ID!) {
  activityDataTables(orgId: $orgId) {
    edges {
      node {
        ...AdtFieldsForRunPipelineDialog
      }
    }
  }
  shouldAllowPipelineResultFootprintKind: featureFlagEnabledForOrg(
    orgId: $orgId
    flag: ShouldAllowPipelineResultFootprintKind
  )
}
    ${AdtFieldsForRunPipelineDialogFragmentDoc}`;

export function useAdtsForRunPipelineDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAdtsForRunPipelineDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAdtsForRunPipelineDialogQuery,
    GQTypes.GQAdtsForRunPipelineDialogQueryVariables
  >({ query: AdtsForRunPipelineDialogDocument, ...options });
}
export const GetPipelineRunsForCreateFootprintDialogDocument = gql`
    query GetPipelineRunsForCreateFootprintDialog($orgId: ID!) {
  pipelineRuns(orgId: $orgId) {
    edges {
      node {
        id
        name
        createdAt
        pipelineResults {
          ...PipelineResultForCreateFootprintSnapshotDialog
        }
        ...PipelineRunForCreateFootprintDialog
      }
    }
  }
  footprintsForOrg(orgId: $orgId) {
    edges {
      node {
        ...FootprintForCreateFootprintSnapshotDialog
      }
    }
  }
}
    ${PipelineResultForCreateFootprintSnapshotDialogFragmentDoc}
${PipelineRunForCreateFootprintDialogFragmentDoc}
${FootprintForCreateFootprintSnapshotDialogFragmentDoc}`;

export function useGetPipelineRunsForCreateFootprintDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPipelineRunsForCreateFootprintDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPipelineRunsForCreateFootprintDialogQuery,
    GQTypes.GQGetPipelineRunsForCreateFootprintDialogQueryVariables
  >({ query: GetPipelineRunsForCreateFootprintDialogDocument, ...options });
}
export const CreateFootprintSnapshotDocument = gql`
    mutation CreateFootprintSnapshot($input: CreateFootprintSnapshotInput!) {
  createFootprintSnapshot(input: $input) {
    job {
      id
      error {
        message
        details
      }
    }
  }
}
    `;

export function useCreateFootprintSnapshotMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFootprintSnapshotMutation,
    GQTypes.GQCreateFootprintSnapshotMutationVariables
  >(CreateFootprintSnapshotDocument);
}
export const GetPipelineResultsByIdDocument = gql`
    query GetPipelineResultsById($orgId: ID!, $pipelineResultIds: [ID!]!) {
  pipelineResults(orgId: $orgId, pipelineResultIds: $pipelineResultIds) {
    edges {
      node {
        ...PipelineResultForOrg
      }
    }
  }
}
    ${PipelineResultForOrgFragmentDoc}`;

export function useGetPipelineResultsByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPipelineResultsByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPipelineResultsByIdQuery,
    GQTypes.GQGetPipelineResultsByIdQueryVariables
  >({ query: GetPipelineResultsByIdDocument, ...options });
}
export const GetAdtsFromAdeIdsDocument = gql`
    query GetAdtsFromAdeIds($ids: [ID!]!) {
  activityDataExtractorsById(ids: $ids) {
    id
    activityDataTable {
      id
      name
    }
  }
}
    `;

export function useGetAdtsFromAdeIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAdtsFromAdeIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAdtsFromAdeIdsQuery,
    GQTypes.GQGetAdtsFromAdeIdsQueryVariables
  >({ query: GetAdtsFromAdeIdsDocument, ...options });
}
export const GetFootprintTagDetailsForOrgDocument = gql`
    query GetFootprintTagDetailsForOrg($orgId: ID!) {
  footprintTagsForOrg(orgId: $orgId) {
    id
    tagName
    description
    watershedEditor {
      id
      name
      email
    }
  }
}
    `;

export function useGetFootprintTagDetailsForOrgQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintTagDetailsForOrgQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintTagDetailsForOrgQuery,
    GQTypes.GQGetFootprintTagDetailsForOrgQueryVariables
  >({ query: GetFootprintTagDetailsForOrgDocument, ...options });
}
export const GetFootprintExclusionAndCategoryRulesForOrgDocument = gql`
    query GetFootprintExclusionAndCategoryRulesForOrg($orgId: ID!) {
  organization(id: $orgId) {
    id
    footprintExclusionRules {
      id
    }
    footprintCategoryRules {
      id
    }
  }
}
    `;

export function useGetFootprintExclusionAndCategoryRulesForOrgQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintExclusionAndCategoryRulesForOrgQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintExclusionAndCategoryRulesForOrgQuery,
    GQTypes.GQGetFootprintExclusionAndCategoryRulesForOrgQueryVariables
  >({ query: GetFootprintExclusionAndCategoryRulesForOrgDocument, ...options });
}
export const GetMarketplaceAllocationsDocument = gql`
    query GetMarketplaceAllocations($orgId: ID!) {
  marketplaceAllocationInstructions(orgId: $orgId) {
    edges {
      node {
        id
        amountKwh
        locationCity
        locationGrid
        locationState
        locationCountry
        buildingName
        vendorEntity
        vendorSubentity
        categoryId
        ghgCategoryId
        footprintKind
        subcategoryId
        interval
        purchaseLineItem {
          id
          purchase {
            id
          }
        }
      }
    }
  }
}
    `;

export function useGetMarketplaceAllocationsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetMarketplaceAllocationsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetMarketplaceAllocationsQuery,
    GQTypes.GQGetMarketplaceAllocationsQueryVariables
  >({ query: GetMarketplaceAllocationsDocument, ...options });
}
export const EmissionsModelPipelineDialogDocument = gql`
    query EmissionsModelPipelineDialog($orgId: ID!) {
  availableReleases: emissionsModelReleases(orgId: $orgId) {
    ...EmissionsModelReleaseMetadata
  }
  measurementTestSuites(includeDeleted: false, criticalOnly: true) {
    ...MeasurementTestSuiteLiteListFields
  }
  activityDataTables(orgId: $orgId) {
    edges {
      node {
        ...AdtFieldsForRunPipelineDialog
      }
    }
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}
${MeasurementTestSuiteLiteListFieldsFragmentDoc}
${AdtFieldsForRunPipelineDialogFragmentDoc}`;

export function useEmissionsModelPipelineDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelPipelineDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelPipelineDialogQuery,
    GQTypes.GQEmissionsModelPipelineDialogQueryVariables
  >({ query: EmissionsModelPipelineDialogDocument, ...options });
}
export const RefreshCarbonRemovalActivityDataTableDocument = gql`
    mutation RefreshCarbonRemovalActivityDataTable($input: RefreshCarbonRemovalActivityDataTableInput!) {
  refreshCarbonRemovalActivityDataTable(input: $input) {
    jobId
  }
}
    `;

export function useRefreshCarbonRemovalActivityDataTableMutation() {
  return Urql.useMutation<
    GQTypes.GQRefreshCarbonRemovalActivityDataTableMutation,
    GQTypes.GQRefreshCarbonRemovalActivityDataTableMutationVariables
  >(RefreshCarbonRemovalActivityDataTableDocument);
}
export const GetFootprintSnapshotDetailsDocument = gql`
    query GetFootprintSnapshotDetails($snapshotId: ID!) {
  footprintSnapshot(id: $snapshotId) {
    ...GetFootprintSnapshotDetailsSnapshot
  }
}
    ${GetFootprintSnapshotDetailsSnapshotFragmentDoc}`;

export function useGetFootprintSnapshotDetailsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintSnapshotDetailsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintSnapshotDetailsQuery,
    GQTypes.GQGetFootprintSnapshotDetailsQueryVariables
  >({ query: GetFootprintSnapshotDetailsDocument, ...options });
}
export const FootprintSnapshotsDocument = gql`
    query FootprintSnapshots($orgId: ID!, $isAutoGenerated: Boolean, $after: String, $before: String, $first: Int, $last: Int) {
  footprintSnapshots(
    orgId: $orgId
    isAutoGenerated: $isAutoGenerated
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...FootprintSnapshotOrgFootprintsFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${FootprintSnapshotOrgFootprintsFieldsFragmentDoc}`;

export function useFootprintSnapshotsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintSnapshotsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintSnapshotsQuery,
    GQTypes.GQFootprintSnapshotsQueryVariables
  >({ query: FootprintSnapshotsDocument, ...options });
}
export const MakeFootprintSnapshotUserVisibleDocument = gql`
    mutation MakeFootprintSnapshotUserVisible($input: MakeFootprintSnapshotUserVisibleInput!) {
  makeFootprintSnapshotUserVisible(input: $input) {
    id
    isUserVisible
  }
}
    `;

export function useMakeFootprintSnapshotUserVisibleMutation() {
  return Urql.useMutation<
    GQTypes.GQMakeFootprintSnapshotUserVisibleMutation,
    GQTypes.GQMakeFootprintSnapshotUserVisibleMutationVariables
  >(MakeFootprintSnapshotUserVisibleDocument);
}
export const GetFootprintTagsForOrgDocument = gql`
    query GetFootprintTagsForOrg($orgId: ID!) {
  footprintTagsForOrg(orgId: $orgId) {
    id
    createdAt
    tagName
    description
    rulesList
    watershedEditor {
      id
      name
      email
    }
  }
}
    `;

export function useGetFootprintTagsForOrgQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintTagsForOrgQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintTagsForOrgQuery,
    GQTypes.GQGetFootprintTagsForOrgQueryVariables
  >({ query: GetFootprintTagsForOrgDocument, ...options });
}
export const DeleteFootprintTagDocument = gql`
    mutation DeleteFootprintTag($input: DeleteFootprintTagInput!) {
  deleteFootprintTag(input: $input) {
    footprintTag {
      id
    }
  }
}
    `;

export function useDeleteFootprintTagMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFootprintTagMutation,
    GQTypes.GQDeleteFootprintTagMutationVariables
  >(DeleteFootprintTagDocument);
}
export const OrganizationInboxDocument = gql`
    query OrganizationInbox($orgId: ID!) {
  organization(id: $orgId) {
    __typename
    id
    name
    measurementProjects(active: true) {
      id
    }
  }
  inboxItemsForOrg(orgId: $orgId) {
    edges {
      node {
        ...InboxItemFields
      }
    }
  }
}
    ${InboxItemFieldsFragmentDoc}`;

export function useOrganizationInboxQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationInboxQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationInboxQuery,
    GQTypes.GQOrganizationInboxQueryVariables
  >({ query: OrganizationInboxDocument, ...options });
}
export const OrgResetDialogDocument = gql`
    query OrgResetDialog {
  organizations {
    edges {
      node {
        id
        name
        demoOrg
        company {
          id
          name
        }
      }
    }
  }
}
    `;

export function useOrgResetDialogQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgResetDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgResetDialogQuery,
    GQTypes.GQOrgResetDialogQueryVariables
  >({ query: OrgResetDialogDocument, ...options });
}
export const OrganizationFilesPageDocument = gql`
    query OrganizationFilesPage($id: ID!) {
  organization(id: $id) {
    id
    name
    sharedFiles {
      edges {
        node {
          ...SharedFileForFilesTable
        }
      }
    }
    sharedFileRequests {
      edges {
        node {
          ...SharedFileRequestForTable
        }
      }
    }
  }
}
    ${SharedFileForFilesTableFragmentDoc}
${SharedFileRequestForTableFragmentDoc}`;

export function useOrganizationFilesPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationFilesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationFilesPageQuery,
    GQTypes.GQOrganizationFilesPageQueryVariables
  >({ query: OrganizationFilesPageDocument, ...options });
}
export const FulfillShareFileRequestDocument = gql`
    mutation FulfillShareFileRequest($input: FulfillShareFileRequestInput!) {
  fulfillShareFileRequest(input: $input) {
    sharedFileRequests {
      ...SharedFileRequestForTable
    }
    newSharedFiles {
      ...SharedFileForFilesTable
    }
  }
}
    ${SharedFileRequestForTableFragmentDoc}
${SharedFileForFilesTableFragmentDoc}`;

export function useFulfillShareFileRequestMutation() {
  return Urql.useMutation<
    GQTypes.GQFulfillShareFileRequestMutation,
    GQTypes.GQFulfillShareFileRequestMutationVariables
  >(FulfillShareFileRequestDocument);
}
export const OrganizationDocument = gql`
    query Organization($id: ID!) {
  organization(id: $id) {
    ...OrganizationFieldsForOrgPage
    ...AdminUsersForOrgPage
    orgAccess {
      id
      expiresAt
    }
  }
}
    ${OrganizationFieldsForOrgPageFragmentDoc}
${AdminUsersForOrgPageFragmentDoc}`;

export function useOrganizationQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationQuery,
    GQTypes.GQOrganizationQueryVariables
  >({ query: OrganizationDocument, ...options });
}
export const PlansForOrgDocument = gql`
    query PlansForOrg($id: ID!) {
  plansForOrg(id: $id) {
    id
  }
}
    `;

export function usePlansForOrgQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQPlansForOrgQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQPlansForOrgQuery,
    GQTypes.GQPlansForOrgQueryVariables
  >({ query: PlansForOrgDocument, ...options });
}
export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    org {
      id
      passwordAuthDisabled
      magicLinkDisabled
      notionUrl
      domains
      currency
      fiscalYearStartMonth
      logoRemotePath
      iconRemotePath
      country
      company {
        name
        id
      }
      ...OrganizationFieldsForOrgPage
    }
  }
}
    ${OrganizationFieldsForOrgPageFragmentDoc}`;

export function useUpdateOrganizationMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOrganizationMutation,
    GQTypes.GQUpdateOrganizationMutationVariables
  >(UpdateOrganizationDocument);
}
export const DeleteOrganizationDocument = gql`
    mutation DeleteOrganization($input: DeleteOrganizationInput!) {
  deleteOrganization(input: $input) {
    id
  }
}
    `;

export function useDeleteOrganizationMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteOrganizationMutation,
    GQTypes.GQDeleteOrganizationMutationVariables
  >(DeleteOrganizationDocument);
}
export const InitializeWorkosDocument = gql`
    mutation InitializeWorkos($input: InitializeWorkosInput!) {
  initializeWorkos(input: $input) {
    org {
      id
      workosOrgId
    }
  }
}
    `;

export function useInitializeWorkosMutation() {
  return Urql.useMutation<
    GQTypes.GQInitializeWorkosMutation,
    GQTypes.GQInitializeWorkosMutationVariables
  >(InitializeWorkosDocument);
}
export const UpdateOrgProfileAdminDocument = gql`
    mutation UpdateOrgProfileAdmin($input: UpdateOrgProfileInput!) {
  updateOrgProfile(input: $input) {
    organization {
      ...OrganizationFieldsForOrgPage
    }
  }
}
    ${OrganizationFieldsForOrgPageFragmentDoc}`;

export function useUpdateOrgProfileAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOrgProfileAdminMutation,
    GQTypes.GQUpdateOrgProfileAdminMutationVariables
  >(UpdateOrgProfileAdminDocument);
}
export const OrganizationUsersDocument = gql`
    query OrganizationUsers($orgId: ID!) {
  organization(id: $orgId) {
    roles {
      edges {
        node {
          ...RoleInfo
        }
      }
    }
    id
    name
    domains
    watershedPlanLegacy
    canAccessCorporate
    canAccessFinance
    passwordAuthDisabled
    users(filters: {includeWatershedEmployees: true}) {
      edges {
        node {
          ...UserFieldsForOrgPage
        }
      }
    }
    magicLinkDisabled
    workosOrgId
  }
}
    ${RoleInfoFragmentDoc}
${UserFieldsForOrgPageFragmentDoc}`;

export function useOrganizationUsersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationUsersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationUsersQuery,
    GQTypes.GQOrganizationUsersQueryVariables
  >({ query: OrganizationUsersDocument, ...options });
}
export const GetOrganizationPointOfContactDocument = gql`
    query GetOrganizationPointOfContact($orgId: ID!, $pointOfContactKinds: [OrgPointOfContactKind!]) {
  organization(id: $orgId) {
    id
    name
    pointsOfContact(kinds: $pointOfContactKinds) {
      id
      watershedEmployee {
        id
        name
      }
    }
  }
}
    `;

export function useGetOrganizationPointOfContactQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrganizationPointOfContactQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrganizationPointOfContactQuery,
    GQTypes.GQGetOrganizationPointOfContactQueryVariables
  >({ query: GetOrganizationPointOfContactDocument, ...options });
}
export const OrganizationSelectorDataDocument = gql`
    query OrganizationSelectorData {
  organizations {
    edges {
      node {
        id
        name
        demoOrg
        testOrg
      }
    }
  }
}
    `;

export function useOrganizationSelectorDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationSelectorDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationSelectorDataQuery,
    GQTypes.GQOrganizationSelectorDataQueryVariables
  >({ query: OrganizationSelectorDataDocument, ...options });
}
export const OrganizationAllUsersDocument = gql`
    query OrganizationAllUsers($orgId: ID!) {
  organization(id: $orgId) {
    id
    users(filters: {includeWatershedEmployees: true}) {
      edges {
        node {
          id
          name
          email
        }
      }
    }
    pointsOfContact {
      id
      watershedEmployee {
        id
        name
        email
      }
    }
  }
}
    `;

export function useOrganizationAllUsersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationAllUsersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationAllUsersQuery,
    GQTypes.GQOrganizationAllUsersQueryVariables
  >({ query: OrganizationAllUsersDocument, ...options });
}
export const ArchivePipelineResultDocument = gql`
    mutation ArchivePipelineResult($id: ID!) {
  archivePipelineResult(input: {id: $id}) {
    pipelineResult {
      id
      orgId
    }
  }
}
    `;

export function useArchivePipelineResultMutation() {
  return Urql.useMutation<
    GQTypes.GQArchivePipelineResultMutation,
    GQTypes.GQArchivePipelineResultMutationVariables
  >(ArchivePipelineResultDocument);
}
export const UpdatePipelineResultDocument = gql`
    mutation UpdatePipelineResult($input: UpdatePipelineResultInput!) {
  updatePipelineResult(input: $input) {
    pipelineResult {
      id
      name
    }
  }
}
    `;

export function useUpdatePipelineResultMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePipelineResultMutation,
    GQTypes.GQUpdatePipelineResultMutationVariables
  >(UpdatePipelineResultDocument);
}
export const GetAdeInfoByIdDocument = gql`
    query GetAdeInfoById($ids: [ID!]!) {
  activityDataExtractorsById(ids: $ids) {
    id
    name
  }
}
    `;

export function useGetAdeInfoByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAdeInfoByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAdeInfoByIdQuery,
    GQTypes.GQGetAdeInfoByIdQueryVariables
  >({ query: GetAdeInfoByIdDocument, ...options });
}
export const GetAddUploadToAdtDataDocument = gql`
    query GetAddUploadToAdtData($orgId: ID!) {
  organization(id: $orgId) {
    id
    datasets {
      ...DatasetFieldsForOrgPage
    }
  }
  activityDataTables(orgId: $orgId) {
    edges {
      node {
        ...ActivityDataTableFields
      }
    }
  }
}
    ${DatasetFieldsForOrgPageFragmentDoc}
${ActivityDataTableFieldsFragmentDoc}`;

export function useGetAddUploadToAdtDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAddUploadToAdtDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAddUploadToAdtDataQuery,
    GQTypes.GQGetAddUploadToAdtDataQueryVariables
  >({ query: GetAddUploadToAdtDataDocument, ...options });
}
export const GetUserUploadedTableDataPreviewDocument = gql`
    query GetUserUploadedTableDataPreview($ids: [ID!]!) {
  userUploadedTablesById(ids: $ids) {
    ...UserUploadedTableDataPreviewFields
  }
}
    ${UserUploadedTableDataPreviewFieldsFragmentDoc}`;

export function useGetUserUploadedTableDataPreviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetUserUploadedTableDataPreviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetUserUploadedTableDataPreviewQuery,
    GQTypes.GQGetUserUploadedTableDataPreviewQueryVariables
  >({ query: GetUserUploadedTableDataPreviewDocument, ...options });
}
export const AdminProductPreviewBartBuildingsPushDocument = gql`
    query AdminProductPreviewBartBuildingsPush($activityDataExtractorId: ID!) @withOwner(owner: DataIngestion) {
  previewCadtBuildingsPush(activityDataExtractorId: $activityDataExtractorId) {
    overwriteDuplicatesDiffUntyped
    mergePeriodsDiffUntyped
    customFields {
      id
      name
    }
    orgUnitTypes {
      id
      name
      stableId
    }
    orgUnits {
      id
      name
      stableId
    }
  }
}
    `;

export function useAdminProductPreviewBartBuildingsPushQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAdminProductPreviewBartBuildingsPushQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAdminProductPreviewBartBuildingsPushQuery,
    GQTypes.GQAdminProductPreviewBartBuildingsPushQueryVariables
  >({ query: AdminProductPreviewBartBuildingsPushDocument, ...options });
}
export const RunPlaygroundSqlDocument = gql`
    mutation RunPlaygroundSql($input: RunPlaygroundSqlInput!) {
  runPlaygroundSql(input: $input) {
    run {
      id
    }
  }
}
    `;

export function useRunPlaygroundSqlMutation() {
  return Urql.useMutation<
    GQTypes.GQRunPlaygroundSqlMutation,
    GQTypes.GQRunPlaygroundSqlMutationVariables
  >(RunPlaygroundSqlDocument);
}
export const GetPlaygroundSqlRunsDocument = gql`
    query GetPlaygroundSqlRuns($orgId: ID!, $userId: ID, $first: Int, $after: String) {
  playgroundSqlRuns(orgId: $orgId, userId: $userId, first: $first, after: $after) {
    edges {
      node {
        ...PlaygroundSqlRunForPlayground
      }
    }
  }
}
    ${PlaygroundSqlRunForPlaygroundFragmentDoc}`;

export function useGetPlaygroundSqlRunsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPlaygroundSqlRunsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPlaygroundSqlRunsQuery,
    GQTypes.GQGetPlaygroundSqlRunsQueryVariables
  >({ query: GetPlaygroundSqlRunsDocument, ...options });
}
export const GetPlaygroundSqlDocument = gql`
    query GetPlaygroundSql($id: ID!) {
  playgroundSql(id: $id) {
    id
    orgId
    sql
  }
}
    `;

export function useGetPlaygroundSqlQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPlaygroundSqlQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPlaygroundSqlQuery,
    GQTypes.GQGetPlaygroundSqlQueryVariables
  >({ query: GetPlaygroundSqlDocument, ...options });
}
export const GetNonOrgSpecificQuickSwitcherDataDocument = gql`
    query GetNonOrgSpecificQuickSwitcherData {
  emissionsModelsStable(showArchived: false) {
    edges {
      node {
        id
        title
      }
    }
  }
  referenceDataSources(filter: All, excludeArchived: true, last: 1000) {
    edges {
      node {
        name
        id
        orgId
      }
    }
  }
  measurementTestSuites(includeDeleted: false, criticalOnly: false) {
    id
    title
  }
  reportConfigs {
    edges {
      node {
        id
        reportType
        shortName
        longName
        description
      }
    }
  }
  emissionsModelReleases {
    id
    businessActivityTypeName
    displayName
  }
}
    `;

export function useGetNonOrgSpecificQuickSwitcherDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetNonOrgSpecificQuickSwitcherDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetNonOrgSpecificQuickSwitcherDataQuery,
    GQTypes.GQGetNonOrgSpecificQuickSwitcherDataQueryVariables
  >({ query: GetNonOrgSpecificQuickSwitcherDataDocument, ...options });
}
export const GetOrgSpecificQuickSwitcherDataDocument = gql`
    query GetOrgSpecificQuickSwitcherData($orgId: ID!) {
  footprintTagsForOrg(orgId: $orgId) {
    id
    tagName
  }
  activityDataTables(orgId: $orgId, last: 1000) {
    edges {
      node {
        id
        name
      }
    }
  }
  userUploadTasksForOrg(orgId: $orgId) {
    id
    datasource {
      id
      name
    }
    measurementProject {
      id
      name
    }
  }
}
    `;

export function useGetOrgSpecificQuickSwitcherDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrgSpecificQuickSwitcherDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrgSpecificQuickSwitcherDataQuery,
    GQTypes.GQGetOrgSpecificQuickSwitcherDataQueryVariables
  >({ query: GetOrgSpecificQuickSwitcherDataDocument, ...options });
}
export const GetPublicDisclosureIDsDocument = gql`
    query GetPublicDisclosureIDs {
  publicDisclosureIDs
}
    `;

export function useGetPublicDisclosureIDsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPublicDisclosureIDsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPublicDisclosureIDsQuery,
    GQTypes.GQGetPublicDisclosureIDsQueryVariables
  >({ query: GetPublicDisclosureIDsDocument, ...options });
}
export const GetPrivateDisclosureIDsDocument = gql`
    query GetPrivateDisclosureIDs($orgId: ID!) {
  privateDisclosureIDs(orgId: $orgId)
}
    `;

export function useGetPrivateDisclosureIDsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPrivateDisclosureIDsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPrivateDisclosureIDsQuery,
    GQTypes.GQGetPrivateDisclosureIDsQueryVariables
  >({ query: GetPrivateDisclosureIDsDocument, ...options });
}
export const CreateReferenceDataCitationDocument = gql`
    mutation CreateReferenceDataCitation($input: CreateReferenceDataCitationInput!) {
  createReferenceDataCitation(input: $input) {
    referenceDataCitation {
      ...ReferenceDataCitationFields
    }
  }
}
    ${ReferenceDataCitationFieldsFragmentDoc}`;

export function useCreateReferenceDataCitationMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReferenceDataCitationMutation,
    GQTypes.GQCreateReferenceDataCitationMutationVariables
  >(CreateReferenceDataCitationDocument);
}
export const UpdateReferenceDataCitationDocument = gql`
    mutation UpdateReferenceDataCitation($input: UpdateReferenceDataCitationInput!) {
  updateReferenceDataCitation(input: $input) {
    referenceDataCitation {
      ...ReferenceDataCitationFields
    }
  }
}
    ${ReferenceDataCitationFieldsFragmentDoc}`;

export function useUpdateReferenceDataCitationMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReferenceDataCitationMutation,
    GQTypes.GQUpdateReferenceDataCitationMutationVariables
  >(UpdateReferenceDataCitationDocument);
}
export const ReferenceDataCitationsDocument = gql`
    query ReferenceDataCitations($after: String, $before: String, $first: Int, $last: Int, $orgId: ID, $searchString: String, $excludeArchived: Boolean, $includeSlugWithName: String) {
  referenceDataCitations(
    after: $after
    before: $before
    first: $first
    last: $last
    orgId: $orgId
    searchString: $searchString
    excludeArchived: $excludeArchived
    includeSlugWithName: $includeSlugWithName
  ) {
    edges {
      node {
        ...ReferenceDataCitationFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${ReferenceDataCitationFieldsFragmentDoc}`;

export function useReferenceDataCitationsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataCitationsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataCitationsQuery,
    GQTypes.GQReferenceDataCitationsQueryVariables
  >({ query: ReferenceDataCitationsDocument, ...options });
}
export const ReferenceDataCitationDocument = gql`
    query ReferenceDataCitation($id: ID!) {
  referenceDataCitation(id: $id) {
    ...ReferenceDataCitationFields
  }
}
    ${ReferenceDataCitationFieldsFragmentDoc}`;

export function useReferenceDataCitationQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataCitationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataCitationQuery,
    GQTypes.GQReferenceDataCitationQueryVariables
  >({ query: ReferenceDataCitationDocument, ...options });
}
export const ReferenceDataCitationSlugsDocument = gql`
    query ReferenceDataCitationSlugs {
  referenceDataCitationSlugs
}
    `;

export function useReferenceDataCitationSlugsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataCitationSlugsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataCitationSlugsQuery,
    GQTypes.GQReferenceDataCitationSlugsQueryVariables
  >({ query: ReferenceDataCitationSlugsDocument, ...options });
}
export const ReferenceDataVersionSchemaDocument = gql`
    query ReferenceDataVersionSchema($referenceDataVersionId: ID!) {
  referenceDataVersion(referenceDataVersionId: $referenceDataVersionId) {
    id
    versionName
    latestSchema {
      id
      schemaJson
    }
  }
}
    `;

export function useReferenceDataVersionSchemaQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataVersionSchemaQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataVersionSchemaQuery,
    GQTypes.GQReferenceDataVersionSchemaQueryVariables
  >({ query: ReferenceDataVersionSchemaDocument, ...options });
}
export const LatestReferenceDataRevisionForCompositeDataEditorDocument = gql`
    query latestReferenceDataRevisionForCompositeDataEditor($referenceDataVersionId: ID!, $orgId: ID) {
  latestReferenceDataRevisionForVersion(
    referenceDataVersionId: $referenceDataVersionId
    orgId: $orgId
    anyOrg: false
  ) {
    id
    createdAt
    kind
  }
}
    `;

export function useLatestReferenceDataRevisionForCompositeDataEditorQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestReferenceDataRevisionForCompositeDataEditorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestReferenceDataRevisionForCompositeDataEditorQuery,
    GQTypes.GQLatestReferenceDataRevisionForCompositeDataEditorQueryVariables
  >({
    query: LatestReferenceDataRevisionForCompositeDataEditorDocument,
    ...options,
  });
}
export const CompositeDataVersionEditorDataDocument = gql`
    query CompositeDataVersionEditorData($compositeDataVersionId: ID!, $orgId: ID, $fetchOrgDetails: Boolean!) {
  compositeDataVersion(compositeDataVersionId: $compositeDataVersionId) {
    ...ReferenceDataVersionSchema
    versionName
    source {
      id
      name
      orgId
    }
    referenceDataSqlRuns(orgId: $orgId) {
      id
      createdAt
      status
      sql
      error
      warnings
      queryResultFileId
      revisionId
      signedParquetOutputUrl
      signedParquetRawResultUrl
      revisionState
      revisionLastUpdatedDate
    }
  }
  organization(id: $orgId) @include(if: $fetchOrgDetails) {
    id
    name
  }
}
    ${ReferenceDataVersionSchemaFragmentDoc}`;

export function useCompositeDataVersionEditorDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompositeDataVersionEditorDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompositeDataVersionEditorDataQuery,
    GQTypes.GQCompositeDataVersionEditorDataQueryVariables
  >({ query: CompositeDataVersionEditorDataDocument, ...options });
}
export const ReferenceDataOverlayByRevisionIdDocument = gql`
    query ReferenceDataOverlayByRevisionId($referenceDataRevision: ID!) {
  referenceDataOverlayByRevisionId(referenceDataRevision: $referenceDataRevision) {
    id
  }
}
    `;

export function useReferenceDataOverlayByRevisionIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataOverlayByRevisionIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataOverlayByRevisionIdQuery,
    GQTypes.GQReferenceDataOverlayByRevisionIdQueryVariables
  >({ query: ReferenceDataOverlayByRevisionIdDocument, ...options });
}
export const ReferenceDataVersionsBySlugsIfExistsDocument = gql`
    query referenceDataVersionsBySlugsIfExists($slugs: [String!]!) {
  referenceDataVersionsBySlugsIfExists(slugs: $slugs) {
    slug
    referenceDataVersion {
      id
      latestSchema {
        id
        schemaJson
      }
      latestSignedParquetOutputUrl
    }
  }
}
    `;

export function useReferenceDataVersionsBySlugsIfExistsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataVersionsBySlugsIfExistsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataVersionsBySlugsIfExistsQuery,
    GQTypes.GQReferenceDataVersionsBySlugsIfExistsQueryVariables
  >({ query: ReferenceDataVersionsBySlugsIfExistsDocument, ...options });
}
export const ReferenceDataDifferDocument = gql`
    query ReferenceDataDiffer($sourceRevisionId: ID!, $targetRevisionId: ID!) {
  referenceDataDiffer(
    sourceRevisionId: $sourceRevisionId
    targetRevisionId: $targetRevisionId
  ) {
    fileMetadata {
      id
      signedParquetUrl
    }
    sourceReferenceDataRevision {
      id
      revisionName
      state
      createdAt
      organization {
        id
        name
      }
      version {
        id
        slug
        sourceId
      }
    }
    targetReferenceDataRevision {
      id
      revisionName
      state
      createdAt
      organization {
        id
        name
      }
      version {
        id
        slug
        sourceId
      }
    }
  }
}
    `;

export function useReferenceDataDifferQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataDifferQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataDifferQuery,
    GQTypes.GQReferenceDataDifferQueryVariables
  >({ query: ReferenceDataDifferDocument, ...options });
}
export const ReferenceDataRevisionsForSourceByVersionDocument = gql`
    query ReferenceDataRevisionsForSourceByVersion($sourceId: ID!, $excludeArchived: Boolean!, $includeDrafts: Boolean!) {
  referenceDataSource(referenceDataSourceId: $sourceId) {
    id
    referenceDataVersions(
      excludeArchived: $excludeArchived
      includeDrafts: $includeDrafts
    ) {
      id
      versionName
      referenceDataRevisions(
        excludeArchived: $excludeArchived
        includeDrafts: $includeDrafts
      ) {
        id
        revisionName
        createdAt
        state
      }
    }
  }
}
    `;

export function useReferenceDataRevisionsForSourceByVersionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataRevisionsForSourceByVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataRevisionsForSourceByVersionQuery,
    GQTypes.GQReferenceDataRevisionsForSourceByVersionQueryVariables
  >({ query: ReferenceDataRevisionsForSourceByVersionDocument, ...options });
}
export const UpdateReferenceDataRevisionDocument = gql`
    mutation UpdateReferenceDataRevision($input: UpdateReferenceDataRevisionInput!) {
  updateReferenceDataRevision(input: $input) {
    referenceDataRevision {
      id
      revisionName
      internalSourceNotes
    }
  }
}
    `;

export function useUpdateReferenceDataRevisionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReferenceDataRevisionMutation,
    GQTypes.GQUpdateReferenceDataRevisionMutationVariables
  >(UpdateReferenceDataRevisionDocument);
}
export const LatestPublishedRevisionByRevisionIdOrNullDocument = gql`
    query LatestPublishedRevisionByRevisionIdOrNull($revisionId: ID!) {
  latestPublishedRevisionForRevisionIdOrNull(revisionId: $revisionId) {
    id
  }
}
    `;

export function useLatestPublishedRevisionByRevisionIdOrNullQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestPublishedRevisionByRevisionIdOrNullQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestPublishedRevisionByRevisionIdOrNullQuery,
    GQTypes.GQLatestPublishedRevisionByRevisionIdOrNullQueryVariables
  >({ query: LatestPublishedRevisionByRevisionIdOrNullDocument, ...options });
}
export const CreateReferenceDataSourceDocument = gql`
    mutation CreateReferenceDataSource($input: CreateReferenceDataSourceInput!) {
  createReferenceDataSource(input: $input) {
    referenceDataSource {
      id
    }
  }
}
    `;

export function useCreateReferenceDataSourceMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReferenceDataSourceMutation,
    GQTypes.GQCreateReferenceDataSourceMutationVariables
  >(CreateReferenceDataSourceDocument);
}
export const ArchiveReferenceDataSourceDocument = gql`
    mutation ArchiveReferenceDataSource($input: ArchiveReferenceDataSourceInput!) {
  archiveReferenceDataSource(input: $input) {
    referenceDataSource {
      ...ReferenceDataSourceSchema
    }
  }
}
    ${ReferenceDataSourceSchemaFragmentDoc}`;

export function useArchiveReferenceDataSourceMutation() {
  return Urql.useMutation<
    GQTypes.GQArchiveReferenceDataSourceMutation,
    GQTypes.GQArchiveReferenceDataSourceMutationVariables
  >(ArchiveReferenceDataSourceDocument);
}
export const UpdateReferenceDataSourceDocument = gql`
    mutation UpdateReferenceDataSource($input: UpdateReferenceDataSourceInput!) {
  updateReferenceDataSource(input: $input) {
    referenceDataSource {
      id
      name
      isSyncedWithCliq
    }
  }
}
    `;

export function useUpdateReferenceDataSourceMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReferenceDataSourceMutation,
    GQTypes.GQUpdateReferenceDataSourceMutationVariables
  >(UpdateReferenceDataSourceDocument);
}
export const ToggleCliqSyncForReferenceDataSourceDocument = gql`
    mutation ToggleCliqSyncForReferenceDataSource($input: ToggleCliqSyncForReferenceDataSourceInput!) {
  toggleCliqSyncForReferenceDataSource(input: $input) {
    cliqReferenceDataConnection {
      id
      isActive
    }
  }
}
    `;

export function useToggleCliqSyncForReferenceDataSourceMutation() {
  return Urql.useMutation<
    GQTypes.GQToggleCliqSyncForReferenceDataSourceMutation,
    GQTypes.GQToggleCliqSyncForReferenceDataSourceMutationVariables
  >(ToggleCliqSyncForReferenceDataSourceDocument);
}
export const ReferenceDataSourcesDocument = gql`
    query ReferenceDataSources($after: String, $before: String, $first: Int, $last: Int, $search: String, $filters: [String!], $excludeArchived: Boolean) {
  referenceDataSources(
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
    filter: All
    tagIdsFilter: $filters
    excludeArchived: $excludeArchived
  ) {
    edges {
      node {
        ...ReferenceDataSourceSchema
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${ReferenceDataSourceSchemaFragmentDoc}`;

export function useReferenceDataSourcesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataSourcesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataSourcesQuery,
    GQTypes.GQReferenceDataSourcesQueryVariables
  >({ query: ReferenceDataSourcesDocument, ...options });
}
export const AddCalculationTagToReferenceDataSourceDocument = gql`
    mutation AddCalculationTagToReferenceDataSource($input: AddCalculationTagToReferenceDataInput!) {
  addCalculationTagToReferenceData(input: $input) {
    success
  }
}
    `;

export function useAddCalculationTagToReferenceDataSourceMutation() {
  return Urql.useMutation<
    GQTypes.GQAddCalculationTagToReferenceDataSourceMutation,
    GQTypes.GQAddCalculationTagToReferenceDataSourceMutationVariables
  >(AddCalculationTagToReferenceDataSourceDocument);
}
export const RemoveCalculationTagFromReferenceDataSourceDocument = gql`
    mutation RemoveCalculationTagFromReferenceDataSource($input: RemoveCalculationTagFromReferenceDataInput!) {
  removeCalculationTagFromReferenceData(input: $input) {
    success
  }
}
    `;

export function useRemoveCalculationTagFromReferenceDataSourceMutation() {
  return Urql.useMutation<
    GQTypes.GQRemoveCalculationTagFromReferenceDataSourceMutation,
    GQTypes.GQRemoveCalculationTagFromReferenceDataSourceMutationVariables
  >(RemoveCalculationTagFromReferenceDataSourceDocument);
}
export const CreateCalculationTagDocument = gql`
    mutation CreateCalculationTag($input: CreateCalculationTagInput!) {
  createCalculationTag(input: $input) {
    tag {
      id
      name
    }
  }
}
    `;

export function useCreateCalculationTagMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCalculationTagMutation,
    GQTypes.GQCreateCalculationTagMutationVariables
  >(CreateCalculationTagDocument);
}
export const ReferenceDataSqlForRevisionIdDocument = gql`
    query ReferenceDataSqlForRevisionId($revisionId: ID!) {
  referenceDataSqlForRevisionId(revisionId: $revisionId) {
    id
    sql
    queryResultFileId
  }
}
    `;

export function useReferenceDataSqlForRevisionIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataSqlForRevisionIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataSqlForRevisionIdQuery,
    GQTypes.GQReferenceDataSqlForRevisionIdQueryVariables
  >({ query: ReferenceDataSqlForRevisionIdDocument, ...options });
}
export const CreateReferenceDataVersionDocument = gql`
    mutation CreateReferenceDataVersion($input: CreateReferenceDataVersionInput!) {
  createReferenceDataVersion(input: $input) {
    referenceDataVersion {
      id
      versionName
    }
  }
}
    `;

export function useCreateReferenceDataVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReferenceDataVersionMutation,
    GQTypes.GQCreateReferenceDataVersionMutationVariables
  >(CreateReferenceDataVersionDocument);
}
export const ReferenceDataVersionNamesForSourceDocument = gql`
    query ReferenceDataVersionNamesForSource($sourceId: ID!) {
  referenceDataSource(referenceDataSourceId: $sourceId) {
    id
    referenceDataVersions {
      id
      versionName
    }
  }
}
    `;

export function useReferenceDataVersionNamesForSourceQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataVersionNamesForSourceQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataVersionNamesForSourceQuery,
    GQTypes.GQReferenceDataVersionNamesForSourceQueryVariables
  >({ query: ReferenceDataVersionNamesForSourceDocument, ...options });
}
export const DependentEmissionsModelsForRdvDocument = gql`
    query DependentEmissionsModelsForRdv($referenceDataVersionId: ID!) {
  dependentEmissionsModelsForRdv(referenceDataVersionId: $referenceDataVersionId) {
    stableId
    stableName
    isArchived
    latestPublishedEmvId
    latestPublishedEmvUsesRdv
    latestPublishedEmvChangelog
    latestPublishedEmvCreatedAt
  }
}
    `;

export function useDependentEmissionsModelsForRdvQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDependentEmissionsModelsForRdvQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDependentEmissionsModelsForRdvQuery,
    GQTypes.GQDependentEmissionsModelsForRdvQueryVariables
  >({ query: DependentEmissionsModelsForRdvDocument, ...options });
}
export const DependentEmissionsModelReleasesForRdvDocument = gql`
    query DependentEmissionsModelReleasesForRdv($referenceDataVersionId: ID!) {
  dependentEmissionsModelReleasesForRdv(
    referenceDataVersionId: $referenceDataVersionId
  ) {
    ...EmissionsModelReleaseMetadata
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;

export function useDependentEmissionsModelReleasesForRdvQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDependentEmissionsModelReleasesForRdvQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDependentEmissionsModelReleasesForRdvQuery,
    GQTypes.GQDependentEmissionsModelReleasesForRdvQueryVariables
  >({ query: DependentEmissionsModelReleasesForRdvDocument, ...options });
}
export const DependentReferenceDataVersionsForRdvDocument = gql`
    query DependentReferenceDataVersionsForRdv($referenceDataVersionId: ID!) {
  dependentReferenceDataVersionsForRdv(
    referenceDataVersionId: $referenceDataVersionId
  ) {
    rdvId
    rdvName
    rdsId
    rdsName
    rdvCreatedAt
    rdvUpdatedAt
    usingOutdatedRevision
    rdvIsLatestPublishedVersion
  }
}
    `;

export function useDependentReferenceDataVersionsForRdvQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDependentReferenceDataVersionsForRdvQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDependentReferenceDataVersionsForRdvQuery,
    GQTypes.GQDependentReferenceDataVersionsForRdvQueryVariables
  >({ query: DependentReferenceDataVersionsForRdvDocument, ...options });
}
export const CitationsForRdvDocument = gql`
    query CitationsForRdv($versionId: ID!) {
  referenceDataVersion(referenceDataVersionId: $versionId) {
    id
    citations {
      id
      displayName
      slug
      isCustomerProvided
      org {
        id
        name
      }
    }
  }
}
    `;

export function useCitationsForRdvQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCitationsForRdvQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCitationsForRdvQuery,
    GQTypes.GQCitationsForRdvQueryVariables
  >({ query: CitationsForRdvDocument, ...options });
}
export const UpdateReferenceDataVersionDocument = gql`
    mutation UpdateReferenceDataVersion($input: UpdateReferenceDataVersionInput!) {
  updateReferenceDataVersion(input: $input) {
    referenceDataVersion {
      id
      versionName
      versionVintage
    }
  }
}
    `;

export function useUpdateReferenceDataVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReferenceDataVersionMutation,
    GQTypes.GQUpdateReferenceDataVersionMutationVariables
  >(UpdateReferenceDataVersionDocument);
}
export const ReferenceDataRevisionsDocument = gql`
    query ReferenceDataRevisions($referenceDataVersionId: ID!, $excludeArchived: Boolean, $includeDrafts: Boolean, $filterOrgId: ID, $after: String, $before: String, $first: Int, $last: Int) {
  referenceDataRevisionsByVersionId(
    referenceDataVersionId: $referenceDataVersionId
    excludeArchived: $excludeArchived
    includeDrafts: $includeDrafts
    orgId: $filterOrgId
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...ReferenceDataRevisionSchema
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${ReferenceDataRevisionSchemaFragmentDoc}`;

export function useReferenceDataRevisionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataRevisionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataRevisionsQuery,
    GQTypes.GQReferenceDataRevisionsQueryVariables
  >({ query: ReferenceDataRevisionsDocument, ...options });
}
export const LatestReferenceDataRevisionForVersionDocument = gql`
    query latestReferenceDataRevisionForVersion($referenceDataVersionId: ID!) {
  latestReferenceDataRevisionForVersion(
    referenceDataVersionId: $referenceDataVersionId
    anyOrg: true
  ) {
    ...ReferenceDataRevisionSchema
  }
}
    ${ReferenceDataRevisionSchemaFragmentDoc}`;

export function useLatestReferenceDataRevisionForVersionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestReferenceDataRevisionForVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestReferenceDataRevisionForVersionQuery,
    GQTypes.GQLatestReferenceDataRevisionForVersionQueryVariables
  >({ query: LatestReferenceDataRevisionForVersionDocument, ...options });
}
export const RunSchemaValidationForReferenceDataVersionDocument = gql`
    mutation RunSchemaValidationForReferenceDataVersion($input: RunSchemaValidationForReferenceDataVersionInput!) {
  runSchemaValidationForReferenceDataVersion(input: $input) {
    workflowId
  }
}
    `;

export function useRunSchemaValidationForReferenceDataVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQRunSchemaValidationForReferenceDataVersionMutation,
    GQTypes.GQRunSchemaValidationForReferenceDataVersionMutationVariables
  >(RunSchemaValidationForReferenceDataVersionDocument);
}
export const PublishReferenceDataVersionDocument = gql`
    mutation PublishReferenceDataVersion($input: PublishReferenceDataVersionInput!) {
  publishReferenceDataVersion(input: $input) {
    referenceDataVersion {
      ...ReferenceDataVersionSchema
    }
  }
}
    ${ReferenceDataVersionSchemaFragmentDoc}`;

export function usePublishReferenceDataVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishReferenceDataVersionMutation,
    GQTypes.GQPublishReferenceDataVersionMutationVariables
  >(PublishReferenceDataVersionDocument);
}
export const ArchiveReferenceDataVersionDocument = gql`
    mutation ArchiveReferenceDataVersion($input: ArchiveReferenceDataVersionInput!) {
  archiveReferenceDataVersion(input: $input) {
    referenceDataVersion {
      ...ReferenceDataVersionSchema
    }
  }
}
    ${ReferenceDataVersionSchemaFragmentDoc}`;

export function useArchiveReferenceDataVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQArchiveReferenceDataVersionMutation,
    GQTypes.GQArchiveReferenceDataVersionMutationVariables
  >(ArchiveReferenceDataVersionDocument);
}
export const ArchiveReferenceDataRevisionDocument = gql`
    mutation ArchiveReferenceDataRevision($input: ArchiveReferenceDataRevisionInput!) {
  archiveReferenceDataRevision(input: $input) {
    referenceDataRevision {
      ...ReferenceDataRevisionSchema
    }
  }
}
    ${ReferenceDataRevisionSchemaFragmentDoc}`;

export function useArchiveReferenceDataRevisionMutation() {
  return Urql.useMutation<
    GQTypes.GQArchiveReferenceDataRevisionMutation,
    GQTypes.GQArchiveReferenceDataRevisionMutationVariables
  >(ArchiveReferenceDataRevisionDocument);
}
export const RevisionForSafePublicationDocument = gql`
    query RevisionForSafePublication($revisionId: ID!) {
  referenceDataRevision(revisionId: $revisionId) {
    id
    signedParquetOutputUrl
    outputFileId
  }
  latestPublishedRevision: latestPublishedRevisionForRevisionIdOrNull(
    revisionId: $revisionId
  ) {
    id
  }
}
    `;

export function useRevisionForSafePublicationQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQRevisionForSafePublicationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQRevisionForSafePublicationQuery,
    GQTypes.GQRevisionForSafePublicationQueryVariables
  >({ query: RevisionForSafePublicationDocument, ...options });
}
export const ReviewPrepublicationTestResultsForReferenceDataRevisionDocument = gql`
    mutation ReviewPrepublicationTestResultsForReferenceDataRevision($input: ReviewPrepublicationTestResultsInput!) {
  reviewResult: reviewPrepublicationTestResultsForReferenceDataRevision(
    input: $input
  ) {
    outcome
    errorCode
    publishedReferenceDataRevision {
      id
    }
  }
}
    `;

export function useReviewPrepublicationTestResultsForReferenceDataRevisionMutation() {
  return Urql.useMutation<
    GQTypes.GQReviewPrepublicationTestResultsForReferenceDataRevisionMutation,
    GQTypes.GQReviewPrepublicationTestResultsForReferenceDataRevisionMutationVariables
  >(ReviewPrepublicationTestResultsForReferenceDataRevisionDocument);
}
export const SchemaUpdateValidationWarningsDocument = gql`
    query SchemaUpdateValidationWarnings($referenceDataVersionId: ID!, $schemaJson: JSONSchema!, $orgId: String) {
  validateUpdateReferenceDataVersionSchema(
    referenceDataVersionId: $referenceDataVersionId
    schemaJson: $schemaJson
    orgId: $orgId
  ) {
    warnings
    errors
  }
}
    `;

export function useSchemaUpdateValidationWarningsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSchemaUpdateValidationWarningsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSchemaUpdateValidationWarningsQuery,
    GQTypes.GQSchemaUpdateValidationWarningsQueryVariables
  >({ query: SchemaUpdateValidationWarningsDocument, ...options });
}
export const UpdateReferenceDataVersionSchemaDocument = gql`
    mutation UpdateReferenceDataVersionSchema($input: UpdateReferenceDataVersionSchemaInput!) {
  updateReferenceDataVersionSchema(input: $input) {
    referenceDataVersion {
      id
      latestSchema {
        id
        schemaJson
      }
    }
  }
}
    `;

export function useUpdateReferenceDataVersionSchemaMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReferenceDataVersionSchemaMutation,
    GQTypes.GQUpdateReferenceDataVersionSchemaMutationVariables
  >(UpdateReferenceDataVersionSchemaDocument);
}
export const UpstreamReferenceDataForVersionDocument = gql`
    query UpstreamReferenceDataForVersion($versionId: ID!) {
  referenceDataVersion(referenceDataVersionId: $versionId) {
    id
    slug
    upstreamReferenceDataVersions {
      id
    }
    source {
      id
      name
    }
  }
}
    `;

export function useUpstreamReferenceDataForVersionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUpstreamReferenceDataForVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUpstreamReferenceDataForVersionQuery,
    GQTypes.GQUpstreamReferenceDataForVersionQueryVariables
  >({ query: UpstreamReferenceDataForVersionDocument, ...options });
}
export const UpstreamReferenceDataSlugsForRevisionDocument = gql`
    query UpstreamReferenceDataSlugsForRevision($revisionId: ID!) {
  referenceDataRevision(revisionId: $revisionId) {
    id
    upstreamReferenceDataVersions {
      id
    }
  }
}
    `;

export function useUpstreamReferenceDataSlugsForRevisionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUpstreamReferenceDataSlugsForRevisionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUpstreamReferenceDataSlugsForRevisionQuery,
    GQTypes.GQUpstreamReferenceDataSlugsForRevisionQueryVariables
  >({ query: UpstreamReferenceDataSlugsForRevisionDocument, ...options });
}
export const CreateReferenceDataOverlayTemplateDocument = gql`
    mutation CreateReferenceDataOverlayTemplate($input: CreateReferenceDataOverlayTemplateInput!) {
  createReferenceDataOverlayTemplate(input: $input) {
    oneSchemaTemplateKey
  }
}
    `;

export function useCreateReferenceDataOverlayTemplateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReferenceDataOverlayTemplateMutation,
    GQTypes.GQCreateReferenceDataOverlayTemplateMutationVariables
  >(CreateReferenceDataOverlayTemplateDocument);
}
export const StartPrepublicationTestsForReferenceDataRevisionDocument = gql`
    mutation StartPrepublicationTestsForReferenceDataRevision($input: StartPrepublicationTestsForReferenceDataRevisionInput!) {
  startPrepublicationTestsForReferenceDataRevision(input: $input) {
    workflowId
  }
}
    `;

export function useStartPrepublicationTestsForReferenceDataRevisionMutation() {
  return Urql.useMutation<
    GQTypes.GQStartPrepublicationTestsForReferenceDataRevisionMutation,
    GQTypes.GQStartPrepublicationTestsForReferenceDataRevisionMutationVariables
  >(StartPrepublicationTestsForReferenceDataRevisionDocument);
}
export const RenameCustomEmissionsFactorTaskDocument = gql`
    mutation RenameCustomEmissionsFactorTask($input: RenameCustomEmissionsFactorSetupTaskInput!) {
  renameCustomEmissionsFactorSetupTask(input: $input) {
    taskId
  }
}
    `;

export function useRenameCustomEmissionsFactorTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQRenameCustomEmissionsFactorTaskMutation,
    GQTypes.GQRenameCustomEmissionsFactorTaskMutationVariables
  >(RenameCustomEmissionsFactorTaskDocument);
}
export const ShareFilesDocument = gql`
    mutation ShareFiles($input: ShareFilesInput!) {
  shareFiles(input: $input) {
    sharedFiles {
      id
    }
  }
}
    `;

export function useShareFilesMutation() {
  return Urql.useMutation<
    GQTypes.GQShareFilesMutation,
    GQTypes.GQShareFilesMutationVariables
  >(ShareFilesDocument);
}
export const MeasurementProjectForShareFilesDocument = gql`
    query MeasurementProjectForShareFiles($measurementProjectId: ID!) {
  measurementProject(id: $measurementProjectId) {
    ...MeasurementProjectForShareFiles
  }
}
    ${MeasurementProjectForShareFilesFragmentDoc}`;

export function useMeasurementProjectForShareFilesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementProjectForShareFilesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementProjectForShareFilesQuery,
    GQTypes.GQMeasurementProjectForShareFilesQueryVariables
  >({ query: MeasurementProjectForShareFilesDocument, ...options });
}
export const SharedFileDialogDocument = gql`
    query SharedFileDialog($orgId: ID!) {
  organization(id: $orgId) {
    id
    measurementProjects {
      id
      ...MeasurementProjectForShareFiles
    }
  }
}
    ${MeasurementProjectForShareFilesFragmentDoc}`;

export function useSharedFileDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSharedFileDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSharedFileDialogQuery,
    GQTypes.GQSharedFileDialogQueryVariables
  >({ query: SharedFileDialogDocument, ...options });
}
export const CreateSupplierChartAdminDocument = gql`
    mutation CreateSupplierChartAdmin($input: CreateSupplierChartInputAdmin!) {
  createSupplierChartAdmin(input: $input) {
    chart {
      id
    }
    views {
      id
      charts: chartsV2 {
        ...SupplierChartConfigFieldsAdmin
      }
    }
  }
}
    ${SupplierChartConfigFieldsAdminFragmentDoc}`;

export function useCreateSupplierChartAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateSupplierChartAdminMutation,
    GQTypes.GQCreateSupplierChartAdminMutationVariables
  >(CreateSupplierChartAdminDocument);
}
export const UpdateSupplierChartAdminDocument = gql`
    mutation UpdateSupplierChartAdmin($input: UpdateSupplierChartInputAdmin!) {
  updateSupplierChartAdmin(input: $input) {
    views {
      id
      charts: chartsV2 {
        ...SupplierChartConfigFieldsAdmin
      }
    }
  }
}
    ${SupplierChartConfigFieldsAdminFragmentDoc}`;

export function useUpdateSupplierChartAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSupplierChartAdminMutation,
    GQTypes.GQUpdateSupplierChartAdminMutationVariables
  >(UpdateSupplierChartAdminDocument);
}
export const DeleteSupplierChartAdminDocument = gql`
    mutation DeleteSupplierChartAdmin($input: DeleteSupplierChartInputAdmin!) {
  deleteSupplierChartAdmin(input: $input) {
    views {
      id
      charts: chartsV2 {
        ...SupplierChartConfigFieldsAdmin
      }
    }
  }
}
    ${SupplierChartConfigFieldsAdminFragmentDoc}`;

export function useDeleteSupplierChartAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteSupplierChartAdminMutation,
    GQTypes.GQDeleteSupplierChartAdminMutationVariables
  >(DeleteSupplierChartAdminDocument);
}
export const GetSupplierColumnsDocument = gql`
    query GetSupplierColumns($orgId: ID!) {
  supplierTableColumns(orgId: $orgId) {
    ...SupplierColumnFieldsAdmin
  }
}
    ${SupplierColumnFieldsAdminFragmentDoc}`;

export function useGetSupplierColumnsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplierColumnsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplierColumnsQuery,
    GQTypes.GQGetSupplierColumnsQueryVariables
  >({ query: GetSupplierColumnsDocument, ...options });
}
export const DeleteEngagementTaskDocument = gql`
    mutation DeleteEngagementTask($input: DeleteEngagementTasksAndAnswersByCrossOrgModelIdInput!) {
  deleteEngagementTasksAndAnswersByCrossOrgModelId(input: $input) {
    engagementTasks {
      id
      deletedAt
    }
  }
}
    `;

export function useDeleteEngagementTaskMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteEngagementTaskMutation,
    GQTypes.GQDeleteEngagementTaskMutationVariables
  >(DeleteEngagementTaskDocument);
}
export const CreateTargetDocument = gql`
    mutation CreateTarget($input: CreateTargetInput!) {
  createTarget(input: $input) {
    org {
      ...OrganizationForTargetsForm
    }
  }
}
    ${OrganizationForTargetsFormFragmentDoc}`;

export function useCreateTargetMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateTargetMutation,
    GQTypes.GQCreateTargetMutationVariables
  >(CreateTargetDocument);
}
export const UpdateTargetDocument = gql`
    mutation UpdateTarget($input: UpdateTargetInput!) {
  updateTarget(input: $input) {
    target {
      ...TargetForTargetsForm
    }
  }
}
    ${TargetForTargetsFormFragmentDoc}`;

export function useUpdateTargetMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateTargetMutation,
    GQTypes.GQUpdateTargetMutationVariables
  >(UpdateTargetDocument);
}
export const DeleteTargetDocument = gql`
    mutation DeleteTarget($input: DeleteTargetInput!) {
  deleteTarget(input: $input) {
    org {
      ...OrganizationForTargetsForm
    }
  }
}
    ${OrganizationForTargetsFormFragmentDoc}`;

export function useDeleteTargetMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteTargetMutation,
    GQTypes.GQDeleteTargetMutationVariables
  >(DeleteTargetDocument);
}
export const UpdateUserNameAdminVersionDocument = gql`
    mutation UpdateUserNameAdminVersion($input: UpdateUserNameAdminVersionInput!) {
  updateUserNameAdminVersion(input: $input) {
    user {
      id
      name
    }
  }
}
    `;

export function useUpdateUserNameAdminVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateUserNameAdminVersionMutation,
    GQTypes.GQUpdateUserNameAdminVersionMutationVariables
  >(UpdateUserNameAdminVersionDocument);
}
export const UpdateUserEmailAdminVersionDocument = gql`
    mutation UpdateUserEmailAdminVersion($input: UpdateUserEmailAdminVersionInput!) {
  updateUserEmailAdminVersion(input: $input) {
    user {
      id
      email
    }
  }
}
    `;

export function useUpdateUserEmailAdminVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateUserEmailAdminVersionMutation,
    GQTypes.GQUpdateUserEmailAdminVersionMutationVariables
  >(UpdateUserEmailAdminVersionDocument);
}
export const UserUploadsForAdtSelectorDialogDocument = gql`
    query UserUploadsForAdtSelectorDialog($orgId: ID!, $datasourceIds: [ID!]) {
  userUploads(
    orgId: $orgId
    datasourceIds: $datasourceIds
    includeRevisions: false
  ) {
    edges {
      node {
        ...UserUploadForAdtSelectorDialog
      }
    }
  }
}
    ${UserUploadForAdtSelectorDialogFragmentDoc}`;

export function useUserUploadsForAdtSelectorDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadsForAdtSelectorDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadsForAdtSelectorDialogQuery,
    GQTypes.GQUserUploadsForAdtSelectorDialogQueryVariables
  >({ query: UserUploadsForAdtSelectorDialogDocument, ...options });
}
export const RemoveUserAdminVersionDocument = gql`
    mutation RemoveUserAdminVersion($input: RemoveUserAdminVersionInput!) {
  removeUserAdminVersion(input: $input) {
    user {
      id
      email
      deletedAt
    }
  }
}
    `;

export function useRemoveUserAdminVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQRemoveUserAdminVersionMutation,
    GQTypes.GQRemoveUserAdminVersionMutationVariables
  >(RemoveUserAdminVersionDocument);
}
export const ResetLockedUserAdminVersionDocument = gql`
    mutation ResetLockedUserAdminVersion($input: ResetLockedUserAdminVersionInput!) {
  resetLockedUserAdminVersion(input: $input) {
    user {
      id
      consecutiveLoginFailures
    }
  }
}
    `;

export function useResetLockedUserAdminVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQResetLockedUserAdminVersionMutation,
    GQTypes.GQResetLockedUserAdminVersionMutationVariables
  >(ResetLockedUserAdminVersionDocument);
}
export const WatershedEmployeeDropdownFieldDocument = gql`
    query WatershedEmployeeDropdownField($orgPointOfContactKind: OrgPointOfContactKind) {
  watershedEmployees(orgPointOfContactKind: $orgPointOfContactKind) {
    edges {
      node {
        id
        name
        user {
          id
        }
      }
    }
  }
}
    `;

export function useWatershedEmployeeDropdownFieldQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQWatershedEmployeeDropdownFieldQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQWatershedEmployeeDropdownFieldQuery,
    GQTypes.GQWatershedEmployeeDropdownFieldQueryVariables
  >({ query: WatershedEmployeeDropdownFieldDocument, ...options });
}
export const BusinessActivityTypeSettingsListDocument = gql`
    query BusinessActivityTypeSettingsList($after: String, $before: String, $first: Int, $last: Int, $search: String) {
  businessActivityTypeSettings(
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
  ) {
    edges {
      node {
        ...BusinessActivityTypeSettingsSchema
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${BusinessActivityTypeSettingsSchemaFragmentDoc}`;

export function useBusinessActivityTypeSettingsListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeSettingsListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeSettingsListQuery,
    GQTypes.GQBusinessActivityTypeSettingsListQueryVariables
  >({ query: BusinessActivityTypeSettingsListDocument, ...options });
}
export const SeedBatOntologyDocument = gql`
    mutation SeedBatOntology($input: SeedBatOntologyInput!) {
  seedBatOntology(input: $input) {
    backgroundJobId
  }
}
    `;

export function useSeedBatOntologyMutation() {
  return Urql.useMutation<
    GQTypes.GQSeedBatOntologyMutation,
    GQTypes.GQSeedBatOntologyMutationVariables
  >(SeedBatOntologyDocument);
}
export const BusinessActivityTypeNamesDocument = gql`
    query BusinessActivityTypeNames @withOwner(owner: DatasetsAndObjects) {
  businessActivityTypeNames
}
    `;

export function useBusinessActivityTypeNamesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeNamesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeNamesQuery,
    GQTypes.GQBusinessActivityTypeNamesQueryVariables
  >({ query: BusinessActivityTypeNamesDocument, ...options });
}
export const CreateCanonicalClimateProgramProjectDocument = gql`
    mutation CreateCanonicalClimateProgramProject($input: CreateCanonicalClimateProgramProjectInput!) {
  createCanonicalClimateProgramProject(input: $input) {
    canonicalClimateProgramProject {
      ...CanonicalClimateProgramProjectFields
    }
  }
}
    ${CanonicalClimateProgramProjectFieldsFragmentDoc}`;

export function useCreateCanonicalClimateProgramProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCanonicalClimateProgramProjectMutation,
    GQTypes.GQCreateCanonicalClimateProgramProjectMutationVariables
  >(CreateCanonicalClimateProgramProjectDocument);
}
export const CreateCanonicalClimateProgramProjectRequirementDatasetDocument = gql`
    mutation CreateCanonicalClimateProgramProjectRequirementDataset($input: CreateCanonicalClimateProgramProjectRequirementDatasetInput!) {
  createCanonicalClimateProgramProjectRequirementDataset(input: $input) {
    canonicalClimateProgramProject {
      id
      datasetRequirements {
        ...CanonicalProjectRequirementDatasetFields
      }
    }
    canonicalClimateProgramProjectRequirementDataset {
      ...CanonicalProjectRequirementDatasetFields
    }
  }
}
    ${CanonicalProjectRequirementDatasetFieldsFragmentDoc}`;

export function useCreateCanonicalClimateProgramProjectRequirementDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCanonicalClimateProgramProjectRequirementDatasetMutation,
    GQTypes.GQCreateCanonicalClimateProgramProjectRequirementDatasetMutationVariables
  >(CreateCanonicalClimateProgramProjectRequirementDatasetDocument);
}
export const CreateCanonicalClimateProgramProjectRequirementGhgpDocument = gql`
    mutation CreateCanonicalClimateProgramProjectRequirementGhgp($input: CreateCanonicalClimateProgramProjectRequirementGhgpInput!) {
  createCanonicalClimateProgramProjectRequirementGhgp(input: $input) {
    canonicalClimateProgramProject {
      id
      ghgpRequirements {
        ...CanonicalProjectRequirementGhgpFields
      }
    }
    canonicalClimateProgramProjectRequirementGhgp {
      ...CanonicalClimateProgramProjectRequirementGhgpFields
    }
  }
}
    ${CanonicalProjectRequirementGhgpFieldsFragmentDoc}
${CanonicalClimateProgramProjectRequirementGhgpFieldsFragmentDoc}`;

export function useCreateCanonicalClimateProgramProjectRequirementGhgpMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCanonicalClimateProgramProjectRequirementGhgpMutation,
    GQTypes.GQCreateCanonicalClimateProgramProjectRequirementGhgpMutationVariables
  >(CreateCanonicalClimateProgramProjectRequirementGhgpDocument);
}
export const UpdateCanonicalClimateProgramProjectDocument = gql`
    mutation UpdateCanonicalClimateProgramProject($input: UpdateCanonicalClimateProgramProjectInput!) {
  updateCanonicalClimateProgramProject(input: $input) {
    canonicalClimateProgramProject {
      ...CanonicalClimateProgramProjectFields
    }
  }
}
    ${CanonicalClimateProgramProjectFieldsFragmentDoc}`;

export function useUpdateCanonicalClimateProgramProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCanonicalClimateProgramProjectMutation,
    GQTypes.GQUpdateCanonicalClimateProgramProjectMutationVariables
  >(UpdateCanonicalClimateProgramProjectDocument);
}
export const UpdateCanonicalClimateProgramProjectRequirementDatasetDocument = gql`
    mutation UpdateCanonicalClimateProgramProjectRequirementDataset($input: UpdateCanonicalClimateProgramProjectRequirementDatasetInput!) {
  updateCanonicalClimateProgramProjectRequirementDataset(input: $input) {
    canonicalClimateProgramProjectRequirementDataset {
      ...CanonicalProjectRequirementDatasetFields
    }
  }
}
    ${CanonicalProjectRequirementDatasetFieldsFragmentDoc}`;

export function useUpdateCanonicalClimateProgramProjectRequirementDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCanonicalClimateProgramProjectRequirementDatasetMutation,
    GQTypes.GQUpdateCanonicalClimateProgramProjectRequirementDatasetMutationVariables
  >(UpdateCanonicalClimateProgramProjectRequirementDatasetDocument);
}
export const GetCanonicalDatasetsDocument = gql`
    query GetCanonicalDatasets {
  canonicalDatasets {
    id
    name
  }
}
    `;

export function useGetCanonicalDatasetsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCanonicalDatasetsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCanonicalDatasetsQuery,
    GQTypes.GQGetCanonicalDatasetsQueryVariables
  >({ query: GetCanonicalDatasetsDocument, ...options });
}
export const UpdateCanonicalClimateProgramProjectRequirementGhgpDocument = gql`
    mutation UpdateCanonicalClimateProgramProjectRequirementGhgp($input: UpdateCanonicalClimateProgramProjectRequirementGhgpInput!) {
  updateCanonicalClimateProgramProjectRequirementGhgp(input: $input) {
    canonicalClimateProgramProjectRequirementGhgp {
      ...CanonicalProjectRequirementGhgpFields
    }
  }
}
    ${CanonicalProjectRequirementGhgpFieldsFragmentDoc}`;

export function useUpdateCanonicalClimateProgramProjectRequirementGhgpMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCanonicalClimateProgramProjectRequirementGhgpMutation,
    GQTypes.GQUpdateCanonicalClimateProgramProjectRequirementGhgpMutationVariables
  >(UpdateCanonicalClimateProgramProjectRequirementGhgpDocument);
}
export const AdminOrgListCommandPaletteDocument = gql`
    query AdminOrgListCommandPalette {
  organizations {
    edges {
      node {
        id
        name
        demoOrg
        testOrg
        watershedPlanLegacy
      }
    }
  }
}
    `;

export function useAdminOrgListCommandPaletteQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAdminOrgListCommandPaletteQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAdminOrgListCommandPaletteQuery,
    GQTypes.GQAdminOrgListCommandPaletteQueryVariables
  >({ query: AdminOrgListCommandPaletteDocument, ...options });
}
export const GetCompanyRelationshipsDocument = gql`
    query GetCompanyRelationships($id: ID!) @withOwner(owner: SupplyChain) {
  company(id: $id) {
    ...CompanyRelationshipsCard
  }
}
    ${CompanyRelationshipsCardFragmentDoc}`;

export function useGetCompanyRelationshipsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCompanyRelationshipsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyRelationshipsQuery,
    GQTypes.GQGetCompanyRelationshipsQueryVariables
  >({ query: GetCompanyRelationshipsDocument, ...options });
}
export const CreateCompanyRelationshipDocument = gql`
    mutation CreateCompanyRelationship($input: CreateCompanyRelationshipInput!) @withOwner(owner: SupplyChain) {
  createCompanyRelationship(input: $input) {
    companyRelationship {
      id
      companyA {
        id
        name
      }
      companyB {
        id
        name
      }
      relationshipType
      relationshipStartDate
      relationshipEndDate
    }
  }
}
    `;

export function useCreateCompanyRelationshipMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCompanyRelationshipMutation,
    GQTypes.GQCreateCompanyRelationshipMutationVariables
  >(CreateCompanyRelationshipDocument);
}
export const DeleteCompanyRelationshipDocument = gql`
    mutation DeleteCompanyRelationship($input: DeleteCompanyRelationshipInput!) @withOwner(owner: SupplyChain) {
  deleteCompanyRelationship(input: $input) {
    id
  }
}
    `;

export function useDeleteCompanyRelationshipMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCompanyRelationshipMutation,
    GQTypes.GQDeleteCompanyRelationshipMutationVariables
  >(DeleteCompanyRelationshipDocument);
}
export const UpsertCompanyDocument = gql`
    mutation UpsertCompany($input: CompanyInput!) {
  upsertCompany(input: $input) {
    company {
      ...CompanyFields
    }
  }
}
    ${CompanyFieldsFragmentDoc}`;

export function useUpsertCompanyMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertCompanyMutation,
    GQTypes.GQUpsertCompanyMutationVariables
  >(UpsertCompanyDocument);
}
export const CompaniesPageDocument = gql`
    query CompaniesPage($q: String, $after: String, $before: String, $first: Int, $last: Int, $includeDemo: Boolean) {
  companies(
    search: $q
    after: $after
    before: $before
    first: $first
    last: $last
    includeDemo: $includeDemo
  ) {
    edges {
      node {
        ...CompanyFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${CompanyFieldsFragmentDoc}`;

export function useCompaniesPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompaniesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompaniesPageQuery,
    GQTypes.GQCompaniesPageQueryVariables
  >({ query: CompaniesPageDocument, ...options });
}
export const CompanyIngestionRecordUpdatesDocument = gql`
    query CompanyIngestionRecordUpdates($filters: CompanyIngestionRecordUpdateFilters, $sortDirection: DataGridSortDirection, $after: String, $before: String, $first: Int, $last: Int) {
  companyIngestionRecordUpdates(
    filters: $filters
    sortDirection: $sortDirection
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...CompanyIngestionRecordUpdateFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${CompanyIngestionRecordUpdateFieldsFragmentDoc}`;

export function useCompanyIngestionRecordUpdatesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanyIngestionRecordUpdatesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanyIngestionRecordUpdatesQuery,
    GQTypes.GQCompanyIngestionRecordUpdatesQueryVariables
  >({ query: CompanyIngestionRecordUpdatesDocument, ...options });
}
export const ApproveCompanyIngestionUpdateDocument = gql`
    mutation ApproveCompanyIngestionUpdate($input: IdInput!) {
  approveCompanyIngestionUpdate(input: $input) {
    companyIngestionRecordUpdate {
      ...CompanyIngestionRecordUpdateFields
    }
  }
}
    ${CompanyIngestionRecordUpdateFieldsFragmentDoc}`;

export function useApproveCompanyIngestionUpdateMutation() {
  return Urql.useMutation<
    GQTypes.GQApproveCompanyIngestionUpdateMutation,
    GQTypes.GQApproveCompanyIngestionUpdateMutationVariables
  >(ApproveCompanyIngestionUpdateDocument);
}
export const DeclineCompanyIngestionUpdateDocument = gql`
    mutation DeclineCompanyIngestionUpdate($input: IdInput!) {
  declineCompanyIngestionUpdate(input: $input) {
    companyIngestionRecordUpdate {
      ...CompanyIngestionRecordUpdateFields
    }
    company {
      ...CompanyFields
    }
  }
}
    ${CompanyIngestionRecordUpdateFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}`;

export function useDeclineCompanyIngestionUpdateMutation() {
  return Urql.useMutation<
    GQTypes.GQDeclineCompanyIngestionUpdateMutation,
    GQTypes.GQDeclineCompanyIngestionUpdateMutationVariables
  >(DeclineCompanyIngestionUpdateDocument);
}
export const ApproveCompanyIngestionDiffDocument = gql`
    mutation ApproveCompanyIngestionDiff($input: IdInput!) {
  approveCompanyIngestionDiff(input: $input) {
    companyIngestionDiff {
      ...CompanyIngestionDiffFields
    }
    companyIngestionRecordUpdates {
      ...CompanyIngestionRecordUpdateFields
    }
  }
}
    ${CompanyIngestionDiffFieldsFragmentDoc}
${CompanyIngestionRecordUpdateFieldsFragmentDoc}`;

export function useApproveCompanyIngestionDiffMutation() {
  return Urql.useMutation<
    GQTypes.GQApproveCompanyIngestionDiffMutation,
    GQTypes.GQApproveCompanyIngestionDiffMutationVariables
  >(ApproveCompanyIngestionDiffDocument);
}
export const DeclineCompanyIngestionDiffDocument = gql`
    mutation DeclineCompanyIngestionDiff($input: IdInput!) {
  declineCompanyIngestionDiff(input: $input) {
    companyIngestionDiff {
      ...CompanyIngestionDiffFields
    }
    companyIngestionRecordUpdates {
      ...CompanyIngestionRecordUpdateFields
    }
  }
}
    ${CompanyIngestionDiffFieldsFragmentDoc}
${CompanyIngestionRecordUpdateFieldsFragmentDoc}`;

export function useDeclineCompanyIngestionDiffMutation() {
  return Urql.useMutation<
    GQTypes.GQDeclineCompanyIngestionDiffMutation,
    GQTypes.GQDeclineCompanyIngestionDiffMutationVariables
  >(DeclineCompanyIngestionDiffDocument);
}
export const ReingestCompanyIngestionDiffsDocument = gql`
    mutation ReingestCompanyIngestionDiffs($input: ReingestCompanyIngestionDiffInput!) {
  reingestCompanyIngestionDiffs(input: $input) {
    companyIngestionDiffs {
      ...CompanyIngestionDiffFields
    }
    companyIngestionRecordUpdates {
      ...CompanyIngestionRecordUpdateFields
    }
  }
}
    ${CompanyIngestionDiffFieldsFragmentDoc}
${CompanyIngestionRecordUpdateFieldsFragmentDoc}`;

export function useReingestCompanyIngestionDiffsMutation() {
  return Urql.useMutation<
    GQTypes.GQReingestCompanyIngestionDiffsMutation,
    GQTypes.GQReingestCompanyIngestionDiffsMutationVariables
  >(ReingestCompanyIngestionDiffsDocument);
}
export const GetPublicDisclosureForIngestionRecordDocument = gql`
    query GetPublicDisclosureForIngestionRecord($publicDisclosureId: ID, $privateDisclosureId: ID) {
  disclosure(
    publicDisclosureId: $publicDisclosureId
    privateDisclosureId: $privateDisclosureId
    opts: {historicalYearFilters: {}, climateCommitmentFilters: {}}
  ) {
    id
    publicDisclosure {
      ...PublicDisclosureForAdminEdit
    }
    privateDisclosure {
      ...PrivateDisclosureAllFields
    }
    historicalEmissionsYears {
      ...EmissionsYearAllFields
    }
    climateCommitments {
      ...CompanyClimateCommitmentFields
    }
  }
}
    ${PublicDisclosureForAdminEditFragmentDoc}
${PrivateDisclosureAllFieldsFragmentDoc}
${EmissionsYearAllFieldsFragmentDoc}
${CompanyClimateCommitmentFieldsFragmentDoc}`;

export function useGetPublicDisclosureForIngestionRecordQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPublicDisclosureForIngestionRecordQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPublicDisclosureForIngestionRecordQuery,
    GQTypes.GQGetPublicDisclosureForIngestionRecordQueryVariables
  >({ query: GetPublicDisclosureForIngestionRecordDocument, ...options });
}
export const CompanyIngestionDiffErrorsDocument = gql`
    query CompanyIngestionDiffErrors {
  companyIngestionDiffErrors {
    id
    createdAt
    companyIngestionDiffId
    errorCode
    errorData
    status
  }
}
    `;

export function useCompanyIngestionDiffErrorsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanyIngestionDiffErrorsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanyIngestionDiffErrorsQuery,
    GQTypes.GQCompanyIngestionDiffErrorsQueryVariables
  >({ query: CompanyIngestionDiffErrorsDocument, ...options });
}
export const CreateCtsDocument = gql`
    mutation CreateCts($input: CreateCtsInput!) {
  createCts(input: $input) {
    schema {
      id
      ...CustomerTargetSchemaForListView
    }
  }
}
    ${CustomerTargetSchemaForListViewFragmentDoc}`;

export function useCreateCtsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCtsMutation,
    GQTypes.GQCreateCtsMutationVariables
  >(CreateCtsDocument);
}
export const UpdateCtsDocument = gql`
    mutation UpdateCts($input: UpdateCtsInput!) {
  updateCts(input: $input) {
    schema {
      id
      ...CustomerTargetSchemaForListView
    }
  }
}
    ${CustomerTargetSchemaForListViewFragmentDoc}`;

export function useUpdateCtsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCtsMutation,
    GQTypes.GQUpdateCtsMutationVariables
  >(UpdateCtsDocument);
}
export const ConvertCtsToTSchemaDocument = gql`
    mutation ConvertCtsToTSchema($input: ConvertCtsToTSchemaInput!) {
  convertCtsToTSchema(input: $input) {
    cts {
      ...CtsInfoHeader
    }
  }
}
    ${CtsInfoHeaderFragmentDoc}`;

export function useConvertCtsToTSchemaMutation() {
  return Urql.useMutation<
    GQTypes.GQConvertCtsToTSchemaMutation,
    GQTypes.GQConvertCtsToTSchemaMutationVariables
  >(ConvertCtsToTSchemaDocument);
}
export const ConvertCtsBackDocument = gql`
    mutation ConvertCtsBack($input: ConvertCtsBackInput!) {
  convertCtsBack(input: $input) {
    cts {
      ...CtsInfoHeader
    }
  }
}
    ${CtsInfoHeaderFragmentDoc}`;

export function useConvertCtsBackMutation() {
  return Urql.useMutation<
    GQTypes.GQConvertCtsBackMutation,
    GQTypes.GQConvertCtsBackMutationVariables
  >(ConvertCtsBackDocument);
}
export const RunCtsTransformTestCaseDocument = gql`
    mutation RunCtsTransformTestCase($input: RunCtsTransformTestCaseInput!) {
  runCtsTransformTestCase(input: $input) {
    workflowId
  }
}
    `;

export function useRunCtsTransformTestCaseMutation() {
  return Urql.useMutation<
    GQTypes.GQRunCtsTransformTestCaseMutation,
    GQTypes.GQRunCtsTransformTestCaseMutationVariables
  >(RunCtsTransformTestCaseDocument);
}
export const CreateCtsTransformTestCaseDocument = gql`
    mutation CreateCtsTransformTestCase($input: CreateCtsTransformTestCaseInput!) {
  createCtsTransformTestCase(input: $input) {
    ctsTransformTestCase {
      id
      transform {
        ...CtsTransformForTests
      }
    }
  }
}
    ${CtsTransformForTestsFragmentDoc}`;

export function useCreateCtsTransformTestCaseMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCtsTransformTestCaseMutation,
    GQTypes.GQCreateCtsTransformTestCaseMutationVariables
  >(CreateCtsTransformTestCaseDocument);
}
export const UpdateCtsTransformTestCaseDocument = gql`
    mutation UpdateCtsTransformTestCase($input: UpdateCtsTransformTestCaseInput!) {
  updateCtsTransformTestCase(input: $input) {
    ctsTransformTestCase {
      ...CtsTest
    }
  }
}
    ${CtsTestFragmentDoc}`;

export function useUpdateCtsTransformTestCaseMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCtsTransformTestCaseMutation,
    GQTypes.GQUpdateCtsTransformTestCaseMutationVariables
  >(UpdateCtsTransformTestCaseDocument);
}
export const DeleteCtsTransformTestCaseDocument = gql`
    mutation DeleteCtsTransformTestCase($input: DeleteCtsTransformTestCaseInput!) {
  deleteCtsTransformTestCase(input: $input) {
    ctsTransform {
      ...CtsTransformForTests
    }
  }
}
    ${CtsTransformForTestsFragmentDoc}`;

export function useDeleteCtsTransformTestCaseMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCtsTransformTestCaseMutation,
    GQTypes.GQDeleteCtsTransformTestCaseMutationVariables
  >(DeleteCtsTransformTestCaseDocument);
}
export const ApproveCtsTransformTestCaseDocument = gql`
    mutation ApproveCtsTransformTestCase($input: ApproveCtsTransformTestCaseInput!) {
  approveCtsTransformTestCase(input: $input) {
    ctsTransformTestCase {
      ...CtsTest
    }
  }
}
    ${CtsTestFragmentDoc}`;

export function useApproveCtsTransformTestCaseMutation() {
  return Urql.useMutation<
    GQTypes.GQApproveCtsTransformTestCaseMutation,
    GQTypes.GQApproveCtsTransformTestCaseMutationVariables
  >(ApproveCtsTransformTestCaseDocument);
}
export const CustomerTargetSchemaTransformDocument = gql`
    query CustomerTargetSchemaTransform($id: ID!) {
  customerTargetSchemaTransform(id: $id) {
    id
    ...CtsTransformForTests
  }
}
    ${CtsTransformForTestsFragmentDoc}`;

export function useCustomerTargetSchemaTransformQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCustomerTargetSchemaTransformQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCustomerTargetSchemaTransformQuery,
    GQTypes.GQCustomerTargetSchemaTransformQueryVariables
  >({ query: CustomerTargetSchemaTransformDocument, ...options });
}
export const PublishCtsVersionDocument = gql`
    mutation PublishCtsVersion($input: PublishCtsVersionInput!) {
  publishCtsVersion(input: $input) {
    ctsVersion {
      id
      ...CtsVersionAdmin
    }
    customerTargetSchema {
      ...CtsInfoHeader
    }
  }
}
    ${CtsVersionAdminFragmentDoc}
${CtsInfoHeaderFragmentDoc}`;

export function usePublishCtsVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishCtsVersionMutation,
    GQTypes.GQPublishCtsVersionMutationVariables
  >(PublishCtsVersionDocument);
}
export const PublishCtsVersionForTestingDocument = gql`
    mutation PublishCtsVersionForTesting($input: PublishCtsVersionInput!) {
  publishCtsVersionForTesting(input: $input) {
    ctsVersion {
      id
      ...CtsVersionAdmin
    }
  }
}
    ${CtsVersionAdminFragmentDoc}`;

export function usePublishCtsVersionForTestingMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishCtsVersionForTestingMutation,
    GQTypes.GQPublishCtsVersionForTestingMutationVariables
  >(PublishCtsVersionForTestingDocument);
}
export const UnpublishCtsVersionDocument = gql`
    mutation UnpublishCtsVersion($input: UnpublishCtsVersionInput!) {
  unpublishCtsVersion(input: $input) {
    ctsVersion {
      id
      ...CtsVersionAdmin
    }
    customerTargetSchema {
      ...CtsInfoHeader
    }
  }
}
    ${CtsVersionAdminFragmentDoc}
${CtsInfoHeaderFragmentDoc}`;

export function useUnpublishCtsVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQUnpublishCtsVersionMutation,
    GQTypes.GQUnpublishCtsVersionMutationVariables
  >(UnpublishCtsVersionDocument);
}
export const PushCtsVersionToOneSchemaDocument = gql`
    mutation PushCtsVersionToOneSchema($input: PushCtsVersionToOneSchemaInput!) {
  pushCtsVersionToOneSchema(input: $input) {
    oneSchemaTemplateKey
    signedUrl
    fileMetadata {
      ...FileMetadataForCtsVersionEditor
    }
    oneSchemaTemplateUrl
  }
}
    ${FileMetadataForCtsVersionEditorFragmentDoc}`;

export function usePushCtsVersionToOneSchemaMutation() {
  return Urql.useMutation<
    GQTypes.GQPushCtsVersionToOneSchemaMutation,
    GQTypes.GQPushCtsVersionToOneSchemaMutationVariables
  >(PushCtsVersionToOneSchemaDocument);
}
export const OrganizationProjectCreateDataIssuesFieldsDocument = gql`
    query OrganizationProjectCreateDataIssuesFields($orgId: ID!) {
  organization(id: $orgId) {
    id
    measurementProjects {
      ...MeasurementProjectFieldsForCreateDataIssue
    }
  }
}
    ${MeasurementProjectFieldsForCreateDataIssueFragmentDoc}`;

export function useOrganizationProjectCreateDataIssuesFieldsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationProjectCreateDataIssuesFieldsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationProjectCreateDataIssuesFieldsQuery,
    GQTypes.GQOrganizationProjectCreateDataIssuesFieldsQueryVariables
  >({ query: OrganizationProjectCreateDataIssuesFieldsDocument, ...options });
}
export const ToggleCliqMutabilityForEmissionsModelDocument = gql`
    mutation ToggleCliqMutabilityForEmissionsModel($input: ToggleCliqMutabilityForEmissionsModelInput!) {
  toggleCliqMutabilityForEmissionsModel(input: $input) {
    emissionsModelStable {
      id
      isMutable
    }
  }
}
    `;

export function useToggleCliqMutabilityForEmissionsModelMutation() {
  return Urql.useMutation<
    GQTypes.GQToggleCliqMutabilityForEmissionsModelMutation,
    GQTypes.GQToggleCliqMutabilityForEmissionsModelMutationVariables
  >(ToggleCliqMutabilityForEmissionsModelDocument);
}
export const CreateEmissionsModelDocument = gql`
    mutation CreateEmissionsModel($input: CreateEmissionsModelInput!) {
  createEmissionsModel(input: $input) {
    __typename
    ... on CreateEmissionsModelPayloadInvalid {
      validationErrors {
        msg
        path
      }
    }
    ... on CreateEmissionsModelPayloadValid {
      emissionsModelStableRecord {
        id
      }
    }
  }
}
    `;

export function useCreateEmissionsModelMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEmissionsModelMutation,
    GQTypes.GQCreateEmissionsModelMutationVariables
  >(CreateEmissionsModelDocument);
}
export const ValidateEmissionsModelMatcherDocument = gql`
    mutation ValidateEmissionsModelMatcher($input: ValidateEmissionsModelMatcherInput!) {
  validateEmissionsModelMatcher(input: $input) {
    isValid
    errors {
      path
      msg
      severity
    }
  }
}
    `;

export function useValidateEmissionsModelMatcherMutation() {
  return Urql.useMutation<
    GQTypes.GQValidateEmissionsModelMatcherMutation,
    GQTypes.GQValidateEmissionsModelMatcherMutationVariables
  >(ValidateEmissionsModelMatcherDocument);
}
export const GetDiffSourceEmissionsModelVersionDocument = gql`
    query GetDiffSourceEmissionsModelVersion($stableId: ID!) {
  latestEmissionsModelVersionForStableId(id: $stableId, includeDrafts: false) {
    id
    emissionsModelJson
    parameterResolverJson
  }
  emissionsModelMatchersForStableId(id: $stableId, includeDrafts: false) {
    ...EmissionsModelMatcherFields
  }
}
    ${EmissionsModelMatcherFieldsFragmentDoc}`;

export function useGetDiffSourceEmissionsModelVersionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDiffSourceEmissionsModelVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDiffSourceEmissionsModelVersionQuery,
    GQTypes.GQGetDiffSourceEmissionsModelVersionQueryVariables
  >({ query: GetDiffSourceEmissionsModelVersionDocument, ...options });
}
export const GetReferenceDataFromSlugsDocument = gql`
    query GetReferenceDataFromSlugs($slugs: [String!]!) {
  getReferenceDataFromRawSlugs(slugs: $slugs) {
    name
    id
  }
}
    `;

export function useGetReferenceDataFromSlugsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReferenceDataFromSlugsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReferenceDataFromSlugsQuery,
    GQTypes.GQGetReferenceDataFromSlugsQueryVariables
  >({ query: GetReferenceDataFromSlugsDocument, ...options });
}
export const ReviewPrepublicationTestResultsForEmissionsModelDocument = gql`
    mutation ReviewPrepublicationTestResultsForEmissionsModel($input: ReviewPrepublicationTestResultsInput!) {
  reviewResult: reviewPrepublicationTestResultsForEmissionsModel(input: $input) {
    outcome
    errorCode
    publishedEmissionsModelVersion {
      ...EmissionsModelVersionFieldsForEditor
    }
    publishedEmissionsModelMatchers {
      ...EmissionsModelMatcherFields
    }
  }
}
    ${EmissionsModelVersionFieldsForEditorFragmentDoc}
${EmissionsModelMatcherFieldsFragmentDoc}`;

export function useReviewPrepublicationTestResultsForEmissionsModelMutation() {
  return Urql.useMutation<
    GQTypes.GQReviewPrepublicationTestResultsForEmissionsModelMutation,
    GQTypes.GQReviewPrepublicationTestResultsForEmissionsModelMutationVariables
  >(ReviewPrepublicationTestResultsForEmissionsModelDocument);
}
export const SaveEmissionsModelDraftDocument = gql`
    mutation SaveEmissionsModelDraft($input: SaveEmissionsModelDraftInput!) {
  saveEmissionsModelDraft(input: $input) {
    __typename
    ... on SaveEmissionsModelDraftPayloadInvalid {
      validationErrors {
        msg
        path
        severity
      }
    }
    ... on SaveEmissionsModelDraftPayloadValid {
      emissionsModelVersionRecord {
        ...EmissionsModelVersionFieldsForEditor
      }
      parameterResolverRecord {
        id
      }
      matchers {
        ...EmissionsModelMatcherFields
      }
    }
  }
}
    ${EmissionsModelVersionFieldsForEditorFragmentDoc}
${EmissionsModelMatcherFieldsFragmentDoc}`;

export function useSaveEmissionsModelDraftMutation() {
  return Urql.useMutation<
    GQTypes.GQSaveEmissionsModelDraftMutation,
    GQTypes.GQSaveEmissionsModelDraftMutationVariables
  >(SaveEmissionsModelDraftDocument);
}
export const TypeAheadRefDataSourceDocument = gql`
    query TypeAheadRefDataSource($q: String!, $orgId: ID) {
  searchReferenceDataSourceName(q: $q, orgId: $orgId) {
    ...ReferenceDataSourceFieldsForPicker
  }
}
    ${ReferenceDataSourceFieldsForPickerFragmentDoc}`;

export function useTypeAheadRefDataSourceQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQTypeAheadRefDataSourceQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQTypeAheadRefDataSourceQuery,
    GQTypes.GQTypeAheadRefDataSourceQueryVariables
  >({ query: TypeAheadRefDataSourceDocument, ...options });
}
export const GetReferenceDataSourceByNameDocument = gql`
    query GetReferenceDataSourceByName($name: String!, $orgId: ID) {
  referenceDataSourceByName(name: $name) {
    ...ReferenceDataSourceFieldsForPicker
  }
}
    ${ReferenceDataSourceFieldsForPickerFragmentDoc}`;

export function useGetReferenceDataSourceByNameQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReferenceDataSourceByNameQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReferenceDataSourceByNameQuery,
    GQTypes.GQGetReferenceDataSourceByNameQueryVariables
  >({ query: GetReferenceDataSourceByNameDocument, ...options });
}
export const AutosaveEmissionsModelDocument = gql`
    mutation AutosaveEmissionsModel($input: AutosaveEmissionsModelInput!) {
  autosaveEmissionsModel(input: $input) {
    __typename
    ... on AutosaveEmissionsModelPayloadOk {
      emissionsModelActiveDraftRecord {
        id
        autosavedAt
      }
    }
    ... on AutosaveEmissionsModelPayloadBlocked {
      authorId
      authorName
      autosavedAt
    }
    ... on AutosaveEmissionsModelPayloadError {
      error {
        message
        code
        errorType
        details
      }
    }
  }
}
    `;

export function useAutosaveEmissionsModelMutation() {
  return Urql.useMutation<
    GQTypes.GQAutosaveEmissionsModelMutation,
    GQTypes.GQAutosaveEmissionsModelMutationVariables
  >(AutosaveEmissionsModelDocument);
}
export const ReleaseEmissionsModelActiveDraftDocument = gql`
    mutation ReleaseEmissionsModelActiveDraft($input: ReleaseEmissionsModelActiveDraftInput!) {
  releaseEmissionsModelActiveDraft(input: $input) {
    __typename
    ... on ReleaseEmissionsModelActiveDraftPayloadSuccess {
      emissionsModelActiveDraftRecord {
        id
        isCheckedOut
        autosavedAt
        releasedAt
      }
    }
    ... on ReleaseEmissionsModelActiveDraftPayloadNonOp {
      message
    }
  }
}
    `;

export function useReleaseEmissionsModelActiveDraftMutation() {
  return Urql.useMutation<
    GQTypes.GQReleaseEmissionsModelActiveDraftMutation,
    GQTypes.GQReleaseEmissionsModelActiveDraftMutationVariables
  >(ReleaseEmissionsModelActiveDraftDocument);
}
export const SlugSchemasDocument = gql`
    query SlugSchemas($slugs: [String!]!) {
  referenceDataVersionsBySlugs(slugs: $slugs) {
    slug
    referenceDataVersion {
      id
      latestSchema {
        id
        schemaJson
      }
    }
  }
}
    `;

export function useSlugSchemasQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQSlugSchemasQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQSlugSchemasQuery,
    GQTypes.GQSlugSchemasQueryVariables
  >({ query: SlugSchemasDocument, ...options });
}
export const AddEmissionsModelToOrgDocument = gql`
    mutation AddEmissionsModelToOrg($input: AddEmissionsModelToOrgInput!) {
  addEmissionsModelToOrg(input: $input) {
    emissionsModelStable {
      id
      org {
        id
        name
      }
    }
  }
}
    `;

export function useAddEmissionsModelToOrgMutation() {
  return Urql.useMutation<
    GQTypes.GQAddEmissionsModelToOrgMutation,
    GQTypes.GQAddEmissionsModelToOrgMutationVariables
  >(AddEmissionsModelToOrgDocument);
}
export const ForkEmissionsModelDocument = gql`
    mutation ForkEmissionsModel($input: ForkEmissionsModelInput!) {
  forkEmissionsModel(input: $input) {
    emissionsModelStableRecord {
      id
    }
  }
}
    `;

export function useForkEmissionsModelMutation() {
  return Urql.useMutation<
    GQTypes.GQForkEmissionsModelMutation,
    GQTypes.GQForkEmissionsModelMutationVariables
  >(ForkEmissionsModelDocument);
}
export const SetEmissionsModelIsArchivedDocument = gql`
    mutation SetEmissionsModelIsArchived($input: SetEmissionsModelIsArchivedInput!) {
  setEmissionsModelIsArchived(input: $input) {
    emissionsModelStableRecord {
      id
      isArchived
    }
  }
}
    `;

export function useSetEmissionsModelIsArchivedMutation() {
  return Urql.useMutation<
    GQTypes.GQSetEmissionsModelIsArchivedMutation,
    GQTypes.GQSetEmissionsModelIsArchivedMutationVariables
  >(SetEmissionsModelIsArchivedDocument);
}
export const GetEmissionsModelLibraryForStableModelDocument = gql`
    query GetEmissionsModelLibraryForStableModel($id: ID!) {
  emissionsModelLibraryForStableModel(id: $id) {
    id
    description
    title
    isArchived
    kind
    org {
      id
      name
    }
    latestPublishedVersion {
      id
      createdAt
      author {
        id
        name
      }
      kind
      changelog
    }
  }
}
    `;

export function useGetEmissionsModelLibraryForStableModelQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelLibraryForStableModelQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelLibraryForStableModelQuery,
    GQTypes.GQGetEmissionsModelLibraryForStableModelQueryVariables
  >({ query: GetEmissionsModelLibraryForStableModelDocument, ...options });
}
export const GetEmissionsModelForkRelationshipsDocument = gql`
    query GetEmissionsModelForkRelationships($stableId: ID!) {
  emissionsModelForkRelationships(stableId: $stableId) {
    upstreamEntry {
      id
      forkedStableId
      forkedStable {
        id
        title
      }
    }
    downstreamEntries {
      id
      newStableId
      newStable {
        id
        title
      }
    }
  }
}
    `;

export function useGetEmissionsModelForkRelationshipsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelForkRelationshipsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelForkRelationshipsQuery,
    GQTypes.GQGetEmissionsModelForkRelationshipsQueryVariables
  >({ query: GetEmissionsModelForkRelationshipsDocument, ...options });
}
export const StartPublishingEmissionsModelDocument = gql`
    mutation StartPublishingEmissionsModel($input: StartPublishingEmissionsModelInput!) {
  startPublishingEmissionsModel(input: $input) {
    __typename
    ... on StartPublishingEmissionsModelPayloadInvalid {
      validationErrors {
        msg
        path
      }
    }
    ... on StartPublishingEmissionsModelPayloadValid {
      emissionsModelVersionRecord {
        ...EmissionsModelVersionFieldsForEditor
      }
      parameterResolverRecord {
        id
      }
      matchers {
        ...EmissionsModelMatcherFields
      }
    }
    ... on StartPublishingEmissionsModelPayloadError {
      error {
        message
        code
        stackTrace
        errorType
        details
      }
    }
  }
}
    ${EmissionsModelVersionFieldsForEditorFragmentDoc}
${EmissionsModelMatcherFieldsFragmentDoc}`;

export function useStartPublishingEmissionsModelMutation() {
  return Urql.useMutation<
    GQTypes.GQStartPublishingEmissionsModelMutation,
    GQTypes.GQStartPublishingEmissionsModelMutationVariables
  >(StartPublishingEmissionsModelDocument);
}
export const GetEmissionsModelVersionHistoryDocument = gql`
    query GetEmissionsModelVersionHistory($id: ID!, $before: String, $after: String, $first: Int, $last: Int) {
  emissionsModelStableVersionHistory(
    id: $id
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    edges {
      node {
        id
        createdAt
        changelog
        kind
        author {
          id
          name
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

export function useGetEmissionsModelVersionHistoryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelVersionHistoryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelVersionHistoryQuery,
    GQTypes.GQGetEmissionsModelVersionHistoryQueryVariables
  >({ query: GetEmissionsModelVersionHistoryDocument, ...options });
}
export const GetEmissionsModelVersionJsonDocument = gql`
    query GetEmissionsModelVersionJson($id: ID!) {
  emissionsModelVersion(id: $id) {
    id
    emissionsModelJson
    parameterResolverJson
  }
}
    `;

export function useGetEmissionsModelVersionJsonQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelVersionJsonQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelVersionJsonQuery,
    GQTypes.GQGetEmissionsModelVersionJsonQueryVariables
  >({ query: GetEmissionsModelVersionJsonDocument, ...options });
}
export const GetEmissionsModelAncestryDocument = gql`
    query GetEmissionsModelAncestry($emissionsModelStableId: ID!) {
  emissionsModelStable(id: $emissionsModelStableId) {
    id
    ancestry {
      ancestors {
        ...EmissionsModelAncestorFields
      }
    }
  }
}
    ${EmissionsModelAncestorFieldsFragmentDoc}`;

export function useGetEmissionsModelAncestryQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelAncestryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelAncestryQuery,
    GQTypes.GQGetEmissionsModelAncestryQueryVariables
  >({ query: GetEmissionsModelAncestryDocument, ...options });
}
export const EmissionsModelEditorEvalDocument = gql`
    query EmissionsModelEditorEval($input: EmissionsModelEditorEvalInput!) {
  emissionsModelEditorEval(input: $input) {
    resultJson
    warnings
    emissionsEquations {
      emissionsVariableName
      traceDetails {
        referenceValueDetails
        emissionsModelVariableDetails
        bartValueDetails
        emissionsModelVersionId
        emissionsModelEvaluationId
      }
      trace
    }
  }
}
    `;

export function useEmissionsModelEditorEvalQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelEditorEvalQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelEditorEvalQuery,
    GQTypes.GQEmissionsModelEditorEvalQueryVariables
  >({ query: EmissionsModelEditorEvalDocument, ...options });
}
export const StartPrepublicationTestsForEmissionsModelDocument = gql`
    mutation StartPrepublicationTestsForEmissionsModel($input: StartPrepublicationTestsForEmissionsModelInput!) {
  startPrepublicationTestsForEmissionsModel(input: $input) {
    workflowId
  }
}
    `;

export function useStartPrepublicationTestsForEmissionsModelMutation() {
  return Urql.useMutation<
    GQTypes.GQStartPrepublicationTestsForEmissionsModelMutation,
    GQTypes.GQStartPrepublicationTestsForEmissionsModelMutationVariables
  >(StartPrepublicationTestsForEmissionsModelDocument);
}
export const EmissionsModelReleasesForPickerDocument = gql`
    query EmissionsModelReleasesForPicker($orgId: ID, $businessActivityTypeNames: [String!], $includeAllOrgs: Boolean!, $includeAlphas: Boolean) {
  emissionsModelReleases(
    orgId: $orgId
    businessActivityTypeNames: $businessActivityTypeNames
    includeAllOrgs: $includeAllOrgs
    includeAlphas: $includeAlphas
  ) {
    ...EmissionsModelReleaseMetadata
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;

export function useEmissionsModelReleasesForPickerQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelReleasesForPickerQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelReleasesForPickerQuery,
    GQTypes.GQEmissionsModelReleasesForPickerQueryVariables
  >({ query: EmissionsModelReleasesForPickerDocument, ...options });
}
export const GetAllGlobalFootprintTagNamesDocument = gql`
    query GetAllGlobalFootprintTagNames {
  globalFootprintTags(latestOfEachTag: true) {
    id
    tagName
    recordEndAt
  }
}
    `;

export function useGetAllGlobalFootprintTagNamesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllGlobalFootprintTagNamesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllGlobalFootprintTagNamesQuery,
    GQTypes.GQGetAllGlobalFootprintTagNamesQueryVariables
  >({ query: GetAllGlobalFootprintTagNamesDocument, ...options });
}
export const GetGlobalFootprintTagVersionsForTagNameDocument = gql`
    query GetGlobalFootprintTagVersionsForTagName($tagName: String) {
  globalFootprintTags(includeDeleted: true, tagName: $tagName) {
    id
    tagName
    description
    createdAt
    recordStartAt
    rulesList
    watershedEditor {
      id
      name
    }
  }
}
    `;

export function useGetGlobalFootprintTagVersionsForTagNameQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetGlobalFootprintTagVersionsForTagNameQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetGlobalFootprintTagVersionsForTagNameQuery,
    GQTypes.GQGetGlobalFootprintTagVersionsForTagNameQueryVariables
  >({ query: GetGlobalFootprintTagVersionsForTagNameDocument, ...options });
}
export const GetManyEmissionsModelStablesDocument = gql`
    query getManyEmissionsModelStables($ids: [ID!]!) {
  emissionsModelsStableByIds(ids: $ids) {
    id
    title
    description
  }
}
    `;

export function useGetManyEmissionsModelStablesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetManyEmissionsModelStablesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetManyEmissionsModelStablesQuery,
    GQTypes.GQGetManyEmissionsModelStablesQueryVariables
  >({ query: GetManyEmissionsModelStablesDocument, ...options });
}
export const ReviewPrepublicationTestResultsForReleaseDocument = gql`
    mutation ReviewPrepublicationTestResultsForRelease($input: ReviewPrepublicationTestResultsInput!) {
  reviewResult: reviewPrepublicationTestResultsForRelease(input: $input) {
    outcome
    errorCode
    publishedRelease {
      id
    }
  }
}
    `;

export function useReviewPrepublicationTestResultsForReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQReviewPrepublicationTestResultsForReleaseMutation,
    GQTypes.GQReviewPrepublicationTestResultsForReleaseMutationVariables
  >(ReviewPrepublicationTestResultsForReleaseDocument);
}
export const StartPrepublicationTestsForReleaseDocument = gql`
    mutation StartPrepublicationTestsForRelease($input: StartPrepublicationTestsForReleaseInput!) {
  startPrepublicationTestsForRelease(input: $input) {
    workflowId
  }
}
    `;

export function useStartPrepublicationTestsForReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQStartPrepublicationTestsForReleaseMutation,
    GQTypes.GQStartPrepublicationTestsForReleaseMutationVariables
  >(StartPrepublicationTestsForReleaseDocument);
}
export const CreateEmissionsModelReleaseDocument = gql`
    mutation CreateEmissionsModelRelease($input: CreateEmissionsModelReleaseInput!) {
  createEmissionsModelRelease(input: $input) {
    emissionsModelRelease {
      id
    }
  }
}
    `;

export function useCreateEmissionsModelReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEmissionsModelReleaseMutation,
    GQTypes.GQCreateEmissionsModelReleaseMutationVariables
  >(CreateEmissionsModelReleaseDocument);
}
export const UpdateEmissionsModelReleaseMetadataDocument = gql`
    mutation UpdateEmissionsModelReleaseMetadata($input: UpdateEmissionsModelReleaseMetadataInput!) {
  updateEmissionsModelReleaseMetadata(input: $input) {
    emissionsModelRelease {
      ...EmissionsModelReleaseMetadata
    }
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;

export function useUpdateEmissionsModelReleaseMetadataMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEmissionsModelReleaseMetadataMutation,
    GQTypes.GQUpdateEmissionsModelReleaseMetadataMutationVariables
  >(UpdateEmissionsModelReleaseMetadataDocument);
}
export const DeleteEmissionsModelReleaseDocument = gql`
    mutation DeleteEmissionsModelRelease($input: IdInput!) {
  deleteEmissionsModelRelease(input: $input) {
    id
  }
}
    `;

export function useDeleteEmissionsModelReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteEmissionsModelReleaseMutation,
    GQTypes.GQDeleteEmissionsModelReleaseMutationVariables
  >(DeleteEmissionsModelReleaseDocument);
}
export const UpdateEmissionsModelReleaseLifecyclePhaseDocument = gql`
    mutation UpdateEmissionsModelReleaseLifecyclePhase($input: UpdateEmissionsModelReleaseLifecyclePhaseInput!) {
  updateEmissionsModelReleaseLifecyclePhase(input: $input) {
    emissionsModelRelease {
      ...EmissionsModelReleaseDetailsPage
    }
  }
}
    ${EmissionsModelReleaseDetailsPageFragmentDoc}`;

export function useUpdateEmissionsModelReleaseLifecyclePhaseMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEmissionsModelReleaseLifecyclePhaseMutation,
    GQTypes.GQUpdateEmissionsModelReleaseLifecyclePhaseMutationVariables
  >(UpdateEmissionsModelReleaseLifecyclePhaseDocument);
}
export const LinkAndUnlinkEmissionsModelsToReleaseDocument = gql`
    mutation LinkAndUnlinkEmissionsModelsToRelease($input: LinkAndUnlinkEmissionsModelsToReleaseInput!) {
  linkAndUnlinkEmissionsModelsToRelease(input: $input) {
    emissionsModelRelease {
      id
      emissionsModels {
        ...EmissionsModelStableFieldsForReleases
      }
    }
  }
}
    ${EmissionsModelStableFieldsForReleasesFragmentDoc}`;

export function useLinkAndUnlinkEmissionsModelsToReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQLinkAndUnlinkEmissionsModelsToReleaseMutation,
    GQTypes.GQLinkAndUnlinkEmissionsModelsToReleaseMutationVariables
  >(LinkAndUnlinkEmissionsModelsToReleaseDocument);
}
export const LinkGlobalFootprintTagsToReleaseDocument = gql`
    mutation LinkGlobalFootprintTagsToRelease($input: LinkGlobalFootprintTagsToReleaseInput!) {
  linkGlobalFootprintTagsToRelease(input: $input) {
    emissionsModelRelease {
      id
      globalFootprintTags {
        ...GlobalFootprintTagFieldsForReleases
      }
    }
  }
}
    ${GlobalFootprintTagFieldsForReleasesFragmentDoc}`;

export function useLinkGlobalFootprintTagsToReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQLinkGlobalFootprintTagsToReleaseMutation,
    GQTypes.GQLinkGlobalFootprintTagsToReleaseMutationVariables
  >(LinkGlobalFootprintTagsToReleaseDocument);
}
export const UnlinkGlobalFootprintTagsFromReleaseDocument = gql`
    mutation UnlinkGlobalFootprintTagsFromRelease($input: UnlinkGlobalFootprintTagsFromReleaseInput!) {
  unlinkGlobalFootprintTagsFromRelease(input: $input) {
    emissionsModelRelease {
      id
      globalFootprintTags {
        ...GlobalFootprintTagFieldsForReleases
      }
    }
  }
}
    ${GlobalFootprintTagFieldsForReleasesFragmentDoc}`;

export function useUnlinkGlobalFootprintTagsFromReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQUnlinkGlobalFootprintTagsFromReleaseMutation,
    GQTypes.GQUnlinkGlobalFootprintTagsFromReleaseMutationVariables
  >(UnlinkGlobalFootprintTagsFromReleaseDocument);
}
export const AddOrgAccessToReleaseDocument = gql`
    mutation AddOrgAccessToRelease($input: AddOrgAccessToReleaseInput!) {
  addOrgAccessToRelease(input: $input) {
    emissionsModelRelease {
      id
      orgAccess {
        ...EmissionsModelReleaseOrgAccessFields
      }
    }
  }
}
    ${EmissionsModelReleaseOrgAccessFieldsFragmentDoc}`;

export function useAddOrgAccessToReleaseMutation() {
  return Urql.useMutation<
    GQTypes.GQAddOrgAccessToReleaseMutation,
    GQTypes.GQAddOrgAccessToReleaseMutationVariables
  >(AddOrgAccessToReleaseDocument);
}
export const ConvertGlobalReleaseToOrgGatedDocument = gql`
    mutation ConvertGlobalReleaseToOrgGated($input: EmissionsModelReleaseIdInput!) {
  convertGlobalReleaseToOrgGated(input: $input) {
    emissionsModelRelease {
      id
      isOrgGated
    }
  }
}
    `;

export function useConvertGlobalReleaseToOrgGatedMutation() {
  return Urql.useMutation<
    GQTypes.GQConvertGlobalReleaseToOrgGatedMutation,
    GQTypes.GQConvertGlobalReleaseToOrgGatedMutationVariables
  >(ConvertGlobalReleaseToOrgGatedDocument);
}
export const ConvertOrgGatedReleaseToGlobalDocument = gql`
    mutation ConvertOrgGatedReleaseToGlobal($input: EmissionsModelReleaseIdInput!) {
  convertOrgGatedReleaseToGlobal(input: $input) {
    emissionsModelRelease {
      id
      isOrgGated
    }
  }
}
    `;

export function useConvertOrgGatedReleaseToGlobalMutation() {
  return Urql.useMutation<
    GQTypes.GQConvertOrgGatedReleaseToGlobalMutation,
    GQTypes.GQConvertOrgGatedReleaseToGlobalMutationVariables
  >(ConvertOrgGatedReleaseToGlobalDocument);
}
export const EmissionsModelReleaseDetailsDocument = gql`
    query EmissionsModelReleaseDetails($releaseId: ID!) {
  emissionsModelRelease(id: $releaseId) {
    ...EmissionsModelReleaseDetailsPage
  }
}
    ${EmissionsModelReleaseDetailsPageFragmentDoc}`;

export function useEmissionsModelReleaseDetailsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelReleaseDetailsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelReleaseDetailsQuery,
    GQTypes.GQEmissionsModelReleaseDetailsQueryVariables
  >({ query: EmissionsModelReleaseDetailsDocument, ...options });
}
export const EmissionsModelReleasesDocument = gql`
    query EmissionsModelReleases($orgId: ID, $batNames: [String!], $includeAllOrgs: Boolean) {
  emissionsModelReleases(
    orgId: $orgId
    businessActivityTypeNames: $batNames
    includeAllOrgs: $includeAllOrgs
  ) {
    ...EmissionsModelReleaseListEntry
  }
}
    ${EmissionsModelReleaseListEntryFragmentDoc}`;

export function useEmissionsModelReleasesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelReleasesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelReleasesQuery,
    GQTypes.GQEmissionsModelReleasesQueryVariables
  >({ query: EmissionsModelReleasesDocument, ...options });
}
export const EmissionsModelsStableForBusinessActivityTypeDocument = gql`
    query EmissionsModelsStableForBusinessActivityType($businessActivityTypeName: String!, $orgId: ID) {
  emissionsModelsStableForBusinessActivityType(
    businessActvityTypeName: $businessActivityTypeName
    orgId: $orgId
  ) {
    ...EmissionsModelStableFieldsForReleases
  }
}
    ${EmissionsModelStableFieldsForReleasesFragmentDoc}`;

export function useEmissionsModelsStableForBusinessActivityTypeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelsStableForBusinessActivityTypeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelsStableForBusinessActivityTypeQuery,
    GQTypes.GQEmissionsModelsStableForBusinessActivityTypeQueryVariables
  >({
    query: EmissionsModelsStableForBusinessActivityTypeDocument,
    ...options,
  });
}
export const EmissionsModelsStablesForReleasesDocument = gql`
    query EmissionsModelsStablesForReleases($releaseIds: [ID!]!, $excludeOrgSpecificModels: Boolean = true) {
  emissionsModelsStablesForReleases(
    releaseIds: $releaseIds
    excludeOrgSpecificModels: $excludeOrgSpecificModels
  ) {
    ...EmissionsModelStableFieldsForReleases
  }
}
    ${EmissionsModelStableFieldsForReleasesFragmentDoc}`;

export function useEmissionsModelsStablesForReleasesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelsStablesForReleasesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelsStablesForReleasesQuery,
    GQTypes.GQEmissionsModelsStablesForReleasesQueryVariables
  >({ query: EmissionsModelsStablesForReleasesDocument, ...options });
}
export const EmissionsModelReleasesForEmissionsModelStableDocument = gql`
    query EmissionsModelReleasesForEmissionsModelStable($stableId: ID!) {
  emissionsModelReleasesForEmissionsModelStable(stableId: $stableId) {
    ...EmissionsModelReleaseMetadata
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;

export function useEmissionsModelReleasesForEmissionsModelStableQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelReleasesForEmissionsModelStableQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelReleasesForEmissionsModelStableQuery,
    GQTypes.GQEmissionsModelReleasesForEmissionsModelStableQueryVariables
  >({
    query: EmissionsModelReleasesForEmissionsModelStableDocument,
    ...options,
  });
}
export const EmissionsModelReleasesForEmissionsModelsStableDocument = gql`
    query EmissionsModelReleasesForEmissionsModelsStable($stableIds: [ID!]!) {
  emissionsModelReleasesForEmissionsModelsStable(stableIds: $stableIds) {
    models {
      id
      title
      isArchived
    }
    release {
      ...EmissionsModelReleaseMetadata
    }
  }
}
    ${EmissionsModelReleaseMetadataFragmentDoc}`;

export function useEmissionsModelReleasesForEmissionsModelsStableQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEmissionsModelReleasesForEmissionsModelsStableQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEmissionsModelReleasesForEmissionsModelsStableQuery,
    GQTypes.GQEmissionsModelReleasesForEmissionsModelsStableQueryVariables
  >({
    query: EmissionsModelReleasesForEmissionsModelsStableDocument,
    ...options,
  });
}
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($input: CreateOrganizationInput!) {
  createOrganization(input: $input) {
    org {
      id
    }
  }
}
    `;

export function useCreateOrganizationMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateOrganizationMutation,
    GQTypes.GQCreateOrganizationMutationVariables
  >(CreateOrganizationDocument);
}
export const OrganizationListDocument = gql`
    query OrganizationList($demoTestOnly: Boolean, $onlyOrgsWithAccess: Boolean, $region: String, $first: Int, $after: String, $last: Int, $before: String, $search: String) {
  organizations(
    demoTestOnly: $demoTestOnly
    onlyOrgsWithAccess: $onlyOrgsWithAccess
    region: $region
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
  ) {
    edges {
      node {
        __typename
        ...OrganizationListPageOrganization
      }
    }
    pageInfo {
      ...PageInfoWithCount
    }
  }
}
    ${OrganizationListPageOrganizationFragmentDoc}
${PageInfoWithCountFragmentDoc}`;

export function useOrganizationListQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationListQuery,
    GQTypes.GQOrganizationListQueryVariables
  >({ query: OrganizationListDocument, ...options });
}
export const OrganizationFeatureFlagsDocument = gql`
    query OrganizationFeatureFlags {
  featureFlags(targetType: Organization) {
    edges {
      node {
        ...FeatureFlagDetails
      }
    }
  }
  organizations {
    edges {
      node {
        id
        name
        demoOrg
        testOrg
      }
    }
  }
}
    ${FeatureFlagDetailsFragmentDoc}`;

export function useOrganizationFeatureFlagsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationFeatureFlagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationFeatureFlagsQuery,
    GQTypes.GQOrganizationFeatureFlagsQueryVariables
  >({ query: OrganizationFeatureFlagsDocument, ...options });
}
export const SetOrgsForFeatureFlagDocument = gql`
    mutation SetOrgsForFeatureFlag($input: SetOrgsForFeatureFlagInput!) {
  setOrgsForFeatureFlag(input: $input) {
    featureFlag {
      ...FeatureFlagDetails
    }
  }
}
    ${FeatureFlagDetailsFragmentDoc}`;

export function useSetOrgsForFeatureFlagMutation() {
  return Urql.useMutation<
    GQTypes.GQSetOrgsForFeatureFlagMutation,
    GQTypes.GQSetOrgsForFeatureFlagMutationVariables
  >(SetOrgsForFeatureFlagDocument);
}
export const UpdateOrgForFeatureFlagDocument = gql`
    mutation UpdateOrgForFeatureFlag($input: UpdateOrgForFeatureFlagInput!) {
  updateOrgForFeatureFlag(input: $input) {
    featureFlag {
      ...FeatureFlagDetails
    }
  }
}
    ${FeatureFlagDetailsFragmentDoc}`;

export function useUpdateOrgForFeatureFlagMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateOrgForFeatureFlagMutation,
    GQTypes.GQUpdateOrgForFeatureFlagMutationVariables
  >(UpdateOrgForFeatureFlagDocument);
}
export const SyncFeatureFlagsDocument = gql`
    mutation SyncFeatureFlags($input: SyncFeatureFlagsInput!) {
  syncFeatureFlags(input: $input) {
    diff {
      add
      remove
    }
    featureFlags {
      ...FeatureFlagDetails
    }
  }
}
    ${FeatureFlagDetailsFragmentDoc}`;

export function useSyncFeatureFlagsMutation() {
  return Urql.useMutation<
    GQTypes.GQSyncFeatureFlagsMutation,
    GQTypes.GQSyncFeatureFlagsMutationVariables
  >(SyncFeatureFlagsDocument);
}
export const UserFeatureFlagsDocument = gql`
    query UserFeatureFlags {
  featureFlags(targetType: User) {
    edges {
      node {
        ...UserFeatureFlagDetails
      }
    }
  }
  watershedEmployeeUsers {
    id
    name
  }
}
    ${UserFeatureFlagDetailsFragmentDoc}`;

export function useUserFeatureFlagsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserFeatureFlagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserFeatureFlagsQuery,
    GQTypes.GQUserFeatureFlagsQueryVariables
  >({ query: UserFeatureFlagsDocument, ...options });
}
export const SetUsersForFeatureFlagDocument = gql`
    mutation SetUsersForFeatureFlag($input: SetUsersForFeatureFlagInput!) {
  setUsersForFeatureFlag(input: $input) {
    featureFlag {
      ...UserFeatureFlagDetails
    }
  }
}
    ${UserFeatureFlagDetailsFragmentDoc}`;

export function useSetUsersForFeatureFlagMutation() {
  return Urql.useMutation<
    GQTypes.GQSetUsersForFeatureFlagMutation,
    GQTypes.GQSetUsersForFeatureFlagMutationVariables
  >(SetUsersForFeatureFlagDocument);
}
export const FootprintsForAdminFootprintSelectorDocument = gql`
    query FootprintsForAdminFootprintSelector($orgId: ID!) {
  footprintsForOrg(orgId: $orgId) {
    edges {
      node {
        ...FootprintFieldsAdmin
        footprintSnapshots(includeNonUserVisible: true) {
          ...FootprintSnapshotForFootprintSelectorAdmin
        }
      }
    }
  }
}
    ${FootprintFieldsAdminFragmentDoc}
${FootprintSnapshotForFootprintSelectorAdminFragmentDoc}`;

export function useFootprintsForAdminFootprintSelectorQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintsForAdminFootprintSelectorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintsForAdminFootprintSelectorQuery,
    GQTypes.GQFootprintsForAdminFootprintSelectorQueryVariables
  >({ query: FootprintsForAdminFootprintSelectorDocument, ...options });
}
export const GetFootprintTagDocument = gql`
    query GetFootprintTag($id: ID!) {
  footprintTag(id: $id) {
    id
    tagName
    description
    rulesList
  }
}
    `;

export function useGetFootprintTagQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintTagQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintTagQuery,
    GQTypes.GQGetFootprintTagQueryVariables
  >({ query: GetFootprintTagDocument, ...options });
}
export const ApplyTagsToPipelineResultDocument = gql`
    mutation ApplyTagsToPipelineResult($input: ApplyTagsToPipelineResultInput!) {
  applyTagsToPipelineResult(input: $input) {
    taggedResultParquetSignedUrl
    distinctCount
  }
}
    `;

export function useApplyTagsToPipelineResultMutation() {
  return Urql.useMutation<
    GQTypes.GQApplyTagsToPipelineResultMutation,
    GQTypes.GQApplyTagsToPipelineResultMutationVariables
  >(ApplyTagsToPipelineResultDocument);
}
export const SaveFootprintTagDocument = gql`
    mutation SaveFootprintTag($input: SaveFootprintTagInput!) {
  saveFootprintTag(input: $input) {
    footprintTag {
      id
    }
  }
}
    `;

export function useSaveFootprintTagMutation() {
  return Urql.useMutation<
    GQTypes.GQSaveFootprintTagMutation,
    GQTypes.GQSaveFootprintTagMutationVariables
  >(SaveFootprintTagDocument);
}
export const ApplyGlobalTagToTestDataDocument = gql`
    mutation ApplyGlobalTagToTestData($input: ApplyGlobalTagToTestDataInput!) {
  applyGlobalTagToTestData(input: $input) {
    taggedResultParquetSignedUrl
  }
}
    `;

export function useApplyGlobalTagToTestDataMutation() {
  return Urql.useMutation<
    GQTypes.GQApplyGlobalTagToTestDataMutation,
    GQTypes.GQApplyGlobalTagToTestDataMutationVariables
  >(ApplyGlobalTagToTestDataDocument);
}
export const SaveGlobalFootprintTagDocument = gql`
    mutation SaveGlobalFootprintTag($input: SaveGlobalFootprintTagInput!) {
  saveGlobalFootprintTag(input: $input) {
    footprintTag {
      id
    }
  }
}
    `;

export function useSaveGlobalFootprintTagMutation() {
  return Urql.useMutation<
    GQTypes.GQSaveGlobalFootprintTagMutation,
    GQTypes.GQSaveGlobalFootprintTagMutationVariables
  >(SaveGlobalFootprintTagDocument);
}
export const GetGlobalFootprintTagsDocument = gql`
    query GetGlobalFootprintTags($tagName: String!) {
  globalFootprintTags(tagName: $tagName, includeDeleted: true) {
    ...GlobalFootprintTagsForGlobalTagsEditor
  }
}
    ${GlobalFootprintTagsForGlobalTagsEditorFragmentDoc}`;

export function useGetGlobalFootprintTagsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetGlobalFootprintTagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetGlobalFootprintTagsQuery,
    GQTypes.GQGetGlobalFootprintTagsQueryVariables
  >({ query: GetGlobalFootprintTagsDocument, ...options });
}
export const GenerateTokenDocument = gql`
    mutation GenerateToken($input: GetNewDecryptedTokenInput!) {
  getNewDecryptedToken(input: $input) {
    decryptedToken
  }
}
    `;

export function useGenerateTokenMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateTokenMutation,
    GQTypes.GQGenerateTokenMutationVariables
  >(GenerateTokenDocument);
}
export const UpdateFinancialsReviewItemDocument = gql`
    mutation UpdateFinancialsReviewItem($input: UpdateFinancialsReviewItemInput!) {
  updateFinancialsReviewItem(input: $input) {
    financialsReviewItem {
      ...FinancialsReviewItemAdminDialog
    }
  }
}
    ${FinancialsReviewItemAdminDialogFragmentDoc}`;

export function useUpdateFinancialsReviewItemMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFinancialsReviewItemMutation,
    GQTypes.GQUpdateFinancialsReviewItemMutationVariables
  >(UpdateFinancialsReviewItemDocument);
}
export const CreateFinancialsReviewItemDraftsDocument = gql`
    mutation CreateFinancialsReviewItemDrafts($input: CreateFinancialsReviewItemDraftsInput!) {
  createFinancialsReviewItemDrafts(input: $input) {
    financialsReviewItems {
      edges {
        node {
          ...FinancialsReviewItemAdminDialog
        }
      }
    }
  }
}
    ${FinancialsReviewItemAdminDialogFragmentDoc}`;

export function useCreateFinancialsReviewItemDraftsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFinancialsReviewItemDraftsMutation,
    GQTypes.GQCreateFinancialsReviewItemDraftsMutationVariables
  >(CreateFinancialsReviewItemDraftsDocument);
}
export const ReviewItemsDialogDocument = gql`
    query ReviewItemsDialog($orgId: ID!) {
  financialsReviewItemsAdmin(orgId: $orgId) {
    edges {
      node {
        ...FinancialsReviewItemAdminDialog
      }
    }
  }
  organization(id: $orgId) {
    id
    measurementProjects {
      id
      name
      completedAt
      status
      coverageEndDate
      deadline
    }
  }
}
    ${FinancialsReviewItemAdminDialogFragmentDoc}`;

export function useReviewItemsDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReviewItemsDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReviewItemsDialogQuery,
    GQTypes.GQReviewItemsDialogQueryVariables
  >({ query: ReviewItemsDialogDocument, ...options });
}
export const PublishFinancialsReviewItemsDocument = gql`
    mutation PublishFinancialsReviewItems($input: PublishFinancialsReviewItemsInput!) {
  publishFinancialsReviewItems(input: $input) {
    financialsReviewItems {
      edges {
        node {
          ...FinancialsReviewItemAdminDialog
        }
      }
    }
  }
}
    ${FinancialsReviewItemAdminDialogFragmentDoc}`;

export function usePublishFinancialsReviewItemsMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishFinancialsReviewItemsMutation,
    GQTypes.GQPublishFinancialsReviewItemsMutationVariables
  >(PublishFinancialsReviewItemsDocument);
}
export const PreviewFinancialsReviewEmailsDocument = gql`
    mutation PreviewFinancialsReviewEmails($input: PreviewFinancialsReviewEmailsInput!) {
  previewFinancialsReviewEmails(input: $input) {
    emails {
      ...EmailContentWithUserRecipientFields
    }
  }
}
    ${EmailContentWithUserRecipientFieldsFragmentDoc}`;

export function usePreviewFinancialsReviewEmailsMutation() {
  return Urql.useMutation<
    GQTypes.GQPreviewFinancialsReviewEmailsMutation,
    GQTypes.GQPreviewFinancialsReviewEmailsMutationVariables
  >(PreviewFinancialsReviewEmailsDocument);
}
export const DeleteFinancialsReviewItemDocument = gql`
    mutation DeleteFinancialsReviewItem($input: DeleteFinancialsReviewItemInput!) {
  deleteFinancialsReviewItem(input: $input) {
    id
  }
}
    `;

export function useDeleteFinancialsReviewItemMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFinancialsReviewItemMutation,
    GQTypes.GQDeleteFinancialsReviewItemMutationVariables
  >(DeleteFinancialsReviewItemDocument);
}
export const LogLocalMeasurementStateDocument = gql`
    mutation LogLocalMeasurementState($orgId: String!, $tag: String!, $data: String!) {
  logLocalMeasurementState(orgId: $orgId, tag: $tag, data: $data)
}
    `;

export function useLogLocalMeasurementStateMutation() {
  return Urql.useMutation<
    GQTypes.GQLogLocalMeasurementStateMutation,
    GQTypes.GQLogLocalMeasurementStateMutationVariables
  >(LogLocalMeasurementStateDocument);
}
export const GetPrepublicationMeasurementTestResultsDocument = gql`
    query GetPrepublicationMeasurementTestResults($workflowId: ID!) {
  prepublicationMeasurementTestResults(workflowId: $workflowId) {
    measurementTestSuiteExecutions {
      ...PrepublicationTestSuiteExecutionFields
    }
    emissionsModelValidationErrors {
      msg
      path
      severity
    }
  }
}
    ${PrepublicationTestSuiteExecutionFieldsFragmentDoc}`;

export function useGetPrepublicationMeasurementTestResultsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPrepublicationMeasurementTestResultsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPrepublicationMeasurementTestResultsQuery,
    GQTypes.GQGetPrepublicationMeasurementTestResultsQueryVariables
  >({ query: GetPrepublicationMeasurementTestResultsDocument, ...options });
}
export const MethodologyTestSuiteValidationResultsDocument = gql`
    query MethodologyTestSuiteValidationResults($measurementTestSuiteId: ID!) {
  methodologyTestSuiteValidationResults(
    measurementTestSuiteId: $measurementTestSuiteId
  ) {
    id
    isValid
    warningMessage
  }
}
    `;

export function useMethodologyTestSuiteValidationResultsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyTestSuiteValidationResultsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyTestSuiteValidationResultsQuery,
    GQTypes.GQMethodologyTestSuiteValidationResultsQueryVariables
  >({ query: MethodologyTestSuiteValidationResultsDocument, ...options });
}
export const CreateMeasurementTestSuiteDocument = gql`
    mutation CreateMeasurementTestSuite($input: CreateMeasurementTestSuiteInput!) {
  createMeasurementTestSuite(input: $input) {
    measurementTestSuite {
      ...MeasurementTestSuiteListFields
    }
  }
}
    ${MeasurementTestSuiteListFieldsFragmentDoc}`;

export function useCreateMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMeasurementTestSuiteMutation,
    GQTypes.GQCreateMeasurementTestSuiteMutationVariables
  >(CreateMeasurementTestSuiteDocument);
}
export const UpdateMeasurementTestSuiteDocument = gql`
    mutation UpdateMeasurementTestSuite($input: UpdateMeasurementTestSuiteInput!) {
  updateMeasurementTestSuite(input: $input) {
    measurementTestSuite {
      ...MeasurementTestSuiteMetadataFields
    }
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}`;

export function useUpdateMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMeasurementTestSuiteMutation,
    GQTypes.GQUpdateMeasurementTestSuiteMutationVariables
  >(UpdateMeasurementTestSuiteDocument);
}
export const DeleteMeasurementTestSuiteDocument = gql`
    mutation DeleteMeasurementTestSuite($input: IdInput!) {
  deleteMeasurementTestSuite(input: $input) {
    id
  }
}
    `;

export function useDeleteMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMeasurementTestSuiteMutation,
    GQTypes.GQDeleteMeasurementTestSuiteMutationVariables
  >(DeleteMeasurementTestSuiteDocument);
}
export const UndeleteMeasurementTestSuiteDocument = gql`
    mutation UndeleteMeasurementTestSuite($input: IdInput!) {
  undeleteMeasurementTestSuite(input: $input) {
    id
  }
}
    `;

export function useUndeleteMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQUndeleteMeasurementTestSuiteMutation,
    GQTypes.GQUndeleteMeasurementTestSuiteMutationVariables
  >(UndeleteMeasurementTestSuiteDocument);
}
export const CreateMeasurementTestSuiteBartDocument = gql`
    mutation CreateMeasurementTestSuiteBart($input: CreateMeasurementTestSuiteBartInput!) {
  createMeasurementTestSuiteBart(input: $input) {
    measurementTestSuite {
      id
      measurementTestSuiteBarts {
        id
      }
    }
  }
}
    `;

export function useCreateMeasurementTestSuiteBartMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMeasurementTestSuiteBartMutation,
    GQTypes.GQCreateMeasurementTestSuiteBartMutationVariables
  >(CreateMeasurementTestSuiteBartDocument);
}
export const UpdateMeasurementTestSuiteBartDocument = gql`
    mutation UpdateMeasurementTestSuiteBart($input: UpdateMeasurementTestSuiteBartInput!) {
  updateMeasurementTestSuiteBart(input: $input) {
    measurementTestSuiteBart {
      ...MeasurementTestSuiteBartMetadataFields
    }
  }
}
    ${MeasurementTestSuiteBartMetadataFieldsFragmentDoc}`;

export function useUpdateMeasurementTestSuiteBartMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMeasurementTestSuiteBartMutation,
    GQTypes.GQUpdateMeasurementTestSuiteBartMutationVariables
  >(UpdateMeasurementTestSuiteBartDocument);
}
export const ToggleMeasurementTestSuiteCriticalDocument = gql`
    mutation ToggleMeasurementTestSuiteCritical($measurementTestSuiteId: ID!) {
  toggleMeasurementTestSuiteCritical(
    measurementTestSuiteId: $measurementTestSuiteId
  ) {
    measurementTestSuite {
      id
      kind
    }
  }
}
    `;

export function useToggleMeasurementTestSuiteCriticalMutation() {
  return Urql.useMutation<
    GQTypes.GQToggleMeasurementTestSuiteCriticalMutation,
    GQTypes.GQToggleMeasurementTestSuiteCriticalMutationVariables
  >(ToggleMeasurementTestSuiteCriticalDocument);
}
export const DeleteMeasurementTestSuiteBartDocument = gql`
    mutation DeleteMeasurementTestSuiteBart($input: IdInput!) {
  deleteMeasurementTestSuiteBart(input: $input) {
    id
  }
}
    `;

export function useDeleteMeasurementTestSuiteBartMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMeasurementTestSuiteBartMutation,
    GQTypes.GQDeleteMeasurementTestSuiteBartMutationVariables
  >(DeleteMeasurementTestSuiteBartDocument);
}
export const AddTestRowsToTestSuiteBartDocument = gql`
    mutation AddTestRowsToTestSuiteBart($input: AddTestRowsToTestSuiteBartInput!) {
  addTestRowsToTestSuiteBart(input: $input) {
    measurementTestSuiteBart {
      ...MeasurementTestSuiteBartMetadataFields
      measurementTestRows {
        ...MeasurementTestRowFields
      }
    }
  }
}
    ${MeasurementTestSuiteBartMetadataFieldsFragmentDoc}
${MeasurementTestRowFieldsFragmentDoc}`;

export function useAddTestRowsToTestSuiteBartMutation() {
  return Urql.useMutation<
    GQTypes.GQAddTestRowsToTestSuiteBartMutation,
    GQTypes.GQAddTestRowsToTestSuiteBartMutationVariables
  >(AddTestRowsToTestSuiteBartDocument);
}
export const UpdateTestRowsInTestSuiteBartDocument = gql`
    mutation UpdateTestRowsInTestSuiteBart($input: UpdateTestRowsInTestSuiteBartInput!) {
  updateTestRowsInTestSuiteBart(input: $input) {
    measurementTestSuiteBart {
      ...MeasurementTestSuiteBartMetadataFields
      measurementTestRows {
        ...MeasurementTestRowFields
      }
    }
  }
}
    ${MeasurementTestSuiteBartMetadataFieldsFragmentDoc}
${MeasurementTestRowFieldsFragmentDoc}`;

export function useUpdateTestRowsInTestSuiteBartMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateTestRowsInTestSuiteBartMutation,
    GQTypes.GQUpdateTestRowsInTestSuiteBartMutationVariables
  >(UpdateTestRowsInTestSuiteBartDocument);
}
export const DeleteMeasurementTestRowsDocument = gql`
    mutation DeleteMeasurementTestRows($input: DeleteMeasurementTestRowsInput!) {
  deleteMeasurementTestRows(input: $input) {
    ids
  }
}
    `;

export function useDeleteMeasurementTestRowsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMeasurementTestRowsMutation,
    GQTypes.GQDeleteMeasurementTestRowsMutationVariables
  >(DeleteMeasurementTestRowsDocument);
}
export const AcceptMethodologyTestSuiteExecutionResultsDocument = gql`
    mutation AcceptMethodologyTestSuiteExecutionResults($input: AcceptMethodologyTestSuiteExecutionResultsInput!) {
  acceptMethodologyTestSuiteExecutionResults(input: $input) {
    measurementTestSuite {
      ...MeasurementTestSuiteMetadataFields
      latestMethodologyTestSuiteExecution {
        id
        status
        methodologyTestRowResults {
          id
          resultAccepted
        }
      }
    }
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}`;

export function useAcceptMethodologyTestSuiteExecutionResultsMutation() {
  return Urql.useMutation<
    GQTypes.GQAcceptMethodologyTestSuiteExecutionResultsMutation,
    GQTypes.GQAcceptMethodologyTestSuiteExecutionResultsMutationVariables
  >(AcceptMethodologyTestSuiteExecutionResultsDocument);
}
export const SignalExecuteMeasurementTestSuiteDocument = gql`
    mutation SignalExecuteMeasurementTestSuite($input: SignalExecuteMeasurementTestSuiteInput!) {
  signalExecuteMeasurementTestSuite(input: $input) {
    id
  }
}
    `;

export function useSignalExecuteMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQSignalExecuteMeasurementTestSuiteMutation,
    GQTypes.GQSignalExecuteMeasurementTestSuiteMutationVariables
  >(SignalExecuteMeasurementTestSuiteDocument);
}
export const InvalidateMeasurementTestSuitePagePostRunDocument = gql`
    mutation InvalidateMeasurementTestSuitePagePostRun($input: IdInput!) {
  invalidateMeasurementTestSuitePage(input: $input) {
    measurementTestSuite {
      ...MeasurementTestSuiteMetadataFields
      latestMethodologyTestSuiteExecution {
        ...MethodologyTestSuiteExecutionWithResultsFields
      }
      latestFootprintTestSuiteExecution {
        ...FootprintTestExecutionMetadataFields
      }
    }
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}
${MethodologyTestSuiteExecutionWithResultsFieldsFragmentDoc}
${FootprintTestExecutionMetadataFieldsFragmentDoc}`;

export function useInvalidateMeasurementTestSuitePagePostRunMutation() {
  return Urql.useMutation<
    GQTypes.GQInvalidateMeasurementTestSuitePagePostRunMutation,
    GQTypes.GQInvalidateMeasurementTestSuitePagePostRunMutationVariables
  >(InvalidateMeasurementTestSuitePagePostRunDocument);
}
export const UpsertFootprintTestSuiteConfigDocument = gql`
    mutation UpsertFootprintTestSuiteConfig($input: UpsertFootprintTestSuiteConfigInput!) {
  upsertFootprintTestSuiteConfig(input: $input) {
    measurementTestSuite {
      ...MeasurementTestSuiteMetadataFields
      footprintTestSuiteConfig {
        ...FootprintTestSuiteConfigFields
      }
    }
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}
${FootprintTestSuiteConfigFieldsFragmentDoc}`;

export function useUpsertFootprintTestSuiteConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertFootprintTestSuiteConfigMutation,
    GQTypes.GQUpsertFootprintTestSuiteConfigMutationVariables
  >(UpsertFootprintTestSuiteConfigDocument);
}
export const DeleteFootprintTestSuiteConfigDocument = gql`
    mutation DeleteFootprintTestSuiteConfig($input: DeleteFootprintTestSuiteConfigInput!) {
  deleteFootprintTestSuiteConfig(input: $input) {
    measurementTestSuite {
      ...MeasurementTestSuiteMetadataFields
      footprintTestSuiteConfig {
        ...FootprintTestSuiteConfigFields
      }
    }
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}
${FootprintTestSuiteConfigFieldsFragmentDoc}`;

export function useDeleteFootprintTestSuiteConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFootprintTestSuiteConfigMutation,
    GQTypes.GQDeleteFootprintTestSuiteConfigMutationVariables
  >(DeleteFootprintTestSuiteConfigDocument);
}
export const AcceptFootprintTestSuiteExecutionResultsDocument = gql`
    mutation AcceptFootprintTestSuiteExecutionResults($input: AcceptFootprintTestSuiteExecutionResultsInput!) {
  acceptFootprintTestSuiteExecutionResults(input: $input) {
    measurementTestSuite {
      ...MeasurementTestSuiteMetadataFields
      latestFootprintTestSuiteExecution {
        ...FootprintTestExecutionMetadataFields
      }
    }
    footprintTestExecutionStepResult {
      ...FootprintTestExecutionStepResultFields
    }
  }
}
    ${MeasurementTestSuiteMetadataFieldsFragmentDoc}
${FootprintTestExecutionMetadataFieldsFragmentDoc}
${FootprintTestExecutionStepResultFieldsFragmentDoc}`;

export function useAcceptFootprintTestSuiteExecutionResultsMutation() {
  return Urql.useMutation<
    GQTypes.GQAcceptFootprintTestSuiteExecutionResultsMutation,
    GQTypes.GQAcceptFootprintTestSuiteExecutionResultsMutationVariables
  >(AcceptFootprintTestSuiteExecutionResultsDocument);
}
export const ImportMeasurementTestSuiteDocument = gql`
    mutation ImportMeasurementTestSuite($input: ImportMeasurementTestSuiteInput!) {
  importMeasurementTestSuite(input: $input) {
    id
  }
}
    `;

export function useImportMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQImportMeasurementTestSuiteMutation,
    GQTypes.GQImportMeasurementTestSuiteMutationVariables
  >(ImportMeasurementTestSuiteDocument);
}
export const ExportMeasurementTestSuiteDocument = gql`
    mutation ExportMeasurementTestSuite($input: IdInput!) {
  exportMeasurementTestSuite(input: $input) {
    downloadUrl
  }
}
    `;

export function useExportMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQExportMeasurementTestSuiteMutation,
    GQTypes.GQExportMeasurementTestSuiteMutationVariables
  >(ExportMeasurementTestSuiteDocument);
}
export const ExportMeasurementTestSuiteBundleDocument = gql`
    mutation ExportMeasurementTestSuiteBundle($input: IdInput!) {
  exportMeasurementTestSuiteBundle(input: $input) {
    downloadUrl
  }
}
    `;

export function useExportMeasurementTestSuiteBundleMutation() {
  return Urql.useMutation<
    GQTypes.GQExportMeasurementTestSuiteBundleMutation,
    GQTypes.GQExportMeasurementTestSuiteBundleMutationVariables
  >(ExportMeasurementTestSuiteBundleDocument);
}
export const DuplicateMeasurementTestSuiteDocument = gql`
    mutation DuplicateMeasurementTestSuite($input: DuplicateMeasurementTestSuiteInput!) {
  duplicateMeasurementTestSuite(input: $input) {
    id
  }
}
    `;

export function useDuplicateMeasurementTestSuiteMutation() {
  return Urql.useMutation<
    GQTypes.GQDuplicateMeasurementTestSuiteMutation,
    GQTypes.GQDuplicateMeasurementTestSuiteMutationVariables
  >(DuplicateMeasurementTestSuiteDocument);
}
export const SwapEmissionsModelExpectationsForMeasurementTestRowsDocument = gql`
    mutation SwapEmissionsModelExpectationsForMeasurementTestRows($input: SwapEmissionsModelExpectationsForMeasurementTestRowsInput!) {
  swapEmissionsModelExpectationsForTestRows(input: $input) {
    ids
  }
}
    `;

export function useSwapEmissionsModelExpectationsForMeasurementTestRowsMutation() {
  return Urql.useMutation<
    GQTypes.GQSwapEmissionsModelExpectationsForMeasurementTestRowsMutation,
    GQTypes.GQSwapEmissionsModelExpectationsForMeasurementTestRowsMutationVariables
  >(SwapEmissionsModelExpectationsForMeasurementTestRowsDocument);
}
export const SwapEmissionsModelExpectationsForFootprintTestRowsDocument = gql`
    mutation SwapEmissionsModelExpectationsForFootprintTestRows($input: SwapEmissionsModelExpectationsForFootprintTestRowsInput!) {
  swapEmissionsModelExpectationsForFootprintTestRows(input: $input) {
    ids
  }
}
    `;

export function useSwapEmissionsModelExpectationsForFootprintTestRowsMutation() {
  return Urql.useMutation<
    GQTypes.GQSwapEmissionsModelExpectationsForFootprintTestRowsMutation,
    GQTypes.GQSwapEmissionsModelExpectationsForFootprintTestRowsMutationVariables
  >(SwapEmissionsModelExpectationsForFootprintTestRowsDocument);
}
export const DefaultCalculationTagsDocument = gql`
    query DefaultCalculationTags {
  defaultCalculationTags {
    id
    name
  }
}
    `;

export function useDefaultCalculationTagsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDefaultCalculationTagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDefaultCalculationTagsQuery,
    GQTypes.GQDefaultCalculationTagsQueryVariables
  >({ query: DefaultCalculationTagsDocument, ...options });
}
export const AvailableActivityTypeNamesDocument = gql`
    query AvailableActivityTypeNames {
  activityTypes: businessActivityTypesPrimaryForVersionWithFields(
    versionName: "current"
  ) {
    id
    activityTypeName: name
  }
}
    `;

export function useAvailableActivityTypeNamesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAvailableActivityTypeNamesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAvailableActivityTypeNamesQuery,
    GQTypes.GQAvailableActivityTypeNamesQueryVariables
  >({ query: AvailableActivityTypeNamesDocument, ...options });
}
export const MeasurementTestSuiteListDocument = gql`
    query MeasurementTestSuiteList($includeDeleted: Boolean!) {
  measurementTestSuites(includeDeleted: $includeDeleted, criticalOnly: false) {
    ...MeasurementTestSuiteListFields
  }
}
    ${MeasurementTestSuiteListFieldsFragmentDoc}`;

export function useMeasurementTestSuiteListQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementTestSuiteListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementTestSuiteListQuery,
    GQTypes.GQMeasurementTestSuiteListQueryVariables
  >({ query: MeasurementTestSuiteListDocument, ...options });
}
export const MeasurementTestSuiteDetailDocument = gql`
    query MeasurementTestSuiteDetail($id: ID!) {
  measurementTestSuite(id: $id) {
    ...MeasurementTestSuiteDetailsWithoutExecutions
    latestMethodologyTestSuiteExecution {
      ...MethodologyTestSuiteExecutionMetadataFields
    }
    latestFootprintTestSuiteExecution {
      ...FootprintTestExecutionMetadataFields
    }
  }
}
    ${MeasurementTestSuiteDetailsWithoutExecutionsFragmentDoc}
${MethodologyTestSuiteExecutionMetadataFieldsFragmentDoc}
${FootprintTestExecutionMetadataFieldsFragmentDoc}`;

export function useMeasurementTestSuiteDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementTestSuiteDetailQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementTestSuiteDetailQuery,
    GQTypes.GQMeasurementTestSuiteDetailQueryVariables
  >({ query: MeasurementTestSuiteDetailDocument, ...options });
}
export const MethodologyTestSuiteExecutionDetailDocument = gql`
    query MethodologyTestSuiteExecutionDetail($id: ID!) {
  methodologyTestSuiteExecution(id: $id) {
    ...MethodologyTestSuiteExecutionWithResultsFields
  }
}
    ${MethodologyTestSuiteExecutionWithResultsFieldsFragmentDoc}`;

export function useMethodologyTestSuiteExecutionDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMethodologyTestSuiteExecutionDetailQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMethodologyTestSuiteExecutionDetailQuery,
    GQTypes.GQMethodologyTestSuiteExecutionDetailQueryVariables
  >({ query: MethodologyTestSuiteExecutionDetailDocument, ...options });
}
export const MeasurementTestSuiteChangelogDocument = gql`
    query MeasurementTestSuiteChangelog($id: ID!, $before: String, $after: String, $first: Int, $last: Int) {
  measurementTestSuiteChangelog(
    id: $id
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...MeasurementTestSuiteChangelogFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${MeasurementTestSuiteChangelogFieldsFragmentDoc}`;

export function useMeasurementTestSuiteChangelogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementTestSuiteChangelogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementTestSuiteChangelogQuery,
    GQTypes.GQMeasurementTestSuiteChangelogQueryVariables
  >({ query: MeasurementTestSuiteChangelogDocument, ...options });
}
export const MeasurementTestSuiteStatusDocument = gql`
    query MeasurementTestSuiteStatus($id: ID!) {
  measurementTestSuite(id: $id) {
    id
    status
    latestChangelogEntry {
      ...MeasurementTestSuiteChangelogFields
    }
    latestMethodologyTestSuiteExecution {
      id
      status
    }
    latestFootprintTestSuiteExecution {
      id
      status
    }
  }
}
    ${MeasurementTestSuiteChangelogFieldsFragmentDoc}`;

export function useMeasurementTestSuiteStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMeasurementTestSuiteStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMeasurementTestSuiteStatusQuery,
    GQTypes.GQMeasurementTestSuiteStatusQueryVariables
  >({ query: MeasurementTestSuiteStatusDocument, ...options });
}
export const FootprintTestSuiteConfigFieldOptionsDocument = gql`
    query FootprintTestSuiteConfigFieldOptions($orgId: ID) {
  footprintTestSuiteFootprintTags(orgId: $orgId) {
    id
    tagName
    rulesList
  }
  footprintTestSuiteMarketplaceAllocInstrs(orgId: $orgId) {
    id
    amountKwh
    isZeroedOut
    locationCountry
  }
  footprintTestSuiteExclusionRules(orgId: $orgId) {
    id
    ruleOrder
    filters {
      dimension
      operator
      value
    }
    outputValue
  }
  footprintTestSuiteCategoryRules(orgId: $orgId) {
    id
    filters {
      dimension
      operator
      value
    }
    outputColumn
    outputValue
    ruleOrder
  }
}
    `;

export function useFootprintTestSuiteConfigFieldOptionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintTestSuiteConfigFieldOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintTestSuiteConfigFieldOptionsQuery,
    GQTypes.GQFootprintTestSuiteConfigFieldOptionsQueryVariables
  >({ query: FootprintTestSuiteConfigFieldOptionsDocument, ...options });
}
export const FootprintTestSuiteExecutionDocument = gql`
    query FootprintTestSuiteExecution($footprintTestSuiteExecutionId: ID!, $footprintAssemblyStep: FootprintAssemblyStep!) {
  footprintTestSuiteExecutionStepResult(
    footprintTestSuiteExecutionId: $footprintTestSuiteExecutionId
    footprintAssemblyStep: $footprintAssemblyStep
  ) {
    ...FootprintTestExecutionStepResultFields
  }
}
    ${FootprintTestExecutionStepResultFieldsFragmentDoc}`;

export function useFootprintTestSuiteExecutionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFootprintTestSuiteExecutionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFootprintTestSuiteExecutionQuery,
    GQTypes.GQFootprintTestSuiteExecutionQueryVariables
  >({ query: FootprintTestSuiteExecutionDocument, ...options });
}
export const GetRefDataMetadataForMethodologyTreeDocument = gql`
    query getRefDataMetadataForMethodologyTree($versionId: ID!) {
  referenceDataVersion(referenceDataVersionId: $versionId) {
    id
    versionName
    latestPublishedRevisionByVersionId {
      id
      kind
      signedParquetOutputUrl
      outputFileId
    }
    latestReferenceDataSqlRun {
      id
      sql
      status
    }
    latestSchema {
      id
      schemaJson
    }
    source {
      id
      name
    }
  }
}
    `;

export function useGetRefDataMetadataForMethodologyTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetRefDataMetadataForMethodologyTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetRefDataMetadataForMethodologyTreeQuery,
    GQTypes.GQGetRefDataMetadataForMethodologyTreeQueryVariables
  >({ query: GetRefDataMetadataForMethodologyTreeDocument, ...options });
}
export const GetBusinessActivityTypeMetadataForMethodologyTreeDocument = gql`
    query getBusinessActivityTypeMetadataForMethodologyTree($name: String!) {
  latestBusinessActivityTypeInstanceForName(name: $name) {
    id
    versionName
  }
}
    `;

export function useGetBusinessActivityTypeMetadataForMethodologyTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetBusinessActivityTypeMetadataForMethodologyTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetBusinessActivityTypeMetadataForMethodologyTreeQuery,
    GQTypes.GQGetBusinessActivityTypeMetadataForMethodologyTreeQueryVariables
  >({
    query: GetBusinessActivityTypeMetadataForMethodologyTreeDocument,
    ...options,
  });
}
export const GetModelMetadataForMethodologyTreeDocument = gql`
    query getModelMetadataForMethodologyTree($versionId: ID!) {
  emissionsModelVersion(id: $versionId) {
    id
    stableId
  }
}
    `;

export function useGetModelMetadataForMethodologyTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetModelMetadataForMethodologyTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetModelMetadataForMethodologyTreeQuery,
    GQTypes.GQGetModelMetadataForMethodologyTreeQueryVariables
  >({ query: GetModelMetadataForMethodologyTreeDocument, ...options });
}
export const GetReleaseMetadataForMethodologyTreeDocument = gql`
    query getReleaseMetadataForMethodologyTree($releaseId: ID!) {
  emissionsModelRelease(id: $releaseId) {
    id
    displayName
    orgId
    internalNotes
    externalNotes
    businessActivityTypeName
    isOrgGated
    emissionsModels {
      id
      title
    }
    lifecyclePhase
  }
}
    `;

export function useGetReleaseMetadataForMethodologyTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReleaseMetadataForMethodologyTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReleaseMetadataForMethodologyTreeQuery,
    GQTypes.GQGetReleaseMetadataForMethodologyTreeQueryVariables
  >({ query: GetReleaseMetadataForMethodologyTreeDocument, ...options });
}
export const GetCtsMetadataForMethodologyTreeDocument = gql`
    query getCtsMetadataForMethodologyTree($versionId: ID!) {
  rawCustomerTargetSchemaVersion(id: $versionId) {
    id
    schemaJson
    exampleData
    transforms {
      id
      sql
      businessActivityType {
        id
        name
        versionName
      }
    }
    schema {
      id
      name
      descriptionMd
      utilityType
      rank
      org {
        id
        name
      }
    }
  }
}
    `;

export function useGetCtsMetadataForMethodologyTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCtsMetadataForMethodologyTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCtsMetadataForMethodologyTreeQuery,
    GQTypes.GQGetCtsMetadataForMethodologyTreeQueryVariables
  >({ query: GetCtsMetadataForMethodologyTreeDocument, ...options });
}
export const GetDatasetMetadataForMethodologyTreeDocument = gql`
    query getDatasetMetadataForMethodologyTree($datasetId: ID!) {
  canonicalDataset(id: $datasetId) {
    id
    name
    description
    kind
  }
}
    `;

export function useGetDatasetMetadataForMethodologyTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDatasetMetadataForMethodologyTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDatasetMetadataForMethodologyTreeQuery,
    GQTypes.GQGetDatasetMetadataForMethodologyTreeQueryVariables
  >({ query: GetDatasetMetadataForMethodologyTreeDocument, ...options });
}
export const GetGlobalEmissionsModelTreeFromReleasesDocument = gql`
    query GetGlobalEmissionsModelTreeFromReleases {
  emissionsModelReleasesForMethodologyExplorer {
    id
    emissionsModels {
      ...StableFieldsForGlobalEmissionsModelTree
    }
  }
}
    ${StableFieldsForGlobalEmissionsModelTreeFragmentDoc}`;

export function useGetGlobalEmissionsModelTreeFromReleasesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetGlobalEmissionsModelTreeFromReleasesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetGlobalEmissionsModelTreeFromReleasesQuery,
    GQTypes.GQGetGlobalEmissionsModelTreeFromReleasesQueryVariables
  >({ query: GetGlobalEmissionsModelTreeFromReleasesDocument, ...options });
}
export const GetEmissionsModelTreeDocument = gql`
    query getEmissionsModelTree($ids: [ID!]!) {
  stableModels: emissionsModelsStableByIds(ids: $ids) {
    ...StableFieldsForGlobalEmissionsModelTree
  }
}
    ${StableFieldsForGlobalEmissionsModelTreeFragmentDoc}`;

export function useGetEmissionsModelTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelTreeQuery,
    GQTypes.GQGetEmissionsModelTreeQueryVariables
  >({ query: GetEmissionsModelTreeDocument, ...options });
}
export const GetReferenceDataAncestryTreeDocument = gql`
    query getReferenceDataAncestryTree($ids: [ID!]!) {
  ancestorRelationships: referenceDataAncestryForVersionIds(versionIds: $ids) {
    versionId
    versionSlug
    upstreamVersionId
    upstreamVersionSlug
  }
}
    `;

export function useGetReferenceDataAncestryTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReferenceDataAncestryTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReferenceDataAncestryTreeQuery,
    GQTypes.GQGetReferenceDataAncestryTreeQueryVariables
  >({ query: GetReferenceDataAncestryTreeDocument, ...options });
}
export const GetCtsMethodologyTreeDocument = gql`
    query getCTSMethodologyTree {
  customerTargetSchemas {
    id
    name
    latestPublishedVersion {
      id
      transforms {
        id
        businessActivityType {
          id
          name
          versionName
        }
      }
    }
    canonicalDatasets {
      id
      name
    }
  }
}
    `;

export function useGetCtsMethodologyTreeQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCtsMethodologyTreeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCtsMethodologyTreeQuery,
    GQTypes.GQGetCtsMethodologyTreeQueryVariables
  >({ query: GetCtsMethodologyTreeDocument, ...options });
}
export const SchemaRegistrySchemaSelectorDataDocument = gql`
    query SchemaRegistrySchemaSelectorData {
  schemaRegistrySchemas {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useSchemaRegistrySchemaSelectorDataQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSchemaRegistrySchemaSelectorDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSchemaRegistrySchemaSelectorDataQuery,
    GQTypes.GQSchemaRegistrySchemaSelectorDataQueryVariables
  >({ query: SchemaRegistrySchemaSelectorDataDocument, ...options });
}
export const SchemaRegistrySchemaVersionSelectorDataDocument = gql`
    query SchemaRegistrySchemaVersionSelectorData($schemaId: ID!) {
  schemaRegistrySchemaVersions(schemaId: $schemaId) {
    edges {
      node {
        id
        version
      }
    }
  }
}
    `;

export function useSchemaRegistrySchemaVersionSelectorDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSchemaRegistrySchemaVersionSelectorDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSchemaRegistrySchemaVersionSelectorDataQuery,
    GQTypes.GQSchemaRegistrySchemaVersionSelectorDataQueryVariables
  >({ query: SchemaRegistrySchemaVersionSelectorDataDocument, ...options });
}
export const DeleteDataRegistryTablesDocument = gql`
    mutation DeleteDataRegistryTables($input: DeleteDataRegistryTablesInput!) {
  deleteDataRegistryTables(input: $input) {
    deletedCount
  }
}
    `;

export function useDeleteDataRegistryTablesMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteDataRegistryTablesMutation,
    GQTypes.GQDeleteDataRegistryTablesMutationVariables
  >(DeleteDataRegistryTablesDocument);
}
export const SchemaRegistrySchemasDocument = gql`
    query SchemaRegistrySchemas($after: String, $before: String, $first: Int, $last: Int, $search: String, $namespace: SchemaRegistrySchemaNamespace) {
  schemaRegistrySchemas(
    after: $after
    before: $before
    first: $first
    last: $last
    search: $search
    namespace: $namespace
  ) {
    edges {
      node {
        ...SchemaRegistrySchemaSchema
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${SchemaRegistrySchemaSchemaFragmentDoc}`;

export function useSchemaRegistrySchemasQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSchemaRegistrySchemasQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSchemaRegistrySchemasQuery,
    GQTypes.GQSchemaRegistrySchemasQueryVariables
  >({ query: SchemaRegistrySchemasDocument, ...options });
}
export const DataRegistryBatchMigratorRunsDocument = gql`
    query DataRegistryBatchMigratorRuns($schemaId: ID, $after: String, $before: String, $first: Int, $last: Int, $searchDescription: String) {
  dataRegistryBatchMigratorRuns(
    schemaId: $schemaId
    after: $after
    before: $before
    first: $first
    last: $last
    searchDescription: $searchDescription
  ) {
    edges {
      node {
        ...DataRegistryBatchMigratorRunSchema
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${DataRegistryBatchMigratorRunSchemaFragmentDoc}`;

export function useDataRegistryBatchMigratorRunsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataRegistryBatchMigratorRunsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataRegistryBatchMigratorRunsQuery,
    GQTypes.GQDataRegistryBatchMigratorRunsQueryVariables
  >({ query: DataRegistryBatchMigratorRunsDocument, ...options });
}
export const DataRegistryMigratorsDocument = gql`
    query DataRegistryMigrators($after: String, $before: String, $first: Int, $last: Int, $schemaId: ID) {
  dataRegistryMigrators(
    after: $after
    before: $before
    first: $first
    last: $last
    schemaId: $schemaId
  ) {
    edges {
      node {
        ...DataRegistryMigratorSchema
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${DataRegistryMigratorSchemaFragmentDoc}`;

export function useDataRegistryMigratorsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataRegistryMigratorsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataRegistryMigratorsQuery,
    GQTypes.GQDataRegistryMigratorsQueryVariables
  >({ query: DataRegistryMigratorsDocument, ...options });
}
export const GetDataRegistryMigratorDocument = gql`
    query GetDataRegistryMigrator($migratorId: ID!) {
  dataRegistryMigrator(migratorId: $migratorId) {
    id
    createdAt
    migratorType
    migrationCode
    description
    inputSchemaVersionId
    outputSchemaVersionId
  }
}
    `;

export function useGetDataRegistryMigratorQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDataRegistryMigratorQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDataRegistryMigratorQuery,
    GQTypes.GQGetDataRegistryMigratorQueryVariables
  >({ query: GetDataRegistryMigratorDocument, ...options });
}
export const RunDataRegistryMigrationDocument = gql`
    mutation RunDataRegistryMigration($input: RunDataRegistryMigrationInput!) {
  runDataRegistryMigration(input: $input)
}
    `;

export function useRunDataRegistryMigrationMutation() {
  return Urql.useMutation<
    GQTypes.GQRunDataRegistryMigrationMutation,
    GQTypes.GQRunDataRegistryMigrationMutationVariables
  >(RunDataRegistryMigrationDocument);
}
export const DataRegistryMigratorBestPathDocument = gql`
    query DataRegistryMigratorBestPath($fromSchemaVersionId: ID!, $toSchemaVersionId: ID!) {
  dataRegistryMigratorBestPath(
    fromSchemaVersionId: $fromSchemaVersionId
    toSchemaVersionId: $toSchemaVersionId
  ) {
    migrator {
      id
      migratorType
      migrationCode
      description
    }
    outputSchema {
      id
      version
    }
  }
}
    `;

export function useDataRegistryMigratorBestPathQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDataRegistryMigratorBestPathQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDataRegistryMigratorBestPathQuery,
    GQTypes.GQDataRegistryMigratorBestPathQueryVariables
  >({ query: DataRegistryMigratorBestPathDocument, ...options });
}
export const RegionForTSchemaMigrationsDocument = gql`
    query RegionForTSchemaMigrations {
  region
}
    `;

export function useRegionForTSchemaMigrationsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQRegionForTSchemaMigrationsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQRegionForTSchemaMigrationsQuery,
    GQTypes.GQRegionForTSchemaMigrationsQueryVariables
  >({ query: RegionForTSchemaMigrationsDocument, ...options });
}
export const ValidateTSchemaSettingsDocument = gql`
    mutation ValidateTSchemaSettings($input: ValidateSchemaRegistrySettingsInput!) {
  validateSchemaRegistrySettings(input: $input) {
    warnings
    errors
  }
}
    `;

export function useValidateTSchemaSettingsMutation() {
  return Urql.useMutation<
    GQTypes.GQValidateTSchemaSettingsMutation,
    GQTypes.GQValidateTSchemaSettingsMutationVariables
  >(ValidateTSchemaSettingsDocument);
}
export const SetTSchemaSettingsDocument = gql`
    mutation SetTSchemaSettings($input: SetSchemaRegistrySettingsInput!) {
  setSchemaRegistrySettings(input: $input) {
    schemaId
  }
}
    `;

export function useSetTSchemaSettingsMutation() {
  return Urql.useMutation<
    GQTypes.GQSetTSchemaSettingsMutation,
    GQTypes.GQSetTSchemaSettingsMutationVariables
  >(SetTSchemaSettingsDocument);
}
export const SchemaRegistrySchemaVersionsDocument = gql`
    query SchemaRegistrySchemaVersions($after: String, $before: String, $first: Int, $last: Int, $schemaId: ID, $search: String) {
  schemaRegistrySchemaVersions(
    after: $after
    before: $before
    first: $first
    last: $last
    schemaId: $schemaId
    search: $search
  ) {
    edges {
      node {
        ...SchemaRegistrySchemaVersionSchema
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${SchemaRegistrySchemaVersionSchemaFragmentDoc}`;

export function useSchemaRegistrySchemaVersionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQSchemaRegistrySchemaVersionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSchemaRegistrySchemaVersionsQuery,
    GQTypes.GQSchemaRegistrySchemaVersionsQueryVariables
  >({ query: SchemaRegistrySchemaVersionsDocument, ...options });
}
export const DeleteOneSchemaTemplateDocument = gql`
    mutation DeleteOneSchemaTemplate($input: DeleteOneSchemaTemplateInput!) {
  deleteOneSchemaTemplate(input: $input) {
    oneSchemaTemplateKey
  }
}
    `;

export function useDeleteOneSchemaTemplateMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteOneSchemaTemplateMutation,
    GQTypes.GQDeleteOneSchemaTemplateMutationVariables
  >(DeleteOneSchemaTemplateDocument);
}
export const GetEngagementTasksAdminDocument = gql`
    query GetEngagementTasksAdmin($orgId: ID!) {
  engagementTasksAdmin(orgId: $orgId) {
    edges {
      node {
        ...EngagementTaskFieldsAdmin
      }
    }
  }
}
    ${EngagementTaskFieldsAdminFragmentDoc}`;

export function useGetEngagementTasksAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTasksAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTasksAdminQuery,
    GQTypes.GQGetEngagementTasksAdminQueryVariables
  >({ query: GetEngagementTasksAdminDocument, ...options });
}
export const GetAssetsWithTasksDocument = gql`
    query GetAssetsWithTasks($orgId: ID!) {
  assetsWithTasks(orgId: $orgId) {
    id
    companyId
    name
  }
}
    `;

export function useGetAssetsWithTasksQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAssetsWithTasksQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAssetsWithTasksQuery,
    GQTypes.GQGetAssetsWithTasksQueryVariables
  >({ query: GetAssetsWithTasksDocument, ...options });
}
export const ShareDisclosureDialogDocument = gql`
    query ShareDisclosureDialog($orgId: ID!) {
  orgFootprintDisclosures(orgId: $orgId) {
    edges {
      node {
        ...FootprintDisclosureFields
      }
    }
  }
}
    ${FootprintDisclosureFieldsFragmentDoc}`;

export function useShareDisclosureDialogQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQShareDisclosureDialogQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQShareDisclosureDialogQuery,
    GQTypes.GQShareDisclosureDialogQueryVariables
  >({ query: ShareDisclosureDialogDocument, ...options });
}
export const CreateFootprintDisclosureDocument = gql`
    mutation CreateFootprintDisclosure($input: CreateFootprintDisclosureInput!) {
  createFootprintDisclosure(input: $input) {
    footprintDisclosure {
      id
    }
  }
}
    `;

export function useCreateFootprintDisclosureMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFootprintDisclosureMutation,
    GQTypes.GQCreateFootprintDisclosureMutationVariables
  >(CreateFootprintDisclosureDocument);
}
export const GetOrgsForOrgPickerDocument = gql`
    query GetOrgsForOrgPicker {
  organizations {
    edges {
      node {
        id
        name
        demoOrg
        testOrg
      }
    }
  }
}
    `;

export function useGetOrgsForOrgPickerQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetOrgsForOrgPickerQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetOrgsForOrgPickerQuery,
    GQTypes.GQGetOrgsForOrgPickerQueryVariables
  >({ query: GetOrgsForOrgPickerDocument, ...options });
}
export const FinanceAdminAssetDifferDocument = gql`
    query FinanceAdminAssetDiffer($orgId: ID!) {
  portcos(orgId: $orgId) {
    id
    name
  }
}
    `;

export function useFinanceAdminAssetDifferQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAdminAssetDifferQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAdminAssetDifferQuery,
    GQTypes.GQFinanceAdminAssetDifferQueryVariables
  >({ query: FinanceAdminAssetDifferDocument, ...options });
}
export const FinanceAdminAssetDocument = gql`
    query FinanceAdminAsset($assetId: ID!) {
  assetCorporateAdmin(id: $assetId) {
    id
    orgId
    createdAt
    updatedAt
    name
    naicsCode
    companyIsin
    companyLei
    companyUrl
    companyTicker
    companySAndPId
    companyDunsNumber
    companyEin
    companyVatId
    companyLocalRegistryId
    currencyCode
    countryAlpha2
    buildingSizeUnit
    companyId
    orgId
    otherData
    tagData
    externalId
    assetGroupId
    anonymousCompanyUuid
    company {
      id
      name
    }
    assetYears {
      id
      orgId
      createdAt
      updatedAt
      year
      assetCommercialRealEstateId
      assetCorporateId
      assetGroupId
      assetSovereignBondId
      assetPersonalMotorVehicleInsuranceId
      buildingSizeNative
      estimationMethodology
      headcount
      percentRenewable
      cloudSpendNative
      valueNative
      revenueNative
      flightMiles
      percentEmployeesWfh
      currencyCode
      currencyConversionRate
      totalMwh
      revenueSourceEmissionsYearId
      emissionsYearId
      scope1OverrideTco2e
      scope1OverridePcaf
      scope2LocationOverrideTco2e
      scope2LocationOverridePcaf
      scope2MarketOverrideTco2e
      scope2MarketOverridePcaf
      scope3OverrideKind
      scope3OverrideTco2e
      scope3OverridePcaf
      scope301OverrideTco2e
      scope302OverrideTco2e
      scope303OverrideTco2e
      scope304OverrideTco2e
      scope305OverrideTco2e
      scope306OverrideTco2e
      scope307OverrideTco2e
      scope308OverrideTco2e
      scope309OverrideTco2e
      scope310OverrideTco2e
      scope311OverrideTco2e
      scope312OverrideTco2e
      scope313OverrideTco2e
      scope314OverrideTco2e
      scope315OverrideTco2e
      buildingEstimationErr
      companyEstimationErr
      measuringScope1And2Emissions
      measuringMaterialScope3Emissions
      initiatedDecarbonizationPlan
      committedToNearTermSbtAlignedNetZero
      demonstratingYoyEmissionsInLineWithNetZero
      iciAlignmentOverride
      iciAlignmentStatus
      scenario
      tagData
    }
    assetHoldings {
      id
      orgId
      createdAt
      updatedAt
      assetYearId
      fundId
      yearOfInitialInvestment
      outstandingAmountNative
      outstandingAmountNativeQ1
      outstandingAmountNativeQ2
      outstandingAmountNativeQ3
      outstandingAmountNativeQ4
      currencyCode
      currencyConversionRate
      assetClass
      currencyCode
      currencyConversionRate
      tagData
      premiumNative
      emissionsYearId
    }
  }
}
    `;

export function useFinanceAdminAssetQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAdminAssetQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAdminAssetQuery,
    GQTypes.GQFinanceAdminAssetQueryVariables
  >({ query: FinanceAdminAssetDocument, ...options });
}
export const FinanceMergeAssetsDocument = gql`
    mutation FinanceMergeAssets($input: MergeAssetsInput!) {
  mergeAssets(input: $input) {
    id
  }
}
    `;

export function useFinanceMergeAssetsMutation() {
  return Urql.useMutation<
    GQTypes.GQFinanceMergeAssetsMutation,
    GQTypes.GQFinanceMergeAssetsMutationVariables
  >(FinanceMergeAssetsDocument);
}
export const OrganizationsFinanceDisclosuresPageDocument = gql`
    query OrganizationsFinanceDisclosuresPage($orgId: ID!) {
  organization(id: $orgId) {
    id
    name
    company {
      id
    }
  }
  orgFootprintDisclosures(orgId: $orgId) {
    edges {
      node {
        ...FootprintDisclosureFields
      }
    }
  }
  orgFootprintDisclosureShareLogs(orgId: $orgId) {
    edges {
      node {
        ...FootprintDisclosureShareLogFields
      }
    }
  }
}
    ${FootprintDisclosureFieldsFragmentDoc}
${FootprintDisclosureShareLogFieldsFragmentDoc}`;

export function useOrganizationsFinanceDisclosuresPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationsFinanceDisclosuresPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationsFinanceDisclosuresPageQuery,
    GQTypes.GQOrganizationsFinanceDisclosuresPageQueryVariables
  >({ query: OrganizationsFinanceDisclosuresPageDocument, ...options });
}
export const FinanceAdminGetRefreshableAssetsDocument = gql`
    query FinanceAdminGetRefreshableAssets($orgId: ID!, $year: Int!) {
  refreshableAssets(orgId: $orgId, year: $year) {
    id
    name
    naicsCode
    countryAlpha2
    assetYears {
      id
    }
  }
}
    `;

export function useFinanceAdminGetRefreshableAssetsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAdminGetRefreshableAssetsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAdminGetRefreshableAssetsQuery,
    GQTypes.GQFinanceAdminGetRefreshableAssetsQueryVariables
  >({ query: FinanceAdminGetRefreshableAssetsDocument, ...options });
}
export const RefreshAssetYearEstimationsDocument = gql`
    mutation RefreshAssetYearEstimations($input: RefreshAssetYearEstimationsInput!) {
  refreshAssetYearEstimations(input: $input) {
    invalidatedSnapshots {
      id
      isStale
    }
  }
}
    `;

export function useRefreshAssetYearEstimationsMutation() {
  return Urql.useMutation<
    GQTypes.GQRefreshAssetYearEstimationsMutation,
    GQTypes.GQRefreshAssetYearEstimationsMutationVariables
  >(RefreshAssetYearEstimationsDocument);
}
export const OrganizationsFundsPageDocument = gql`
    query OrganizationsFundsPage($orgId: ID!) {
  organization(id: $orgId) {
    id
    name
    company {
      id
    }
  }
  orgFunds(orgId: $orgId) {
    edges {
      node {
        id
        ...FundAllFields
      }
    }
  }
}
    ${FundAllFieldsFragmentDoc}`;

export function useOrganizationsFundsPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationsFundsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationsFundsPageQuery,
    GQTypes.GQOrganizationsFundsPageQueryVariables
  >({ query: OrganizationsFundsPageDocument, ...options });
}
export const CreateFundAdminDocument = gql`
    mutation CreateFundAdmin($input: CreateFundInputAdmin!) {
  createFundAdmin(input: $input) {
    fund {
      id
      ...FundAllFields
    }
  }
}
    ${FundAllFieldsFragmentDoc}`;

export function useCreateFundAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFundAdminMutation,
    GQTypes.GQCreateFundAdminMutationVariables
  >(CreateFundAdminDocument);
}
export const UpdateFundDocument = gql`
    mutation UpdateFund($input: UpdateFundInput!) {
  updateFund(input: $input) {
    fund {
      id
      ...FundAllFields
    }
  }
}
    ${FundAllFieldsFragmentDoc}`;

export function useUpdateFundMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateFundMutation,
    GQTypes.GQUpdateFundMutationVariables
  >(UpdateFundDocument);
}
export const DeleteFundsDocument = gql`
    mutation DeleteFunds($input: DeleteFundsInput!) {
  deleteFunds(input: $input) {
    ids
    invalidatedSnapshots {
      id
      isStale
    }
  }
}
    `;

export function useDeleteFundsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteFundsMutation,
    GQTypes.GQDeleteFundsMutationVariables
  >(DeleteFundsDocument);
}
export const FinanceMeasurementProgressTabAdminDocument = gql`
    query FinanceMeasurementProgressTabAdmin($orgId: ID!, $year: Int!) {
  financeMeasurementProgressRowsAdmin(orgId: $orgId, year: $year) {
    assetId
    assetName
    companyId
    projectName
    hasRequestedProgress
    hasRequestedFootprint
    coverageStartDate
    coverageEndDate
    contacts {
      id
      name
      email
      role
      userId
      companyId
    }
    taskStatusDumps {
      taskId
      taskName
      assigneeNames
      datasetName
      status
    }
    portcoFootprintReviewRequestMetadata {
      id
      footprintSnapshotId
      footprintVersionKind
      status
    }
    updatedAt
    measuredFootprintDetails {
      footprintSharedAt
      emissionsTco2e
    }
    fundIds
    hasApprovedProgressRequest
  }
}
    `;

export function useFinanceMeasurementProgressTabAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceMeasurementProgressTabAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceMeasurementProgressTabAdminQuery,
    GQTypes.GQFinanceMeasurementProgressTabAdminQueryVariables
  >({ query: FinanceMeasurementProgressTabAdminDocument, ...options });
}
export const AvailableProjectsForCompanyDocument = gql`
    query AvailableProjectsForCompany($companyId: ID!, $year: Int!) {
  availableProjectsForCompany(companyId: $companyId, year: $year) {
    id
    name
    coverageStartDate
    coverageEndDate
  }
}
    `;

export function useAvailableProjectsForCompanyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAvailableProjectsForCompanyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAvailableProjectsForCompanyQuery,
    GQTypes.GQAvailableProjectsForCompanyQueryVariables
  >({ query: AvailableProjectsForCompanyDocument, ...options });
}
export const ShareMeasurementProjectDocument = gql`
    mutation ShareMeasurementProject($input: ShareMeasurementProjectInput!) {
  shareMeasurementProject(input: $input) {
    measurementProjectStatusShare {
      id
    }
  }
}
    `;

export function useShareMeasurementProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQShareMeasurementProjectMutation,
    GQTypes.GQShareMeasurementProjectMutationVariables
  >(ShareMeasurementProjectDocument);
}
export const FinanceAdminPortcoLoginTabDocument = gql`
    query FinanceAdminPortcoLoginTab($orgId: ID!) {
  users(orgId: $orgId, includeWatershedEmployees: true) {
    ...UserFieldsForFinancePage
  }
  portcoOrgs(orgId: $orgId) {
    ...PortcoOrgFieldsForFinancePage
  }
}
    ${UserFieldsForFinancePageFragmentDoc}
${PortcoOrgFieldsForFinancePageFragmentDoc}`;

export function useFinanceAdminPortcoLoginTabQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFinanceAdminPortcoLoginTabQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFinanceAdminPortcoLoginTabQuery,
    GQTypes.GQFinanceAdminPortcoLoginTabQueryVariables
  >({ query: FinanceAdminPortcoLoginTabDocument, ...options });
}
export const GrantFinanceUsersPortcoAccessesDocument = gql`
    mutation GrantFinanceUsersPortcoAccesses($input: GrantFinanceUsersPortcoAccessesInput!) {
  grantFinanceUsersPortcoAccesses(input: $input) {
    backgroundJobId
  }
}
    `;

export function useGrantFinanceUsersPortcoAccessesMutation() {
  return Urql.useMutation<
    GQTypes.GQGrantFinanceUsersPortcoAccessesMutation,
    GQTypes.GQGrantFinanceUsersPortcoAccessesMutationVariables
  >(GrantFinanceUsersPortcoAccessesDocument);
}
export const CreateLifecycleAssessmentDocument = gql`
    mutation CreateLifecycleAssessment($input: CreateLifecycleAssessmentInput!) {
  createLifecycleAssessment(input: $input) {
    id
  }
}
    `;

export function useCreateLifecycleAssessmentMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateLifecycleAssessmentMutation,
    GQTypes.GQCreateLifecycleAssessmentMutationVariables
  >(CreateLifecycleAssessmentDocument);
}
export const UpdateLifecycleAssessmentDocument = gql`
    mutation UpdateLifecycleAssessment($input: UpdateLifecycleAssessmentInput!) {
  updateLifecycleAssessment(input: $input) {
    id
  }
}
    `;

export function useUpdateLifecycleAssessmentMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateLifecycleAssessmentMutation,
    GQTypes.GQUpdateLifecycleAssessmentMutationVariables
  >(UpdateLifecycleAssessmentDocument);
}
export const LifecycleAssessmentsDocument = gql`
    query LifecycleAssessments($orgId: ID!) {
  lifecycleAssessments(orgId: $orgId) {
    ...LifecycleAssessmentForAdminListView
  }
}
    ${LifecycleAssessmentForAdminListViewFragmentDoc}`;

export function useLifecycleAssessmentsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLifecycleAssessmentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLifecycleAssessmentsQuery,
    GQTypes.GQLifecycleAssessmentsQueryVariables
  >({ query: LifecycleAssessmentsDocument, ...options });
}
export const ProcessProductionGraphFileDocument = gql`
    mutation ProcessProductionGraphFile($input: ProcessProductionGraphFileInput!) {
  processProductionGraphFile(input: $input) {
    id
  }
}
    `;

export function useProcessProductionGraphFileMutation() {
  return Urql.useMutation<
    GQTypes.GQProcessProductionGraphFileMutation,
    GQTypes.GQProcessProductionGraphFileMutationVariables
  >(ProcessProductionGraphFileDocument);
}
export const MaterialVariantsDocument = gql`
    query MaterialVariants($orgId: ID!) {
  materialVariants(orgId: $orgId) {
    ...MaterialVariantForAdminListView
  }
}
    ${MaterialVariantForAdminListViewFragmentDoc}`;

export function useMaterialVariantsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMaterialVariantsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMaterialVariantsQuery,
    GQTypes.GQMaterialVariantsQueryVariables
  >({ query: MaterialVariantsDocument, ...options });
}
export const ProcessMaterialVariantAndLcaFileDocument = gql`
    mutation ProcessMaterialVariantAndLCAFile($input: ProcessMaterialVariantAndLCAFileInput!) {
  processMaterialVariantAndLCAFile(input: $input) {
    success
  }
}
    `;

export function useProcessMaterialVariantAndLcaFileMutation() {
  return Urql.useMutation<
    GQTypes.GQProcessMaterialVariantAndLcaFileMutation,
    GQTypes.GQProcessMaterialVariantAndLcaFileMutationVariables
  >(ProcessMaterialVariantAndLcaFileDocument);
}
export const DeleteMaterialVariantsForOrgDocument = gql`
    mutation DeleteMaterialVariantsForOrg($input: DeleteMaterialVariantsForOrgInput!) {
  deleteMaterialVariantsForOrg(input: $input) {
    success
  }
}
    `;

export function useDeleteMaterialVariantsForOrgMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMaterialVariantsForOrgMutation,
    GQTypes.GQDeleteMaterialVariantsForOrgMutationVariables
  >(DeleteMaterialVariantsForOrgDocument);
}
export const DeleteCompanyClimateCommitmentDocument = gql`
    mutation DeleteCompanyClimateCommitment($input: DeleteCompanyClimateCommitmentInput!) {
  deleteCompanyClimateCommitment(input: $input) {
    disclosure {
      id
    }
  }
}
    `;

export function useDeleteCompanyClimateCommitmentMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCompanyClimateCommitmentMutation,
    GQTypes.GQDeleteCompanyClimateCommitmentMutationVariables
  >(DeleteCompanyClimateCommitmentDocument);
}
export const CreatePublicDisclosureDocument = gql`
    mutation CreatePublicDisclosure($input: CreatePublicDisclosureInput!) {
  createPublicDisclosure(input: $input) {
    publicDisclosure {
      id
      ...PublicDisclosureTable
    }
  }
}
    ${PublicDisclosureTableFragmentDoc}`;

export function useCreatePublicDisclosureMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePublicDisclosureMutation,
    GQTypes.GQCreatePublicDisclosureMutationVariables
  >(CreatePublicDisclosureDocument);
}
export const EditEmissionsYearDocument = gql`
    mutation EditEmissionsYear($input: EditEmissionsYearInput!) {
  editEmissionsYear(input: $input) {
    emissionsYear {
      ...EmissionsYearAllFields
    }
  }
}
    ${EmissionsYearAllFieldsFragmentDoc}`;

export function useEditEmissionsYearMutation() {
  return Urql.useMutation<
    GQTypes.GQEditEmissionsYearMutation,
    GQTypes.GQEditEmissionsYearMutationVariables
  >(EditEmissionsYearDocument);
}
export const DeleteEmissionsYearDocument = gql`
    mutation DeleteEmissionsYear($input: DeleteEmissionsYearInput!) {
  deleteEmissionsYear(input: $input) {
    id
  }
}
    `;

export function useDeleteEmissionsYearMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteEmissionsYearMutation,
    GQTypes.GQDeleteEmissionsYearMutationVariables
  >(DeleteEmissionsYearDocument);
}
export const EditPublicDisclosureDocument = gql`
    mutation EditPublicDisclosure($input: EditPublicDisclosureInput!) {
  editPublicDisclosure(input: $input) {
    publicDisclosure {
      id
      ...PublicDisclosureTable
    }
  }
}
    ${PublicDisclosureTableFragmentDoc}`;

export function useEditPublicDisclosureMutation() {
  return Urql.useMutation<
    GQTypes.GQEditPublicDisclosureMutation,
    GQTypes.GQEditPublicDisclosureMutationVariables
  >(EditPublicDisclosureDocument);
}
export const DeletePublicDisclosureDocument = gql`
    mutation DeletePublicDisclosure($input: DeletePublicDisclosureInput!) {
  deletePublicDisclosure(input: $input) {
    id
  }
}
    `;

export function useDeletePublicDisclosureMutation() {
  return Urql.useMutation<
    GQTypes.GQDeletePublicDisclosureMutation,
    GQTypes.GQDeletePublicDisclosureMutationVariables
  >(DeletePublicDisclosureDocument);
}
export const CreatePlanWithDefaultsDocument = gql`
    mutation CreatePlanWithDefaults($input: CreatePlanInput!) @withOwner(owner: Reductions) {
  createPlan(input: $input) {
    plan {
      id
      name
    }
  }
}
    `;

export function useCreatePlanWithDefaultsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreatePlanWithDefaultsMutation,
    GQTypes.GQCreatePlanWithDefaultsMutationVariables
  >(CreatePlanWithDefaultsDocument);
}
export const UpdatePlanAdminDocument = gql`
    mutation UpdatePlanAdmin($input: UpdatePlanInput!) @withOwner(owner: Reductions) {
  adminUpdatePlan(input: $input) {
    plan {
      ...PlanForPlanPage
    }
  }
}
    ${PlanForPlanPageFragmentDoc}`;

export function useUpdatePlanAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdatePlanAdminMutation,
    GQTypes.GQUpdatePlanAdminMutationVariables
  >(UpdatePlanAdminDocument);
}
export const ReportQuestionIdentifiersDocument = gql`
    query ReportQuestionIdentifiers {
  reportQuestionIdentifiers {
    edges {
      node {
        id
        identifier
      }
    }
  }
}
    `;

export function useReportQuestionIdentifiersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportQuestionIdentifiersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportQuestionIdentifiersQuery,
    GQTypes.GQReportQuestionIdentifiersQueryVariables
  >({ query: ReportQuestionIdentifiersDocument, ...options });
}
export const InsertReportQuestionIdentifierDocument = gql`
    mutation InsertReportQuestionIdentifier($input: InsertReportQuestionIdentifierInput!) {
  insertReportQuestionIdentifier(input: $input) {
    id
    identifier
  }
}
    `;

export function useInsertReportQuestionIdentifierMutation() {
  return Urql.useMutation<
    GQTypes.GQInsertReportQuestionIdentifierMutation,
    GQTypes.GQInsertReportQuestionIdentifierMutationVariables
  >(InsertReportQuestionIdentifierDocument);
}
export const ReportQuestionForAnswerCopyDocument = gql`
    query ReportQuestionForAnswerCopy($id: ID!) {
  reportQuestion(id: $id) {
    ...ReportQuestionForCopy
    questionsWithMatchingOutputSchema {
      ...ReportQuestionForCopy
    }
  }
}
    ${ReportQuestionForCopyFragmentDoc}`;

export function useReportQuestionForAnswerCopyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportQuestionForAnswerCopyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportQuestionForAnswerCopyQuery,
    GQTypes.GQReportQuestionForAnswerCopyQueryVariables
  >({ query: ReportQuestionForAnswerCopyDocument, ...options });
}
export const CopyReportQuestionAnswersDocument = gql`
    mutation CopyReportQuestionAnswers($input: CopyReportQuestionAnswersInput!) {
  copyReportQuestionAnswers(input: $input) {
    numCopied
  }
}
    `;

export function useCopyReportQuestionAnswersMutation() {
  return Urql.useMutation<
    GQTypes.GQCopyReportQuestionAnswersMutation,
    GQTypes.GQCopyReportQuestionAnswersMutationVariables
  >(CopyReportQuestionAnswersDocument);
}
export const ReportAnswersDocument = gql`
    query ReportAnswers($filters: ReportAnswerFilters, $before: String, $after: String, $first: Int, $last: Int) {
  reportAnswers(
    filters: $filters
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ...ReportAnswerForAdminTable
      }
    }
  }
}
    ${ReportAnswerForAdminTableFragmentDoc}`;

export function useReportAnswersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportAnswersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportAnswersQuery,
    GQTypes.GQReportAnswersQueryVariables
  >({ query: ReportAnswersDocument, ...options });
}
export const UpdateReportConfigFromAdminTableDocument = gql`
    mutation UpdateReportConfigFromAdminTable($input: UpdateReportConfigInput!) {
  updateReportConfig(input: $input) {
    reportConfig {
      ...ReportConfigFieldsForAdminTable
    }
  }
}
    ${ReportConfigFieldsForAdminTableFragmentDoc}`;

export function useUpdateReportConfigFromAdminTableMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportConfigFromAdminTableMutation,
    GQTypes.GQUpdateReportConfigFromAdminTableMutationVariables
  >(UpdateReportConfigFromAdminTableDocument);
}
export const CreateReportConfigFromAdminTableDocument = gql`
    mutation CreateReportConfigFromAdminTable($input: CreateReportConfigInput!) {
  createReportConfig(input: $input) {
    reportConfig {
      ...ReportConfigFieldsForAdminTable
    }
  }
}
    ${ReportConfigFieldsForAdminTableFragmentDoc}`;

export function useCreateReportConfigFromAdminTableMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportConfigFromAdminTableMutation,
    GQTypes.GQCreateReportConfigFromAdminTableMutationVariables
  >(CreateReportConfigFromAdminTableDocument);
}
export const CreateReportQuestionContainerDocument = gql`
    mutation CreateReportQuestionContainer($input: CreateReportQuestionContainerInput!) {
  createReportQuestionContainer(input: $input) {
    container {
      ...ReportQuestionContainerFields
    }
  }
}
    ${ReportQuestionContainerFieldsFragmentDoc}`;

export function useCreateReportQuestionContainerMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportQuestionContainerMutation,
    GQTypes.GQCreateReportQuestionContainerMutationVariables
  >(CreateReportQuestionContainerDocument);
}
export const UpdateReportQuestionContainerDocument = gql`
    mutation UpdateReportQuestionContainer($input: UpdateReportQuestionContainerInput!) {
  updateReportQuestionContainer(input: $input) {
    container {
      ...ReportQuestionContainerFields
    }
  }
}
    ${ReportQuestionContainerFieldsFragmentDoc}`;

export function useUpdateReportQuestionContainerMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportQuestionContainerMutation,
    GQTypes.GQUpdateReportQuestionContainerMutationVariables
  >(UpdateReportQuestionContainerDocument);
}
export const DeleteReportQuestionContainerDocument = gql`
    mutation DeleteReportQuestionContainer($input: DeleteReportQuestionContainerInput!) {
  deleteReportQuestionContainer(input: $input) {
    parentId
    container {
      ...ReportQuestionContainerFields
    }
  }
}
    ${ReportQuestionContainerFieldsFragmentDoc}`;

export function useDeleteReportQuestionContainerMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportQuestionContainerMutation,
    GQTypes.GQDeleteReportQuestionContainerMutationVariables
  >(DeleteReportQuestionContainerDocument);
}
export const ReportsToBeDeletedDocument = gql`
    query ReportsToBeDeleted($configId: ID!) {
  reports(configId: $configId) {
    edges {
      node {
        id
        name
        updatedAt
        organization {
          id
          name
          demoOrg
          testOrg
        }
      }
    }
  }
}
    `;

export function useReportsToBeDeletedQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportsToBeDeletedQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportsToBeDeletedQuery,
    GQTypes.GQReportsToBeDeletedQueryVariables
  >({ query: ReportsToBeDeletedDocument, ...options });
}
export const CreateReportConfigFromSrcDocument = gql`
    mutation CreateReportConfigFromSrc($input: CreateReportConfigFromSrcInput!) {
  createReportConfigFromSrc(input: $input) {
    reportConfig {
      id
    }
  }
}
    `;

export function useCreateReportConfigFromSrcMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportConfigFromSrcMutation,
    GQTypes.GQCreateReportConfigFromSrcMutationVariables
  >(CreateReportConfigFromSrcDocument);
}
export const GetReportConfigForAdminDocument = gql`
    query GetReportConfigForAdmin($id: ID!) {
  reportConfig(id: $id) {
    id
    reportType
    shortName
    longName
    description
    brandColor
    isHiddenFromOverview
    isCreationBlocked
    isRegulatoryExposureBlocked
    requiredPermissions
    reportObjectives
    inputIds
    computedItemIds
    itemIds
    lastLintedAt
    latestLinterStatus
    latestComponentUpdatedAt(id: $id)
    allQuestions {
      id
      identifier
      componentId
      isComputed
    }
    allContainers {
      id
      label
      itemIds
    }
  }
}
    `;

export function useGetReportConfigForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportConfigForAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportConfigForAdminQuery,
    GQTypes.GQGetReportConfigForAdminQueryVariables
  >({ query: GetReportConfigForAdminDocument, ...options });
}
export const GetReportQuestionContainerForAdminDocument = gql`
    query GetReportQuestionContainerForAdmin($id: ID!) {
  reportQuestionContainer(id: $id) {
    ...ReportQuestionContainerFields
  }
}
    ${ReportQuestionContainerFieldsFragmentDoc}`;

export function useGetReportQuestionContainerForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionContainerForAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionContainerForAdminQuery,
    GQTypes.GQGetReportQuestionContainerForAdminQueryVariables
  >({ query: GetReportQuestionContainerForAdminDocument, ...options });
}
export const GetReportQuestionForAdminDocument = gql`
    query GetReportQuestionForAdmin($id: ID!) {
  reportQuestion(id: $id) {
    ...ReportQuestionRawFields
  }
}
    ${ReportQuestionRawFieldsFragmentDoc}`;

export function useGetReportQuestionForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionForAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionForAdminQuery,
    GQTypes.GQGetReportQuestionForAdminQueryVariables
  >({ query: GetReportQuestionForAdminDocument, ...options });
}
export const LintReportConfigDocument = gql`
    mutation LintReportConfig($input: LintReportConfigInput!) {
  lintReportConfig(input: $input) {
    status
    lintErrors {
      reportObjectId
      message
      type
    }
  }
}
    `;

export function useLintReportConfigMutation() {
  return Urql.useMutation<
    GQTypes.GQLintReportConfigMutation,
    GQTypes.GQLintReportConfigMutationVariables
  >(LintReportConfigDocument);
}
export const VerifyReportAnswerWithAlertDocument = gql`
    mutation VerifyReportAnswerWithAlert($input: VerifyReportAnswerWithAlertInput!) {
  verifyReportAnswerWithAlert(input: $input) {
    status
  }
}
    `;

export function useVerifyReportAnswerWithAlertMutation() {
  return Urql.useMutation<
    GQTypes.GQVerifyReportAnswerWithAlertMutation,
    GQTypes.GQVerifyReportAnswerWithAlertMutationVariables
  >(VerifyReportAnswerWithAlertDocument);
}
export const CreateReportQuestionDocument = gql`
    mutation CreateReportQuestion($input: CreateReportQuestionInput!) {
  createReportQuestion(input: $input) {
    question {
      ...ReportQuestionRawFields
      parentContainer {
        ...ReportQuestionContainerFields
      }
    }
  }
}
    ${ReportQuestionRawFieldsFragmentDoc}
${ReportQuestionContainerFieldsFragmentDoc}`;

export function useCreateReportQuestionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportQuestionMutation,
    GQTypes.GQCreateReportQuestionMutationVariables
  >(CreateReportQuestionDocument);
}
export const UpdateReportQuestionDocument = gql`
    mutation UpdateReportQuestion($input: UpdateReportQuestionInput!) {
  updateReportQuestion(input: $input) {
    question {
      ...ReportQuestionRawFields
    }
  }
}
    ${ReportQuestionRawFieldsFragmentDoc}`;

export function useUpdateReportQuestionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportQuestionMutation,
    GQTypes.GQUpdateReportQuestionMutationVariables
  >(UpdateReportQuestionDocument);
}
export const DeleteReportQuestionDocument = gql`
    mutation DeleteReportQuestion($input: DeleteReportQuestionInput!) {
  deleteReportQuestion(input: $input) {
    parentId
    question {
      ...ReportQuestionRawFields
    }
  }
}
    ${ReportQuestionRawFieldsFragmentDoc}`;

export function useDeleteReportQuestionMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportQuestionMutation,
    GQTypes.GQDeleteReportQuestionMutationVariables
  >(DeleteReportQuestionDocument);
}
export const UpdateReportEsrsMappingDocument = gql`
    mutation updateReportEsrsMapping($input: UpdateReportEsrsMappingInput!) {
  updateReportEsrsMapping(input: $input) {
    ... on UpdateReportEsrsMappingSuccess {
      __typename
      mapping {
        reportConfigId
        csv
      }
      warnings {
        msg
      }
    }
    ... on UpdateReportEsrsMappingFailure {
      __typename
      errors {
        msg
      }
    }
  }
}
    `;

export function useUpdateReportEsrsMappingMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportEsrsMappingMutation,
    GQTypes.GQUpdateReportEsrsMappingMutationVariables
  >(UpdateReportEsrsMappingDocument);
}
export const GetReportConfigFrameworkMappingDocument = gql`
    query getReportConfigFrameworkMapping($reportConfigId: ID!) {
  reportConfigFrameworkMapping(reportConfigId: $reportConfigId) {
    reportConfigId
    csv
  }
}
    `;

export function useGetReportConfigFrameworkMappingQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportConfigFrameworkMappingQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportConfigFrameworkMappingQuery,
    GQTypes.GQGetReportConfigFrameworkMappingQueryVariables
  >({ query: GetReportConfigFrameworkMappingDocument, ...options });
}
export const CreateReportWithConfigAdminDocument = gql`
    mutation CreateReportWithConfigAdmin($input: CreateReportWithConfigAdminInput!) {
  createReportWithConfigAdmin(input: $input) {
    id
  }
}
    `;

export function useCreateReportWithConfigAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportWithConfigAdminMutation,
    GQTypes.GQCreateReportWithConfigAdminMutationVariables
  >(CreateReportWithConfigAdminDocument);
}
export const ExternalReportAnswersForReportDocument = gql`
    query ExternalReportAnswersForReport($publicDisclosureId: ID!) {
  externalReportAnswersForReport(publicDisclosureId: $publicDisclosureId) {
    ...ExternalReportAnswerFields
  }
}
    ${ExternalReportAnswerFieldsFragmentDoc}`;

export function useExternalReportAnswersForReportQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQExternalReportAnswersForReportQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQExternalReportAnswersForReportQuery,
    GQTypes.GQExternalReportAnswersForReportQueryVariables
  >({ query: ExternalReportAnswersForReportDocument, ...options });
}
export const ScoreAnswerDocument = gql`
    mutation ScoreAnswer($answerId: ID!, $score: DisclosureQualityScore) {
  scoreAnswer(answerId: $answerId, score: $score) {
    id
    watershedQualityScore
  }
}
    `;

export function useScoreAnswerMutation() {
  return Urql.useMutation<
    GQTypes.GQScoreAnswerMutation,
    GQTypes.GQScoreAnswerMutationVariables
  >(ScoreAnswerDocument);
}
export const SearchCompaniesForExternalReportAnswersDocument = gql`
    query SearchCompaniesForExternalReportAnswers($company: String!, $first: Int!) {
  companies(search: $company, first: $first) {
    edges {
      node {
        id
        name
        disclosuresV2 {
          id
          publicDisclosure {
            id
            reportType
            source
            reportingYears
          }
        }
      }
    }
  }
}
    `;

export function useSearchCompaniesForExternalReportAnswersQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSearchCompaniesForExternalReportAnswersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSearchCompaniesForExternalReportAnswersQuery,
    GQTypes.GQSearchCompaniesForExternalReportAnswersQueryVariables
  >({ query: SearchCompaniesForExternalReportAnswersDocument, ...options });
}
export const GetArchetypeOpportunitiesDocument = gql`
    query GetArchetypeOpportunities {
  tcfdArchetypeOpportunities {
    ...ArchetypeOpportunityFields
  }
}
    ${ArchetypeOpportunityFieldsFragmentDoc}`;

export function useGetArchetypeOpportunitiesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetArchetypeOpportunitiesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetArchetypeOpportunitiesQuery,
    GQTypes.GQGetArchetypeOpportunitiesQueryVariables
  >({ query: GetArchetypeOpportunitiesDocument, ...options });
}
export const CreateTcfdArchetypeOpportunityDocument = gql`
    mutation CreateTcfdArchetypeOpportunity($input: CreateTcfdArchetypeOpportunityInput!) {
  createTcfdArchetypeOpportunity(input: $input) {
    ...ArchetypeOpportunityFields
  }
}
    ${ArchetypeOpportunityFieldsFragmentDoc}`;

export function useCreateTcfdArchetypeOpportunityMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateTcfdArchetypeOpportunityMutation,
    GQTypes.GQCreateTcfdArchetypeOpportunityMutationVariables
  >(CreateTcfdArchetypeOpportunityDocument);
}
export const UpdateTcfdArchetypeOpportunityDocument = gql`
    mutation UpdateTcfdArchetypeOpportunity($input: UpdateTcfdArchetypeOpportunityInput!) {
  updateTcfdArchetypeOpportunity(input: $input) {
    ...ArchetypeOpportunityFields
  }
}
    ${ArchetypeOpportunityFieldsFragmentDoc}`;

export function useUpdateTcfdArchetypeOpportunityMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateTcfdArchetypeOpportunityMutation,
    GQTypes.GQUpdateTcfdArchetypeOpportunityMutationVariables
  >(UpdateTcfdArchetypeOpportunityDocument);
}
export const GetArchetypeRisksDocument = gql`
    query GetArchetypeRisks {
  tcfdArchetypeRisks {
    ...RiskArchetypeFields
  }
}
    ${RiskArchetypeFieldsFragmentDoc}`;

export function useGetArchetypeRisksQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetArchetypeRisksQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetArchetypeRisksQuery,
    GQTypes.GQGetArchetypeRisksQueryVariables
  >({ query: GetArchetypeRisksDocument, ...options });
}
export const DeleteTcfdArchetypeRiskDocument = gql`
    mutation DeleteTcfdArchetypeRisk($input: DeleteTcfdArchetypeRiskInput!) {
  deleteTcfdArchetypeRisk(input: $input) {
    ...RiskArchetypeFields
  }
}
    ${RiskArchetypeFieldsFragmentDoc}`;

export function useDeleteTcfdArchetypeRiskMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteTcfdArchetypeRiskMutation,
    GQTypes.GQDeleteTcfdArchetypeRiskMutationVariables
  >(DeleteTcfdArchetypeRiskDocument);
}
export const CreateTcfdArchetypeRiskDocument = gql`
    mutation CreateTcfdArchetypeRisk($input: CreateTcfdArchetypeRiskInput!) {
  createTcfdArchetypeRisk(input: $input) {
    ...RiskArchetypeFields
  }
}
    ${RiskArchetypeFieldsFragmentDoc}`;

export function useCreateTcfdArchetypeRiskMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateTcfdArchetypeRiskMutation,
    GQTypes.GQCreateTcfdArchetypeRiskMutationVariables
  >(CreateTcfdArchetypeRiskDocument);
}
export const UpdateTcfdArchetypeRiskDocument = gql`
    mutation UpdateTcfdArchetypeRisk($input: UpdateTcfdArchetypeRiskInput!) {
  updateTcfdArchetypeRisk(input: $input) {
    ...RiskArchetypeFields
  }
}
    ${RiskArchetypeFieldsFragmentDoc}`;

export function useUpdateTcfdArchetypeRiskMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateTcfdArchetypeRiskMutation,
    GQTypes.GQUpdateTcfdArchetypeRiskMutationVariables
  >(UpdateTcfdArchetypeRiskDocument);
}
export const CanAccessOrganizationDocument = gql`
    query CanAccessOrganization($orgId: ID!) {
  canAccessOrganization(orgId: $orgId)
  organization(id: $orgId) {
    id
    demoOrg
    testOrg
  }
  accessControlsEnabled: featureFlagEnabledForActiveUser(
    flag: EntFoundGateAdminAccess
  )
}
    `;

export function useCanAccessOrganizationQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCanAccessOrganizationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCanAccessOrganizationQuery,
    GQTypes.GQCanAccessOrganizationQueryVariables
  >({ query: CanAccessOrganizationDocument, ...options });
}
export const GrantCurrentUserAdminAccessToOrgDocument = gql`
    mutation GrantCurrentUserAdminAccessToOrg($input: GrantCurrentUserAdminAccessToOrgInput!) {
  grantCurrentUserAdminAccessToOrg(input: $input) {
    user {
      id
    }
  }
}
    `;

export function useGrantCurrentUserAdminAccessToOrgMutation() {
  return Urql.useMutation<
    GQTypes.GQGrantCurrentUserAdminAccessToOrgMutation,
    GQTypes.GQGrantCurrentUserAdminAccessToOrgMutationVariables
  >(GrantCurrentUserAdminAccessToOrgDocument);
}
export const OrganizationAccessRequestDocument = gql`
    query OrganizationAccessRequest($orgId: ID!) {
  canAccessOrganization(orgId: $orgId)
  organization(id: $orgId) {
    id
    name
    orgAccess {
      id
      expiresAt
    }
  }
}
    `;

export function useOrganizationAccessRequestQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationAccessRequestQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationAccessRequestQuery,
    GQTypes.GQOrganizationAccessRequestQueryVariables
  >({ query: OrganizationAccessRequestDocument, ...options });
}
export const GrantContractorAccessToOrgDocument = gql`
    mutation GrantContractorAccessToOrg($input: GrantContractorAccessToOrgInput!) {
  grantContractorAccessToOrg(input: $input) {
    user {
      id
    }
  }
}
    `;

export function useGrantContractorAccessToOrgMutation() {
  return Urql.useMutation<
    GQTypes.GQGrantContractorAccessToOrgMutation,
    GQTypes.GQGrantContractorAccessToOrgMutationVariables
  >(GrantContractorAccessToOrgDocument);
}
export const GetVendorMatchingTasksByOrgIdDocument = gql`
    query GetVendorMatchingTasksByOrgId($orgId: ID!) {
  vendorMatchingTasks(orgId: $orgId) {
    ...VendorMatchingTaskFieldsForAdmin
  }
}
    ${VendorMatchingTaskFieldsForAdminFragmentDoc}`;

export function useGetVendorMatchingTasksByOrgIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetVendorMatchingTasksByOrgIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetVendorMatchingTasksByOrgIdQuery,
    GQTypes.GQGetVendorMatchingTasksByOrgIdQueryVariables
  >({ query: GetVendorMatchingTasksByOrgIdDocument, ...options });
}
export const ToggleVendorMatchingTaskAutomappingDocument = gql`
    mutation ToggleVendorMatchingTaskAutomapping($vendorMatchingTaskId: ID!) {
  toggleVendorMatchingTaskAutomapping(vendorMatchingTaskId: $vendorMatchingTaskId)
}
    `;

export function useToggleVendorMatchingTaskAutomappingMutation() {
  return Urql.useMutation<
    GQTypes.GQToggleVendorMatchingTaskAutomappingMutation,
    GQTypes.GQToggleVendorMatchingTaskAutomappingMutationVariables
  >(ToggleVendorMatchingTaskAutomappingDocument);
}
export const InitializeDefaultSupplierViewAdminDocument = gql`
    mutation InitializeDefaultSupplierViewAdmin($input: InitializeDefaultSupplierViewAdminInput!) {
  initializeDefaultSupplierViewAdmin(input: $input) {
    view {
      ...SupplierViewFieldsAdmin
    }
  }
}
    ${SupplierViewFieldsAdminFragmentDoc}`;

export function useInitializeDefaultSupplierViewAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQInitializeDefaultSupplierViewAdminMutation,
    GQTypes.GQInitializeDefaultSupplierViewAdminMutationVariables
  >(InitializeDefaultSupplierViewAdminDocument);
}
export const ActiveWatershedEmployeeDocument = gql`
    query ActiveWatershedEmployee {
  activeWatershedEmployee {
    ...ActiveWatershedEmployeeFields
  }
  featureFlags(targetType: User) {
    edges {
      node {
        ...AdminFeatureFlagDetails
      }
    }
  }
}
    ${ActiveWatershedEmployeeFieldsFragmentDoc}
${AdminFeatureFlagDetailsFragmentDoc}`;

export function useActiveWatershedEmployeeQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQActiveWatershedEmployeeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQActiveWatershedEmployeeQuery,
    GQTypes.GQActiveWatershedEmployeeQueryVariables
  >({ query: ActiveWatershedEmployeeDocument, ...options });
}
export const BusinessActivityTypeWithSettingsByIdDocument = gql`
    query businessActivityTypeWithSettingsById($typeId: ID!) {
  businessActivityTypeById(batId: $typeId) {
    id
    versionName
    name
    tschemaVersionId
    schemaActivationMode
  }
}
    `;

export function useBusinessActivityTypeWithSettingsByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeWithSettingsByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeWithSettingsByIdQuery,
    GQTypes.GQBusinessActivityTypeWithSettingsByIdQueryVariables
  >({ query: BusinessActivityTypeWithSettingsByIdDocument, ...options });
}
export const CalculationTagsWithUsageDocument = gql`
    query CalculationTagsWithUsage {
  calculationTagsWithUsage {
    tag {
      id
      name
      createdAt
      updatedAt
    }
    referenceDataSources {
      id
      name
      updatedAt
      externalSourceNotes
    }
  }
}
    `;

export function useCalculationTagsWithUsageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCalculationTagsWithUsageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCalculationTagsWithUsageQuery,
    GQTypes.GQCalculationTagsWithUsageQueryVariables
  >({ query: CalculationTagsWithUsageDocument, ...options });
}
export const UpdateCalculationTagDocument = gql`
    mutation UpdateCalculationTag($input: UpdateCalculationTagInput!) {
  updateCalculationTag(input: $input) {
    success
  }
}
    `;

export function useUpdateCalculationTagMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCalculationTagMutation,
    GQTypes.GQUpdateCalculationTagMutationVariables
  >(UpdateCalculationTagDocument);
}
export const DeleteCalculationTagDocument = gql`
    mutation DeleteCalculationTag($input: DeleteCalculationTagInput!) {
  deleteCalculationTag(input: $input) {
    success
  }
}
    `;

export function useDeleteCalculationTagMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCalculationTagMutation,
    GQTypes.GQDeleteCalculationTagMutationVariables
  >(DeleteCalculationTagDocument);
}
export const CalculationTagsDocument = gql`
    query CalculationTags {
  calculationTags {
    name
    id
    createdAt
    updatedAt
  }
}
    `;

export function useCalculationTagsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCalculationTagsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCalculationTagsQuery,
    GQTypes.GQCalculationTagsQueryVariables
  >({ query: CalculationTagsDocument, ...options });
}
export const GetCanonicalDatasetsWithCanonicalDatasourcesDocument = gql`
    query GetCanonicalDatasetsWithCanonicalDatasources {
  canonicalDatasets {
    ...CanonicalDatasetBaseFields
    canonicalDatasources {
      ...CanonicalDatasourceBaseFields
    }
  }
  allIntegrationPartners {
    ...IntegrationPartnerBaseFields
  }
}
    ${CanonicalDatasetBaseFieldsFragmentDoc}
${CanonicalDatasourceBaseFieldsFragmentDoc}
${IntegrationPartnerBaseFieldsFragmentDoc}`;

export function useGetCanonicalDatasetsWithCanonicalDatasourcesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCanonicalDatasetsWithCanonicalDatasourcesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCanonicalDatasetsWithCanonicalDatasourcesQuery,
    GQTypes.GQGetCanonicalDatasetsWithCanonicalDatasourcesQueryVariables
  >({
    query: GetCanonicalDatasetsWithCanonicalDatasourcesDocument,
    ...options,
  });
}
export const CreateCanonicalDatasetDocument = gql`
    mutation CreateCanonicalDataset($input: CreateCanonicalDatasetInput!) {
  createCanonicalDataset(input: $input) {
    canonicalDataset {
      ...CanonicalDatasetBaseFields
    }
  }
}
    ${CanonicalDatasetBaseFieldsFragmentDoc}`;

export function useCreateCanonicalDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCanonicalDatasetMutation,
    GQTypes.GQCreateCanonicalDatasetMutationVariables
  >(CreateCanonicalDatasetDocument);
}
export const CreateCanonicalDatasourceDocument = gql`
    mutation CreateCanonicalDatasource($input: CreateCanonicalDatasourceInput!) {
  createCanonicalDatasource(input: $input) {
    canonicalDatasource {
      ...CanonicalDatasourceBaseFields
    }
  }
}
    ${CanonicalDatasourceBaseFieldsFragmentDoc}`;

export function useCreateCanonicalDatasourceMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCanonicalDatasourceMutation,
    GQTypes.GQCreateCanonicalDatasourceMutationVariables
  >(CreateCanonicalDatasourceDocument);
}
export const UpdateCanonicalDatasetDocument = gql`
    mutation UpdateCanonicalDataset($input: UpdateCanonicalDatasetInput!) {
  updateCanonicalDataset(input: $input) {
    canonicalDataset {
      ...CanonicalDatasetBaseFields
    }
  }
}
    ${CanonicalDatasetBaseFieldsFragmentDoc}`;

export function useUpdateCanonicalDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCanonicalDatasetMutation,
    GQTypes.GQUpdateCanonicalDatasetMutationVariables
  >(UpdateCanonicalDatasetDocument);
}
export const UpdateCanonicalDatasourceDocument = gql`
    mutation UpdateCanonicalDatasource($input: UpdateCanonicalDatasourceInput!) {
  updateCanonicalDatasource(input: $input) {
    canonicalDatasource {
      ...CanonicalDatasourceBaseFields
    }
  }
}
    ${CanonicalDatasourceBaseFieldsFragmentDoc}`;

export function useUpdateCanonicalDatasourceMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCanonicalDatasourceMutation,
    GQTypes.GQUpdateCanonicalDatasourceMutationVariables
  >(UpdateCanonicalDatasourceDocument);
}
export const CanonicalClimateProgramProjectPageDocument = gql`
    query CanonicalClimateProgramProjectPage($id: ID!) {
  canonicalClimateProgramProject(id: $id) {
    ...CanonicalClimateProgramProjectFields
    datasetRequirements {
      ...CanonicalProjectRequirementDatasetFields
    }
    ghgpRequirements {
      ...CanonicalProjectRequirementGhgpFields
    }
  }
}
    ${CanonicalClimateProgramProjectFieldsFragmentDoc}
${CanonicalProjectRequirementDatasetFieldsFragmentDoc}
${CanonicalProjectRequirementGhgpFieldsFragmentDoc}`;

export function useCanonicalClimateProgramProjectPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCanonicalClimateProgramProjectPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCanonicalClimateProgramProjectPageQuery,
    GQTypes.GQCanonicalClimateProgramProjectPageQueryVariables
  >({ query: CanonicalClimateProgramProjectPageDocument, ...options });
}
export const DeleteCanonicalClimateProgramProjectRequirementDatasetDocument = gql`
    mutation DeleteCanonicalClimateProgramProjectRequirementDataset($input: DeleteCanonicalClimateProgramProjectRequirementDatasetInput!) {
  deleteCanonicalClimateProgramProjectRequirementDataset(input: $input) {
    canonicalClimateProgramProject {
      ...CanonicalClimateProgramProjectFields
      datasetRequirements {
        ...CanonicalProjectRequirementDatasetFields
      }
    }
  }
}
    ${CanonicalClimateProgramProjectFieldsFragmentDoc}
${CanonicalProjectRequirementDatasetFieldsFragmentDoc}`;

export function useDeleteCanonicalClimateProgramProjectRequirementDatasetMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCanonicalClimateProgramProjectRequirementDatasetMutation,
    GQTypes.GQDeleteCanonicalClimateProgramProjectRequirementDatasetMutationVariables
  >(DeleteCanonicalClimateProgramProjectRequirementDatasetDocument);
}
export const DeleteCanonicalClimateProgramProjectRequirementGhgpDocument = gql`
    mutation DeleteCanonicalClimateProgramProjectRequirementGhgp($input: DeleteCanonicalClimateProgramProjectRequirementGhgpInput!) {
  deleteCanonicalClimateProgramProjectRequirementGhgp(input: $input) {
    canonicalClimateProgramProject {
      ...CanonicalClimateProgramProjectFields
      ghgpRequirements {
        ...CanonicalProjectRequirementGhgpFields
      }
    }
  }
}
    ${CanonicalClimateProgramProjectFieldsFragmentDoc}
${CanonicalProjectRequirementGhgpFieldsFragmentDoc}`;

export function useDeleteCanonicalClimateProgramProjectRequirementGhgpMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCanonicalClimateProgramProjectRequirementGhgpMutation,
    GQTypes.GQDeleteCanonicalClimateProgramProjectRequirementGhgpMutationVariables
  >(DeleteCanonicalClimateProgramProjectRequirementGhgpDocument);
}
export const CanonicalClimateProgramProjectsDocument = gql`
    query CanonicalClimateProgramProjects {
  canonicalClimateProgramProjects {
    ...CanonicalClimateProgramProjectFields
  }
}
    ${CanonicalClimateProgramProjectFieldsFragmentDoc}`;

export function useCanonicalClimateProgramProjectsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCanonicalClimateProgramProjectsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCanonicalClimateProgramProjectsQuery,
    GQTypes.GQCanonicalClimateProgramProjectsQueryVariables
  >({ query: CanonicalClimateProgramProjectsDocument, ...options });
}
export const DeleteCanonicalClimateProgramProjectDocument = gql`
    mutation DeleteCanonicalClimateProgramProject($input: IdInput!) {
  deleteCanonicalClimateProgramProject(input: $input) {
    id
  }
}
    `;

export function useDeleteCanonicalClimateProgramProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCanonicalClimateProgramProjectMutation,
    GQTypes.GQDeleteCanonicalClimateProgramProjectMutationVariables
  >(DeleteCanonicalClimateProgramProjectDocument);
}
export const UnifiedPublicCompanyDisclosuresPageDocument = gql`
    query UnifiedPublicCompanyDisclosuresPage($companyId: ID!) {
  company(id: $companyId) {
    id
    name
    ...CompanyAutocompleteResult
  }
}
    ${CompanyAutocompleteResultFragmentDoc}`;

export function useUnifiedPublicCompanyDisclosuresPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUnifiedPublicCompanyDisclosuresPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUnifiedPublicCompanyDisclosuresPageQuery,
    GQTypes.GQUnifiedPublicCompanyDisclosuresPageQueryVariables
  >({ query: UnifiedPublicCompanyDisclosuresPageDocument, ...options });
}
export const GetCompanyForAdminDocument = gql`
    query GetCompanyForAdmin($id: ID!) {
  company(id: $id) {
    ...CompanyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;

export function useGetCompanyForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCompanyForAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyForAdminQuery,
    GQTypes.GQGetCompanyForAdminQueryVariables
  >({ query: GetCompanyForAdminDocument, ...options });
}
export const CompanySourceKeysPageDocument = gql`
    query CompanySourceKeysPage {
  companySourceKeys {
    ...CompanySourceKeyFields
  }
}
    ${CompanySourceKeyFieldsFragmentDoc}`;

export function useCompanySourceKeysPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompanySourceKeysPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompanySourceKeysPageQuery,
    GQTypes.GQCompanySourceKeysPageQueryVariables
  >({ query: CompanySourceKeysPageDocument, ...options });
}
export const CreateCompanySourceKeyDocument = gql`
    mutation CreateCompanySourceKey($input: CreateCompanySourceKeyInput!) {
  createCompanySourceKey(input: $input) {
    companySourceKey {
      ...CompanySourceKeyFields
    }
  }
}
    ${CompanySourceKeyFieldsFragmentDoc}`;

export function useCreateCompanySourceKeyMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCompanySourceKeyMutation,
    GQTypes.GQCreateCompanySourceKeyMutationVariables
  >(CreateCompanySourceKeyDocument);
}
export const UpdateCompanySourceKeyDocument = gql`
    mutation UpdateCompanySourceKey($input: UpdateCompanySourceKeyInput!) {
  updateCompanySourceKey(input: $input) {
    companySourceKey {
      ...CompanySourceKeyFields
    }
  }
}
    ${CompanySourceKeyFieldsFragmentDoc}`;

export function useUpdateCompanySourceKeyMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateCompanySourceKeyMutation,
    GQTypes.GQUpdateCompanySourceKeyMutationVariables
  >(UpdateCompanySourceKeyDocument);
}
export const DeleteCompanySourceKeyDocument = gql`
    mutation DeleteCompanySourceKey($input: IdInput!) {
  deleteCompanySourceKey(input: $input) {
    id
  }
}
    `;

export function useDeleteCompanySourceKeyMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCompanySourceKeyMutation,
    GQTypes.GQDeleteCompanySourceKeyMutationVariables
  >(DeleteCompanySourceKeyDocument);
}
export const CustomerTargetSchemaDocument = gql`
    query CustomerTargetSchema($ctsId: ID!) {
  customerTargetSchema(id: $ctsId) {
    ...CtsInfoHeader
  }
  canonicalDatasets {
    id
    name
  }
}
    ${CtsInfoHeaderFragmentDoc}`;

export function useCustomerTargetSchemaQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCustomerTargetSchemaQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCustomerTargetSchemaQuery,
    GQTypes.GQCustomerTargetSchemaQueryVariables
  >({ query: CustomerTargetSchemaDocument, ...options });
}
export const CreateCtsVersionDocument = gql`
    mutation CreateCtsVersion($input: CreateCtsVersionInput!) {
  createCtsVersion(input: $input) {
    customerTargetSchema {
      id
      ...CtsInfoHeader
    }
  }
}
    ${CtsInfoHeaderFragmentDoc}`;

export function useCreateCtsVersionMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCtsVersionMutation,
    GQTypes.GQCreateCtsVersionMutationVariables
  >(CreateCtsVersionDocument);
}
export const GlobalCustomerTargetSchemasDocument = gql`
    query GlobalCustomerTargetSchemas {
  customerTargetSchemas {
    ...CustomerTargetSchemaForListView
  }
  canonicalDatasets {
    id
    name
  }
}
    ${CustomerTargetSchemaForListViewFragmentDoc}`;

export function useGlobalCustomerTargetSchemasQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGlobalCustomerTargetSchemasQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGlobalCustomerTargetSchemasQuery,
    GQTypes.GQGlobalCustomerTargetSchemasQueryVariables
  >({ query: GlobalCustomerTargetSchemasDocument, ...options });
}
export const DuplicateCtsDocument = gql`
    mutation DuplicateCts($input: DuplicateCtsInput!) {
  duplicateCts(input: $input) {
    schema {
      ...CustomerTargetSchemaForListView
    }
  }
}
    ${CustomerTargetSchemaForListViewFragmentDoc}`;

export function useDuplicateCtsMutation() {
  return Urql.useMutation<
    GQTypes.GQDuplicateCtsMutation,
    GQTypes.GQDuplicateCtsMutationVariables
  >(DuplicateCtsDocument);
}
export const DeleteCtsDocument = gql`
    mutation DeleteCts($input: DeleteCtsInput!) {
  deleteCts(input: $input) {
    schema {
      ...CustomerTargetSchemaForListView
    }
  }
}
    ${CustomerTargetSchemaForListViewFragmentDoc}`;

export function useDeleteCtsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCtsMutation,
    GQTypes.GQDeleteCtsMutationVariables
  >(DeleteCtsDocument);
}
export const DuckHuntPageDocument = gql`
    query DuckHuntPage {
  duckHuntLeaderboard {
    hunter {
      id
      name
    }
    numDucks
    lastFoundAt
  }
  ducksForHunter
}
    `;

export function useDuckHuntPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQDuckHuntPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDuckHuntPageQuery,
    GQTypes.GQDuckHuntPageQueryVariables
  >({ query: DuckHuntPageDocument, ...options });
}
export const OrganizationEmailsSentDocument = gql`
    query OrganizationEmailsSent($orgId: ID, $after: String, $before: String, $first: Int, $last: Int) {
  emailsSent(
    orgId: $orgId
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
    edges {
      node {
        ...EmailSentForAdminTable
      }
    }
  }
  organizations {
    edges {
      node {
        id
        name
        demoOrg
      }
    }
  }
}
    ${EmailSentForAdminTableFragmentDoc}`;

export function useOrganizationEmailsSentQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationEmailsSentQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationEmailsSentQuery,
    GQTypes.GQOrganizationEmailsSentQueryVariables
  >({ query: OrganizationEmailsSentDocument, ...options });
}
export const GetEmissionsModelEvaluationPageDataDocument = gql`
    query GetEmissionsModelEvaluationPageData($id: ID!) {
  emissionsModelEvaluation(id: $id) {
    id
    fingerprintCount
    missingTraceMetadata {
      ...EmeMissingTraceMetadataFields
    }
    calculationSampler {
      samples {
        rowId
        fingerprint
        equation {
          emissionsVariableName
          traceDetails {
            referenceValueDetails
            emissionsModelVariableDetails
            bartValueDetails
            emissionsModelVersionId
            emissionsModelEvaluationId
          }
          trace
        }
      }
    }
  }
}
    ${EmeMissingTraceMetadataFieldsFragmentDoc}`;

export function useGetEmissionsModelEvaluationPageDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelEvaluationPageDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelEvaluationPageDataQuery,
    GQTypes.GQGetEmissionsModelEvaluationPageDataQueryVariables
  >({ query: GetEmissionsModelEvaluationPageDataDocument, ...options });
}
export const GetEmissionsModelPageDataDocument = gql`
    query GetEmissionsModelPageData($id: ID!) {
  emissionsModelStable(id: $id) {
    ...EmissionsModelStableFields
  }
}
    ${EmissionsModelStableFieldsFragmentDoc}`;

export function useGetEmissionsModelPageDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelPageDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelPageDataQuery,
    GQTypes.GQGetEmissionsModelPageDataQueryVariables
  >({ query: GetEmissionsModelPageDataDocument, ...options });
}
export const GetEmissionsModelVersionImportedDescendantsFromDirectImportIdsDocument = gql`
    query GetEmissionsModelVersionImportedDescendantsFromDirectImportIds($ids: [ID!]!) {
  emissionsModelVersionImportedDescendantsFromDirectImportIds(ids: $ids) {
    ...EmissionsModelVersionFieldsForImportedDescendants
  }
}
    ${EmissionsModelVersionFieldsForImportedDescendantsFragmentDoc}`;

export function useGetEmissionsModelVersionImportedDescendantsFromDirectImportIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelVersionImportedDescendantsFromDirectImportIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelVersionImportedDescendantsFromDirectImportIdsQuery,
    GQTypes.GQGetEmissionsModelVersionImportedDescendantsFromDirectImportIdsQueryVariables
  >({
    query:
      GetEmissionsModelVersionImportedDescendantsFromDirectImportIdsDocument,
    ...options,
  });
}
export const UpdateEmissionsModelStableDocument = gql`
    mutation UpdateEmissionsModelStable($input: UpdateEmissionsModelStableInput!) {
  updateEmissionsModelStable(input: $input) {
    emissionsModelStable {
      ...EmissionsModelStableBaseFields
    }
  }
}
    ${EmissionsModelStableBaseFieldsFragmentDoc}`;

export function useUpdateEmissionsModelStableMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEmissionsModelStableMutation,
    GQTypes.GQUpdateEmissionsModelStableMutationVariables
  >(UpdateEmissionsModelStableDocument);
}
export const GetEmissionsModelTableDataDocument = gql`
    query GetEmissionsModelTableData($showArchived: Boolean!, $emissionsModelListTab: String, $before: String, $after: String, $first: Int, $last: Int, $search: String) {
  emissionsModelsStable(
    showArchived: $showArchived
    emissionsModelListTab: $emissionsModelListTab
    before: $before
    after: $after
    first: $first
    last: $last
    search: $search
  ) {
    edges {
      node {
        ...EmissionsModelStableListViewFields
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      totalRowCount
    }
  }
}
    ${EmissionsModelStableListViewFieldsFragmentDoc}`;

export function useGetEmissionsModelTableDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEmissionsModelTableDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEmissionsModelTableDataQuery,
    GQTypes.GQGetEmissionsModelTableDataQueryVariables
  >({ query: GetEmissionsModelTableDataDocument, ...options });
}
export const WatershedEmployeesPageDocument = gql`
    query WatershedEmployeesPage {
  watershedEmployees {
    edges {
      node {
        id
        name
        email
      }
    }
  }
}
    `;

export function useWatershedEmployeesPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQWatershedEmployeesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQWatershedEmployeesPageQuery,
    GQTypes.GQWatershedEmployeesPageQueryVariables
  >({ query: WatershedEmployeesPageDocument, ...options });
}
export const AddWatershedEmployeeDocument = gql`
    mutation AddWatershedEmployee($input: AddWatershedEmployeeInput!) {
  addWatershedEmployee(input: $input) {
    watershedEmployee {
      id
      name
      email
    }
  }
}
    `;

export function useAddWatershedEmployeeMutation() {
  return Urql.useMutation<
    GQTypes.GQAddWatershedEmployeeMutation,
    GQTypes.GQAddWatershedEmployeeMutationVariables
  >(AddWatershedEmployeeDocument);
}
export const EngagementTaskConfigAdminDocument = gql`
    query EngagementTaskConfigAdmin($id: ID!) {
  engagementTaskConfig(engagementTaskConfigId: $id) {
    ...EngagementTaskConfigFieldsAdmin
  }
}
    ${EngagementTaskConfigFieldsAdminFragmentDoc}`;

export function useEngagementTaskConfigAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEngagementTaskConfigAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEngagementTaskConfigAdminQuery,
    GQTypes.GQEngagementTaskConfigAdminQueryVariables
  >({ query: EngagementTaskConfigAdminDocument, ...options });
}
export const UpdateEngagementTaskConfigAdminDocument = gql`
    mutation UpdateEngagementTaskConfigAdmin($input: EngagementTaskConfigInputForAdmin!) {
  updateEngagementTaskConfigForAdmin(input: $input) {
    engagementTaskConfig {
      ...EngagementTaskConfigFieldsAdmin
    }
  }
}
    ${EngagementTaskConfigFieldsAdminFragmentDoc}`;

export function useUpdateEngagementTaskConfigAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEngagementTaskConfigAdminMutation,
    GQTypes.GQUpdateEngagementTaskConfigAdminMutationVariables
  >(UpdateEngagementTaskConfigAdminDocument);
}
export const GlobalEngagementTaskConfigsAdminDocument = gql`
    query GlobalEngagementTaskConfigsAdmin {
  globalEngagementTaskConfigs {
    ...EngagementTaskConfigFieldsAdmin
  }
}
    ${EngagementTaskConfigFieldsAdminFragmentDoc}`;

export function useGlobalEngagementTaskConfigsAdminQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGlobalEngagementTaskConfigsAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGlobalEngagementTaskConfigsAdminQuery,
    GQTypes.GQGlobalEngagementTaskConfigsAdminQueryVariables
  >({ query: GlobalEngagementTaskConfigsAdminDocument, ...options });
}
export const OrgSpecificEngagementTaskConfigsAdminDocument = gql`
    query OrgSpecificEngagementTaskConfigsAdmin($orgId: ID!) {
  engagementTaskConfigs(
    orgId: $orgId
    includeGlobalConfigs: false
    includeUnpublishedOrgSpecific: true
    includeArchived: true
  ) {
    ...EngagementTaskConfigFieldsAdmin
  }
}
    ${EngagementTaskConfigFieldsAdminFragmentDoc}`;

export function useOrgSpecificEngagementTaskConfigsAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgSpecificEngagementTaskConfigsAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgSpecificEngagementTaskConfigsAdminQuery,
    GQTypes.GQOrgSpecificEngagementTaskConfigsAdminQueryVariables
  >({ query: OrgSpecificEngagementTaskConfigsAdminDocument, ...options });
}
export const CreateEngagementTaskConfigAdminDocument = gql`
    mutation CreateEngagementTaskConfigAdmin($input: EngagementTaskConfigCreateInputForAdmin!) {
  createEngagementTaskConfigForAdmin(input: $input) {
    engagementTaskConfig {
      ...EngagementTaskConfigFieldsAdmin
    }
  }
}
    ${EngagementTaskConfigFieldsAdminFragmentDoc}`;

export function useCreateEngagementTaskConfigAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEngagementTaskConfigAdminMutation,
    GQTypes.GQCreateEngagementTaskConfigAdminMutationVariables
  >(CreateEngagementTaskConfigAdminDocument);
}
export const DeleteEngagementTaskConfigAdminDocument = gql`
    mutation DeleteEngagementTaskConfigAdmin($input: IdInput!) {
  deleteEngagementTaskConfigForAdmin(input: $input) {
    id
  }
}
    `;

export function useDeleteEngagementTaskConfigAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteEngagementTaskConfigAdminMutation,
    GQTypes.GQDeleteEngagementTaskConfigAdminMutationVariables
  >(DeleteEngagementTaskConfigAdminDocument);
}
export const PublishEngagementTaskConfigAdminDocument = gql`
    mutation PublishEngagementTaskConfigAdmin($input: PublishEngagementTaskConfigForAdminInput!) {
  publishEngagementTaskConfigForAdmin(input: $input) {
    engagementTaskConfig {
      ...EngagementTaskConfigFieldsAdmin
    }
  }
}
    ${EngagementTaskConfigFieldsAdminFragmentDoc}`;

export function usePublishEngagementTaskConfigAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQPublishEngagementTaskConfigAdminMutation,
    GQTypes.GQPublishEngagementTaskConfigAdminMutationVariables
  >(PublishEngagementTaskConfigAdminDocument);
}
export const CopyEngagementTaskConfigToOrgForAdminDocument = gql`
    mutation CopyEngagementTaskConfigToOrgForAdmin($input: CopyEngagementTaskConfigToOrgForAdminInput!) {
  copyEngagementTaskConfigToOrgForAdmin(input: $input) {
    ...EngagementTaskConfigFieldsAdmin
  }
}
    ${EngagementTaskConfigFieldsAdminFragmentDoc}`;

export function useCopyEngagementTaskConfigToOrgForAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQCopyEngagementTaskConfigToOrgForAdminMutation,
    GQTypes.GQCopyEngagementTaskConfigToOrgForAdminMutationVariables
  >(CopyEngagementTaskConfigToOrgForAdminDocument);
}
export const GetFootprintDebugDocument = gql`
    query GetFootprintDebug($fpsId: ID!) {
  footprintSnapshot(id: $fpsId) {
    ...FootprintSnapshotForFootprintDebugger
  }
  footprintDebug(fpsId: $fpsId) {
    id
    appliedExclusionRules {
      ...FootprintSnapshotExclusionRuleForFootprintDebugger
    }
    cleanPowerImpact {
      ...CleanPowerImpactForFootprintDebugger
    }
  }
}
    ${FootprintSnapshotForFootprintDebuggerFragmentDoc}
${FootprintSnapshotExclusionRuleForFootprintDebuggerFragmentDoc}
${CleanPowerImpactForFootprintDebuggerFragmentDoc}`;

export function useGetFootprintDebugQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetFootprintDebugQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetFootprintDebugQuery,
    GQTypes.GQGetFootprintDebugQueryVariables
  >({ query: GetFootprintDebugDocument, ...options });
}
export const CreateFormuLakeJobDocument = gql`
    mutation CreateFormuLakeJob($input: CreateFormuLakeJobInput!) {
  createFormuLakeJob(input: $input) {
    workflowId
  }
}
    `;

export function useCreateFormuLakeJobMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateFormuLakeJobMutation,
    GQTypes.GQCreateFormuLakeJobMutationVariables
  >(CreateFormuLakeJobDocument);
}
export const GenerateGmailXmlDocument = gql`
    mutation GenerateGmailXml($input: GenerateGmailXmlInput!) {
  generateGmailXml(input: $input) {
    xml
  }
}
    `;

export function useGenerateGmailXmlMutation() {
  return Urql.useMutation<
    GQTypes.GQGenerateGmailXmlMutation,
    GQTypes.GQGenerateGmailXmlMutationVariables
  >(GenerateGmailXmlDocument);
}
export const IntegrationConnectionDocument = gql`
    query IntegrationConnection($id: ID!) {
  integrationConnection(id: $id) {
    ...IntegrationConnectionFields
  }
}
    ${IntegrationConnectionFieldsFragmentDoc}`;

export function useIntegrationConnectionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQIntegrationConnectionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQIntegrationConnectionQuery,
    GQTypes.GQIntegrationConnectionQueryVariables
  >({ query: IntegrationConnectionDocument, ...options });
}
export const IntegrationRequestsPaginatedDocument = gql`
    query IntegrationRequestsPaginated($integrationConnectionId: ID!, $first: Int, $last: Int, $after: String, $before: String) {
  integrationConnection(id: $integrationConnectionId) {
    id
    integrationRequests(first: $first, last: $last, after: $after, before: $before) {
      edges {
        node {
          ...IntegrationRequestFields
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
}
    ${IntegrationRequestFieldsFragmentDoc}`;

export function useIntegrationRequestsPaginatedQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQIntegrationRequestsPaginatedQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQIntegrationRequestsPaginatedQuery,
    GQTypes.GQIntegrationRequestsPaginatedQueryVariables
  >({ query: IntegrationRequestsPaginatedDocument, ...options });
}
export const IntegrationDataPullsDocument = gql`
    query IntegrationDataPulls($ids: [ID!]!) {
  integrationDataPulls(ids: $ids) {
    ...IntegrationDataPullFields
  }
}
    ${IntegrationDataPullFieldsFragmentDoc}`;

export function useIntegrationDataPullsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQIntegrationDataPullsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQIntegrationDataPullsQuery,
    GQTypes.GQIntegrationDataPullsQueryVariables
  >({ query: IntegrationDataPullsDocument, ...options });
}
export const UpdateIntegrationConnectionDocument = gql`
    mutation UpdateIntegrationConnection($input: UpdateIntegrationConnectionInput!) {
  updateIntegrationConnection(input: $input) {
    integrationConnection {
      id
      netsuiteIntegrationSettings {
        id
        transactionTypeFilters
        accountNamesToExclude
        usePostingPeriodForDateFilters
      }
    }
  }
}
    `;

export function useUpdateIntegrationConnectionMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateIntegrationConnectionMutation,
    GQTypes.GQUpdateIntegrationConnectionMutationVariables
  >(UpdateIntegrationConnectionDocument);
}
export const UnassociateIntegrationConnectionFromDatasourceDocument = gql`
    mutation UnassociateIntegrationConnectionFromDatasource($input: UnassociateIntegrationConnectionFromDatasourceInput!) {
  unassociateIntegrationConnectionFromDatasource(input: $input) {
    integrationConnection {
      id
      datasources {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
    `;

export function useUnassociateIntegrationConnectionFromDatasourceMutation() {
  return Urql.useMutation<
    GQTypes.GQUnassociateIntegrationConnectionFromDatasourceMutation,
    GQTypes.GQUnassociateIntegrationConnectionFromDatasourceMutationVariables
  >(UnassociateIntegrationConnectionFromDatasourceDocument);
}
export const IntegrationsIndexPageDocument = gql`
    query IntegrationsIndexPage {
  integrationConnections {
    edges {
      node {
        ...IntegrationConnectionListFields
      }
    }
  }
}
    ${IntegrationConnectionListFieldsFragmentDoc}`;

export function useIntegrationsIndexPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQIntegrationsIndexPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQIntegrationsIndexPageQuery,
    GQTypes.GQIntegrationsIndexPageQueryVariables
  >({ query: IntegrationsIndexPageDocument, ...options });
}
export const StartWorkflowDocument = gql`
    mutation StartWorkflow($input: StartWorkflowInput!) {
  startWorkflow(input: $input) {
    workflowExecution {
      workflowId
      runId
    }
  }
}
    `;

export function useStartWorkflowMutation() {
  return Urql.useMutation<
    GQTypes.GQStartWorkflowMutation,
    GQTypes.GQStartWorkflowMutationVariables
  >(StartWorkflowDocument);
}
export const BusinessActivityTypesForTypeNameAndVersionPageDocument = gql`
    query businessActivityTypesForTypeNameAndVersionPage($versionName: String!, $typeName: String!) {
  businessActivityTypeVersions {
    id
    name
    recordStartAt
    recordEndAt
  }
  businessActivityTypeVersionByName(versionName: $versionName) {
    id
    name
    recordStartAt
    recordEndAt
  }
  businessActivityTypesForVersion(versionName: $versionName) {
    id
    versionId
    name
    description
  }
  businessActivityTypeWithFields(versionName: $versionName, typeName: $typeName) {
    ...AllBusinessActivityTypeFields
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useBusinessActivityTypesForTypeNameAndVersionPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypesForTypeNameAndVersionPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypesForTypeNameAndVersionPageQuery,
    GQTypes.GQBusinessActivityTypesForTypeNameAndVersionPageQueryVariables
  >({
    query: BusinessActivityTypesForTypeNameAndVersionPageDocument,
    ...options,
  });
}
export const BusinessActivityTypesForVersionPageDocument = gql`
    query businessActivityTypesForVersionPage($versionName: String!) {
  businessActivityTypeVersions {
    id
    name
    recordStartAt
    recordEndAt
  }
  businessActivityTypeVersionByName(versionName: $versionName) {
    id
    name
    recordStartAt
    recordEndAt
  }
  businessActivityTypesForVersion(versionName: $versionName) {
    id
    versionId
    name
    description
  }
  businessActivityTypesPrimaryForVersionWithFields(versionName: $versionName) {
    ...AllBusinessActivityTypeFields
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useBusinessActivityTypesForVersionPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypesForVersionPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypesForVersionPageQuery,
    GQTypes.GQBusinessActivityTypesForVersionPageQueryVariables
  >({ query: BusinessActivityTypesForVersionPageDocument, ...options });
}
export const BusinessActivityTypesPageDocument = gql`
    query businessActivityTypesPage {
  businessActivityTypeVersionLive {
    id
    name
    recordStartAt
    recordEndAt
  }
}
    `;

export function useBusinessActivityTypesPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypesPageQuery,
    GQTypes.GQBusinessActivityTypesPageQueryVariables
  >({ query: BusinessActivityTypesPageDocument, ...options });
}
export const GetAdminLineageGraphDocument = gql`
    query GetAdminLineageGraph($input: GetAdminLineageGraphInput!) {
  adminLineageGraph(input: $input) {
    serializedGraph
  }
}
    `;

export function useGetAdminLineageGraphQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAdminLineageGraphQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAdminLineageGraphQuery,
    GQTypes.GQGetAdminLineageGraphQueryVariables
  >({ query: GetAdminLineageGraphDocument, ...options });
}
export const CheckAdminAuthenticationDocument = gql`
    query CheckAdminAuthentication {
  activeWatershedEmployee {
    id
  }
}
    `;

export function useCheckAdminAuthenticationQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQCheckAdminAuthenticationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCheckAdminAuthenticationQuery,
    GQTypes.GQCheckAdminAuthenticationQueryVariables
  >({ query: CheckAdminAuthenticationDocument, ...options });
}
export const MarketplaceProjectArchetypeDocument = gql`
    query MarketplaceProjectArchetype($id: ID!) {
  marketplaceProjectArchetype(id: $id) {
    ...MarketplaceProjectArchetypeFields
  }
}
    ${MarketplaceProjectArchetypeFieldsFragmentDoc}`;

export function useMarketplaceProjectArchetypeQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceProjectArchetypeQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceProjectArchetypeQuery,
    GQTypes.GQMarketplaceProjectArchetypeQueryVariables
  >({ query: MarketplaceProjectArchetypeDocument, ...options });
}
export const MarketplaceProjectArchetypesDocument = gql`
    query MarketplaceProjectArchetypes {
  marketplaceProjectArchetypes {
    edges {
      node {
        ...MarketplaceProjectArchetypeListFields
      }
    }
  }
}
    ${MarketplaceProjectArchetypeListFieldsFragmentDoc}`;

export function useMarketplaceProjectArchetypesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceProjectArchetypesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceProjectArchetypesQuery,
    GQTypes.GQMarketplaceProjectArchetypesQueryVariables
  >({ query: MarketplaceProjectArchetypesDocument, ...options });
}
export const MarketplaceDeveloperPageDocument = gql`
    query MarketplaceDeveloperPage($id: ID!) {
  marketplaceDeveloper(id: $id) {
    ...MarketplaceDeveloperWithProjectsFields
  }
}
    ${MarketplaceDeveloperWithProjectsFieldsFragmentDoc}`;

export function useMarketplaceDeveloperPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceDeveloperPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceDeveloperPageQuery,
    GQTypes.GQMarketplaceDeveloperPageQueryVariables
  >({ query: MarketplaceDeveloperPageDocument, ...options });
}
export const CreateMarketplaceDeveloperDocument = gql`
    mutation CreateMarketplaceDeveloper($input: CreateMarketplaceDeveloperInput!) {
  createMarketplaceDeveloper(input: $input) {
    marketplaceDeveloper {
      ...MarketplaceDeveloperFields
    }
  }
}
    ${MarketplaceDeveloperFieldsFragmentDoc}`;

export function useCreateMarketplaceDeveloperMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplaceDeveloperMutation,
    GQTypes.GQCreateMarketplaceDeveloperMutationVariables
  >(CreateMarketplaceDeveloperDocument);
}
export const UpdateMarketplaceDeveloperDocument = gql`
    mutation UpdateMarketplaceDeveloper($input: UpdateMarketplaceDeveloperInput!) {
  updateMarketplaceDeveloper(input: $input) {
    marketplaceDeveloper {
      ...MarketplaceDeveloperFields
    }
  }
}
    ${MarketplaceDeveloperFieldsFragmentDoc}`;

export function useUpdateMarketplaceDeveloperMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMarketplaceDeveloperMutation,
    GQTypes.GQUpdateMarketplaceDeveloperMutationVariables
  >(UpdateMarketplaceDeveloperDocument);
}
export const DeleteMarketplaceDeveloperDocument = gql`
    mutation DeleteMarketplaceDeveloper($input: DeleteMarketplaceDeveloperInput!) {
  deleteMarketplaceDeveloper(input: $input) {
    marketplaceDeveloper {
      ...MarketplaceDeveloperFields
    }
    marketplaceProjects {
      ...MarketplaceProjectFields
    }
  }
}
    ${MarketplaceDeveloperFieldsFragmentDoc}
${MarketplaceProjectFieldsFragmentDoc}`;

export function useDeleteMarketplaceDeveloperMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMarketplaceDeveloperMutation,
    GQTypes.GQDeleteMarketplaceDeveloperMutationVariables
  >(DeleteMarketplaceDeveloperDocument);
}
export const MarketplaceDevelopersListPageDocument = gql`
    query MarketplaceDevelopersListPage {
  marketplaceDevelopers {
    edges {
      node {
        ...MarketplaceDeveloperListFields
      }
    }
  }
}
    ${MarketplaceDeveloperListFieldsFragmentDoc}`;

export function useMarketplaceDevelopersListPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceDevelopersListPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceDevelopersListPageQuery,
    GQTypes.GQMarketplaceDevelopersListPageQueryVariables
  >({ query: MarketplaceDevelopersListPageDocument, ...options });
}
export const MarketplaceDocumentPageDocument = gql`
    query MarketplaceDocumentPage($id: ID!) {
  marketplaceDocument(id: $id) {
    ...MarketplaceDocumentForDetailPage
  }
}
    ${MarketplaceDocumentForDetailPageFragmentDoc}`;

export function useMarketplaceDocumentPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceDocumentPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceDocumentPageQuery,
    GQTypes.GQMarketplaceDocumentPageQueryVariables
  >({ query: MarketplaceDocumentPageDocument, ...options });
}
export const UpdateMarketplaceDocumentDocument = gql`
    mutation UpdateMarketplaceDocument($input: UpdateMarketplaceDocumentInput!) {
  updateMarketplaceDocument(input: $input) {
    document {
      ...MarketplaceDocumentForDetailPage
    }
  }
}
    ${MarketplaceDocumentForDetailPageFragmentDoc}`;

export function useUpdateMarketplaceDocumentMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMarketplaceDocumentMutation,
    GQTypes.GQUpdateMarketplaceDocumentMutationVariables
  >(UpdateMarketplaceDocumentDocument);
}
export const DeleteMarketplaceDocumentDocument = gql`
    mutation DeleteMarketplaceDocument($input: DeleteMarketplaceDocumentInput!) {
  deleteMarketplaceDocument(input: $input) {
    document {
      ...MarketplaceDocumentForDetailPage
    }
  }
}
    ${MarketplaceDocumentForDetailPageFragmentDoc}`;

export function useDeleteMarketplaceDocumentMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMarketplaceDocumentMutation,
    GQTypes.GQDeleteMarketplaceDocumentMutationVariables
  >(DeleteMarketplaceDocumentDocument);
}
export const MarketplaceDocumentsDocument = gql`
    query MarketplaceDocuments {
  marketplaceDocuments {
    edges {
      node {
        ...MarketplaceDocumentListFields
      }
    }
  }
}
    ${MarketplaceDocumentListFieldsFragmentDoc}`;

export function useMarketplaceDocumentsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceDocumentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceDocumentsQuery,
    GQTypes.GQMarketplaceDocumentsQueryVariables
  >({ query: MarketplaceDocumentsDocument, ...options });
}
export const MarketplaceIndexPageDocument = gql`
    query MarketplaceIndexPage {
  marketplacePurchases {
    edges {
      node {
        ...MarketplacePurchaseListFields
      }
    }
  }
  marketplacePurchaseLineItems {
    edges {
      node {
        ...MarketplacePurchaseLineItemFields
      }
    }
  }
  organizations {
    edges {
      node {
        id
        name
        demoOrg
      }
    }
  }
}
    ${MarketplacePurchaseListFieldsFragmentDoc}
${MarketplacePurchaseLineItemFieldsFragmentDoc}`;

export function useMarketplaceIndexPageQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceIndexPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceIndexPageQuery,
    GQTypes.GQMarketplaceIndexPageQueryVariables
  >({ query: MarketplaceIndexPageDocument, ...options });
}
export const CreateMarketplacePurchaseDocument = gql`
    mutation CreateMarketplacePurchase($input: CreateMarketplacePurchaseInput!) {
  createMarketplacePurchase(input: $input) {
    marketplacePurchase {
      ...MarketplacePurchaseFields
    }
  }
}
    ${MarketplacePurchaseFieldsFragmentDoc}`;

export function useCreateMarketplacePurchaseMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateMarketplacePurchaseMutation,
    GQTypes.GQCreateMarketplacePurchaseMutationVariables
  >(CreateMarketplacePurchaseDocument);
}
export const MarketplaceProjectOfferingDocument = gql`
    query MarketplaceProjectOffering($id: ID!) {
  marketplaceProjectOffering(id: $id) {
    ...MarketplaceProjectOfferingFields
  }
}
    ${MarketplaceProjectOfferingFieldsFragmentDoc}`;

export function useMarketplaceProjectOfferingQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceProjectOfferingQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceProjectOfferingQuery,
    GQTypes.GQMarketplaceProjectOfferingQueryVariables
  >({ query: MarketplaceProjectOfferingDocument, ...options });
}
export const MarketplaceProjectOfferingUpdatesDocument = gql`
    query MarketplaceProjectOfferingUpdates($baseOfferingId: ID!) {
  marketplaceProjectOfferingUpdates(baseOfferingId: $baseOfferingId) {
    edges {
      node {
        ...MarketplaceProjectOfferingFields
      }
    }
  }
}
    ${MarketplaceProjectOfferingFieldsFragmentDoc}`;

export function useMarketplaceProjectOfferingUpdatesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceProjectOfferingUpdatesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceProjectOfferingUpdatesQuery,
    GQTypes.GQMarketplaceProjectOfferingUpdatesQueryVariables
  >({ query: MarketplaceProjectOfferingUpdatesDocument, ...options });
}
export const MarketplaceProjectOfferingsDocument = gql`
    query MarketplaceProjectOfferings {
  marketplaceProjectOfferings {
    edges {
      node {
        ...MarketplaceProjectOfferingFields
      }
    }
  }
  marketplaceProjectArchetypes {
    edges {
      node {
        id
        name
      }
    }
  }
  marketplaceSuppliers {
    edges {
      node {
        id
        name
      }
    }
  }
  marketplaceProjects {
    edges {
      node {
        id
        name
        deletedAt
        archetype {
          id
        }
      }
    }
  }
}
    ${MarketplaceProjectOfferingFieldsFragmentDoc}`;

export function useMarketplaceProjectOfferingsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceProjectOfferingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceProjectOfferingsQuery,
    GQTypes.GQMarketplaceProjectOfferingsQueryVariables
  >({ query: MarketplaceProjectOfferingsDocument, ...options });
}
export const GetEacPriceEstimatesDocument = gql`
    query GetEacPriceEstimates {
  eacPriceEstimates {
    ...EacPriceEstimateFields
  }
}
    ${EacPriceEstimateFieldsFragmentDoc}`;

export function useGetEacPriceEstimatesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEacPriceEstimatesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEacPriceEstimatesQuery,
    GQTypes.GQGetEacPriceEstimatesQueryVariables
  >({ query: GetEacPriceEstimatesDocument, ...options });
}
export const CreateEacPriceEstimateDocument = gql`
    mutation CreateEacPriceEstimate($input: CreateEacPriceEstimateInput!) {
  createEacPriceEstimate(input: $input) {
    id
    eacPriceEstimate {
      ...EacPriceEstimateFields
    }
  }
}
    ${EacPriceEstimateFieldsFragmentDoc}`;

export function useCreateEacPriceEstimateMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateEacPriceEstimateMutation,
    GQTypes.GQCreateEacPriceEstimateMutationVariables
  >(CreateEacPriceEstimateDocument);
}
export const UpdateEacPriceEstimateDocument = gql`
    mutation UpdateEacPriceEstimate($input: UpdateEacPriceEstimateInput!) {
  updateEacPriceEstimate(input: $input) {
    id
    eacPriceEstimate {
      ...EacPriceEstimateFields
    }
  }
}
    ${EacPriceEstimateFieldsFragmentDoc}`;

export function useUpdateEacPriceEstimateMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEacPriceEstimateMutation,
    GQTypes.GQUpdateEacPriceEstimateMutationVariables
  >(UpdateEacPriceEstimateDocument);
}
export const UpdateEacPriceEstimatesDocument = gql`
    mutation UpdateEacPriceEstimates($input: UpdateEacPriceEstimatesInput!) {
  updateEacPriceEstimates(input: $input) {
    eacPriceEstimates {
      ...EacPriceEstimateFields
    }
  }
}
    ${EacPriceEstimateFieldsFragmentDoc}`;

export function useUpdateEacPriceEstimatesMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateEacPriceEstimatesMutation,
    GQTypes.GQUpdateEacPriceEstimatesMutationVariables
  >(UpdateEacPriceEstimatesDocument);
}
export const DeleteEacPriceEstimateDocument = gql`
    mutation DeleteEacPriceEstimate($input: DeleteEacPriceEstimateInput!) {
  deleteEacPriceEstimate(input: $input) {
    id
  }
}
    `;

export function useDeleteEacPriceEstimateMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteEacPriceEstimateMutation,
    GQTypes.GQDeleteEacPriceEstimateMutationVariables
  >(DeleteEacPriceEstimateDocument);
}
export const MarketplaceProjectDocument = gql`
    query MarketplaceProject($id: ID!) {
  marketplaceProject(id: $id) {
    ...MarketplaceProjectFields
  }
}
    ${MarketplaceProjectFieldsFragmentDoc}`;

export function useMarketplaceProjectQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceProjectQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceProjectQuery,
    GQTypes.GQMarketplaceProjectQueryVariables
  >({ query: MarketplaceProjectDocument, ...options });
}
export const DeleteMarketplaceProjectDocument = gql`
    mutation DeleteMarketplaceProject($input: DeleteMarketplaceProjectInput!) {
  deleteMarketplaceProject(input: $input) {
    marketplaceProject {
      ...MarketplaceProjectFields
    }
  }
}
    ${MarketplaceProjectFieldsFragmentDoc}`;

export function useDeleteMarketplaceProjectMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMarketplaceProjectMutation,
    GQTypes.GQDeleteMarketplaceProjectMutationVariables
  >(DeleteMarketplaceProjectDocument);
}
export const MarketplaceProjectsDocument = gql`
    query MarketplaceProjects($supplierId: ID) {
  marketplaceProjects(supplierId: $supplierId) {
    edges {
      node {
        ...MarketplaceProjectListFields
      }
    }
  }
}
    ${MarketplaceProjectListFieldsFragmentDoc}`;

export function useMarketplaceProjectsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceProjectsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceProjectsQuery,
    GQTypes.GQMarketplaceProjectsQueryVariables
  >({ query: MarketplaceProjectsDocument, ...options });
}
export const MarketplacePurchaseDocument = gql`
    query MarketplacePurchase($id: ID!) {
  marketplacePurchase(id: $id) {
    ...MarketplacePurchaseFieldsForDetailPage
  }
}
    ${MarketplacePurchaseFieldsForDetailPageFragmentDoc}`;

export function useMarketplacePurchaseQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplacePurchaseQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplacePurchaseQuery,
    GQTypes.GQMarketplacePurchaseQueryVariables
  >({ query: MarketplacePurchaseDocument, ...options });
}
export const UpdateMarketplacePurchaseDocument = gql`
    mutation UpdateMarketplacePurchase($input: UpdateMarketplacePurchaseInput!) {
  updateMarketplacePurchase(input: $input) {
    marketplacePurchase {
      ...MarketplacePurchaseFieldsForDetailPage
    }
  }
}
    ${MarketplacePurchaseFieldsForDetailPageFragmentDoc}`;

export function useUpdateMarketplacePurchaseMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateMarketplacePurchaseMutation,
    GQTypes.GQUpdateMarketplacePurchaseMutationVariables
  >(UpdateMarketplacePurchaseDocument);
}
export const DeleteMarketplacePurchaseDocument = gql`
    mutation DeleteMarketplacePurchase($input: DeleteMarketplacePurchaseInput!) {
  deleteMarketplacePurchase(input: $input) {
    marketplacePurchase {
      ...MarketplacePurchaseFieldsForDetailPage
    }
  }
}
    ${MarketplacePurchaseFieldsForDetailPageFragmentDoc}`;

export function useDeleteMarketplacePurchaseMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteMarketplacePurchaseMutation,
    GQTypes.GQDeleteMarketplacePurchaseMutationVariables
  >(DeleteMarketplacePurchaseDocument);
}
export const MarketplacePurchaseLineItemDocument = gql`
    query MarketplacePurchaseLineItem($id: ID!) {
  marketplacePurchaseLineItem(id: $id) {
    ...MarketplacePurchaseLineItemForPurchaseLineItemPage
  }
}
    ${MarketplacePurchaseLineItemForPurchaseLineItemPageFragmentDoc}`;

export function useMarketplacePurchaseLineItemQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplacePurchaseLineItemQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplacePurchaseLineItemQuery,
    GQTypes.GQMarketplacePurchaseLineItemQueryVariables
  >({ query: MarketplacePurchaseLineItemDocument, ...options });
}
export const MarketplaceSupplierDocument = gql`
    query MarketplaceSupplier($id: ID!) {
  marketplaceSupplier(id: $id) {
    ...MarketplaceSupplierFields
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useMarketplaceSupplierQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceSupplierQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceSupplierQuery,
    GQTypes.GQMarketplaceSupplierQueryVariables
  >({ query: MarketplaceSupplierDocument, ...options });
}
export const MarketplaceSupplierPage_RemoveMarketplaceSupplierWatershedEmployeeDocument = gql`
    mutation MarketplaceSupplierPage_RemoveMarketplaceSupplierWatershedEmployee($input: RemoveMarketplaceSupplierWatershedEmployeeInput!) {
  removeMarketplaceSupplierWatershedEmployee(input: $input) {
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useMarketplaceSupplierPage_RemoveMarketplaceSupplierWatershedEmployeeMutation() {
  return Urql.useMutation<
    GQTypes.GQMarketplaceSupplierPage_RemoveMarketplaceSupplierWatershedEmployeeMutation,
    GQTypes.GQMarketplaceSupplierPage_RemoveMarketplaceSupplierWatershedEmployeeMutationVariables
  >(MarketplaceSupplierPage_RemoveMarketplaceSupplierWatershedEmployeeDocument);
}
export const MarketplaceSupplierPage_RemoveMarketplaceSupplierPointOfContactDocument = gql`
    mutation MarketplaceSupplierPage_RemoveMarketplaceSupplierPointOfContact($input: RemoveMarketplaceSupplierPointOfContactInput!) {
  removeMarketplaceSupplierPointOfContact(input: $input) {
    marketplaceSupplier {
      ...MarketplaceSupplierFields
    }
  }
}
    ${MarketplaceSupplierFieldsFragmentDoc}`;

export function useMarketplaceSupplierPage_RemoveMarketplaceSupplierPointOfContactMutation() {
  return Urql.useMutation<
    GQTypes.GQMarketplaceSupplierPage_RemoveMarketplaceSupplierPointOfContactMutation,
    GQTypes.GQMarketplaceSupplierPage_RemoveMarketplaceSupplierPointOfContactMutationVariables
  >(MarketplaceSupplierPage_RemoveMarketplaceSupplierPointOfContactDocument);
}
export const MarketplaceSuppliersDocument = gql`
    query MarketplaceSuppliers {
  marketplaceSuppliers {
    edges {
      node {
        ...MarketplaceSupplierListFields
      }
    }
  }
}
    ${MarketplaceSupplierListFieldsFragmentDoc}`;

export function useMarketplaceSuppliersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceSuppliersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceSuppliersQuery,
    GQTypes.GQMarketplaceSuppliersQueryVariables
  >({ query: MarketplaceSuppliersDocument, ...options });
}
export const GetObjectDocument = gql`
    query GetObject($id: ID!) {
  object(id: $id) {
    id
    model
    data
  }
}
    `;

export function useGetObjectQuery(
  options: Omit<Urql.UseQueryArgs<GQTypes.GQGetObjectQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetObjectQuery,
    GQTypes.GQGetObjectQueryVariables
  >({ query: GetObjectDocument, ...options });
}
export const GetDataRegistryBatchMigratorRunDocument = gql`
    query GetDataRegistryBatchMigratorRun($batchMigratorRunId: ID!) {
  dataRegistryBatchMigratorRun(batchMigratorRunId: $batchMigratorRunId) {
    id
    createdAt
    description
    status
    workflowId
    fromSchemaVersion {
      id
      schema {
        id
        name
      }
      version
      schemaJson
    }
    toSchemaVersion {
      id
      schema {
        id
        name
      }
      version
      schemaJson
    }
    filters
    error
    successCount
    failureCount
    region
  }
}
    `;

export function useGetDataRegistryBatchMigratorRunQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDataRegistryBatchMigratorRunQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDataRegistryBatchMigratorRunQuery,
    GQTypes.GQGetDataRegistryBatchMigratorRunQueryVariables
  >({ query: GetDataRegistryBatchMigratorRunDocument, ...options });
}
export const GetDataRegistryMigratorRunsDocument = gql`
    query GetDataRegistryMigratorRuns($batchMigratorRunId: ID!, $first: Int, $after: String, $filters: DataRegistryMigratorRunFilters, $searchText: String) {
  dataRegistryMigratorRuns(
    batchMigratorRunId: $batchMigratorRunId
    first: $first
    after: $after
    filters: $filters
    searchText: $searchText
  ) {
    edges {
      node {
        ...DataRegistryMigratorRunSchema
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalRowCount
    }
  }
}
    ${DataRegistryMigratorRunSchemaFragmentDoc}`;

export function useGetDataRegistryMigratorRunsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetDataRegistryMigratorRunsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetDataRegistryMigratorRunsQuery,
    GQTypes.GQGetDataRegistryMigratorRunsQueryVariables
  >({ query: GetDataRegistryMigratorRunsDocument, ...options });
}
export const UpdateDataRegistryBatchMigratorRunDocument = gql`
    mutation UpdateDataRegistryBatchMigratorRun($input: UpdateDataRegistryBatchMigratorRunInput!) {
  updateDataRegistryBatchMigratorRun(input: $input) {
    id
    description
  }
}
    `;

export function useUpdateDataRegistryBatchMigratorRunMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateDataRegistryBatchMigratorRunMutation,
    GQTypes.GQUpdateDataRegistryBatchMigratorRunMutationVariables
  >(UpdateDataRegistryBatchMigratorRunDocument);
}
export const GetSchemaRegistrySchemaVersionDocument = gql`
    query GetSchemaRegistrySchemaVersion($schemaVersionId: ID!) {
  schemaRegistrySchemaVersion(schemaVersionId: $schemaVersionId) {
    id
    schema {
      id
      name
      namespace
    }
    version
    schemaJson
    createdAt
  }
}
    `;

export function useGetSchemaRegistrySchemaVersionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSchemaRegistrySchemaVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSchemaRegistrySchemaVersionQuery,
    GQTypes.GQGetSchemaRegistrySchemaVersionQueryVariables
  >({ query: GetSchemaRegistrySchemaVersionDocument, ...options });
}
export const GetSchemaRegistrySchemaDocument = gql`
    query GetSchemaRegistrySchema($schemaId: ID!) {
  schemaRegistrySchema(schemaId: $schemaId) {
    createdAt
    name
    namespace
    id
    ...SchemaRegistrySchemaSettings
  }
}
    ${SchemaRegistrySchemaSettingsFragmentDoc}`;

export function useGetSchemaRegistrySchemaQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSchemaRegistrySchemaQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSchemaRegistrySchemaQuery,
    GQTypes.GQGetSchemaRegistrySchemaQueryVariables
  >({ query: GetSchemaRegistrySchemaDocument, ...options });
}
export const EditDatasetPageDocument = gql`
    query EditDatasetPage($id: ID!) {
  dataset(id: $id) {
    id
    name
    explainer
    customUsedFor
    customPotentialOverlaps
    canonicalDataset {
      id
      name
    }
    datasources {
      id
      ...DatasourceForDatasetPage
    }
    instructions {
      ...InstructionsBundleBaseFields
    }
    customExampleData {
      cells
    }
  }
}
    ${DatasourceForDatasetPageFragmentDoc}
${InstructionsBundleBaseFieldsFragmentDoc}`;

export function useEditDatasetPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQEditDatasetPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQEditDatasetPageQuery,
    GQTypes.GQEditDatasetPageQueryVariables
  >({ query: EditDatasetPageDocument, ...options });
}
export const DeleteDatasourceAdminDocument = gql`
    mutation DeleteDatasourceAdmin($input: DeleteDatasourceInput!) {
  deleteDatasourceAdmin(input: $input) {
    id
    dataset {
      id
      datasources {
        id
      }
    }
  }
}
    `;

export function useDeleteDatasourceAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteDatasourceAdminMutation,
    GQTypes.GQDeleteDatasourceAdminMutationVariables
  >(DeleteDatasourceAdminDocument);
}
export const OrganizationDatasetsPageDocument = gql`
    query OrganizationDatasetsPage($id: ID!) {
  organization(id: $id) {
    id
    name
    measurementProjects {
      ...MeasurementProjectFieldsForOrgPage
    }
    datasets {
      ...OrganizationDatasetsPageDataset
    }
  }
  allowEditingMeasurementProjectDates: featureFlagEnabledForOrg(
    orgId: $id
    flag: MeasurementProjectDateEditingInAdmin
  )
}
    ${MeasurementProjectFieldsForOrgPageFragmentDoc}
${OrganizationDatasetsPageDatasetFragmentDoc}`;

export function useOrganizationDatasetsPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationDatasetsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationDatasetsPageQuery,
    GQTypes.GQOrganizationDatasetsPageQueryVariables
  >({ query: OrganizationDatasetsPageDocument, ...options });
}
export const OrganizationFootprintsPageDocument = gql`
    query OrganizationFootprintsPage($orgId: ID!) {
  organization(id: $orgId) {
    id
    name
    bigqueryTableSchema
    datasets {
      ...DatasetFieldsForOrgPage
    }
    measurementProjects {
      ...MeasurementProjectForFootprintPage
    }
    hasAnyPv2PipelineResults
  }
  activityDataTables(orgId: $orgId) {
    edges {
      node {
        ...ActivityDataTableFields
      }
    }
  }
}
    ${DatasetFieldsForOrgPageFragmentDoc}
${MeasurementProjectForFootprintPageFragmentDoc}
${ActivityDataTableFieldsFragmentDoc}`;

export function useOrganizationFootprintsPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationFootprintsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationFootprintsPageQuery,
    GQTypes.GQOrganizationFootprintsPageQueryVariables
  >({ query: OrganizationFootprintsPageDocument, ...options });
}
export const GetPipelineResultsForOrgDocument = gql`
    query GetPipelineResultsForOrg($orgId: ID!) {
  pipelineResults(orgId: $orgId) {
    edges {
      node {
        ...PipelineResultForOrg
      }
    }
  }
}
    ${PipelineResultForOrgFragmentDoc}`;

export function useGetPipelineResultsForOrgQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPipelineResultsForOrgQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPipelineResultsForOrgQuery,
    GQTypes.GQGetPipelineResultsForOrgQueryVariables
  >({ query: GetPipelineResultsForOrgDocument, ...options });
}
export const PipelineDialogContentsDocument = gql`
    query PipelineDialogContents($orgId: ID!, $after: String, $before: String, $first: Int, $last: Int) {
  pipelineResults(
    orgId: $orgId
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        ...PipelineResultForOrg
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${PipelineResultForOrgFragmentDoc}`;

export function usePipelineDialogContentsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQPipelineDialogContentsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQPipelineDialogContentsQuery,
    GQTypes.GQPipelineDialogContentsQueryVariables
  >({ query: PipelineDialogContentsDocument, ...options });
}
export const OrganizationDataIssuesPageDocument = gql`
    query OrganizationDataIssuesPage($orgId: ID!) {
  organization(id: $orgId) {
    id
    measurementProjects {
      ...MeasurementProjectForEditDataIssuesPage
    }
  }
}
    ${MeasurementProjectForEditDataIssuesPageFragmentDoc}`;

export function useOrganizationDataIssuesPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationDataIssuesPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationDataIssuesPageQuery,
    GQTypes.GQOrganizationDataIssuesPageQueryVariables
  >({ query: OrganizationDataIssuesPageDocument, ...options });
}
export const OrganizationMappingsDocument = gql`
    query OrganizationMappings($orgId: ID!, $startYearMonth: YearMonth, $endYearMonth: YearMonth) {
  organization(id: $orgId) {
    id
    name
    measurementProjects {
      id
      name
      coverageInterval
      completedAt
      supportedValueMappingRules
    }
    demoOrg
  }
  organizations {
    edges {
      node {
        id
        name
        demoOrg
        testOrg
      }
    }
  }
  globalMappingsUrl
  defaultMappingsUrl
  legacyMappingsCodesUrl
  measurementMappingOutputOptions {
    edges {
      node {
        id
        inputEmissionsSpecifier
        outputEmissionsSpecifier
        units
        description
        efKgCo2eDescription
        efKgCo2eAverage
        updatedAt
      }
    }
  }
  cadtRowsToMapForOrg(
    orgId: $orgId
    startYearMonth: $startYearMonth
    endYearMonth: $endYearMonth
  ) {
    cadtRowsToMap {
      edges {
        node {
          ...CadtRowsToMapFields
        }
      }
    }
  }
  measurementMappings(orgId: $orgId) {
    edges {
      node {
        id
        ...MeasurementMappingFieldsFragment
      }
    }
  }
  legacyAssumptionAsMeasurementMappings(orgId: $orgId) {
    edges {
      node {
        id
        ...MeasurementMappingFieldsFragment
      }
    }
  }
  mappingCategoryIds {
    ghgCategoryId {
      id
      name
      description
    }
    categoryId {
      id
      name
      description
    }
    subcategoryId {
      id
      name
      description
    }
  }
  activityDataTables(orgId: $orgId) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${CadtRowsToMapFieldsFragmentDoc}
${MeasurementMappingFieldsFragmentFragmentDoc}`;

export function useOrganizationMappingsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationMappingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationMappingsQuery,
    GQTypes.GQOrganizationMappingsQueryVariables
  >({ query: OrganizationMappingsDocument, ...options });
}
export const OrganizationFinancialsReviewItemsDocument = gql`
    query OrganizationFinancialsReviewItems($orgId: ID!) {
  financialsReviewItemsAdmin(orgId: $orgId) {
    edges {
      node {
        ...FinancialsReviewItemAdminDialog
      }
    }
  }
}
    ${FinancialsReviewItemAdminDialogFragmentDoc}`;

export function useOrganizationFinancialsReviewItemsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationFinancialsReviewItemsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationFinancialsReviewItemsQuery,
    GQTypes.GQOrganizationFinancialsReviewItemsQueryVariables
  >({ query: OrganizationFinancialsReviewItemsDocument, ...options });
}
export const SyncMeasurementMappingsDocument = gql`
    mutation SyncMeasurementMappings($input: SyncMeasurementMappingsInput!) {
  syncMeasurementMappings(input: $input) {
    mappings {
      id
      ...MeasurementMappingFieldsFragment
    }
    jobId
  }
}
    ${MeasurementMappingFieldsFragmentFragmentDoc}`;

export function useSyncMeasurementMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQSyncMeasurementMappingsMutation,
    GQTypes.GQSyncMeasurementMappingsMutationVariables
  >(SyncMeasurementMappingsDocument);
}
export const OrganizationPlanCategoriesDocument = gql`
    query OrganizationPlanCategories($orgId: ID!, $planId: ID!) {
  organization(id: $orgId) {
    id
    name
  }
  plan(id: $planId) {
    id
    name
  }
}
    `;

export function useOrganizationPlanCategoriesQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationPlanCategoriesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationPlanCategoriesQuery,
    GQTypes.GQOrganizationPlanCategoriesQueryVariables
  >({ query: OrganizationPlanCategoriesDocument, ...options });
}
export const OrganizationPlansDocument = gql`
    query OrganizationPlans($id: ID!) {
  organization(id: $id) {
    id
    name
    fiscalYearStartMonth
  }
  plansForOrg(id: $id) {
    ...PlanForPlanPage
  }
}
    ${PlanForPlanPageFragmentDoc}`;

export function useOrganizationPlansQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationPlansQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationPlansQuery,
    GQTypes.GQOrganizationPlansQueryVariables
  >({ query: OrganizationPlansDocument, ...options });
}
export const GetForecastWithFootprintSnapshotIdDocument = gql`
    query GetForecastWithFootprintSnapshotId($orgId: ID!) {
  forecastAdmin(orgId: $orgId) {
    id
    footprintSnapshotId
    footprintInterval
    footprintKinds
  }
}
    `;

export function useGetForecastWithFootprintSnapshotIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetForecastWithFootprintSnapshotIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetForecastWithFootprintSnapshotIdQuery,
    GQTypes.GQGetForecastWithFootprintSnapshotIdQueryVariables
  >({ query: GetForecastWithFootprintSnapshotIdDocument, ...options });
}
export const GetForecastWithReferenceIntervalDocument = gql`
    query GetForecastWithReferenceInterval($orgId: ID!) {
  forecastAdmin(orgId: $orgId) {
    id
    interval
    footprintSnapshotId
    footprintInterval
    footprintKinds
    referencePeriodInterval
    validReferenceInterval
    overrideFootprintKind
  }
}
    `;

export function useGetForecastWithReferenceIntervalQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetForecastWithReferenceIntervalQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetForecastWithReferenceIntervalQuery,
    GQTypes.GQGetForecastWithReferenceIntervalQueryVariables
  >({ query: GetForecastWithReferenceIntervalDocument, ...options });
}
export const DeletePlanAdminDocument = gql`
    mutation DeletePlanAdmin($id: ID!) {
  deletePlanAdmin(id: $id) {
    plans {
      ...PlanForPlanPage
    }
  }
}
    ${PlanForPlanPageFragmentDoc}`;

export function useDeletePlanAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQDeletePlanAdminMutation,
    GQTypes.GQDeletePlanAdminMutationVariables
  >(DeletePlanAdminDocument);
}
export const DuplicatePlanAdminDocument = gql`
    mutation DuplicatePlanAdmin($id: ID!) {
  duplicatePlanAdmin(id: $id) {
    ...PlanForPlanPage
  }
}
    ${PlanForPlanPageFragmentDoc}`;

export function useDuplicatePlanAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQDuplicatePlanAdminMutation,
    GQTypes.GQDuplicatePlanAdminMutationVariables
  >(DuplicatePlanAdminDocument);
}
export const DeleteAllReductionsDataDocument = gql`
    mutation DeleteAllReductionsData($input: DeleteAllReductionsDataInput!) {
  deleteAllReductionsData(input: $input) {
    message
  }
}
    `;

export function useDeleteAllReductionsDataMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteAllReductionsDataMutation,
    GQTypes.GQDeleteAllReductionsDataMutationVariables
  >(DeleteAllReductionsDataDocument);
}
export const SetForecastFootprintSnapshotIdDocument = gql`
    mutation SetForecastFootprintSnapshotId($input: SetForecastFootprintSnapshotIdInput!) {
  setForecastFootprintSnapshotId(input: $input) {
    forecast {
      id
      name
      validReferenceInterval
      footprintSnapshotId
    }
  }
}
    `;

export function useSetForecastFootprintSnapshotIdMutation() {
  return Urql.useMutation<
    GQTypes.GQSetForecastFootprintSnapshotIdMutation,
    GQTypes.GQSetForecastFootprintSnapshotIdMutationVariables
  >(SetForecastFootprintSnapshotIdDocument);
}
export const AdminUpdateForecastDocument = gql`
    mutation AdminUpdateForecast($input: UpdateForecastInput!) @withOwner(owner: Reductions) {
  updateForecast(input: $input) {
    forecast {
      id
      interval
      footprintSnapshotId
      footprintInterval
      footprintKinds
      referencePeriodInterval
      validReferenceInterval
      overrideFootprintKind
    }
  }
}
    `;

export function useAdminUpdateForecastMutation() {
  return Urql.useMutation<
    GQTypes.GQAdminUpdateForecastMutation,
    GQTypes.GQAdminUpdateForecastMutationVariables
  >(AdminUpdateForecastDocument);
}
export const OrganizationReportsPageDocument = gql`
    query OrganizationReportsPage($id: ID!) {
  organization(id: $id) {
    id
    name
  }
  formalReportsAdmin(orgId: $id) {
    edges {
      node {
        id
        ...FormalReportFieldsAdmin
      }
    }
  }
}
    ${FormalReportFieldsAdminFragmentDoc}`;

export function useOrganizationReportsPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrganizationReportsPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrganizationReportsPageQuery,
    GQTypes.GQOrganizationReportsPageQueryVariables
  >({ query: OrganizationReportsPageDocument, ...options });
}
export const UpdateReportDocument = gql`
    mutation UpdateReport($input: UpdateReportInput!) {
  updateReport(input: $input) {
    report {
      id
      ...FormalReportFieldsAdmin
    }
  }
}
    ${FormalReportFieldsAdminFragmentDoc}`;

export function useUpdateReportMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateReportMutation,
    GQTypes.GQUpdateReportMutationVariables
  >(UpdateReportDocument);
}
export const AdminArchiveReportDocument = gql`
    mutation AdminArchiveReport($reportId: ID!) {
  archiveReport(input: {reportId: $reportId}) {
    report {
      id
      deletedAt
    }
  }
}
    `;

export function useAdminArchiveReportMutation() {
  return Urql.useMutation<
    GQTypes.GQAdminArchiveReportMutation,
    GQTypes.GQAdminArchiveReportMutationVariables
  >(AdminArchiveReportDocument);
}
export const RestoreReportDocument = gql`
    mutation RestoreReport($reportId: ID!) {
  restoreReport(input: {reportId: $reportId}) {
    report {
      id
      ...FormalReportFieldsAdmin
    }
  }
}
    ${FormalReportFieldsAdminFragmentDoc}`;

export function useRestoreReportMutation() {
  return Urql.useMutation<
    GQTypes.GQRestoreReportMutation,
    GQTypes.GQRestoreReportMutationVariables
  >(RestoreReportDocument);
}
export const GetSupplyChainDataDocument = gql`
    query GetSupplyChainData($orgId: ID!) {
  engagementTasksAdmin(orgId: $orgId) {
    edges {
      node {
        ...EngagementTaskFieldsAdmin
      }
    }
  }
  suppliersSettingsAdmin(orgId: $orgId) {
    ...SuppliersSettingsFieldsAdmin
  }
  suppliersSettingsAdmin(orgId: $orgId) {
    ...SuppliersSettingsFieldsAdmin
  }
  organization(id: $orgId) {
    id
    name
  }
}
    ${EngagementTaskFieldsAdminFragmentDoc}
${SuppliersSettingsFieldsAdminFragmentDoc}`;

export function useGetSupplyChainDataQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSupplyChainDataQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSupplyChainDataQuery,
    GQTypes.GQGetSupplyChainDataQueryVariables
  >({ query: GetSupplyChainDataDocument, ...options });
}
export const UpdateSuppliersSettingsAdminDocument = gql`
    mutation UpdateSuppliersSettingsAdmin($input: UpdateSuppliersSettingsAdminInput!) {
  updateSuppliersSettingsAdmin(input: $input) {
    suppliersSettings {
      ...SuppliersSettingsFieldsAdmin
    }
  }
}
    ${SuppliersSettingsFieldsAdminFragmentDoc}`;

export function useUpdateSuppliersSettingsAdminMutation() {
  return Urql.useMutation<
    GQTypes.GQUpdateSuppliersSettingsAdminMutation,
    GQTypes.GQUpdateSuppliersSettingsAdminMutationVariables
  >(UpdateSuppliersSettingsAdminDocument);
}
export const UserUploadTaskPageDocument = gql`
    query UserUploadTaskPage($orgId: ID!, $userUploadTaskId: ID!) {
  organization(id: $orgId) {
    id
    name
  }
  userUploadTask(id: $userUploadTaskId) {
    id
    state
    datasource {
      id
      name
      dataset {
        id
        explainer
        canonicalDataset {
          id
          name
        }
      }
    }
    ...UserUploadTaskUploadsFields
  }
}
    ${UserUploadTaskUploadsFieldsFragmentDoc}`;

export function useUserUploadTaskPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUserUploadTaskPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUserUploadTaskPageQuery,
    GQTypes.GQUserUploadTaskPageQueryVariables
  >({ query: UserUploadTaskPageDocument, ...options });
}
export const GetSignedParquetUrlDocument = gql`
    query GetSignedParquetUrl($id: ID!) {
  signedParquetUrl(id: $id)
}
    `;

export function useGetSignedParquetUrlQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSignedParquetUrlQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSignedParquetUrlQuery,
    GQTypes.GQGetSignedParquetUrlQueryVariables
  >({ query: GetSignedParquetUrlDocument, ...options });
}
export const ReferenceDataRevisionDocument = gql`
    query ReferenceDataRevision($id: ID!) {
  referenceDataRevision(revisionId: $id) {
    id
    revisionName
  }
}
    `;

export function useReferenceDataRevisionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataRevisionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataRevisionQuery,
    GQTypes.GQReferenceDataRevisionQueryVariables
  >({ query: ReferenceDataRevisionDocument, ...options });
}
export const CompositeDataSourceDocument = gql`
    query CompositeDataSource($compositeDataSourceId: ID!, $excludeArchived: Boolean, $includeDrafts: Boolean) {
  referenceDataSource(referenceDataSourceId: $compositeDataSourceId) {
    ...ReferenceDataSourceSchema
    referenceDataVersions(
      excludeArchived: $excludeArchived
      includeDrafts: $includeDrafts
    ) {
      ...ReferenceDataVersionSchema
      compositeDataOrgDetails {
        ...CompositeDataOrgDetailSchema
      }
    }
  }
}
    ${ReferenceDataSourceSchemaFragmentDoc}
${ReferenceDataVersionSchemaFragmentDoc}
${CompositeDataOrgDetailSchemaFragmentDoc}`;

export function useCompositeDataSourceQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQCompositeDataSourceQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQCompositeDataSourceQuery,
    GQTypes.GQCompositeDataSourceQueryVariables
  >({ query: CompositeDataSourceDocument, ...options });
}
export const ReferenceDataSourceForCreateOverlayDocument = gql`
    query ReferenceDataSourceForCreateOverlay($referenceDataSourceId: ID!) {
  referenceDataSource(referenceDataSourceId: $referenceDataSourceId) {
    id
    name
  }
}
    `;

export function useReferenceDataSourceForCreateOverlayQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataSourceForCreateOverlayQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataSourceForCreateOverlayQuery,
    GQTypes.GQReferenceDataSourceForCreateOverlayQueryVariables
  >({ query: ReferenceDataSourceForCreateOverlayDocument, ...options });
}
export const ReferenceDataOverlayPreviewDocument = gql`
    query ReferenceDataOverlayPreview($overlayId: ID!) {
  referenceDataOverlay(overlayId: $overlayId) {
    id
    globalCdrId
    signedMergedDataUrl
    orgId
    source {
      id
      name
    }
  }
}
    `;

export function useReferenceDataOverlayPreviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataOverlayPreviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataOverlayPreviewQuery,
    GQTypes.GQReferenceDataOverlayPreviewQueryVariables
  >({ query: ReferenceDataOverlayPreviewDocument, ...options });
}
export const ReferenceDataSourceDocument = gql`
    query ReferenceDataSource($referenceDataSourceId: ID!, $excludeArchived: Boolean, $includeDrafts: Boolean, $filterOrgId: ID) {
  referenceDataSource(referenceDataSourceId: $referenceDataSourceId) {
    ...ReferenceDataSourceSchema
    orgsWithRevisions(includeDrafts: $includeDrafts) {
      id
      name
    }
    referenceDataVersions(
      excludeArchived: $excludeArchived
      includeDrafts: $includeDrafts
    ) {
      ...ReferenceDataVersionSchema
      latestPublishedGlobalRevision: latestPublishedRevisionByVersionId(orgId: null) {
        ...ReferenceDataRevisionSchema
      }
      latestPublishedRevision: latestPublishedRevisionByVersionId(orgId: $filterOrgId) {
        ...ReferenceDataRevisionSchema
      }
    }
  }
}
    ${ReferenceDataSourceSchemaFragmentDoc}
${ReferenceDataVersionSchemaFragmentDoc}
${ReferenceDataRevisionSchemaFragmentDoc}`;

export function useReferenceDataSourceQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataSourceQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataSourceQuery,
    GQTypes.GQReferenceDataSourceQueryVariables
  >({ query: ReferenceDataSourceDocument, ...options });
}
export const ReferenceDataSourceAndVersionDocument = gql`
    query ReferenceDataSourceAndVersion($referenceDataSourceId: ID!, $referenceDataVersionId: ID!) {
  referenceDataSource(referenceDataSourceId: $referenceDataSourceId) {
    id
    name
    organization {
      id
    }
  }
  referenceDataVersion(referenceDataVersionId: $referenceDataVersionId) {
    id
    versionName
    state
    latestSchema {
      id
      schemaJson
    }
  }
}
    `;

export function useReferenceDataSourceAndVersionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataSourceAndVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataSourceAndVersionQuery,
    GQTypes.GQReferenceDataSourceAndVersionQueryVariables
  >({ query: ReferenceDataSourceAndVersionDocument, ...options });
}
export const ReferenceDataSourceFindPageDocument = gql`
    query ReferenceDataSourceFindPage($query: String!) {
  findReferenceDataSource(query: $query) {
    id
  }
}
    `;

export function useReferenceDataSourceFindPageQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceDataSourceFindPageQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceDataSourceFindPageQuery,
    GQTypes.GQReferenceDataSourceFindPageQueryVariables
  >({ query: ReferenceDataSourceFindPageDocument, ...options });
}
export const GetReportConfigQuestionsForAdminDocument = gql`
    query GetReportConfigQuestionsForAdmin($reportConfigId: ID!) {
  reportConfigQuestions(reportConfigId: $reportConfigId) {
    id
    identifier
  }
}
    `;

export function useGetReportConfigQuestionsForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportConfigQuestionsForAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportConfigQuestionsForAdminQuery,
    GQTypes.GQGetReportConfigQuestionsForAdminQueryVariables
  >({ query: GetReportConfigQuestionsForAdminDocument, ...options });
}
export const DeleteReportAnswersDocument = gql`
    mutation DeleteReportAnswers($ids: [ID!]!) {
  deleteReportAnswers(ids: $ids) {
    deleteCount
  }
}
    `;

export function useDeleteReportAnswersMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportAnswersMutation,
    GQTypes.GQDeleteReportAnswersMutationVariables
  >(DeleteReportAnswersDocument);
}
export const VerifyReportAnswersDocument = gql`
    mutation VerifyReportAnswers($reportAnswerIds: [ID!]!) {
  verifyReportAnswers(reportAnswerIds: $reportAnswerIds) {
    successCount
    failureCount
  }
}
    `;

export function useVerifyReportAnswersMutation() {
  return Urql.useMutation<
    GQTypes.GQVerifyReportAnswersMutation,
    GQTypes.GQVerifyReportAnswersMutationVariables
  >(VerifyReportAnswersDocument);
}
export const GetCdpIdMappingsDocument = gql`
    query GetCdpIdMappings($orgId: ID!, $reportId: ID!, $hasOrgAndReportId: Boolean!) {
  cdpApiRegistrations {
    id
    orgId
  }
  cdpIdMappings {
    ...CdpIdMappingsFields
  }
  cdpMissingIdMappings(orgId: $orgId, reportId: $reportId) @include(if: $hasOrgAndReportId) {
    candidateQuestionMappings {
      ...CandidateQuestionMappingFields
    }
    candidateColumnMappings {
      ...CandidateColumnMappingFields
    }
    unmappedWatershedQuestions {
      ...UnmappedWatershedQuestionFields
    }
  }
}
    ${CdpIdMappingsFieldsFragmentDoc}
${CandidateQuestionMappingFieldsFragmentDoc}
${CandidateColumnMappingFieldsFragmentDoc}
${UnmappedWatershedQuestionFieldsFragmentDoc}`;

export function useGetCdpIdMappingsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetCdpIdMappingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetCdpIdMappingsQuery,
    GQTypes.GQGetCdpIdMappingsQueryVariables
  >({ query: GetCdpIdMappingsDocument, ...options });
}
export const CreateCdpQuestionMappingsDocument = gql`
    mutation CreateCdpQuestionMappings($input: CreateCdpQuestionMappingsInput!) {
  createCdpQuestionMappings(input: $input) {
    mappings {
      ...CdpIdMappingsFields
    }
  }
}
    ${CdpIdMappingsFieldsFragmentDoc}`;

export function useCreateCdpQuestionMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCdpQuestionMappingsMutation,
    GQTypes.GQCreateCdpQuestionMappingsMutationVariables
  >(CreateCdpQuestionMappingsDocument);
}
export const DeleteCdpQuestionMappingsDocument = gql`
    mutation DeleteCdpQuestionMappings($input: DeleteCdpQuestionMappingsInput!) {
  deleteCdpQuestionMappings(input: $input) {
    mappings {
      ...CdpIdMappingsFields
    }
  }
}
    ${CdpIdMappingsFieldsFragmentDoc}`;

export function useDeleteCdpQuestionMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCdpQuestionMappingsMutation,
    GQTypes.GQDeleteCdpQuestionMappingsMutationVariables
  >(DeleteCdpQuestionMappingsDocument);
}
export const UpsertCdpQuestionMappingsDocument = gql`
    mutation UpsertCdpQuestionMappings($input: UpsertCdpQuestionMappingsInput!) {
  upsertCdpQuestionMappings(input: $input) {
    updatedMappings {
      ...CdpQuestionIdMapping
    }
  }
}
    ${CdpQuestionIdMappingFragmentDoc}`;

export function useUpsertCdpQuestionMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertCdpQuestionMappingsMutation,
    GQTypes.GQUpsertCdpQuestionMappingsMutationVariables
  >(UpsertCdpQuestionMappingsDocument);
}
export const CreateCdpColumnMappingsDocument = gql`
    mutation CreateCdpColumnMappings($input: CreateCdpColumnMappingsInput!) {
  createCdpColumnMappings(input: $input) {
    mappings {
      ...CdpIdMappingsFields
    }
  }
}
    ${CdpIdMappingsFieldsFragmentDoc}`;

export function useCreateCdpColumnMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateCdpColumnMappingsMutation,
    GQTypes.GQCreateCdpColumnMappingsMutationVariables
  >(CreateCdpColumnMappingsDocument);
}
export const DeleteCdpColumnMappingsDocument = gql`
    mutation DeleteCdpColumnMappings($input: DeleteCdpColumnMappingsInput!) {
  deleteCdpColumnMappings(input: $input) {
    mappings {
      ...CdpIdMappingsFields
    }
  }
}
    ${CdpIdMappingsFieldsFragmentDoc}`;

export function useDeleteCdpColumnMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteCdpColumnMappingsMutation,
    GQTypes.GQDeleteCdpColumnMappingsMutationVariables
  >(DeleteCdpColumnMappingsDocument);
}
export const UpsertCdpColumnMappingsDocument = gql`
    mutation UpsertCdpColumnMappings($input: UpsertCdpColumnMappingsInput!) {
  upsertCdpColumnMappings(input: $input) {
    updatedMappings {
      ...CdpColumnIdMapping
    }
  }
}
    ${CdpColumnIdMappingFragmentDoc}`;

export function useUpsertCdpColumnMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertCdpColumnMappingsMutation,
    GQTypes.GQUpsertCdpColumnMappingsMutationVariables
  >(UpsertCdpColumnMappingsDocument);
}
export const ReplaceReportingCdpMappingsDocument = gql`
    mutation ReplaceReportingCdpMappings($input: ReplaceReportingCdpMappingsInput!) {
  replaceReportingCdpMappings(input: $input) {
    success
    mappings {
      ...CdpIdMappingsFields
    }
  }
}
    ${CdpIdMappingsFieldsFragmentDoc}`;

export function useReplaceReportingCdpMappingsMutation() {
  return Urql.useMutation<
    GQTypes.GQReplaceReportingCdpMappingsMutation,
    GQTypes.GQReplaceReportingCdpMappingsMutationVariables
  >(ReplaceReportingCdpMappingsDocument);
}
export const GetReportsForCdpIdMappingsDocument = gql`
    query GetReportsForCdpIdMappings($orgId: ID!) {
  reports(kind: "CDP", orgId: $orgId) {
    edges {
      node {
        id
        name
        updatedAt
        config {
          id
          shortName
        }
      }
    }
  }
}
    `;

export function useGetReportsForCdpIdMappingsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportsForCdpIdMappingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportsForCdpIdMappingsQuery,
    GQTypes.GQGetReportsForCdpIdMappingsQueryVariables
  >({ query: GetReportsForCdpIdMappingsDocument, ...options });
}
export const ReportObjectConfigPathDocument = gql`
    query ReportObjectConfigPath($input: GetReportObjectConfigPathInput!) {
  getReportObjectConfigPath(input: $input) {
    path {
      id
      label
      isInput
      isComputed
    }
  }
}
    `;

export function useReportObjectConfigPathQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportObjectConfigPathQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportObjectConfigPathQuery,
    GQTypes.GQReportObjectConfigPathQueryVariables
  >({ query: ReportObjectConfigPathDocument, ...options });
}
export const GetReportQuestionWithAllItemsForAdminDocument = gql`
    query GetReportQuestionWithAllItemsForAdmin($id: ID!) {
  reportQuestion(id: $id) {
    id
    identifier
    reportConfig {
      id
      reportType
      itemIds
      allContainers {
        id
        label
        itemIds
      }
      allQuestions {
        id
        identifier
        componentId
        isComputed
      }
    }
  }
}
    `;

export function useGetReportQuestionWithAllItemsForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionWithAllItemsForAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionWithAllItemsForAdminQuery,
    GQTypes.GQGetReportQuestionWithAllItemsForAdminQueryVariables
  >({ query: GetReportQuestionWithAllItemsForAdminDocument, ...options });
}
export const GetReportQuestionContainerWithAllItemsForAdminDocument = gql`
    query GetReportQuestionContainerWithAllItemsForAdmin($id: ID!) {
  reportQuestionContainer(id: $id) {
    id
    label
    reportConfig {
      id
      reportType
      itemIds
      allContainers {
        id
        label
        itemIds
      }
      allQuestions {
        id
        identifier
        componentId
        isComputed
      }
    }
  }
}
    `;

export function useGetReportQuestionContainerWithAllItemsForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionContainerWithAllItemsForAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionContainerWithAllItemsForAdminQuery,
    GQTypes.GQGetReportQuestionContainerWithAllItemsForAdminQueryVariables
  >({
    query: GetReportQuestionContainerWithAllItemsForAdminDocument,
    ...options,
  });
}
export const AllReportConfigsForAdminTableDocument = gql`
    query AllReportConfigsForAdminTable {
  reportConfigs {
    edges {
      node {
        ...ReportConfigFieldsForAdminTable
      }
    }
  }
}
    ${ReportConfigFieldsForAdminTableFragmentDoc}`;

export function useAllReportConfigsForAdminTableQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQAllReportConfigsForAdminTableQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAllReportConfigsForAdminTableQuery,
    GQTypes.GQAllReportConfigsForAdminTableQueryVariables
  >({ query: AllReportConfigsForAdminTableDocument, ...options });
}
export const DumpReportConfigToSrcDocument = gql`
    query DumpReportConfigToSrc($id: ID!) {
  dumpReportConfigToSrc(id: $id) {
    src
    lintStatus
  }
}
    `;

export function useDumpReportConfigToSrcQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQDumpReportConfigToSrcQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQDumpReportConfigToSrcQuery,
    GQTypes.GQDumpReportConfigToSrcQueryVariables
  >({ query: DumpReportConfigToSrcDocument, ...options });
}
export const DeleteReportConfigFromAdminTableDocument = gql`
    mutation DeleteReportConfigFromAdminTable($input: DeleteReportConfigInput!) {
  deleteReportConfig(input: $input) {
    reportConfig {
      ...ReportConfigFieldsForAdminTable
    }
  }
}
    ${ReportConfigFieldsForAdminTableFragmentDoc}`;

export function useDeleteReportConfigFromAdminTableMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportConfigFromAdminTableMutation,
    GQTypes.GQDeleteReportConfigFromAdminTableMutationVariables
  >(DeleteReportConfigFromAdminTableDocument);
}
export const ReportHealthChecksDocument = gql`
    query ReportHealthChecks($orgId: ID!, $footprintSnapshotId: ID!, $interval: YMInterval) {
  reportingFootprintHealthChecks(
    input: {orgId: $orgId, footprintSnapshotId: $footprintSnapshotId, interval: $interval}
  ) {
    footprintSnapshotId
    interval
    failures {
      validatorId
      outputJson
    }
  }
}
    `;

export function useReportHealthChecksQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportHealthChecksQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportHealthChecksQuery,
    GQTypes.GQReportHealthChecksQueryVariables
  >({ query: ReportHealthChecksDocument, ...options });
}
export const GetReportQuestionIdentifierMappingDocument = gql`
    query GetReportQuestionIdentifierMapping($reportQuestionIdentifierId: ID!) {
  reportQuestionIdentifierMapping(
    reportQuestionIdentifierId: $reportQuestionIdentifierId
  ) {
    id
    relatedQuestionIdentifiers {
      ...ReportQuestionIdentifierFields
    }
  }
}
    ${ReportQuestionIdentifierFieldsFragmentDoc}`;

export function useGetReportQuestionIdentifierMappingQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportQuestionIdentifierMappingQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportQuestionIdentifierMappingQuery,
    GQTypes.GQGetReportQuestionIdentifierMappingQueryVariables
  >({ query: GetReportQuestionIdentifierMappingDocument, ...options });
}
export const CreateReportQuestionIdentifierMappingDocument = gql`
    mutation CreateReportQuestionIdentifierMapping($identifierId1: ID!, $identifierId2: ID!) {
  createReportQuestionIdentifierMapping(
    identifierId1: $identifierId1
    identifierId2: $identifierId2
  ) {
    reportQuestionIdentifierMapping1 {
      id
      relatedQuestionIdentifiers {
        ...ReportQuestionIdentifierFields
      }
    }
    reportQuestionIdentifierMapping2 {
      id
      relatedQuestionIdentifiers {
        ...ReportQuestionIdentifierFields
      }
    }
  }
}
    ${ReportQuestionIdentifierFieldsFragmentDoc}`;

export function useCreateReportQuestionIdentifierMappingMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateReportQuestionIdentifierMappingMutation,
    GQTypes.GQCreateReportQuestionIdentifierMappingMutationVariables
  >(CreateReportQuestionIdentifierMappingDocument);
}
export const DeleteReportQuestionIdentifierMappingDocument = gql`
    mutation DeleteReportQuestionIdentifierMapping($identifierId1: ID!, $identifierId2: ID!) {
  deleteReportQuestionIdentifierMapping(
    identifierId1: $identifierId1
    identifierId2: $identifierId2
  ) {
    reportQuestionIdentifierMapping1 {
      id
      relatedQuestionIdentifiers {
        ...ReportQuestionIdentifierFields
      }
    }
    reportQuestionIdentifierMapping2 {
      id
      relatedQuestionIdentifiers {
        ...ReportQuestionIdentifierFields
      }
    }
  }
}
    ${ReportQuestionIdentifierFieldsFragmentDoc}`;

export function useDeleteReportQuestionIdentifierMappingMutation() {
  return Urql.useMutation<
    GQTypes.GQDeleteReportQuestionIdentifierMappingMutation,
    GQTypes.GQDeleteReportQuestionIdentifierMappingMutationVariables
  >(DeleteReportQuestionIdentifierMappingDocument);
}
export const ReportAnswerVerifierFailuresDocument = gql`
    query ReportAnswerVerifierFailures($reportAnswerFilters: ReportAnswerFilterInput!, $before: String, $after: String, $first: Int, $last: Int) {
  reportAnswerVerifierFailures(
    reportAnswerFilters: $reportAnswerFilters
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        organization {
          id
          name
        }
        reportAnswerUpdatedAt
        mismatchTime
        outputJson
        errorJson
        mismatchApprovalTime
        reportAnswer {
          id
          outputJson
          updatedAt
          question {
            id
            identifier
            componentId
          }
          report {
            id
            name
            config {
              id
              reportType
            }
            createdAt
          }
        }
      }
    }
  }
}
    `;

export function useReportAnswerVerifierFailuresQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReportAnswerVerifierFailuresQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReportAnswerVerifierFailuresQuery,
    GQTypes.GQReportAnswerVerifierFailuresQueryVariables
  >({ query: ReportAnswerVerifierFailuresDocument, ...options });
}
export const GetReportAnswerVerifierOptionsDocument = gql`
    query GetReportAnswerVerifierOptions {
  reportAnswerVerifierOptions {
    organizations {
      id
      name
    }
    componentIds
    reportTypes
  }
}
    `;

export function useGetReportAnswerVerifierOptionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetReportAnswerVerifierOptionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetReportAnswerVerifierOptionsQuery,
    GQTypes.GQGetReportAnswerVerifierOptionsQueryVariables
  >({ query: GetReportAnswerVerifierOptionsDocument, ...options });
}
export const ApproveReportAnswerVerifiersDocument = gql`
    mutation ApproveReportAnswerVerifiers($input: ApproveReportAnswerVerifiersInput!) {
  approveReportAnswerVerifiers(input: $input) {
    updatedAnswers {
      id
      updatedAt
      outputJson
    }
    errorMessages {
      errorMessage
      count
    }
  }
}
    `;

export function useApproveReportAnswerVerifiersMutation() {
  return Urql.useMutation<
    GQTypes.GQApproveReportAnswerVerifiersMutation,
    GQTypes.GQApproveReportAnswerVerifiersMutationVariables
  >(ApproveReportAnswerVerifiersDocument);
}
export const AcceptReportAnswerVerifierFailuresDocument = gql`
    mutation AcceptReportAnswerVerifierFailures($input: AcceptReportAnswerVerifierFailuresInput!) {
  acceptReportAnswerVerifierFailures(input: $input) {
    updatedVerifierFailures {
      id
      mismatchApprovalTime
    }
  }
}
    `;

export function useAcceptReportAnswerVerifierFailuresMutation() {
  return Urql.useMutation<
    GQTypes.GQAcceptReportAnswerVerifierFailuresMutation,
    GQTypes.GQAcceptReportAnswerVerifierFailuresMutationVariables
  >(AcceptReportAnswerVerifierFailuresDocument);
}
export const ClearReportAnswerVerifiersDocument = gql`
    mutation ClearReportAnswerVerifiers($input: ClearReportAnswerVerifierFailuresInput!) {
  clearReportAnswerVerifierFailures(input: $input) {
    num
  }
}
    `;

export function useClearReportAnswerVerifiersMutation() {
  return Urql.useMutation<
    GQTypes.GQClearReportAnswerVerifiersMutation,
    GQTypes.GQClearReportAnswerVerifiersMutationVariables
  >(ClearReportAnswerVerifiersDocument);
}
export const RunReportAnswerVerifierDocument = gql`
    mutation RunReportAnswerVerifier($input: RunReportAnswerVerifierInput!) {
  runReportAnswerVerifier(input: $input) {
    reportAnswerId
  }
}
    `;

export function useRunReportAnswerVerifierMutation() {
  return Urql.useMutation<
    GQTypes.GQRunReportAnswerVerifierMutation,
    GQTypes.GQRunReportAnswerVerifierMutationVariables
  >(RunReportAnswerVerifierDocument);
}
export const GetExtractorRunDocument = gql`
    query GetExtractorRun($id: ID!) {
  extractorRun(id: $id) {
    ...QueryRunFields
    activityDataExtractor {
      ...ActivityDataExtractorFields
    }
  }
}
    ${QueryRunFieldsFragmentDoc}
${ActivityDataExtractorFieldsFragmentDoc}`;

export function useGetExtractorRunQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetExtractorRunQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetExtractorRunQuery,
    GQTypes.GQGetExtractorRunQueryVariables
  >({ query: GetExtractorRunDocument, ...options });
}
export const GetPlaygroundSqlRunDocument = gql`
    query GetPlaygroundSqlRun($id: ID!) {
  playgroundSqlRun(id: $id) {
    ...PlaygroundSqlRunForPlayground
  }
}
    ${PlaygroundSqlRunForPlaygroundFragmentDoc}`;

export function useGetPlaygroundSqlRunQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPlaygroundSqlRunQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPlaygroundSqlRunQuery,
    GQTypes.GQGetPlaygroundSqlRunQueryVariables
  >({ query: GetPlaygroundSqlRunDocument, ...options });
}
export const EnqueueBackgroundJobDocument = gql`
    mutation EnqueueBackgroundJob($kind: String!, $args: JSONString!, $orgId: String) {
  enqueueBackgroundJob(
    input: {kind: $kind, args: $args, orgId: $orgId, queueName: null}
  ) {
    job {
      id
    }
  }
}
    `;

export function useEnqueueBackgroundJobMutation() {
  return Urql.useMutation<
    GQTypes.GQEnqueueBackgroundJobMutation,
    GQTypes.GQEnqueueBackgroundJobMutationVariables
  >(EnqueueBackgroundJobDocument);
}
export const EnqueueBackgroundJobWithQueueNameDocument = gql`
    mutation EnqueueBackgroundJobWithQueueName($kind: String!, $args: JSONString!, $orgId: String, $queueName: BackgroundJobQueueName) {
  enqueueBackgroundJob(
    input: {kind: $kind, args: $args, orgId: $orgId, queueName: $queueName}
  ) {
    job {
      id
    }
  }
}
    `;

export function useEnqueueBackgroundJobWithQueueNameMutation() {
  return Urql.useMutation<
    GQTypes.GQEnqueueBackgroundJobWithQueueNameMutation,
    GQTypes.GQEnqueueBackgroundJobWithQueueNameMutationVariables
  >(EnqueueBackgroundJobWithQueueNameDocument);
}
export const MarketplaceInternationalEacProjectsDocument = gql`
    query MarketplaceInternationalEacProjects {
  marketplaceInternationalEacProjects {
    id
    name
  }
}
    `;

export function useMarketplaceInternationalEacProjectsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQMarketplaceInternationalEacProjectsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQMarketplaceInternationalEacProjectsQuery,
    GQTypes.GQMarketplaceInternationalEacProjectsQueryVariables
  >({ query: MarketplaceInternationalEacProjectsDocument, ...options });
}
export const AdtsForMagicSqlDocument = gql`
    query AdtsForMagicSql($orgId: ID!) {
  activityDataTables(orgId: $orgId) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useAdtsForMagicSqlQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAdtsForMagicSqlQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAdtsForMagicSqlQuery,
    GQTypes.GQAdtsForMagicSqlQueryVariables
  >({ query: AdtsForMagicSqlDocument, ...options });
}
export const FmdsForPlaygroundDocument = gql`
    query FmdsForPlayground($orgId: ID!) {
  files(orgId: $orgId) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useFmdsForPlaygroundQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFmdsForPlaygroundQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFmdsForPlaygroundQuery,
    GQTypes.GQFmdsForPlaygroundQueryVariables
  >({ query: FmdsForPlaygroundDocument, ...options });
}
export const FpssForPlaygroundDocument = gql`
    query FpssForPlayground($orgId: ID!) {
  footprintSnapshots(orgId: $orgId) {
    edges {
      node {
        id
      }
    }
  }
}
    `;

export function useFpssForPlaygroundQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFpssForPlaygroundQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFpssForPlaygroundQuery,
    GQTypes.GQFpssForPlaygroundQueryVariables
  >({ query: FpssForPlaygroundDocument, ...options });
}
export const UutsForPlaygroundDocument = gql`
    query UutsForPlayground($orgId: ID!) {
  userUploadedTables(orgId: $orgId) {
    edges {
      node {
        id
        sqlTableName
      }
    }
  }
}
    `;

export function useUutsForPlaygroundQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQUutsForPlaygroundQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQUutsForPlaygroundQuery,
    GQTypes.GQUutsForPlaygroundQueryVariables
  >({ query: UutsForPlaygroundDocument, ...options });
}
export const ReferenceOrgsDocument = gql`
    query ReferenceOrgs($ids: [ID!]!) {
  organizations(ids: $ids) {
    edges {
      node {
        __typename
        ...OrganizationListPageOrganization
      }
    }
  }
}
    ${OrganizationListPageOrganizationFragmentDoc}`;

export function useReferenceOrgsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReferenceOrgsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReferenceOrgsQuery,
    GQTypes.GQReferenceOrgsQueryVariables
  >({ query: ReferenceOrgsDocument, ...options });
}
export const CreateAdminUploadDocument = gql`
    mutation CreateAdminUpload($input: CreateAdminUploadInput!) {
  createAdminUpload(input: $input) {
    url
    fileId
    remotePath
  }
}
    `;

export function useCreateAdminUploadMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateAdminUploadMutation,
    GQTypes.GQCreateAdminUploadMutationVariables
  >(CreateAdminUploadDocument);
}
export const MarkAdminUploadCompletedDocument = gql`
    mutation MarkAdminUploadCompleted($input: MarkAdminUploadCompletedInput!) {
  markAdminUploadCompleted(input: $input) {
    file {
      id
    }
    parseJobId
  }
}
    `;

export function useMarkAdminUploadCompletedMutation() {
  return Urql.useMutation<
    GQTypes.GQMarkAdminUploadCompletedMutation,
    GQTypes.GQMarkAdminUploadCompletedMutationVariables
  >(MarkAdminUploadCompletedDocument);
}
export const FeatureFlagEnabledGloballyDocument = gql`
    query FeatureFlagEnabledGlobally($flag: Flags!) {
  isEnabled: featureFlagEnabledGlobally(flag: $flag)
}
    `;

export function useFeatureFlagEnabledGloballyQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQFeatureFlagEnabledGloballyQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQFeatureFlagEnabledGloballyQuery,
    GQTypes.GQFeatureFlagEnabledGloballyQueryVariables
  >({ query: FeatureFlagEnabledGloballyDocument, ...options });
}
export const SignOneSchemaJwtDocument = gql`
    query signOneSchemaJwt($payload: JSONString!) @withOwner(owner: DataIngestion) {
  signOneSchemaJwt(payload: $payload) {
    token
  }
}
    `;

export function useSignOneSchemaJwtQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQSignOneSchemaJwtQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQSignOneSchemaJwtQuery,
    GQTypes.GQSignOneSchemaJwtQueryVariables
  >({ query: SignOneSchemaJwtDocument, ...options });
}
export const GetSuppliersAdminDocument = gql`
    query GetSuppliersAdmin($orgId: ID!) {
  suppliersAdmin(orgId: $orgId) {
    suppliers {
      suppliersMerged {
        id
        data
      }
    }
  }
}
    `;

export function useGetSuppliersAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetSuppliersAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetSuppliersAdminQuery,
    GQTypes.GQGetSuppliersAdminQueryVariables
  >({ query: GetSuppliersAdminDocument, ...options });
}
export const SetPreferredLocaleDocument = gql`
    mutation SetPreferredLocale($input: SetPreferredLocaleInput!) @withOwner(owner: EnterpriseFoundations) {
  setPreferredLocale(input: $input) {
    preferredLocale
  }
}
    `;

export function useSetPreferredLocaleMutation() {
  return Urql.useMutation<
    GQTypes.GQSetPreferredLocaleMutation,
    GQTypes.GQSetPreferredLocaleMutationVariables
  >(SetPreferredLocaleDocument);
}
export const TypeaheadCompanyWithEfsDocument = gql`
    query TypeaheadCompanyWithEfs($search: String!, $allowMatchById: Boolean!, $searchDemo: Boolean!, $filterHiddenCompanies: Boolean) @withOwner(owner: SupplyChain) {
  companyFuzzyMatchWithEfs(
    q: $search
    allowMatchById: $allowMatchById
    searchDemo: $searchDemo
    filterHiddenCompanies: $filterHiddenCompanies
  ) {
    results {
      ...CompanyAutocompleteResultWithSource
    }
    companyIdsWithSupplierSpecificEfs
  }
}
    ${CompanyAutocompleteResultWithSourceFragmentDoc}`;

export function useTypeaheadCompanyWithEfsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQTypeaheadCompanyWithEfsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQTypeaheadCompanyWithEfsQuery,
    GQTypes.GQTypeaheadCompanyWithEfsQueryVariables
  >({ query: TypeaheadCompanyWithEfsDocument, ...options });
}
export const GetCompanyDocument = gql`
    query getCompany($id: ID) @withOwner(owner: SupplyChain) {
  companyForDashboard(id: $id) {
    ...CompanyAutocompleteResultWithSource
  }
}
    ${CompanyAutocompleteResultWithSourceFragmentDoc}`;

export function useGetCompanyQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQGetCompanyQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyQuery,
    GQTypes.GQGetCompanyQueryVariables
  >({ query: GetCompanyDocument, ...options });
}
export const CreateDemoOrgDocument = gql`
    mutation CreateDemoOrg($input: CreateDemoOrgInput!) @withOwner(owner: EnterpriseFoundations) {
  createDemoOrg(input: $input) {
    backgroundJobId
  }
}
    `;

export function useCreateDemoOrgMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateDemoOrgMutation,
    GQTypes.GQCreateDemoOrgMutationVariables
  >(CreateDemoOrgDocument);
}
export const LatestDocumentParseAttemptForReviewDocument = gql`
    query LatestDocumentParseAttemptForReview($fileId: ID!, $modelType: DocumentParseAttemptModelType!) @withOwner(owner: DataIngestion) {
  latestDocumentParseAttempt(fileId: $fileId, modelType: $modelType) {
    ...ReviewDocumentParseAttempt
  }
}
    ${ReviewDocumentParseAttemptFragmentDoc}`;

export function useLatestDocumentParseAttemptForReviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestDocumentParseAttemptForReviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestDocumentParseAttemptForReviewQuery,
    GQTypes.GQLatestDocumentParseAttemptForReviewQueryVariables
  >({ query: LatestDocumentParseAttemptForReviewDocument, ...options });
}
export const ReviewUserUploadPreviewUrlDocument = gql`
    query ReviewUserUploadPreviewUrl($userUploadId: ID!) @withOwner(owner: DataIngestion) {
  userUpload(id: $userUploadId) {
    userVisibleAttempt {
      previewUrl
    }
  }
}
    `;

export function useReviewUserUploadPreviewUrlQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReviewUserUploadPreviewUrlQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReviewUserUploadPreviewUrlQuery,
    GQTypes.GQReviewUserUploadPreviewUrlQueryVariables
  >({ query: ReviewUserUploadPreviewUrlDocument, ...options });
}
export const UpsertDocumentParseAttemptReviewDocument = gql`
    mutation UpsertDocumentParseAttemptReview($input: UpsertDocumentParseAttemptInput!) @withOwner(owner: DataIngestion) {
  upsertDocumentParseAttempt(input: $input) {
    ...ReviewDocumentParseAttempt
  }
}
    ${ReviewDocumentParseAttemptFragmentDoc}`;

export function useUpsertDocumentParseAttemptReviewMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertDocumentParseAttemptReviewMutation,
    GQTypes.GQUpsertDocumentParseAttemptReviewMutationVariables
  >(UpsertDocumentParseAttemptReviewDocument);
}
export const OrgDatasetsDocument = gql`
    query OrgDatasets @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    datasets {
      id
      name
    }
  }
}
    `;

export function useOrgDatasetsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgDatasetsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgDatasetsQuery,
    GQTypes.GQOrgDatasetsQueryVariables
  >({ query: OrgDatasetsDocument, ...options });
}
export const OrgDatasourcesDocument = gql`
    query OrgDatasources @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    datasets {
      id
      name
      datasources {
        id
        name
      }
    }
  }
}
    `;

export function useOrgDatasourcesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgDatasourcesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgDatasourcesQuery,
    GQTypes.GQOrgDatasourcesQueryVariables
  >({ query: OrgDatasourcesDocument, ...options });
}
export const GetPermissionsForUserDocument = gql`
    query GetPermissionsForUser($userId: ID!, $orgId: ID!) @withOwner(owner: EnterpriseFoundations) {
  user(id: $userId) {
    id
    name
    email
    displayName
    orgAccessId(orgId: $orgId)
    userUploadTasks {
      id
      measurementProject {
        id
        name
        active
      }
      datasource {
        id
        name
        dataset {
          id
          name
        }
      }
    }
    roles(orgId: $orgId) {
      id
      revokedAt
      role {
        id
        name
        description
        permissions {
          id
          permission
          revokedAt
          objectId
          object {
            __typename
            id
            name
          }
        }
      }
    }
    permissions(orgId: $orgId) {
      id
      ...PermissionFields
    }
  }
}
    ${PermissionFieldsFragmentDoc}`;

export function useGetPermissionsForUserQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPermissionsForUserQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPermissionsForUserQuery,
    GQTypes.GQGetPermissionsForUserQueryVariables
  >({ query: GetPermissionsForUserDocument, ...options });
}
export const RevokePermissionDocument = gql`
    mutation RevokePermission($permissionId: ID!) @withOwner(owner: EnterpriseFoundations) {
  revokePermissionItem(input: {permissionItemId: $permissionId}) {
    permissionItem {
      id
      revokedAt
    }
  }
}
    `;

export function useRevokePermissionMutation() {
  return Urql.useMutation<
    GQTypes.GQRevokePermissionMutation,
    GQTypes.GQRevokePermissionMutationVariables
  >(RevokePermissionDocument);
}
export const RevokeRoleForUserDocument = gql`
    mutation RevokeRoleForUser($roleId: ID!, $orgAccessId: ID!, $orgId: ID) @withOwner(owner: EnterpriseFoundations) {
  revokeUserRole(
    input: {roleId: $roleId, orgAccessId: $orgAccessId, orgId: $orgId}
  ) {
    userRoleAssignment {
      id
      revokedAt
    }
  }
}
    `;

export function useRevokeRoleForUserMutation() {
  return Urql.useMutation<
    GQTypes.GQRevokeRoleForUserMutation,
    GQTypes.GQRevokeRoleForUserMutationVariables
  >(RevokeRoleForUserDocument);
}
export const AddPermissionDocument = gql`
    mutation AddPermission($input: CreatePermissionItemInput!) @withOwner(owner: EnterpriseFoundations) {
  createPermissionItem(input: $input) {
    permissionItem {
      id
      user {
        id
      }
      revokedAt
    }
  }
}
    `;

export function useAddPermissionMutation() {
  return Urql.useMutation<
    GQTypes.GQAddPermissionMutation,
    GQTypes.GQAddPermissionMutationVariables
  >(AddPermissionDocument);
}
export const AddUserRoleDocument = gql`
    mutation AddUserRole($input: GrantUserRoleInput!) @withOwner(owner: EnterpriseFoundations) {
  grantUserRole(input: $input) {
    userRoleAssignment {
      id
    }
  }
}
    `;

export function useAddUserRoleMutation() {
  return Urql.useMutation<
    GQTypes.GQAddUserRoleMutation,
    GQTypes.GQAddUserRoleMutationVariables
  >(AddUserRoleDocument);
}
export const GetAllAssignableUsersDocument = gql`
    query GetAllAssignableUsers @withOwner(owner: DataIngestion) {
  organization {
    id
    users {
      edges {
        node {
          ...AssignableUserFields
        }
      }
    }
  }
}
    ${AssignableUserFieldsFragmentDoc}`;

export function useGetAllAssignableUsersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllAssignableUsersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllAssignableUsersQuery,
    GQTypes.GQGetAllAssignableUsersQueryVariables
  >({ query: GetAllAssignableUsersDocument, ...options });
}
export const ParquetDataToCsvDocument = gql`
    mutation ParquetDataToCsv($input: ParquetDataToCsvInput!) @withOwner(owner: DataIngestion) {
  parquetDataToCsv(input: $input) {
    jobId
  }
}
    `;

export function useParquetDataToCsvMutation() {
  return Urql.useMutation<
    GQTypes.GQParquetDataToCsvMutation,
    GQTypes.GQParquetDataToCsvMutationVariables
  >(ParquetDataToCsvDocument);
}
export const GetEngagementTaskConfigsForTaskCreationDocument = gql`
    query GetEngagementTaskConfigsForTaskCreation($includeGlobalConfigs: Boolean!, $orgId: ID!, $includeArchived: Boolean!) @withOwner(owner: SupplyChain) {
  engagementTaskConfigs(
    includeGlobalConfigs: $includeGlobalConfigs
    includeArchived: $includeArchived
    orgId: $orgId
  ) {
    ...EngagementTaskConfigForTaskCreation
  }
}
    ${EngagementTaskConfigForTaskCreationFragmentDoc}`;

export function useGetEngagementTaskConfigsForTaskCreationQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTaskConfigsForTaskCreationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTaskConfigsForTaskCreationQuery,
    GQTypes.GQGetEngagementTaskConfigsForTaskCreationQueryVariables
  >({ query: GetEngagementTaskConfigsForTaskCreationDocument, ...options });
}
export const OrderedBusinessActivityTypeInstancesForNameDocument = gql`
    query OrderedBusinessActivityTypeInstancesForName($name: String!) @withOwner(owner: DatasetsAndObjects) {
  orderedBusinessActivityTypeInstancesForName(name: $name) {
    ...BusinessActivityTypeLite
  }
}
    ${BusinessActivityTypeLiteFragmentDoc}`;

export function useOrderedBusinessActivityTypeInstancesForNameQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrderedBusinessActivityTypeInstancesForNameQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrderedBusinessActivityTypeInstancesForNameQuery,
    GQTypes.GQOrderedBusinessActivityTypeInstancesForNameQueryVariables
  >({ query: OrderedBusinessActivityTypeInstancesForNameDocument, ...options });
}
export const LatestBusinessActivityTypeInstancesDocument = gql`
    query LatestBusinessActivityTypeInstances @withOwner(owner: DatasetsAndObjects) {
  latestBusinessActivityTypeInstances {
    ...BusinessActivityTypeLite
  }
}
    ${BusinessActivityTypeLiteFragmentDoc}`;

export function useLatestBusinessActivityTypeInstancesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestBusinessActivityTypeInstancesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestBusinessActivityTypeInstancesQuery,
    GQTypes.GQLatestBusinessActivityTypeInstancesQueryVariables
  >({ query: LatestBusinessActivityTypeInstancesDocument, ...options });
}
export const LatestBusinessActivityTypeInstanceForNameDocument = gql`
    query LatestBusinessActivityTypeInstanceForName($name: String!) @withOwner(owner: DatasetsAndObjects) {
  latestBusinessActivityTypeInstanceForName(name: $name) {
    ...BusinessActivityTypeLite
  }
}
    ${BusinessActivityTypeLiteFragmentDoc}`;

export function useLatestBusinessActivityTypeInstanceForNameQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestBusinessActivityTypeInstanceForNameQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestBusinessActivityTypeInstanceForNameQuery,
    GQTypes.GQLatestBusinessActivityTypeInstanceForNameQueryVariables
  >({ query: LatestBusinessActivityTypeInstanceForNameDocument, ...options });
}
export const BusinessActivityTypeByIdDocument = gql`
    query BusinessActivityTypeById($batId: ID!) @withOwner(owner: DatasetsAndObjects) {
  businessActivityTypeById(batId: $batId) {
    ...BusinessActivityTypeLite
  }
}
    ${BusinessActivityTypeLiteFragmentDoc}`;

export function useBusinessActivityTypeByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeByIdQuery,
    GQTypes.GQBusinessActivityTypeByIdQueryVariables
  >({ query: BusinessActivityTypeByIdDocument, ...options });
}
export const BusinessActivityTypeVersionByIdDocument = gql`
    query BusinessActivityTypeVersionById($id: ID!) @withOwner(owner: Calcprint) {
  businessActivityTypeVersionById(id: $id) {
    id
    name
    businessActivityTypes {
      ...AllBusinessActivityTypeFields
    }
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useBusinessActivityTypeVersionByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeVersionByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeVersionByIdQuery,
    GQTypes.GQBusinessActivityTypeVersionByIdQueryVariables
  >({ query: BusinessActivityTypeVersionByIdDocument, ...options });
}
export const BusinessActivityTypeByIdsDocument = gql`
    query BusinessActivityTypeByIds($batIds: [ID!]!) @withOwner(owner: Calcprint) {
  businessActivityTypeByIds(batIds: $batIds) {
    version {
      id
      name
    }
    ...AllBusinessActivityTypeFields
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useBusinessActivityTypeByIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeByIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeByIdsQuery,
    GQTypes.GQBusinessActivityTypeByIdsQueryVariables
  >({ query: BusinessActivityTypeByIdsDocument, ...options });
}
export const LiveBusinessActivityTypeVersionDocument = gql`
    query LiveBusinessActivityTypeVersion @withOwner(owner: Calcprint) {
  businessActivityTypeVersionLive {
    id
    name
    businessActivityTypes {
      ...AllBusinessActivityTypeFields
    }
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useLiveBusinessActivityTypeVersionQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQLiveBusinessActivityTypeVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLiveBusinessActivityTypeVersionQuery,
    GQTypes.GQLiveBusinessActivityTypeVersionQueryVariables
  >({ query: LiveBusinessActivityTypeVersionDocument, ...options });
}
export const BusinessActivityTypeByNameAndVersionIdDocument = gql`
    query BusinessActivityTypeByNameAndVersionId($name: String!, $versionId: ID!) @withOwner(owner: Calcprint) {
  businessActivityTypeByNameAndVersionId(name: $name, versionId: $versionId) {
    ...BusinessActivityTypeLite
  }
}
    ${BusinessActivityTypeLiteFragmentDoc}`;

export function useBusinessActivityTypeByNameAndVersionIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeByNameAndVersionIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeByNameAndVersionIdQuery,
    GQTypes.GQBusinessActivityTypeByNameAndVersionIdQueryVariables
  >({ query: BusinessActivityTypeByNameAndVersionIdDocument, ...options });
}
export const AllOrgUsersForPermissionDocument = gql`
    query AllOrgUsersForPermission($orgId: ID!, $permissions: [HasPermissionInput!]!) @withOwner(owner: EnterpriseFoundations) {
  organization(id: $orgId) {
    id
    users {
      edges {
        node {
          ...UserForAllOrgUsersForPermission
        }
      }
    }
  }
}
    ${UserForAllOrgUsersForPermissionFragmentDoc}`;

export function useAllOrgUsersForPermissionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAllOrgUsersForPermissionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAllOrgUsersForPermissionQuery,
    GQTypes.GQAllOrgUsersForPermissionQueryVariables
  >({ query: AllOrgUsersForPermissionDocument, ...options });
}
export const OrgUsersWithPermissionsDocument = gql`
    query OrgUsersWithPermissions($orgId: ID!, $permissionInfos: [HasPermissionInput!]!) @withOwner(owner: EnterpriseFoundations) {
  organization(id: $orgId) {
    id
    users(filters: {withSomePermissions: $permissionInfos}) {
      edges {
        node {
          ...UserForOrgUsersWithPermissions
        }
      }
    }
  }
}
    ${UserForOrgUsersWithPermissionsFragmentDoc}`;

export function useOrgUsersWithPermissionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgUsersWithPermissionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgUsersWithPermissionsQuery,
    GQTypes.GQOrgUsersWithPermissionsQueryVariables
  >({ query: OrgUsersWithPermissionsDocument, ...options });
}
export const GetJobResultsDocument = gql`
    query GetJobResults($id: ID!) {
  job: backgroundJob(id: $id) {
    id
    kind
    state
    args
    result
    error {
      ...SerializableErrorFields
    }
  }
}
    ${SerializableErrorFieldsFragmentDoc}`;

export function useGetJobResultsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetJobResultsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetJobResultsQuery,
    GQTypes.GQGetJobResultsQueryVariables
  >({ query: GetJobResultsDocument, ...options });
}
export const GetJobStatusDocument = gql`
    query GetJobStatus($id: ID!) {
  job: backgroundJobStatus(id: $id) {
    id
    orgId
    state
    error {
      ...SerializableErrorFields
    }
  }
}
    ${SerializableErrorFieldsFragmentDoc}`;

export function useGetJobStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetJobStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetJobStatusQuery,
    GQTypes.GQGetJobStatusQueryVariables
  >({ query: GetJobStatusDocument, ...options });
}
export const ListJobsStatusDocument = gql`
    query ListJobsStatus($ids: [ID!]!) {
  backgroundJobStatuses(ids: $ids) {
    id
    orgId
    state
    error {
      ...SerializableErrorFields
    }
  }
}
    ${SerializableErrorFieldsFragmentDoc}`;

export function useListJobsStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQListJobsStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQListJobsStatusQuery,
    GQTypes.GQListJobsStatusQueryVariables
  >({ query: ListJobsStatusDocument, ...options });
}
export const CancelWorkflowDocument = gql`
    mutation CancelWorkflow($input: CancelWorkflowInput!) {
  cancelWorkflow(input: $input) {
    success
  }
}
    `;

export function useCancelWorkflowMutation() {
  return Urql.useMutation<
    GQTypes.GQCancelWorkflowMutation,
    GQTypes.GQCancelWorkflowMutationVariables
  >(CancelWorkflowDocument);
}
export const GetAllReportConfigsDocument = gql`
    query GetAllReportConfigs($filterHidden: Boolean) @withOwner(owner: Reporting) {
  allReportConfigs(filterHidden: $filterHidden) {
    ...OverviewReportConfigFields
  }
}
    ${OverviewReportConfigFieldsFragmentDoc}`;

export function useGetAllReportConfigsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllReportConfigsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllReportConfigsQuery,
    GQTypes.GQGetAllReportConfigsQueryVariables
  >({ query: GetAllReportConfigsDocument, ...options });
}
