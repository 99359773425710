import { makeBetterEnum } from './betterEnum';

// We have 161 officially supported currencies, and they are listed in many
// places. If you are editing this list, please be sure to update
// py/watershed/models/currency_code_mixin.py and
// shared-universal/utils/currencies.ts files
export const CurrencyCode = makeBetterEnum(
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BTN',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GGP',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'IMP',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JEP',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRU',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RUR',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'SSP',
  'STD',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TVD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'UYU',
  'UZS',
  'VEF',
  'VES',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XCD',
  'XOF',
  'XPF',
  'YER',
  'ZAR',
  'ZMW'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CurrencyCode = (typeof CurrencyCode)[keyof typeof CurrencyCode];

// Pulled from https://www.xe.com/symbols/
export const CURRENCY_MAP: {
  [key in CurrencyCode]: {
    code: CurrencyCode;
    symbol: string;
  };
} = {
  ALL: {
    code: 'ALL',
    symbol: 'Lek',
  },
  AFN: {
    code: 'AFN',
    symbol: '؋',
  },
  ARS: {
    code: 'ARS',
    symbol: '$',
  },
  AOA: {
    code: 'AOA',
    symbol: 'Kz',
  },
  AMD: {
    code: 'AMD',
    symbol: 'դր',
  },
  AWG: {
    code: 'AWG',
    symbol: 'ƒ',
  },
  AUD: {
    code: 'AUD',
    symbol: '$',
  },
  AZN: {
    code: 'AZN',
    symbol: '₼',
  },
  BSD: {
    code: 'BSD',
    symbol: '$',
  },
  BBD: {
    code: 'BBD',
    symbol: '$',
  },
  BYN: {
    code: 'BYN',
    symbol: 'Br',
  },
  BZD: {
    code: 'BZD',
    symbol: 'BZ$',
  },
  BHD: {
    code: 'BHD',
    symbol: '.د.ب',
  },
  BDT: {
    code: 'BDT',
    symbol: '৳',
  },
  BMD: {
    code: 'BMD',
    symbol: '$',
  },
  BTN: {
    code: 'BTN',
    symbol: 'Nu.',
  },
  BOB: {
    code: 'BOB',
    symbol: '$b',
  },
  BAM: {
    code: 'BAM',
    symbol: 'KM',
  },
  BWP: {
    code: 'BWP',
    symbol: 'P',
  },
  BGN: {
    code: 'BGN',
    symbol: 'лв',
  },
  BRL: {
    code: 'BRL',
    symbol: 'R$',
  },
  BND: {
    code: 'BND',
    symbol: '$',
  },
  BIF: {
    code: 'BIF',
    symbol: 'FBu',
  },
  DZD: {
    code: 'DZD',
    symbol: 'دج',
  },
  KMF: {
    code: 'KMF',
    symbol: 'CF',
  },
  CVE: {
    code: 'CVE',
    symbol: '$',
  },
  XAF: {
    code: 'XAF',
    symbol: 'FCFA',
  },
  KHR: {
    code: 'KHR',
    symbol: '៛',
  },
  CAD: {
    code: 'CAD',
    symbol: '$',
  },
  KYD: {
    code: 'KYD',
    symbol: '$',
  },
  JOD: {
    code: 'JOD',
    symbol: 'ينار',
  },
  KES: {
    code: 'KES',
    symbol: 'KSh',
  },
  KWD: {
    code: 'KWD',
    symbol: 'ك',
  },
  LSL: {
    code: 'LSL',
    symbol: 'L',
  },
  LYD: {
    code: 'LYD',
    symbol: 'LD',
  },
  MOP: {
    code: 'MOP',
    symbol: 'MOP$',
  },
  MGA: {
    code: 'MGA',
    symbol: 'Ar',
  },
  MWK: {
    code: 'MWK',
    symbol: 'K',
  },
  MVR: {
    code: 'MVR',
    symbol: 'MRf',
  },
  MRU: {
    code: 'MRU',
    symbol: 'UM',
  },
  MDL: {
    code: 'MDL',
    symbol: 'L',
  },
  MAD: {
    code: 'MAD',
    symbol: 'DH',
  },
  MMK: {
    code: 'MMK',
    symbol: 'K',
  },
  PGK: {
    code: 'PGK',
    symbol: 'K',
  },
  RUR: {
    code: 'RUR',
    symbol: '₽',
  },
  RWF: {
    code: 'RWF',
    symbol: 'R₣',
  },
  WST: {
    code: 'WST',
    symbol: 'SAT',
  },
  STD: {
    code: 'STD',
    symbol: 'Db',
  },
  SLL: {
    code: 'SLL',
    symbol: 'Le',
  },
  SSP: {
    code: 'SSP',
    symbol: 'SS£',
  },
  SDG: {
    code: 'SDG',
    symbol: 'SDG',
  },
  TJS: {
    code: 'TJS',
    symbol: 'TJS',
  },
  TZS: {
    code: 'TZS',
    symbol: 'TSh',
  },
  TND: {
    code: 'TND',
    symbol: 'د.ت',
  },
  TOP: {
    code: 'TOP',
    symbol: 'T$',
  },
  TMT: {
    code: 'TMT',
    symbol: 'T',
  },
  UGX: {
    code: 'UGX',
    symbol: 'Ush',
  },
  AED: {
    code: 'AED',
    symbol: 'AED',
  },
  VUV: {
    code: 'VUV',
    symbol: 'VT',
  },
  HTG: {
    code: 'HTG',
    symbol: 'G',
  },
  CLP: {
    code: 'CLP',
    symbol: '$',
  },
  CNY: {
    code: 'CNY',
    symbol: '¥',
  },
  CDF: {
    code: 'CDF',
    symbol: 'FC',
  },
  COP: {
    code: 'COP',
    symbol: '$',
  },
  CRC: {
    code: 'CRC',
    symbol: '₡',
  },
  HRK: {
    code: 'HRK',
    symbol: 'kn',
  },
  CUP: {
    code: 'CUP',
    symbol: '₱',
  },
  CZK: {
    code: 'CZK',
    symbol: 'Kč',
  },
  DJF: {
    code: 'DJF',
    symbol: 'Fdj',
  },
  DKK: {
    code: 'DKK',
    symbol: 'kr',
  },
  DOP: {
    code: 'DOP',
    symbol: 'RD$',
  },
  SZL: {
    code: 'SZL',
    symbol: 'E',
  },
  ETB: {
    code: 'ETB',
    symbol: 'ብር',
  },
  ERN: {
    code: 'ERN',
    symbol: 'ናቕፋ',
  },
  XCD: {
    code: 'XCD',
    symbol: '$',
  },
  EGP: {
    code: 'EGP',
    symbol: '£',
  },
  SVC: {
    code: 'SVC',
    symbol: '$',
  },
  EUR: {
    code: 'EUR',
    symbol: '€',
  },
  FKP: {
    code: 'FKP',
    symbol: '£',
  },
  FJD: {
    code: 'FJD',
    symbol: '$',
  },
  GHS: {
    code: 'GHS',
    symbol: '¢',
  },
  GNF: {
    code: 'GNF',
    symbol: 'FG',
  },
  GIP: {
    code: 'GIP',
    symbol: '£',
  },
  GTQ: {
    code: 'GTQ',
    symbol: 'Q',
  },
  GGP: {
    code: 'GGP',
    symbol: '£',
  },
  GYD: {
    code: 'GYD',
    symbol: '$',
  },
  HNL: {
    code: 'HNL',
    symbol: 'L',
  },
  HKD: {
    code: 'HKD',
    symbol: '$',
  },
  HUF: {
    code: 'HUF',
    symbol: 'Ft',
  },
  ISK: {
    code: 'ISK',
    symbol: 'kr',
  },
  INR: {
    code: 'INR',
    symbol: '₹',
  },
  IDR: {
    code: 'IDR',
    symbol: 'Rp',
  },
  IRR: {
    code: 'IRR',
    symbol: '﷼',
  },
  IMP: {
    code: 'IMP',
    symbol: '£',
  },
  ILS: {
    code: 'ILS',
    symbol: '₪',
  },
  JMD: {
    code: 'JMD',
    symbol: 'J$',
  },
  JPY: {
    code: 'JPY',
    symbol: '¥',
  },
  JEP: {
    code: 'JEP',
    symbol: '£',
  },
  KZT: {
    code: 'KZT',
    symbol: 'лв',
  },
  KPW: {
    code: 'KPW',
    symbol: '₩',
  },
  KRW: {
    code: 'KRW',
    symbol: '₩',
  },
  KGS: {
    code: 'KGS',
    symbol: 'лв',
  },
  LAK: {
    code: 'LAK',
    symbol: '₭',
  },
  LBP: {
    code: 'LBP',
    symbol: '£',
  },
  LRD: {
    code: 'LRD',
    symbol: '$',
  },
  MKD: {
    code: 'MKD',
    symbol: 'ден',
  },
  MYR: {
    code: 'MYR',
    symbol: 'RM',
  },
  MUR: {
    code: 'MUR',
    symbol: '₨',
  },
  MXN: {
    code: 'MXN',
    symbol: '$',
  },
  MNT: {
    code: 'MNT',
    symbol: '₮',
  },
  MZN: {
    code: 'MZN',
    symbol: 'MT',
  },
  NAD: {
    code: 'NAD',
    symbol: '$',
  },
  NPR: {
    code: 'NPR',
    symbol: '₨',
  },
  ANG: {
    code: 'ANG',
    symbol: 'ƒ',
  },
  NZD: {
    code: 'NZD',
    symbol: '$',
  },
  NIO: {
    code: 'NIO',
    symbol: 'C$',
  },
  NGN: {
    code: 'NGN',
    symbol: '₦',
  },
  NOK: {
    code: 'NOK',
    symbol: 'kr',
  },
  OMR: {
    code: 'OMR',
    symbol: '﷼',
  },
  PKR: {
    code: 'PKR',
    symbol: '₨',
  },
  PAB: {
    code: 'PAB',
    symbol: 'B/.',
  },
  PYG: {
    code: 'PYG',
    symbol: 'Gs',
  },
  PEN: {
    code: 'PEN',
    symbol: 'S/.',
  },
  PHP: {
    code: 'PHP',
    symbol: '₱',
  },
  PLN: {
    code: 'PLN',
    symbol: 'zł',
  },
  QAR: {
    code: 'QAR',
    symbol: '﷼',
  },
  RON: {
    code: 'RON',
    symbol: 'lei',
  },
  RUB: {
    code: 'RUB',
    symbol: '₽',
  },
  SHP: {
    code: 'SHP',
    symbol: '£',
  },
  SAR: {
    code: 'SAR',
    symbol: '﷼',
  },
  RSD: {
    code: 'RSD',
    symbol: 'Дин.',
  },
  SCR: {
    code: 'SCR',
    symbol: '₨',
  },
  SGD: {
    code: 'SGD',
    symbol: '$',
  },
  SBD: {
    code: 'SBD',
    symbol: '$',
  },
  SOS: {
    code: 'SOS',
    symbol: 'S',
  },
  ZAR: {
    code: 'ZAR',
    symbol: 'R',
  },
  LKR: {
    code: 'LKR',
    symbol: '₨',
  },
  SEK: {
    code: 'SEK',
    symbol: 'kr',
  },
  CHF: {
    code: 'CHF',
    symbol: 'CHF',
  },
  SRD: {
    code: 'SRD',
    symbol: '$',
  },
  SYP: {
    code: 'SYP',
    symbol: '£',
  },
  TWD: {
    code: 'TWD',
    symbol: 'NT$',
  },
  THB: {
    code: 'THB',
    symbol: '฿',
  },
  TTD: {
    code: 'TTD',
    symbol: 'TT$',
  },
  TRY: {
    code: 'TRY',
    symbol: '₺',
  },
  TVD: {
    code: 'TVD',
    symbol: '$',
  },
  UAH: {
    code: 'UAH',
    symbol: '₴',
  },
  IQD: {
    code: 'IQD',
    symbol: 'د.ع',
  },
  GBP: {
    code: 'GBP',
    symbol: '£',
  },
  USD: {
    code: 'USD',
    symbol: '$',
  },
  UYU: {
    code: 'UYU',
    symbol: '$U',
  },
  UZS: {
    code: 'UZS',
    symbol: 'лв',
  },
  VEF: {
    code: 'VEF',
    symbol: 'Bs',
  },
  VES: {
    code: 'VES',
    symbol: 'Bs',
  },
  VND: {
    code: 'VND',
    symbol: '₫',
  },
  YER: {
    code: 'YER',
    symbol: '﷼',
  },
  XOF: {
    code: 'XOF',
    symbol: 'CFA',
  },
  XPF: {
    code: 'XPF',
    symbol: 'F',
  },
  GMD: {
    code: 'GMD',
    symbol: 'D',
  },
  GEL: {
    code: 'GEL',
    symbol: '₾',
  },
  ZMW: {
    code: 'ZMW',
    symbol: 'ZK',
  },
} as const;

export type CurrencyOption = {
  code: CurrencyCode;
  symbol: string;
  name: string;
  longName: string; // {name} ({code})
};
export type CurrencySymbol = (typeof CURRENCY_MAP)[CurrencyCode]['symbol'];
