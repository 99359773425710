import { EnumCombinations } from '../canonicalSchemas/validationUtils';

export const fieldTypes = [
  'BOOLEAN',
  'BYTES',
  'DATE',
  'FLOAT',
  'INT',
  'JSON',
  'ONE_OF',
  'STRING',
  'TIMESTAMP',
  'UUID',
] as const;

export type fieldTypesType = (typeof fieldTypes)[number];

export const extensionTypeNames = [
  'ws',
  'has_ontology',
  'has_ontology_required',
  'activity',
  'has_quantity',
  'has_currency_quantity',
  'has_legacy_mapping',
  'has_location',
  'has_expenditure_unmapped',
  'at_building',
  'maybe_at_building',
  'water',
  'cee_activity',
  'cloud',
  'crypto',
  'has_waste',
  'has_material',
  'has_product',
  'has_vendor',
  'has_distance',
  'has_tier_1_factory',
  'has_waste_attributes',
  'finance_holding',
  'has_finance_scope_1_overrides',
  'has_finance_scope_2_overrides',
  'has_finance_scope_3_overrides',
  'corporate_asset_metadata',
  'has_outstanding_amount',

  // For testing - DO NOT USE in a real type
  'test_only_root',
  'test_only_root2',
  'test_only_root3',
] as const;

// These BATs are all non-emissive. Separating out for easy use across
// services.
export const socialAndGovernanceTypeNames = [
  // social and governance
  'workplace_reports',
  'lobbying',
  'workers_social',
  'health_and_safety_incidents',
  'severe_human_rights_incidents',
  'anti_bribery_and_corruption_incidents',
  'payment_orders',
] as const;

export const primaryTypeNames = [
  'electricity_consumption',
  'non_building_electricity_consumption',
  'building_stationary_combustion',
  'refrigerants',
  'direct_ghg_emissions',
  'historical_emissions',
  'historical_emissions_pv2',
  'fuel_combustion',
  'building_occupation',
  'water_consumption',
  'water_usage',
  'water_recycling',
  'water_storage',
  'water_discharge',
  'electricity_consumption_datacenter',
  'employees',
  'expenditure',
  'expenditure_mapped',
  'expenditure_unmapped',
  'travel_air',
  'travel_private_jets',
  'travel_rail',
  'lodging',
  'vehicles',
  'cloud_compute_usage',
  'cloud_spend',
  'cloud_usage_and_spend',
  'logistics',
  'physical_goods_purchase',
  'crypto_holdings',
  'crypto_transactions',
  'carbon_credit_retirements',
  'carbon_removal',
  'ghg_removal',
  'consumer_goods_material_assessment',
  'consumer_goods_product_assessment',
  'consumer_goods_packaging_material',
  'consumer_goods_textile_product_use_phase',
  'consumer_goods_textile_product_manufacturing',
  'consumer_goods_ecoinvent_material',
  'corporate_asset_holding',
  'real_estate_asset_holding',
  'asset_group_holding',
  'personal_motor_vehicle_insurance_holding',
  'sovereign_debt_holding',
  'insurance_commercial_line_holding',
  'legacy_finance_emissions',
  'substances',
  'substance_outflow',
  'substance_inflow',
  'microplastics_outflow',
  'site_land_use',
  'waste',
  'building_waste',
  'intensity_denominator_monetary',
  'intensity_denominator_custom',
  'lifetime_fuel_and_electricity_use_of_sold_products',
  'sold_retail_good',
  'sold_refrigerant',
  'sold_vehicle',
  'custom',
  'legacy_clean_power',
  'cadt',
  'em_development_bat',
  'activity_with_custom_ef',
  'cee_naics_spend',
  'cee_eeio_scope_1',
  'cee_electricity_usage',
  'cee_electricity_usage_scope_2',
  'cee_electricity_usage_scope_3_3_td_loss',
  'cee_electricity_usage_scope_3_3_wtt',
  'cee_electricity_usage_scope_3_3_wtt_td_loss',
  'cee_building_scope_1',
  'cee_building_scope_2',
  'cee_building_scope_3',
  'cee_building',
  'cee_cloud_spend',
  'cee_flights',
  'cee_home_office',
  'cee_commuting',
  'cee_precalculated',
  'food_mass',
  'food_spend',
  'corporate_315_investments',
  'tier_1_factories_spend',
  'tier_1_factories_activity_data',
  'waste_products',
  'data_transfer',
  'sold_product_circularity',
  'sold_product_recyclability',
  'purchase_orders',

  ...socialAndGovernanceTypeNames,

  // For testing - DO NOT USE in a real type
  'test_only_child0',
  'test_only_child1',
  'test_only_child2',
  'test_only_child3',
  'test_only_child4',
  'test_only_shared0',
  'test_only_additional_type',
] as const;

export type ExtensionTypeName = (typeof extensionTypeNames)[number];
export type PrimaryTypeName = (typeof primaryTypeNames)[number];

/**
 * TypeInfoVersion is a meta type that is used to define business activity type schemas in typescript.
 *
 * It is a slightly inverted form of the schema, and is translated to the database schema by the service.
 */
export interface TypeInfoVersion {
  name: string;
  asOf: Date;
  types: Array<TypeInfo>;
}

interface TypeInfoBase {
  versionId?: string;
  fields: Array<TypeInfoField>;
  extends: Array<string>;
  description?: string;
  validators?: Array<ValidatorName>;
  /** Represents valid combinations of enumerated columns. These combinations
   * are used to automatically generate row validators. */
  enumCombinations?: Array<EnumCombinations>;
}

export interface PrimaryTypeInfo extends TypeInfoBase {
  name: PrimaryTypeName;
  isPrimary: true;
}

export interface ExtensionTypeInfo extends TypeInfoBase {
  name: ExtensionTypeName;
  isPrimary: false;
}

export type TypeInfo = PrimaryTypeInfo | ExtensionTypeInfo;

interface TypeInfoFieldBase {
  typeId?: string;
  versionId?: string;
  description?: string;
  fieldName: string;
  fieldValueOneOf?: ReadonlyArray<string> | null;
  isRequired?: boolean;
  validators?: ReadonlyArray<ValidatorName>;
  unitColumn?: string;
}

export interface NumericTypeInfoField extends TypeInfoFieldBase {
  fieldType: Extract<fieldTypesType, 'INT' | 'FLOAT'>;

  // A field is proratable if we can evenly divide its value across a time
  // period.
  // For example, spend is proratable.
  // Meanwhile, employee count is generally NOT proratable because saying you
  // have "30 employees this month" means you have "30 employees every day"
  // instead of "1 employee per day"
  isProratable: boolean;
}

export interface NonNumericTypeInfoField extends TypeInfoFieldBase {
  fieldType: Exclude<fieldTypesType, 'INT' | 'FLOAT'>;
  isProratable?: never;
}

export type TypeInfoField = NumericTypeInfoField | NonNumericTypeInfoField;

// List of validator names mapped to implementation in domain
// When adding a new one please add to VALIDATORS dict in
// validatorsList in domain
const _VALIDATOR_NAMES = [
  'currencyValidator',
  'casNumberValidator',
  'cleanPowerContractualInstrumentValidator',
  'cleanPowerPercentRange',
  'distanceQuantityDep',
  'sourceUutIdsJsonString',
  'wsCategory',
  'activityValidator',
  'activityTags',
  'activityRawData',
  'hasQuantityDep',
  'hasCurrencyQuantityDep',
  'hasFuelQuantityDep',
  'hasSufficientVehicleInformationDep',
  'hasLocation',
  'electricityConsumptionDatacenterRange',
  'employeesRemoteTimeRange',
  'travelAirOrFields',
  'travelAirPrivateOrFields',
  'travelAirRoute',
  'travelAirCabin',
  'travelAirPrivatePlane',
  'logisticsOriginLocation',
  'logisticsDestinationLocation',
  'physicalGoodsPurchasedValidator',
  'cryptoFeeOrCount',
  'cryptoFeeQuantityDep',
  'cryptoTransactionQuantityDep',
  'cryptoTransactionCountDep',
  'intensityDenominatorValidator',
  'cadtUnitValidator',
  'autogeneratedFieldValidator',
  'buildingCleanPowerPercentValidator',
  'tier2ToTier1SupplierDistanceQuantityDep',
  'quantityOrCurrencyQuantity',
  'hasOnsiteCleanFuelKindDep',
  'carbonCreditCostPerKgCo2eCurrency',
  'logisticsOriginAndDestinationValidator',
  'lifetimeFuelConsumptionOrLifetimeElectricityConsumption',
  'oneOfCasNumberAndEuPrtr',
  'substanceOutflowByMediumValidator',
  'substanceInflowByMediumValidator',
  'soldProductCircularityValidator',
  'hazardClassValidator',
  'historicalEmissionsPv2Validator',
  'historicalEmissionsValidator',
  'allOrNoneOfEcoinventFieldsValidator',
  'purchaseOrderValidator',
] as const;

export type ValidatorName = (typeof _VALIDATOR_NAMES)[number];
