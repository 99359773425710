import {
  BACKGROUND_JOBS_ROUTE,
  ORGS_ROUTE,
  routeForIntegrationsIndex,
  routeForMarketplaceDevelopersList,
  routeForMarketplaceIndex,
  routeForMarketplaceProjectArchetypesList,
  routeForMarketplaceProjectOfferingsList,
  routeForMarketplaceProjectsList,
  routeForMarketplaceSuppliersList,
  routeForMarketplacePriceEstimatesList,
  routeForObjectViewer,
  routeForTcfdRisks,
  routeForTcfdOpportunities,
  urlForObject,
  urlForQueryPlayground,
  routeForFeatureFlags,
  routeForCompanies,
  routeForFinance,
  routeForCanonicalSchemas,
  routeForBusinessActivityTypes,
  routeForReportAnswerVerifier,
  routeForReportHealthChecks,
  routeForEmissionsModels,
  routeForMeasurementTests,
  routeForMarketplaceDocumentsList,
  routeForDuckHunt,
  routeForParquetViewer,
  routeForDescriptions,
  routeForReferenceData,
  routeForOrgSpecificMethodologyData,
  routeForGmailTools,
  routeForCanonicalDatasets,
  routeForSentEmails,
  routeForGlobalFootprintTagEditor,
  routeForUnitConverter,
  routeForLicensedCdpAnswers,
  routeForCanonicalProjects,
  routeForEmissionsModel,
  routeForFootprintTaggingEditor,
  adminLoginAsUrl,
  routeForReferenceDataSource,
  routeForReportQuestionAnswers,
  urlForActivityDataTable,
  routeForReportConfigs,
  routeForSupplyChainCharts,
  routeForReportQuestionMapping,
  routeForCdpIdMapping,
  adminLoginAsMyselfUrl,
  routeForMeasurementTestSuite,
  routeForReferenceDataCitations,
  routeForEngagementTasks,
  routeForCalculationTags,
  routeForCustomerTargetSchemas,
  routeForMethodologyExplorer,
  routeForStorybook,
  routeForIconsInStorybook,
  routeForEmissionsModelRelease,
  routeForEmissionsModelReleases,
  routeForFootprintDebugViewer,
  routeForCloudFileStorage,
  routeForReportConfigObject,
  routeForSystemModelArchitecture,
  routeForTSchemaPlatform,
  routeForLifecycleAssessmentsForOrgId,
  routeForMaterialVariantsForOrgId,
  routeForLegacyBusinessActivityTypes,
  routeForUserUploadTaskInProduct,
  routeForLineageViewer,
  routeForFormuLake,
} from '@watershed/shared-universal/adminRoutes';
import {
  GQGetNonOrgSpecificQuickSwitcherDataQuery,
  GQGetOrgSpecificQuickSwitcherDataQueryVariables,
  GQGetOrgSpecificQuickSwitcherDataQuery,
} from '@watershed/app-admin/generated/graphql-operations';
import { GQWatershedPlanLegacy } from '@watershed/app-admin/generated/graphql-schema-types';

import flatten from 'lodash/flatten';

import formatOrgName from '@watershed/shared-universal/utils/formatOrgName';
import gql from 'graphql-tag';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import { OperationResult } from 'urql';
import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';
import { PREFIX_SET } from '@watershed/shared-universal/generated/dbPrefixes';

gql`
  query GetNonOrgSpecificQuickSwitcherData {
    emissionsModelsStable(showArchived: false) {
      edges {
        node {
          id
          title
        }
      }
    }
    referenceDataSources(filter: All, excludeArchived: true, last: 1000) {
      edges {
        node {
          name
          id
          orgId
        }
      }
    }
    measurementTestSuites(includeDeleted: false, criticalOnly: false) {
      id
      title
    }
    reportConfigs {
      edges {
        node {
          id
          reportType
          shortName
          longName
          description
        }
      }
    }
    emissionsModelReleases {
      id
      businessActivityTypeName
      displayName
    }
  }
  query GetOrgSpecificQuickSwitcherData($orgId: ID!) {
    footprintTagsForOrg(orgId: $orgId) {
      id
      tagName
    }
    activityDataTables(orgId: $orgId, last: 1000) {
      edges {
        node {
          id
          name
        }
      }
    }
    userUploadTasksForOrg(orgId: $orgId) {
      id
      datasource {
        id
        name
      }
      measurementProject {
        id 
        name
      }
    }
  }
`;

type GetOrgSpecificData = (
  orgId: string
) => Promise<
  OperationResult<
    GQGetOrgSpecificQuickSwitcherDataQuery,
    GQGetOrgSpecificQuickSwitcherDataQueryVariables
  >
>;

type Item = {
  title: string;
  /**
   * Either the route, or a function that creates a route with the search term passed in
   */
  url: string | ((searchTerm: string) => string);
  rank: number;
  additionalSearchTerms?: Array<string>;

  /**
   * This function can be used to create items that match a regex pattern
   * or match a few different input patterns without having to create a
   * bunch of additional search terms. The title of the item will _always_
   * be a default match, so no need to specify it here.
   */
  matchesSearchTerm?: (searchTerm: string) => boolean;

  /**
   * A tab on the parent will show these items
   */
  childItems?:
    | Array<Item>
    | ((getOrgSpecificData: GetOrgSpecificData) => Promise<Array<Item>>);

  /**
   * A title to interpolate into the "Jump to..." text for the sub menu
   */
  subMenuTitle?: string;

  // To determine whether to open the link in a new tab when clicked
  newTab?: boolean;
};

/**
 * Used in sorting, to slightly boost an item above another, for things like aliases matching in the wrong order
 */
export const MINOR_RANK = 1;

/**
 * Used in sorting, to boost a set of items above others, for things like org-specific items above everything else
 */
export const MAJOR_RANK = 100;

/**
 * Convenience function to apply org ranking to an item based on the current context
 */
function orgIdRanked(orgId: string | null, context: { orgId: string | null }) {
  return context.orgId === orgId ? MAJOR_RANK : -MAJOR_RANK;
}

function orgRanked(
  org: {
    id: string;
    testOrg: boolean;
    demoOrg: boolean;
  },
  context: { orgId: string | null }
) {
  if (org.id === context.orgId) {
    return MAJOR_RANK;
  }
  // Prioritize customer orgs > demo orgs > test orgs
  if (!org.testOrg && !org.demoOrg) {
    return -MINOR_RANK; // Customer org
  }
  if (org.demoOrg) {
    return -MAJOR_RANK; // Demo org
  }
  return -MAJOR_RANK * 2; // Test org
}

/**
 * Returns a list of all quick switcher items, including ones that need to fetch more
 * data to properly populate.
 *
 * The rank is determined as follows:
 * 1. If the orgId is defined, all of its potential tools and children are ranked highest
 * 2. If the orgId is not defined, all of the non-org-specific items are ranked highest (e.g. "Workflows", "Query playground", etc.)
 * 3. If the orgId is not defined, and the item is org-specific, it is ranked lower than internal tools
 *   3a. Customer orgs are ranked higher than demo and test orgs
 */
export function getQuickSwitcherItems(context: {
  orgs: Array<{
    id: string;
    name: string;
    testOrg: boolean;
    demoOrg: boolean;
    hasUsers: boolean;
    watershedPlanLegacy?: GQWatershedPlanLegacy;
  }>;
  orgId: string | null;
  nonOrgSpecificQuickSwitcherData:
    | GQGetNonOrgSpecificQuickSwitcherDataQuery
    | undefined;
  activeWatershedEmployee: {
    name: string;
    user: {
      accessibleOrgs: Array<{ id: string }>;
    };
  };
}): Array<Item> {
  const refDataEntries =
    context.nonOrgSpecificQuickSwitcherData?.referenceDataSources?.edges
      .map((e) => e?.node)
      .filter(isNotNullish) ?? [];
  const accessibleOrgIds =
    context.activeWatershedEmployee.user.accessibleOrgs.map((org) => org.id);
  const items: Array<Item> = [
    {
      title: 'Organizations',
      url: ORGS_ROUTE,
      rank: MINOR_RANK,
    },
    {
      title: 'Marketplace',
      url: routeForMarketplaceIndex(),
      rank: MINOR_RANK,
    },
    {
      title: 'Marketplace: archetypes',
      url: routeForMarketplaceProjectArchetypesList(),
      rank: MINOR_RANK,
    },
    {
      title: 'Marketplace: suppliers',
      url: routeForMarketplaceSuppliersList(),
      rank: MINOR_RANK,
    },
    {
      title: 'Marketplace: projects',
      url: routeForMarketplaceProjectsList(),
      rank: MINOR_RANK,
    },
    {
      title: 'Marketplace: developers',
      url: routeForMarketplaceDevelopersList(),
      rank: MINOR_RANK,
    },
    {
      title: 'Marketplace: offerings',
      url: routeForMarketplaceProjectOfferingsList(),
      rank: MINOR_RANK,
    },
    {
      title: 'Marketplace: documents',
      url: routeForMarketplaceDocumentsList(),
      rank: MINOR_RANK,
    },
    {
      title: 'Marketplace: EAC price estimates',
      url: routeForMarketplacePriceEstimatesList(),
      rank: MINOR_RANK,
    },
    {
      title: 'Integrations',
      url: routeForIntegrationsIndex(),
      rank: MINOR_RANK,
    },
    {
      title: 'Calculation tags',
      url: routeForCalculationTags(),
      rank: MINOR_RANK,
    },
    {
      title: 'Report answers',
      url: routeForReportQuestionAnswers(),
      rank: MINOR_RANK,
    },
    {
      title: 'Emissions models',
      url: routeForEmissionsModels(),
      rank: MINOR_RANK + 1, // above calculation method versions since "model" is more often this than a "version"
      childItems:
        context.nonOrgSpecificQuickSwitcherData?.emissionsModelsStable.edges.map(
          (emissionsModel) => ({
            title: emissionsModel.node.title,
            url: routeForEmissionsModel(emissionsModel.node.id),
            rank: MINOR_RANK,
            additionalSearchTerms: [emissionsModel.node.id],
          })
        ),
      subMenuTitle: 'an emissions model',
    },
    {
      title: 'Measurement test suites (MTS)',
      url: routeForMeasurementTests(),
      rank: MINOR_RANK,
      childItems:
        context.nonOrgSpecificQuickSwitcherData?.measurementTestSuites.map(
          (testSuite) => ({
            title: testSuite.title,
            url: routeForMeasurementTestSuite(testSuite.id),
            rank: MINOR_RANK,
            additionalSearchTerms: [testSuite.id],
          })
        ),
      subMenuTitle: 'a measurement test suite',
    },
    {
      title: 'Calculation method versions (CMV)',
      url: routeForEmissionsModelReleases(),
      rank: MINOR_RANK,
      additionalSearchTerms: ['emissions model releases'],
      childItems:
        context.nonOrgSpecificQuickSwitcherData?.emissionsModelReleases.map(
          (emRelease) => ({
            title: `${emRelease.businessActivityTypeName} / ${emRelease.displayName}`,
            url: routeForEmissionsModelRelease(emRelease.id),
            rank: MINOR_RANK,
            additionalSearchTerms: [emRelease.id],
          })
        ),
      subMenuTitle: 'a calculation method version',
    },
    {
      title: 'Methodology explorer',
      url: routeForMethodologyExplorer(),
      rank: MINOR_RANK,
    },
    {
      title: 'Methodology user-visible descriptions',
      url: routeForDescriptions(),
      rank: MINOR_RANK,
    },
    {
      title: 'Workflows (aka Background jobs)',
      url: BACKGROUND_JOBS_ROUTE,
      rank: MINOR_RANK,
    },
    {
      title: 'Query playground',
      url: urlForQueryPlayground(),
      rank: MINOR_RANK,
    },
    {
      title: 'Footprint debug viewer',
      url: routeForFootprintDebugViewer(),
      rank: MINOR_RANK,
    },
    {
      title: 'Lineage viewer',
      url: routeForLineageViewer(),
      rank: MINOR_RANK,
    },
    {
      title: 'FormuLake (bulk Dust executor)',
      url: routeForFormuLake(),
      rank: MINOR_RANK,
    },
    {
      title: 'Object viewer',
      url: (searchTerm) => routeForObjectViewer(searchTerm),
      rank: MINOR_RANK,
      // This one's special! It matches any db prefix so that you can paste an object id like
      // eme_a7sdfh37s and it'll take you to the object viewer when you hit enter.
      matchesSearchTerm: (input) => {
        // We need both a prefix and an id after the prefix to actually check if it's a valid db prefix
        if (!/[a-z]+_\w+/.test(input)) {
          return false;
        }
        const prefix = input.split('_', 1)[0];
        return PREFIX_SET.has(prefix);
      },
    },
    {
      title: 'Icons',
      url: routeForIconsInStorybook(),
      rank: MINOR_RANK,
    },
    {
      title: 'System Model (go/architecture)',
      url: routeForSystemModelArchitecture(),
      rank: MINOR_RANK,
    },
    {
      title: 'Companies',
      url: routeForCompanies(),
      rank: MINOR_RANK,
    },
    {
      title: 'Licensed CDP answers',
      url: routeForLicensedCdpAnswers(),
      rank: MINOR_RANK,
    },
    {
      title: 'Report question mapping',
      url: routeForReportQuestionMapping(),
      rank: MINOR_RANK,
    },
    {
      title: 'TSchema',
      url: routeForTSchemaPlatform(),
      rank: MINOR_RANK,
    },
    {
      title: 'CDP report identifier mapping',
      url: routeForCdpIdMapping(),
      rank: MINOR_RANK,
    },
    {
      title: 'Feature flags',
      url: routeForFeatureFlags(),
      rank: MINOR_RANK,
    },
    {
      title: 'Canonical schemas',
      url: routeForCanonicalSchemas(),
      rank: MINOR_RANK,
    },
    {
      title: 'Customer target schemas (CTS)',
      url: routeForCustomerTargetSchemas(),
      rank: MINOR_RANK,
    },
    {
      title: 'Duck hunt',
      url: routeForDuckHunt(),
      rank: -MAJOR_RANK,
    },
    {
      title: 'Parquet Viewer',
      url: routeForParquetViewer(),
      rank: MINOR_RANK,
    },
    {
      title: 'Reference Data',
      url: routeForReferenceData(),
      rank: MINOR_RANK,
      childItems: refDataEntries.map(({ id, name, orgId }) => ({
        title: name,
        url: routeForReferenceDataSource(id),
        additionalSearchTerms: [id],
        rank: orgIdRanked(orgId, context),
      })),
      subMenuTitle: 'reference data named',
    },
    {
      title: 'Reference data citations',
      url: routeForReferenceDataCitations(),
      rank: MINOR_RANK,
    },
    {
      title: 'Business activity types',
      url: routeForBusinessActivityTypes(),
      rank: MINOR_RANK,
    },
    {
      title: 'BAT schemas',
      url: routeForBusinessActivityTypes(),
      rank: MINOR_RANK,
      additionalSearchTerms: ['BART', 'BART schemas'],
    },
    {
      title: 'Business activity types (not yet on TSchema)',
      url: routeForLegacyBusinessActivityTypes(),
      rank: MINOR_RANK,
      additionalSearchTerms: ['BAT schemas', 'BART', 'BART schemas'],
    },
    {
      title: 'Report answer verifier failures',
      url: routeForReportAnswerVerifier(),
      rank: MINOR_RANK,
    },
    {
      title: 'Report footprint health checks',
      url: routeForReportHealthChecks(),
      rank: MINOR_RANK,
    },
    {
      title: 'Report configs',
      url: routeForReportConfigs(),
      rank: MINOR_RANK,
      subMenuTitle: 'a report config',
      childItems: context.nonOrgSpecificQuickSwitcherData?.reportConfigs.edges
        .map((e) => {
          if (!e?.node) {
            return null;
          }
          const { id, reportType, shortName, longName, description } = e.node;
          return {
            title: shortName,
            url: routeForReportConfigObject(e.node.id),
            rank: MINOR_RANK,
            additionalSearchTerms: [id, longName, description, reportType],
          };
        })
        .filter(isNotNullish),
    },
    {
      title: 'TCFD risk',
      url: routeForTcfdRisks(),
      rank: MINOR_RANK,
    },
    {
      title: 'TCFD opportunities',
      url: routeForTcfdOpportunities(),
      rank: MINOR_RANK,
    },
    {
      title: 'Gmail tools',
      url: routeForGmailTools(),
      rank: MINOR_RANK,
    },
    {
      title: 'Design system (Storybook)',
      url: routeForStorybook(),
      rank: MINOR_RANK,
      newTab: true,
    },
    {
      title: 'Canonical datasets and datasources',
      url: routeForCanonicalDatasets(),
      rank: MINOR_RANK,
    },
    {
      title: 'Sent emails',
      url: routeForSentEmails(),
      rank: MINOR_RANK,
    },
    {
      title: 'GraphQL Explorer',
      url: '/graphql',
      rank: MINOR_RANK,
    },
    {
      title: 'Default Tags',
      url: routeForGlobalFootprintTagEditor(),
      rank: MINOR_RANK,
    },
    {
      title: 'Unit converter',
      url: routeForUnitConverter(),
      rank: MINOR_RANK,
    },
    {
      title: 'Canonical projects',
      url: routeForCanonicalProjects(),
      rank: MINOR_RANK,
    },
    {
      title: 'Engagement tasks',
      url: routeForEngagementTasks(),
      rank: MINOR_RANK,
    },
    {
      title: 'Cloud file storage',
      url: routeForCloudFileStorage(),
      rank: MINOR_RANK,
    },
    ...flatten(
      context.orgs.map((org) => {
        const orgPrefix = `Org: ${formatOrgName(org)}`;
        const items: Array<Item> = [
          {
            title: `${orgPrefix}`,
            url: urlForObject('Organization', org.id),
            // rank this slightly above the org-specific items
            rank: orgRanked(org, context) + 1,
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / footprint`,
            url: urlForObject('OrganizationFootprints', org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
            childItems: async (getOrgSpecificData) => {
              const result = await getOrgSpecificData(org.id);
              const adts = flattenConnection(result?.data?.activityDataTables);
              return adts.map((adt) => ({
                title: stripOrgFromAdtName(adt.name),
                url: urlForActivityDataTable(org.id, adt.id),
                rank: orgRanked(org, context),
                additionalSearchTerms: [adt.id, org.id],
              }));
            },
          },
          {
            title: `${orgPrefix} / data review`,
            url: urlForObject('OrganizationDataReview', org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / footprint tags`,
            url: urlForObject('OrganizationFootprints', org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
            childItems: async (getOrgSpecificData) => {
              const result = await getOrgSpecificData(org.id);
              const tags = result?.data?.footprintTagsForOrg || [];
              return tags.map((tag) => ({
                title: tag.tagName,
                url: routeForFootprintTaggingEditor(org.id, tag.id),
                rank: orgRanked(org, context),
                additionalSearchTerms: [tag.id, org.id],
              }));
            },
            subMenuTitle: 'a tag',
          },
          {
            title: `${orgPrefix} / create footprint tag`,
            url: urlForObject('OrganizationCreateFootprintTag', org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / datasets`,
            url: urlForObject('OrganizationDatasets', org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / data issues`,
            url: urlForObject('OrganizationDataIssues', org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / users`,
            url: urlForObject('OrganizationUsers', org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / files`,
            url: urlForObject('OrganizationFiles', org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / feature flags`,
            url: urlForObject('OrganizationFlags', org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / finance`,
            url: routeForFinance(org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / emails`,
            url: urlForObject('OrganizationEmails', org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / mappings`,
            url: urlForObject('OrganizationMappings', org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / reports`,
            url: urlForObject('OrganizationReports', org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / purchases`,
            url: routeForMarketplaceIndex({ orgId: org.id }),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / query playground`,
            url: urlForQueryPlayground({ orgId: org.id }),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / reduction plans`,
            url: urlForObject('OrganizationPlans', org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / company change requests`,
            url: urlForObject('OrganizationCompanyChangeRequests', org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / supply chain`,
            url: routeForSupplyChainCharts(org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / org-specific methodology data`,
            url: routeForOrgSpecificMethodologyData(org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / lifecycle assessment`,
            url: routeForLifecycleAssessmentsForOrgId(org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / material variants`,
            url: routeForMaterialVariantsForOrgId(org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
          },
          {
            title: `${orgPrefix} / go to task`,
            url: urlForObject('OrganizationDatasets', org.id),
            rank: orgRanked(org, context),
            childItems: async (getOrgSpecificData) => {
              const result = await getOrgSpecificData(org.id);
              const tasks = result?.data?.userUploadTasksForOrg || [];
              return tasks.map((task) => ({
                title: `${task.datasource.name} in ${task.measurementProject.name} (login as ${context.activeWatershedEmployee.name})`,
                url: routeForUserUploadTaskInProduct(
                  org.id,
                  task.measurementProject.id,
                  task.datasource.id
                ),
                rank: orgRanked(org, context),
                additionalSearchTerms: [task.id, org.id],
              }));
            },
          },
        ];

        // show Login As Myself for orgs that the authenticated user has access to
        if (accessibleOrgIds.includes(org.id)) {
          items.push({
            title: `${orgPrefix} / login as ${context.activeWatershedEmployee.name}`,
            url: adminLoginAsMyselfUrl(org.id),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
            newTab: true,
          });
        }
        // show Login As for orgs that have any users, if they don't already have Login As Myself access
        if (!accessibleOrgIds.includes(org.id) && org.hasUsers) {
          items.push({
            title: `${orgPrefix} / login as other user`,
            url: adminLoginAsUrl({ orgId: org.id }),
            rank: orgRanked(org, context),
            additionalSearchTerms: [org.id],
            newTab: true,
          });
        }
        return items;
      })
    ),
  ];
  return items;
}

function stripOrgFromAdtName(adtName: string): string {
  // More complicated because adtName.split(".", 2) does something different
  // than literally every other programming language I'm aware of
  const idx = adtName.indexOf('.');
  if (idx !== -1) {
    return adtName.substring(idx + 1);
  }
  return adtName;
}
