/*
Moving forward, namespace attributes like so:

pyjob. - for background jobs
user. - for user specific data
org. - for org specific data
app. - for general app specific data
bart. - for bart related data
measurement. - for measurement related data

and feel free to add new namespaces where needed.
*/

export const CustomSpanAttributes = {
  ACTIVITY_DATA_EXTRACTOR_ID: 'activityDataExtractorId',
  ACTIVITY_DATA_TABLE_ID: 'activityDataTableId',
  ADE_WARNING_COUNT: 'ade.warningCount',
  API_KEY_ID: 'api_key_id',
  BART_ACTIVITY_TYPE_NAME: 'bart.activityTypeName',
  BART_ACTIVITY_TYPE_ID: 'bart.activityTypeId',
  BART_ACTIVITY_TYPE_SCHEMA_VERSION_TARGET:
    'bart.activityTypeSchemaVersionTarget',
  BART_ACTIVITY_TYPE_VERSION_ID: 'bart.activityTypeVersionId',
  BART_ACTIVITY_TYPE_VERSION_NAME: 'bart.activityTypeVersionName',
  BART_INSTANCE_ID: 'bartInstanceId',
  BART_STABLE_ID: 'bart.stableId',
  BART_STANDARDIZATION_INPUT_RECORD_COUNT:
    'bart.standardizationInputRecordCount',
  BART_STANDARDIZATION_OUTPUT_RECORD_COUNT:
    'bart.standardizationOutputRecordCount',
  BART_STANDARDIZATION_SUMMED_PER_STANDARDIZER_MS:
    'bart.standardizationSummedPerStandardizerMs',
  BART_UPDATER_RUN_REASON: 'bart.updaterRunReason',
  BART_TSCHEMA_MIGRATION_PARENT: 'bart.tSchemaMigrationParent',
  BROWSER_PATH: 'browser.path',
  BROWSER_RESOURCE_TIMING: 'browser.resourceTiming',
  BROWSER_WEB_VITAL_METRIC: 'browser.webVitalMetric',
  BROWSER_CLIENT_METRIC: 'browser.clientMetric',
  BROWSER_CLIENT_METRIC_NAME: 'browser.clientMetric.name',
  BROWSER_CLIENT_METRIC_DURATION: 'browser.clientMetric.duration_ms',
  BROWSER_URL: 'browser.url',
  BROWSER_USER_VISIBLE_ERROR_CODE: 'browser.error.code',
  BROWSER_USER_VISIBLE_ERROR_PAGE: 'browser.error.page',
  BROWSER_USER_VISIBLE_ERROR_SNACKBAR: 'browser.error.snackbar',
  BROWSER_USER_VISIBLE_ERROR_CALLOUT: 'browser.error.callout',
  CLEAN_POWER_ALLOCATION_INSTRUCTIONS: 'cleanPowerAllocation.instructions',
  CREATE_FOOTPRINT_SNAPSHOT_PRODUCT_PIPELINE_COUNT:
    'createFpsFromProduct.pipelineCount',
  CREATE_FOOTPRINT_SNAPSHOT_PRODUCT_INPUT_ROW_COUNT:
    'createFpsFromProduct.inputRowCount',
  CTS_VERSION_ID: 'ctsVersionId',
  CTS_TRANSFORM_TARGET: 'ctsTransformTarget',
  DEVELOPER_NAME: 'developer.name',
  EMBED_ID: 'embedId',
  EME_ADDENDUM_DEBUG_GS_URL: 'emissionsModelEvaluation.addendumDebugGsUrl',
  EME_BART_ID: 'emissionsModelEvaluation.evaluatedBartId',
  EME_EXEC_STEP: 'emissionsModelEvaluation.execution.step',
  EME_EXEC_IS_SINGLE_ADE_CANONICAL_SPAN:
    'emissionsModelEvaluation.execution.isSingleAdeExecutionSpan',
  EME_EXEC_ORG_IS_DEMO: 'emissionsModelEvaluation.execution.orgIsDemoOrTest',
  EME_EXEC_RELEASE_IDS: 'emissionsModelEvaluation.execution.releaseIds',
  EME_EXEC_IS_MTS: 'emissionsModelEvaluation.execution.isMts',
  EME_EXEC_STEP_SIZE_DENOM:
    'emissionsModelEvaluation.execution.step.sizeDenominator',
  EME_EXEC_BART_SIZE: 'emissionsModelEvaluation.execution.bartSize',
  EME_ROW_COUNT: 'emissionsModelEvaluation.rowCount',
  EME_UNIT_GROUP: 'emissionsModelEvaluation.unitGroup',
  EME_REF_ACCESS_FMD_ID: 'emissionsModelEvaluation.referenceAccess.outputFmdId',
  EME_REF_ACCESS_FINGERPRINT_COUNT:
    'emissionsModelEvaluation.referenceAccess.fingerprintCount',
  EME_REF_ACCESS_RECORD_COUNT:
    'emissionsModelEvaluation.referenceAccess.referenceAccessRecordCount',
  EME_REF_ACCESS_SKIPPED: 'emissionsModelEvaluation.referenceAccess.skipped',
  EME_REF_ACCESS_SKIPPED_REASON:
    'emissionsModelEvaluation.referenceAccess.skipped.reason',
  EME_TRACE_LEGACY_TRANSFORM: 'emissionsModelEvaluation.trace.legacyTransform',
  EME_TRACE_OUTPUT_FMD_ID: 'emissionsModelEvaluation.trace.outputFmdId',
  EME_TRACE_FINGERPRINT_COUNT:
    'emissionsModelEvaluation.trace.fingerprintCount',
  EME_TRACE_FINGERPRINT: 'emissionsModelEvaluation.trace.fingerprint',
  EME_TRACE_MISSING_CITATION:
    'emissionsModelEvaluation.trace.missingRefValueCitation',
  EME_TRACE_MISSING_REF_VALUE_DESCRIPTION:
    'emissionsModelEvaluation.trace.missingRefValueDescription',
  EME_TRACE_MISSING_BART_META: 'emissionsModelEvaluation.trace.missingBartMeta',
  EME_TRACE_EMISSIONS_REFERENCE_COUNT:
    'emissionsModelEvaluation.trace.referenceCount',
  EME_TRACE_EMISSIONS_VARIABLE_COUNT:
    'emissionsModelEvaluation.trace.emissionsVariableCount',
  EME_TRACE_RESULT_VARIABLE_COUNT:
    'emissionsModelEvaluation.trace.resultVariableCount',
  EME_TRACE_INPUT_FILE_SIZE: 'emissionsModelEvaluation.trace.inputFileSize',
  ERROR_MESSAGE: 'errorMessage',
  EXT_BART_ROW_COUNT: 'extBart.rowCount',
  FAB_OUTPUT_ROW_COUNT: 'fab.outputRowCount',
  FPGEN_INPUT_ROW_COUNT: 'fpgen.inputRowCount',
  FPGEN_EXT_BART_COUNT: 'fpgen.extBartCount',
  FPGEN_DATASET_COUNT: 'fpgen.datasetCount',
  FPGEN_SUCCESS: 'fpgen.success',
  FPS_ROW_COUNT: 'fps.rowCount',
  PLRS_ROW_COUNT: 'fpgen.plrsRowCount',
  FP_DETAIL_FILTER_COUNT: 'fpDetail.filterCount',
  FP_DETAIL_FILTER_FIELDS: 'fpDetail.filterFields',
  MEAS_RESOURCE_EVENT_KIND: 'measurementResourceEvent.kind',
  SYW_INCOMPLETE_MARTA_RESULT: 'syw.incompleteMartaResult',
  EMISSIONS_MODEL_EVALUATION_ID: 'emissionsModelEvaluationId',
  EMISSIONS_MODEL_VERSION_ID: 'emissionsModelVersionId',
  EXTRACTOR_KIND: 'extractorKind',
  FILE_BUCKET_NAME: 'file.bucketName',
  FILE_METADATA_ID: 'fileMetadataId',
  FILE_TRANSFER_SOURCE_BUCKET_NAME: 'fileTransfer.sourceBucketName',
  FILE_TRANSFER_DESTINATION_BUCKET_NAME: 'fileTransfer.destinationBucketName',
  FULLSTORY_SESSION_ID: 'fullstorySession.id',
  FULLSTORY_SESSION_URL: 'fullstorySession.url',
  HTTP_DECODED_BODY_SIZE: 'http.decodedBodySize',
  HTTP_DURATION_MS: 'duration_ms',
  HTTP_ENCODED_BODY_SIZE: 'http.encodedBodySize',
  HTTP_HOST: 'http.host',
  HTTP_STATUS_CODE: 'http.status_code',
  HTTP_URL: 'http.url',
  HTTP_ACCEPT_LANGUAGE: 'http.acceptLanguage',
  LOGIN_AS_USER_ID: 'loginAsUser.id',
  LOGIN_AS_USER_NAME: 'loginAsUser.name',
  NEXT_JS_PATHNAME: 'nextjs.pathname',
  ORG_ID: 'org.id',
  ORG_NAME: 'org.name',
  ORG_REGION: 'org.region',
  ORG_IS_DEMO: 'org.isDemo',
  ORG_IS_TEST: 'org.isTest',
  ORG_IS_DEMO_OR_TEST: 'org.isDemoOrTest',
  ORG_FEATURE_FLAGS_REPORT_QUESTION_PERMISSIONS:
    'org.featureFlags.reportQuestionPermissions',
  PARITY_STATUS: 'parity.status',
  PARITY_MESSAGE: 'parity.message',
  PYJOB_DURATION_S: 'pyjob.duration_s',
  PYJOB_ERROR: 'pyjob.error',
  PYJOB_ID: 'pyjob.id',
  PYJOB_KIND: 'pyjob.kind',
  PYJOB_STATE: 'pyjob.state',
  PYJOB_WAIT_DURATION_DELTA_S: 'pyjob.wait_duration_delta_s',
  PYJOB_WAIT_S: 'pyjob.wait_s',
  RAW_OUTPUT_ROW_COUNT: 'rawOutput.rowCount',
  RAW_OUTPUT_PARQUET_FILE_SIZE: 'rawOutput.parquetFileSize',
  REFERENCE_DATA_SLUG: 'referenceData.slug',
  REFERENCE_DATA_STANDARDIZATION_INPUT_RECORD_COUNT:
    'referenceData.standardizationInputRecordCount',
  REFERENCE_DATA_STANDARDIZATION_OUTPUT_RECORD_COUNT:
    'referenceData.standardizationOutputRecordCount',
  REFERENCE_DATA_STANDARDIZATION_SUMMED_PER_STANDARDIZER_MS:
    'referenceData.standardizationSummedPerStandardizerMs',
  REFERENCE_DATA_REVISION_ID: 'referenceData.revisionId',
  REFERENCE_DATA_REVISION_IDS: 'referenceData.revisionIds',
  REFERENCE_DATA_SLUG_COUNT: 'referenceData.distinctSlugCount', // number of distinct slugs
  REQUEST_ID: 'request_id',
  ROW_COUNT: 'rowCount',
  SERVICE_REGION: 'service.region',
  SUPPLIERS_COUNT: 'supplyChain.suppliersCount',
  SUPPLIERS_COUNT_BUCKET: 'supplyChain.suppliersCountBucket',
  SUPPLIERS_CACHE_HIT: 'supplyChain.cache.status',
  SUPPLIERS_CALCULATION_TAG: 'supplyChain.calculation.tag',
  SUPPLY_CHAIN_CACHE_V2: 'supplyChain.cacheV2.isEnabled',
  TRANSFORMED_OUTPUT_ROW_COUNT: 'transformedOutput.rowCount',
  TEMPORAL_WORKFLOW_ID: 'temporal.workflowId',
  USER_EMAIL: 'user.email',
  USER_ID: 'user.id',
  USER_IS_CUSTOMER: 'user.isCustomer',
  USER_IS_PLAYWRIGHT: 'user.isPlaywright',
  USER_PLAYWRIGHT_TYPE: 'user.playwrightType',
  USER_NAME: 'user.name',
  USER_LOCALE: 'user.locale',
  USER_LOCALIZATION_ENV: 'user.localizationEnv',
  USER_UPLOAD_TASK_ID: 'userUploadTaskId',
  USER_PERMISSIONS_REPORT_QUESTION_ONLY: 'user.permissions.reportQuestionOnly',
  VALUE_MAPPING_CONFIG_ID: 'valueMapping.configId',
  VALUE_MAPPING_MERGE_RECORDS_INPUT_FMD:
    'valueMapping.mergeRecordsAndCreateFileInputFmdId',
  VALUE_MAPPING_MERGE_RECORDS_OUTPUT_FMD:
    'valueMapping.mergeRecordsAndCreateFileOutputFmdId',
  WEB_VITALS_TTFB: 'webVitals.timeToFirstByte',
  WEB_VITALS_CLS: 'webVitals.cumulativeLayoutShift',
  WEB_VITALS_FCP: 'webVitals.firstContentfulPaint',
  WEB_VITALS_FID: 'webVitals.firstInputDelay',
  WEB_VITALS_LCP: 'webVitals.largestContentfulPaint',
  WEB_VITALS_INP: 'webVitals.interactionToNextPaint',
  WEB_VITALS_MP: 'webVitals.meaningfulPaint',
  WEB_VITALS_ADDITIONAL_PROPERTIES_FETCHED_ROW_COUNT:
    'webVitals.fetchedRowCount',
  WEB_VITALS_BLUR_DURING_LOAD: 'webVitals.userLeftPageDuringLoad',
  WEB_VITALS_PAGE_NAME: 'webVitals.pageName',
  ZONE_ID: 'zone_id',
  ZONE_IS_TEST: 'zone_is_test',
  DUCKDB_SQL: 'duckdbSql',
  OWNER: 'owner',
  INPUTS: 'inputs', // For logging the inputs received by whatever the span represents.
  TRANSFORM_VERSION_ID: 'transforms.transformVersionId',
  TRANSFORM_RUN_ID: 'transforms.transformRunId',
  // The REL_* attributes are used in span links to describe how the linked span
  // relates to the subject span.
  REL_PARENT: 'parent',
  REL_CHILD: 'child',
  LINEAGE_JOB_SLUG: 'lineage.jobSlug',
  LINEAGE_JOB_RUN_ID: 'lineage.jobRunId',
} as const;

export type CustomSpanAttribute =
  (typeof CustomSpanAttributes)[keyof typeof CustomSpanAttributes];
