import { i18n } from '@watershed/intl';
import {
  CurrencyCode,
  CURRENCY_MAP,
  CurrencySymbol,
  CurrencyOption,
} from '@watershed/constants/currency';
import { getObjectKeys } from '../getObjectKeys';
import assertKeyOf from './assertKeyOf';
import {
  DEFAULT_LOCALE,
  PSEUDO_LOCALE,
  SupportedLocale,
} from '@watershed/intl/constants';
import { pseudoLocalize } from '@watershed/intl/utils';
import memoize from 'lodash/memoize';

const getIntlDisplayNames = memoize(
  (locale: SupportedLocale) =>
    new Intl.DisplayNames([locale], { type: 'currency' })
);

export const getCurrencyName = memoize(
  (currencyCode: CurrencyCode, locale: SupportedLocale) => {
    if (locale === PSEUDO_LOCALE) {
      return pseudoLocalize(
        getIntlDisplayNames(DEFAULT_LOCALE).of(currencyCode) ?? ''
      );
    }

    return getIntlDisplayNames(locale).of(currencyCode) ?? '';
  },
  (currencyCode, locale) => `${currencyCode}-${locale}`
);

export function getCurrencies(
  locale: SupportedLocale = i18n.locale as SupportedLocale
): Array<{
  id: CurrencyCode;
  name: string; // long name e.g. "US Dollar (USD)"
}> {
  return Object.values(CURRENCY_MAP).map(({ code }) => {
    const name = getCurrencyName(code, locale);
    return {
      id: code,
      name: `${name} (${code})`,
    };
  });
}

export function currencyFromCode(
  currency?: CurrencyCode | string | null,
  locale: SupportedLocale = i18n.locale as SupportedLocale
): CurrencyOption {
  const currencyCode = currency ?? 'USD';
  assertIsValidCurrencyCode(currencyCode);

  const name = getCurrencyName(currencyCode, locale);
  return {
    ...CURRENCY_MAP[currencyCode],
    name,
    longName: `${name} (${currencyCode})`,
  };
}

// This function takes in a currency code or string ('USD' or 'GBP'),
// and returns the symbol ('$' for example).
export function currencySymbolFromCode(
  currency?: CurrencyCode | string | null
): CurrencySymbol {
  if (!currency) {
    return CURRENCY_MAP.USD.symbol;
  }
  assertIsValidCurrencyCode(currency);
  return CURRENCY_MAP[currency].symbol;
}

export function optionalCurrencySymbolFromCode(
  currency?: CurrencyCode | null
): CurrencySymbol {
  if (!currency) {
    return '';
  }
  assertIsValidCurrencyCode(currency);
  return CURRENCY_MAP[currency].symbol;
}

export function isValidCurrencyCode(
  currency?: CurrencyCode | string | null
): currency is CurrencyCode {
  return !!currency && CURRENCY_MAP.hasOwnProperty(currency);
}

export function convertToCurrencyCode(
  str: string | undefined
): CurrencyCode | null {
  if (isValidCurrencyCode(str)) return str;
  return null;
}

export function assertIsValidCurrencyCode(
  currency: CurrencyCode | string
): asserts currency is keyof typeof CURRENCY_MAP {
  assertKeyOf(currency, CURRENCY_MAP);
}

export function getCurrencyForOrg({
  currency,
}: {
  currency: string | null;
}): CurrencyCode {
  const code = currency ?? 'USD';
  assertIsValidCurrencyCode(code);
  return code;
}

export function allCurrencyCodes(): Array<CurrencyCode> {
  return getObjectKeys(CURRENCY_MAP);
}
